import React, { useState } from "react";
import { CurrencyConverter, ReportGenerator } from "../../components/classes";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { ReportHeader } from "./components";
import { Button, Stack } from "@mui/material";
import { DateRangeControl } from "../../components/dates";
import { SelectControl } from "../../components/form";
import { DateTime } from "luxon";
import { toCurrency } from "../../utils";

const UnpaidBalanceReport = () => {
    const { t } = useTranslation();
    const [params, setParams] = useSearchParams();
    const navigate = useNavigate();

    const [report, setReport] = useState(null);
    const { loading, fetch } = useApi();

    const typeOptions = [
        {
            value: "checkin",
            label: t("checkin"),
        },
        {
            value: "checkout",
            label: t("checkout"),
        },
        {
            value: "stayover",
            label: t("stayover"),
        },
        {
            value: "dateCreated",
            label: t("date_created"),
        },
    ];

    const columns = [
        {
            id: "client",
            header: t("client"),
            displayValue: (row) => {
                const { firstName, lastName } = row?.clientsData?.[0] || {};
                return `${firstName || ""} ${lastName || ""}`;
            },
        },
        {
            id: "checkin",
            header: t("checkin"),
            displayValue: (row) => row.checkin,
        },
        {
            id: "checkout",
            header: t("checkout"),
            displayValue: (row) => row.checkout,
        },
        {
            id: "numberOfNights",
            header: t("nr_of_nights"),
            displayValue: (row) => {
                const checkin = DateTime.fromISO(row.checkin);
                const checkout = DateTime.fromISO(row.checkout);
                const difference = checkout.diff(checkin, "days").days;
                return difference;
            },
        },
        {
            id: "totalAmount",
            header: t("total_amount"),
            displayValue: (row) => toCurrency(row?.totalAmount || 0, row?.currency),
        },
        {
            id: "unpaidAmount",
            header: t("unpaid_amount"),
            displayValue: (row) => toCurrency(row?.unpaid || 0, row?.currency),
        },
        {
            id: "createdOn",
            header: t("created_on"),
            displayValue: (row) => DateTime.fromISO(row._id?.split("reservations_")?.[1])?.toFormat("yyyy LLL dd"),
        },

        {
            id: "status",
            header: t("status"),
            displayValue: (row) => row.status,
        },
        {
            id: "refrer",
            header: t("referer"),
            displayValue: (row) => {
                if (!row?.referer) return "-";
                if (!!row.refererData?.firstName) {
                    return `${row.refererData?.firstName} ${row.refererData?.lastName || ""}`;
                }
                return row.referer;
            },
        },
        {
            id: "_id",
            header: "",
            displayValue: () => "",

            cell: ({ row: { original } }) => {
                return (
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            navigate(`/reservations/${original?._id?.split("reservations_")[1]}`);
                        }}
                        variant="outlined"
                    >
                        {t("view")}
                    </Button>
                );
            },
        },
    ];
    const loadData = async () => {
        if (!(params.get("startDate") && params.get("endDate"))) return;
        const start = params.get("startDate");
        const end = params.get("endDate");
        const type = params.get("type");

        let filtersText = "";
        if (!!start) filtersText += t("start_date") + ": " + start;
        if (!!end) filtersText += ", " + t("end_date") + ": " + end;
        if (!!type) filtersText += ", " + t("type") + ": " + type;

        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "unpaidReservations",
                    data: {
                        startDate: start,
                        endDate: end,
                        filterBy: type,
                    },
                    responseData: `_id checkin checkout checkinTime checkoutTime status totalPrice invoiceCurrency 
                        clientsData{firstName lastName} referer refererData{firstName lastName}
                        payments{amount paymentMethod paymentMethodData{name fiscalMethod method} userName timestamp note}
                        invoices{
                            invoiceCurrency 
                            _id 
                            clientsData{firstName lastName}  
                            totalPrice 
                            status 
                            payments{amount paymentMethod paymentMethodData{name fiscalMethod method} userName timestamp note}
                        }
                        orders{
                            _id 
                            customerData{firstName lastName} 
                            payments{paymentStatus paymentMethod cashReceived} 
                            totalPrice 
                            number
                            status
                            pospointData{defaultCurrency}
                            products{
                                productId 
                                price 
                                quantity 
                                productData{
                                    taxData{taxId isIncluded}
                                }
                            }
                            
                        }
                        `,
                },
                {
                    endpoint: "settingsgeneral",
                    responseData: "_id currency currencyData{currency currencyname rate} ",
                    data: { _id: "settings_general" },
                },
                {
                    endpoint: "currencies",
                    responseData: "_id currency rate",
                },
            ],
        });

        const currencyConvert = new CurrencyConverter({
            currencies: response?.currencies,
            settings: response?.settingsgeneral,
        });

        if (!response) return;
        const reportData = response?.unpaidReservations;
        reportData?.forEach((reservation) => {
            reservation.currency =
                reservation.invoiceCurrency || response.settingsgeneral?.currencyData?.currency || "ALL";
            let totalAmount = 0.0;
            let paid = 0.0;
            let unpaid = 0.0;
            const activeInvoices = reservation?.invoices?.filter((invoice) => {
                return !["CANCELLED", "TRANSFERRED"].includes(invoice?.status);
            });
            const orders = reservation?.orders?.filter(
                (order) => !["CANCELLED", "TRANSFERRED"].includes(order?.status)
            );
            if (reservation?.invoices?.length > 0) {
                activeInvoices?.forEach((invoice) => {
                    totalAmount += currencyConvert?.convertAmount({
                        amount: invoice.totalPrice,
                        from: invoice.invoiceCurrency,
                        to: reservation?.currency,
                    });

                    paid +=
                        invoice?.payments?.reduce(
                            (acc, payment) =>
                                acc +
                                currencyConvert?.convertAmount({
                                    amount: payment.amount,
                                    from: invoice.invoiceCurrency,
                                    to: reservation?.currency,
                                }),
                            0.0
                        ) || 0.0;
                });
            } else {
                totalAmount += reservation?.status === "CANCELLED" ? 0.0 : reservation?.totalPrice || 0.0;
                paid +=
                    reservation?.status === "CANCELLED"
                        ? 0.0
                        : reservation?.payments?.reduce((acc, payment) => acc + payment.amount, 0.0) || 0.0;
            }
            orders?.forEach((order) => {
                totalAmount += currencyConvert?.convertAmount({
                    amount: order.totalPrice,
                    from: order?.pospointData?.defaultCurrency,
                    to: reservation?.currency,
                });
                if (
                    !!order?.payments?.cashReceived &&
                    ["paid", "partly_paid"].includes(order?.payments?.paymentStatus)
                ) {
                    paid += currencyConvert?.convertAmount({
                        amount: order?.payments?.cashReceived,
                        from: order?.pospointData?.defaultCurrency,
                        to: reservation?.currency,
                    });
                }
            });
            unpaid = totalAmount - paid;
            if (unpaid < 0) unpaid = 0.0;
            reservation.totalAmount = isNaN(totalAmount) ? 0.0 : totalAmount;
            reservation.paid = isNaN(paid) ? 0.0 : paid;
            reservation.unpaid = isNaN(unpaid) ? 0.0 : unpaid;
        });

        const newRaportGenerator = new ReportGenerator({
            data: reportData,
            columns,
            displayTotal: false,
        });
        setReport(newRaportGenerator.generateTable({ title: t("unpaid_balance"), headerInfo: [filtersText] }));
    };

    const getAllParams = () => {
        const data = {};
        if (!!params.get("startDate")) data.startDate = params.get("startDate");
        if (!!params.get("endDate")) data.endDate = params.get("endDate");
        if (!!params.get("type")) data.type = params.get("type");

        return data;
    };

    return (
        <div>
            <ReportHeader
                title={t("unpaid_balance")}
                loading={loading}
                onReportRun={() => {
                    loadData();
                }}
                disableRun={!(params.get("startDate") && params.get("endDate") && params.get("type"))}
            >
                <Stack direction="row" alignItems="center" gap={2}>
                    <DateRangeControl
                        startLabel={t("start_date")}
                        endLabel={t("end_date")}
                        values={{ startDate: params.get("startDate"), endDate: params.get("endDate") }}
                        onChange={({ startDate, endDate }) =>
                            setParams({
                                ...getAllParams(),
                                startDate: startDate || "",
                                endDate: endDate || "",
                            })
                        }
                    />
                    <SelectControl
                        label={t("type")}
                        options={typeOptions}
                        value={params.get("type")}
                        onChange={(e) =>
                            setParams({
                                ...getAllParams(),
                                type: e.target.value,
                            })
                        }
                        sx={{ width: "400px" }}
                    />
                </Stack>
            </ReportHeader>
            {report}
        </div>
    );
};

export default UnpaidBalanceReport;
