export default {
    dashboard: {
        label: "dashboard",
        rights: ["view"],
    },
    calendar: {
        label: "calendar",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    reservations: {
        label: "reservations",
        rights: ["list", "create", "edit", "view", "delete", "price_change", "cancel"],
    },
    clients: {
        label: "clients",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    groups: {
        label: "groups",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    /* events: {
        label: "Events",
        rights: ["list", "view", "create", "edit", "delete"],
    }, */
    housekeeping: {
        label: "housekeeping",
        rights: ["list", "view", "view_all", "edit"],
    },
    lostfounds: {
        label: "lost_and_found",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    roomtypes: {
        label: "room_types",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    rooms: {
        label: "rooms",
        rights: ["list", "create", "edit", "view", "delete"],
    },
    roomrates: {
        label: "room_rates",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    availability: {
        label: "rate_pricing",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    facilities: {
        label: "facilities",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    tags: {
        label: "tags",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    bedtypes: {
        label: "bedtypes",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    bookingsources: {
        label: "bookingsources",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    guestcategories: {
        label: "guestcategories",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    discounts: {
        label: "discounts",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    services: {
        label: "services",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    pos: {
        label: "pos_dashboard",
        rights: ["view"],
    },
    products: {
        label: "products",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    productcategories: {
        label: "productcategories",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    productmaincategories: {
        label: "main_categories",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    warehouses: {
        label: "warehouses",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    inventories: {
        label: "inventories",
        rights: ["list", "create", "edit", "view", "delete", "adjust"],
    },
    pospoints: {
        label: "pospoints",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    sell: {
        label: "sales_register",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    orders: {
        label: "orders",
        rights: [
            "list",
            "view",
            "create",
            "edit",
            "void",
            "delete",
            "correct",
            "reprint",
            "live_print",
            "change_price_in_sell",
        ],
    },
    posdiscounts: {
        label: "posdiscounts",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    suppliers: {
        label: "suppliers",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    my_shift: {
        label: "my_shift",
        rights: ["list", "view", "change_payment", "change_order_user"],
    },
    posplaces: {
        label: "posplaces",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    invoices: {
        label: "invoices",
        rights: ["list", "view", "create", "edit", "delete", "correct"],
    },
    expenses: {
        label: "expenses",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    bills: {
        label: "bills",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    estimates: {
        label: "estimates",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    transactions: {
        label: "transactions",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    taxes: {
        label: "taxes",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    billcategories: {
        label: "billcategories",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    accounts: {
        label: "chart_of_accounts",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    payments: {
        label: "payments",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    paymentaccounts: {
        label: "paymentaccounts",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    paymentmethods: {
        label: "payment_methods",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    cashdesks: {
        label: "cashdesks",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    nightaudit: {
        label: "nightaudit",
        rights: ["list", "view", "create", "edit"],
    },
    settings: {
        label: "settings",
        rights: ["list", "view", "create", "edit"],
    },
    property: {
        label: "property_settings",
        rights: ["list", "view", "create", "edit"],
    },
    subscription: {
        label: "subscription",
        rights: ["list", "view", "create", "edit"],
    },
    currencies: {
        label: "currencies",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    users: {
        label: "users",
        rights: ["list", "create", "edit", "view", "delete", "waiter_login"],
    },
    roles: {
        label: "roles",
        rights: ["list", "view", "create", "edit", "delete"],
    },
    reports: {
        label: "reports",
        rights: ["list", "view"],
    },
    generalmanagerreport: {
        label: "general_manager_report",
        rights: ["view"],
    },
    forecastreport: {
        label: "forecast_report",
        rights: ["view"],
    },
    roomtypesalesreport: {
        label: "roomtype_sales_report",
        rights: ["view"],
    },
    mealsreport: {
        label: "meals_report",
        rights: ["view"],
    },
    paymentsreport: {
        label: "payments_report",
        rights: ["view"],
    },
    usersalesreport: {
        label: "user_sales_report",
        rights: ["view"],
    },
    day_overview: {
        label: "day_overview",
        rights: ["view"],
    },
    logs_report: {
        label: "logs_report",
        rights: ["view"],
    },
    productsreport: {
        label: "products_report",
        rights: ["view"],
    },
    inventoryreport: {
        label: "inventory_report",
        rights: ["view"],
    },
    productsinventoryreport: {
        label: "products_inventory_report_now",
        rights: ["view"],
    },
    guestsreport: {
        label: "guests_report",
        rights: ["view"],
    },
    unpaidbalancereport: {
        label: "unpaid_balance_report",
        rights: ["view"],
    },
};
