import { Card, Grid, Tab, Tabs } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Modal } from "../../components/modal";
import { useTranslation } from "react-i18next";

import { useSnackbar } from "notistack";

import { NotesView, SupplierProfileCard } from "../../components/common";

const SupplierProfile = () => {
    const [supplierData, setSupplierData] = useState({});
    const [activeTab, setActiveTab] = useState(0);

    const navigate = useNavigate();
    const { loading, fetch } = useApi();
    const params = useParams();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { addRecord } = useOutletContext() || {};

    useEffect(() => {
        loadSupplierData();
    }, []);

    const loadSupplierData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "supplier",
            data: {
                _id: "suppliers_" + params.id,
            },
            responseData: `_id IDNumber name firstName lastName email phone addressLine state city postCode state website
                country description  notes{text timestamp}`,
        });
        if (response?.supplier?._id) setSupplierData(response.supplier);
    };

    const addNote = (text) => {
        const noteDetails = {
            text,
            timestamp: new Date().toJSON(),
        };
        setSupplierData({ ...supplierData, notes: [...(supplierData.notes || []), noteDetails] });
        fetch({
            operation: "mutation",
            endpoint: "addSupplierNote",
            data: { _id: "suppliers_" + params.id, noteText: noteDetails.text },
            responseData: "text timestamp",
        }).then((respone) => {
            if (respone?.addSupplierNote?.timestamp) return;
            removeNote(noteDetails.timestamp);
            enqueueSnackbar(t("note_not_saved"), { variant: "error" });
        });
    };

    const removeNote = (noteTimestamp) => {
        setSupplierData({
            ...supplierData,
            notes: supplierData?.notes?.filter((note) => note.timestamp !== noteTimestamp),
        });
        fetch({
            operation: "mutation",
            endpoint: "removeSupplierNote",
            data: { _id: "suppliers_" + params.id, noteTimestamp },
        }).then((respone) => {
            if (respone?.removeSupplierNote === "success") return;
            enqueueSnackbar(t("note_remove_failed"), { variant: "error" });
        });
    };
    const closeHandler = () => {
        if (addRecord) {
            addRecord(supplierData);
        }
        navigate(-1);
    };

    return (
        <Modal
            titlelabel={t("suppliers_form")}
            loading={loading}
            onClose={() => closeHandler()}
            open
            fullScreen
            yesText="none"
            noText="none"
        >
            <Grid container spacing={4} alignItems="start">
                <Grid item xs={12} sm={4}>
                    <SupplierProfileCard supplierData={supplierData} />
                </Grid>
                <Grid container item xs={12} sm={8} spacing={4}>
                    <Grid item xs={12}>
                        <Card>
                            <Tabs variant="fullWidth" value={activeTab} onChange={(e, value) => setActiveTab(value)}>
                                <Tab iconPosition="start" icon={<InfoOutlinedIcon />} label={t("details")} />
                                <Tab iconPosition="start" icon={<NotesOutlinedIcon />} label={t("notes")} />
                            </Tabs>
                        </Card>
                    </Grid>
                    {activeTab === 0 ? (
                        <>
                            {/* <Grid item xs={12}>
                                <BalanceOverviewTable />
                            </Grid>
                            <Grid item xs={12}>
                                <TransactionsTable data={[{}, {}]} />
                            </Grid> */}
                            <Grid item xs={12}>
                                {/* <ReservationsTable data={clientData?.reservations || []} currency={currency} /> */}
                            </Grid>
                            <Grid item xs={12}>
                                {/* <InvoicesTable data={invoices} /> */}
                            </Grid>

                            {/* <Grid item xs={12}>
                                <OrdersTable data={[{}, {}]} />
                            </Grid>  */}
                        </>
                    ) : (
                        <Grid item xs={12}>
                            <NotesView addNote={addNote} removeNote={removeNote} notes={supplierData.notes || []} />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Modal>
    );
};

export default SupplierProfile;
