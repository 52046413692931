import { Grid, LinearProgress, Paper, Typography, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, InputControl } from "../../../components/form";
import { useApi } from "../../../components/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { SubmitButton } from "../general/components";

const rules = {
    connectionstringname: "string|required",
    username: "string|required",
    password: "string|required",
};

const SettingsBilancForm = () => {
    const [values, setValues] = useState({});

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "settingsbilanc",
            data: {
                _id: "settings_bilanc",
            },
            responseData: "_id connectionstringname apiKey",
        });
        if (response?.settingsbilanc)
            setValues({
                ...response.settingsbilanc,
            });
    };

    const saveHandler = async () => {
        const { _id, connectionstringname, apiKey } = values;
        const data = {
            connectionstringname,
            apiKey,
        };
        if (_id) {
            data._id = _id;
        }

        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateSettingsBilanc" : "createSettingsBilanc",
            data,
            responseData: "_id",
        });
        if (response?.createSettingsBilanc?._id || response?.updateSettingsBilanc?._id)
            enqueueSnackbar(t("settings_bilanc_saved"), { variant: "default" });
    };

    return (
        <Form values={values} onValuesChange={setValues} rules={rules} onSubmit={saveHandler}>
            {loading && <LinearProgress color="primary" />}
            <Paper style={{ minHeight: "calc(100vh - 120px)", padding: "20px" }}>
                <Typography variant="h6">{t("bilanc_settings")}</Typography>
                <Divider />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                        <InputControl name="connectionstringname" label={t("connectionstringname")} />
                        <InputControl name="apiKey" label={t("api_key")} />
                    </Grid>

                    <Grid container item xs={12} justifyContent="end" style={{ marginTop: 20 }}>
                        <SubmitButton
                            onClick={saveHandler}
                            permission={{ settings: values?._id ? { edit: true } : { create: true } }}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Form>
    );
};

export default SettingsBilancForm;
