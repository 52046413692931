import { Button, Card, CardContent, Grid, Stack, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ViewTimelineOutlinedIcon from "@mui/icons-material/ViewTimelineOutlined";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Modal } from "../../components/modal";
import { useTranslation } from "react-i18next";
import {
    BalanceOverviewTable,
    ClientTimeline,
    InvoicesTable,
    OrdersTable,
    ReservationsTable,
    TransactionsTable,
} from "./components";
import { useSnackbar } from "notistack";
import ClientProfileCard from "../../components/common/ClientProfileCard";
import { NotesView } from "../../components/common";
import { DateRangeControl } from "../../components/dates";
import { DateTime } from "luxon";
import TagsChip from "../../components/common/TagsChip";
import { CurrencyConverter } from "../../components/classes";
import { toCurrency } from "../../utils";

const ClientProfile = () => {
    const [clientData, setClientData] = useState({});
    const [recordByDates, setRecordByDates] = useState({ reservations: [], invoices: [], referedReservations: [] });
    const [activeTab, setActiveTab] = useState(0);
    const [activeView, setActiveView] = useState("reservations");
    const [currencyConvert, setCurrencyConvert] = useState(new CurrencyConverter({}));
    const [settings, setSettings] = useState({});
    const [dates, setDates] = useState({
        startDate: DateTime.now().minus({ months: 6 }).toFormat("yyyy-LL-dd"),
        endDate: DateTime.now().plus({ months: 6 }).toFormat("yyyy-LL-dd"),
    });

    const navigate = useNavigate();
    const { loading, fetch } = useApi();
    const params = useParams();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

    useEffect(() => {
        loadClientData();
    }, []);
    useEffect(() => {
        loadExtraData();
    }, []);
    const { addRecord, onClientEdited } = useOutletContext() || {};

    const loadClientData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "client",
            data: {
                _id: "clients_" + params?.clientId,
            },
            responseData: `_id firstName clientType lastName email phone addressLine state city birthdate nationality country placeOfBirth
                IDType IDNumber notes{text timestamp} logs{timestamp title userId} 
                tagsData{_id name description color}
                reservations{
                    _id 
                    checkin 
                    checkout 
                    clientsData{firstName lastName} 
                    totalPrice 
                    rooms{roomId roomtypeId roomData{name} roomtypeData{name _id}}
                    status
                    guests{roomId guests{name number price extra}}
                }
                referedReservations{
                    _id 
                    checkin 
                    checkout 
                    clientsData{firstName lastName} 
                    totalPrice 
                    rooms{roomId roomtypeId roomData{name} roomtypeData{name _id}}
                    status
                    guests{roomId guests{name number price extra}}
                    invoiceCurrency payments{amount paymentMethod userName timestamp}
                    invoices{invoiceCurrency payments{amount paymentMethod userName timestamp}}
                }
                invoices{_id date clientsData{firstName lastName} totalPrice status invoiceCurrency paymentStatus payments{amount paymentMethod userName timestamp}}
                `,
        });
        if (response?.client?._id) setClientData(response.client);
    };
    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "settingsgeneral",
                    responseData: "_id currency currencyData{currency currencyname rate}",
                    data: { _id: "settings_general" },
                },
                {
                    endpoint: "currencies",
                    responseData: "_id currency rate",
                },
            ],
        });

        if (response?.settingsgeneral) setSettings(response.settingsgeneral);

        setCurrencyConvert(
            new CurrencyConverter({
                currencies: response?.currencies,
                settings: response?.settingsgeneral,
            })
        );
    };
    const { currency = "" } = settings?.currencyData || {};

    const invoices = useMemo(() => {
        return clientData?.invoices || [];
    }, [clientData?.invoices]);

    const addNote = (text) => {
        const noteDetails = {
            text,
            timestamp: new Date().toJSON(),
        };
        setClientData({ ...clientData, notes: [...(clientData.notes || []), noteDetails] });
        fetch({
            operation: "mutation",
            endpoint: "addClientNote",
            data: { _id: "clients_" + params.clientId, noteText: noteDetails.text },
            responseData: "text timestamp",
        }).then((respone) => {
            if (respone?.addClientNote?.timestamp) return;
            removeNote(noteDetails.timestamp);
            enqueueSnackbar(t("note_not_saved"), { variant: "error" });
        });
    };

    const removeNote = (noteTimestamp) => {
        setClientData({ ...clientData, notes: clientData?.notes?.filter((note) => note.timestamp !== noteTimestamp) });
        fetch({
            operation: "mutation",
            endpoint: "removeClientNote",
            data: { _id: "clients_" + params.clientId, noteTimestamp },
        }).then((respone) => {
            if (respone?.removeClientNote === "success") return;
            enqueueSnackbar(t("note_remove_failed"), { variant: "error" });
        });
    };
    const totalGuestsByCategories = (reservations) => {
        if (!reservations) return "";

        const categoryTotals = reservations
            .flatMap((reservation) => reservation.guests)
            .flatMap((room) => room.guests)
            .reduce((acc, category) => {
                const existingCategory = acc.find((c) => c.name === category.name);
                if (!existingCategory) {
                    acc.push({ name: category.name, number: category.number });
                } else {
                    existingCategory.number += category.number;
                }
                return acc;
            }, []);

        return categoryTotals.map((category) => `${category.number} ${category.name}`).join(" , ");
    };
    const totalRoomTypesByCategories = (reservations) => {
        if (!reservations) return "";

        const roomtypeTotals = reservations
            .flatMap((reservation) => reservation.rooms)
            .map((room) => room?.roomtypeData)
            .reduce((acc, roomtype) => {
                const existingRoomtype = acc.find((rt) => rt._id === roomtype?._id);
                if (existingRoomtype) {
                    existingRoomtype.number += 1;
                } else {
                    acc.push({ ...roomtype, number: 1 });
                }
                return acc;
            }, []);

        return roomtypeTotals.map((roomtype) => `${roomtype.number} ${roomtype.name}`).join(" , ");
    };

    const calculateReservationsData = useMemo(() => {
        let totalNumberOfNights = 0;
        const filteredReservations = recordByDates.reservations?.filter(
            (res) => !["CANCELLED", "NO_SHOW"]?.includes(res?.status)
        );
        const totalGuests = totalGuestsByCategories(filteredReservations);
        const totalRooms = totalRoomTypesByCategories(filteredReservations);

        filteredReservations?.forEach((res) => {
            totalNumberOfNights += DateTime.fromISO(res.checkout)
                .diff(DateTime.fromISO(res.checkin), "days")
                .toObject().days;
        });

        return {
            totalGuests,
            totalNumberOfNights,
            totalRooms,
        };
    }, [recordByDates.reservations]);

    const calculateReferalReservationsData = useMemo(() => {
        let totalNumberOfNights = 0;
        const filteredReferedReservations = recordByDates.referedReservations?.filter(
            (res) => !["CANCELLED", "NO_SHOW"]?.includes(res?.status)
        );
        const totalGuests = totalGuestsByCategories(filteredReferedReservations);
        const totalRooms = totalRoomTypesByCategories(filteredReferedReservations);

        filteredReferedReservations?.forEach((res) => {
            totalNumberOfNights += DateTime.fromISO(res.checkout)
                .diff(DateTime.fromISO(res.checkin), "days")
                .toObject().days;
        });

        return {
            totalGuests,
            totalNumberOfNights,
            totalRooms,
        };
    }, [recordByDates.referedReservations]);

    const amountsInfo = useMemo(() => {
        let totalPrice = 0.0;
        let paid = 0.0;
        let unpaid = 0.0;

        recordByDates?.invoices?.forEach((invoice) => {
            totalPrice += currencyConvert?.convertAmount({
                amount: invoice.totalPrice,
                from: invoice.invoiceCurrency,
                to: currency,
            });
            paid +=
                invoice?.payments?.reduce(
                    (acc, payment) =>
                        acc +
                        currencyConvert?.convertAmount({
                            amount: payment.amount,
                            from: invoice.invoiceCurrency,
                            to: currency,
                        }),
                    0.0
                ) || 0.0;
        });

        const parsedPaidAmount = parseFloat(parseFloat(paid).toFixed(2));
        const parsedTotalAmount = parseFloat(parseFloat(totalPrice).toFixed(2));

        unpaid = totalPrice - paid;
        const parsedUnpaidAmount = parseFloat(parseFloat(unpaid).toFixed(2));
        if (unpaid < 0) unpaid = 0.0;
        return {
            totalPrice: isNaN(parsedTotalAmount) ? 0.0 : parsedTotalAmount,
            paid: isNaN(parsedPaidAmount) ? 0.0 : parsedPaidAmount,
            unpaid: isNaN(parsedUnpaidAmount) ? 0.0 : parsedUnpaidAmount,
        };
    }, [recordByDates.invoices, currencyConvert]);

    const renderView = () => {
        switch (activeView) {
            case "reservations":
                return (
                    <>
                        <Card variant="outlined" style={{ marginTop: 20 }}>
                            <CardContent>
                                <Grid container xs={12}>
                                    <Grid item xs={6} sm={3}>
                                        <DetailBoxBig
                                            color="#F8F9FD"
                                            title={t("total")}
                                            bigText={
                                                recordByDates?.reservations?.filter(
                                                    (res) => !["CANCELLED", "NO_SHOW"]?.includes(res?.status)
                                                )?.length
                                            }
                                            secondary={t("reservations")}
                                            margin={false}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <DetailBoxBig
                                            color="#F8F9FD"
                                            title={t("total")}
                                            bigText={calculateReservationsData?.totalRooms || 0}
                                            secondary={t("rooms")}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <DetailBoxBig
                                            color="#F8F9FD"
                                            title={t("total")}
                                            bigText={calculateReservationsData?.totalGuests || 0}
                                            secondary={t("guests")}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <DetailBoxBig
                                            color="#F8F9FD"
                                            title={t("total")}
                                            bigText={calculateReservationsData.totalNumberOfNights}
                                            secondary={t("nights")}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <ReservationsTable data={recordByDates?.reservations || []} currency={currency} />
                    </>
                );

            case "referedReservations":
                return (
                    <>
                        <Card variant="outlined" style={{ marginTop: 20 }}>
                            <CardContent>
                                <Grid container xs={12}>
                                    <Grid item xs={6} sm={3}>
                                        <DetailBoxBig
                                            color="#F8F9FD"
                                            title={t("total")}
                                            bigText={
                                                recordByDates?.referedReservations?.filter(
                                                    (res) => !["CANCELLED", "NO_SHOW"]?.includes(res?.status)
                                                )?.length
                                            }
                                            secondary={t("reservations")}
                                            margin={false}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <DetailBoxBig
                                            color="#F8F9FD"
                                            title={t("total")}
                                            bigText={calculateReferalReservationsData?.totalRooms || 0}
                                            secondary={t("rooms")}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <DetailBoxBig
                                            color="#F8F9FD"
                                            title={t("total")}
                                            bigText={calculateReferalReservationsData?.totalGuests || 0}
                                            secondary={t("guests")}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <DetailBoxBig
                                            color="#F8F9FD"
                                            title={t("total")}
                                            bigText={calculateReferalReservationsData.totalNumberOfNights}
                                            secondary={t("nights")}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <ReservationsTable
                            showPaymentColumn={true}
                            data={recordByDates?.referedReservations || []}
                            currency={currency}
                        />
                    </>
                );
            case "invoices":
                return (
                    <>
                        <Card variant="outlined" style={{ marginTop: 20 }}>
                            <CardContent>
                                <Grid container xs={12}>
                                    <Grid item xs={6} sm={3}>
                                        <DetailBoxBig
                                            color="#F8F9FD"
                                            title={t("total")}
                                            bigText={toCurrency(amountsInfo?.totalPrice, currency)}
                                            secondary={t("revenue")}
                                            margin={false}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <DetailBoxBig
                                            color="#F8F9FD"
                                            title={t("total")}
                                            bigText={toCurrency(amountsInfo.paid, currency)}
                                            secondary={t("paid")}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <DetailBoxBig
                                            color="#F8F9FD"
                                            title={t("total")}
                                            bigText={toCurrency(amountsInfo?.unpaid, currency)}
                                            secondary={t("unpaid")}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <InvoicesTable data={recordByDates?.invoices} />
                    </>
                );
            case "orders":
                return "balance";
        }
    };
    const filterRecords = () => {
        const reservations = clientData?.reservations?.filter((res) => {
            const dateCreated = res?._id?.split("reservations_")[1];
            if (
                DateTime.fromISO(dateCreated) >= DateTime.fromISO(dates?.startDate + "T00:00:00.000Z") &&
                DateTime.fromISO(dateCreated) <= DateTime.fromISO(dates?.endDate + "T23:59:59.999Z")
            )
                return true;
        });
        const referedReservations = clientData?.referedReservations?.filter((res) => {
            const dateCreated = res?._id?.split("reservations_")[1];
            if (
                DateTime.fromISO(dateCreated) >= DateTime.fromISO(dates?.startDate + "T00:00:00.000Z") &&
                DateTime.fromISO(dateCreated) <= DateTime.fromISO(dates?.endDate + "T23:59:59.999Z")
            )
                return true;
        });
        const filteredInvoices = invoices?.filter((inv) => {
            const dateCreated = inv?._id?.split("invoices_")[1];
            if (
                DateTime.fromISO(dateCreated) >= DateTime.fromISO(dates?.startDate + "T00:00:00.000Z") &&
                DateTime.fromISO(dateCreated) <= DateTime.fromISO(dates?.endDate + "T23:59:59.999Z")
            )
                return true;
        });

        setRecordByDates({
            ...recordByDates,
            invoices: filteredInvoices,
            reservations: reservations,
            referedReservations: referedReservations,
        });
    };
    const closeHandler = () => {
        if (addRecord) {
            addRecord(clientData);
        }
        navigate(-1);
    };
    return (
        <Modal
            titlelabel={t("clients_form")}
            loading={loading}
            onClose={() => closeHandler()}
            open
            fullScreen
            yesText="none"
            noText="none"
        >
            <Grid container spacing={4} alignItems="start">
                <Grid item xs={12} sm={4}>
                    <ClientProfileCard clientData={clientData} editProfileUrl={`edit`} />
                </Grid>
                <Grid container item xs={12} sm={8} spacing={4}>
                    <Grid item xs={12}>
                        <Card>
                            <Tabs variant="fullWidth" value={activeTab} onChange={(e, value) => setActiveTab(value)}>
                                <Tab iconPosition="start" icon={<InfoOutlinedIcon />} label={t("details")} />
                                <Tab iconPosition="start" icon={<NotesOutlinedIcon />} label={t("notes")} />
                                <Tab iconPosition="start" icon={<ViewTimelineOutlinedIcon />} label={t("timeline")} />
                            </Tabs>
                        </Card>
                    </Grid>
                    {activeTab === 0 ? (
                        <>
                            {/* <Grid item xs={12}>
                                <BalanceOverviewTable />
                            </Grid>
                            <Grid item xs={12}>
                                <TransactionsTable data={[{}, {}]} />
                            </Grid> */}
                            <Grid item xs={12}>
                                <Stack direction="row" gap={2} marginBottom={2}>
                                    {clientData?.tagsData?.map((tag) => {
                                        return <TagsChip color={tag?.color} name={t(tag?.name)} />;
                                    })}
                                </Stack>

                                <Stack direction="row" alignItems="center" gap={2}>
                                    <DateRangeControl
                                        startLabel={t("start_date")}
                                        endLabel={t("end_date")}
                                        values={{
                                            startDate: dates?.startDate,
                                            endDate: dates?.endDate,
                                        }}
                                        onChange={({ startDate, endDate }) =>
                                            setDates({ ...dates, startDate: startDate, endDate: endDate })
                                        }
                                    />
                                    <Button variant="outlined" onClick={() => filterRecords()}>
                                        {t("search")}
                                    </Button>
                                </Stack>
                                <Tabs
                                    variant={isSmallScreen ? "scrollable" : "fullWidth"}
                                    value={activeView}
                                    onChange={(e, newValue) => setActiveView(newValue)}
                                >
                                    <Tab value={"reservations"} label={t("reservations")} />
                                    <Tab value={"referedReservations"} label={t("referals")} />
                                    <Tab value={"invoices"} label={t("invoices")} />
                                    <Tab value={"overview"} label={t("overview")} />
                                </Tabs>

                                {renderView()}
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}></Grid>

                            {/* <Grid item xs={12}>
                                <OrdersTable data={[{}, {}]} />
                            </Grid>  */}
                        </>
                    ) : activeTab === 1 ? (
                        <Grid item xs={12}>
                            <NotesView addNote={addNote} removeNote={removeNote} notes={clientData.notes || []} />
                        </Grid>
                    ) : (
                        <Grid container item xs={12} justifyContent="center">
                            <ClientTimeline actions={clientData.logs} />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Outlet
                context={{
                    onClientSave: (newClientData) => {
                        setClientData({ ...clientData, ...newClientData });
                        onClientEdited && onClientEdited(newClientData);
                    },
                }}
            />
        </Modal>
    );
};
const DetailBoxBig = ({ title, secondary, color, bigText, margin }) => {
    return (
        <Card style={{ backgroundColor: color, marginLeft: margin === false ? 0 : 20, border: "none" }}>
            <CardContent>
                <Typography variant="h6">{title}</Typography>
                <Typography variant="h5">{bigText}</Typography>
                <Typography variant="body1">{secondary}</Typography>
            </CardContent>
        </Card>
    );
};

export default ClientProfile;
