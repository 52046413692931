import {
    AppBar,
    Button,
    Card,
    CardActions,
    CardContent,
    IconButton,
    InputAdornment,
    LinearProgress,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, InputControl, SelectControl } from "../../components/form";
import { AccountCircle, Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { WaiterModal } from ".";
import { setPermission } from "../../utils/setPermission";
import { useSnackbar } from "notistack";
import { ORDER, POSPOINTS } from "../../utils/DataTypes";
import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "../../components/modal";
import { cleanOrdersCache } from "../../components/hooks/cache/cacheData";
import { BackgroundOfflineCacheSync } from "../../components/common";
import NetworkStatusBar from "../../components/common/NetworkStatusBar";

const rules = {
    username: "string|required",
    pin: "string|required",
};

const WaitersLogin = () => {
    const [hidePassword, setHidePassword] = useState(true);
    const [users, setUsers] = useState([]);
    const [credentials, setCredentials] = useState({});
    const [waiterShiftModal, setWaiterShiftModal] = useState({ open: false, user: {} });
    const [loginLoading, setLoginLoading] = useState(false);

    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const posId = "pospoints_" + searchParams.get("pos");
        localStorage.setItem("waiterLoginPOS", posId);
    }, [searchParams]);

    useEffect(() => {
        loadPosWaiters();
    }, []);

    const loadPosWaiters = async () => {
        const offlinePosAsString = localStorage.getItem("offlinePos") || "false";
        const offlinePos = Boolean(JSON.parse(offlinePosAsString));

        const hasOrdersCacheAsString = localStorage.getItem("hasOrdersCache") || "false";
        const hasOrdersCache = Boolean(JSON.parse(hasOrdersCacheAsString));

        const queryData = offlinePos
            ? {
                  operation: "query",
                  endpoint: "getWaitersOfflineData",
                  data: {
                      loadOrders: !hasOrdersCache,
                  },
                  responseData: `
                    waiters{
                        user{
                            _id 
                            username 
                            displayName 
                            language 
                            role{
                                homeRoute 
                                isWaiter 
                                hasShift
                                permissions
                                hasShift
                            } 
                            posPoints
                            email
                            pin
                        }
                        waiterRevenue{_id starting{timestamp amount{amount currency}} closing{timestamp amount{amount currency} paymentsAmount{amount currency}} lastClosed}
                    }
                    orders{${ORDER}}
                `,
              }
            : {
                  operation: "query",
                  endpoint: "getWaiters",
                  responseData: `username posPoints`,
              };
        const response = await fetch(queryData);
        if (response?.getWaiters) {
            setUsers(response.getWaiters);
        }
        if (response?.getWaitersOfflineData?.waiters) {
            setUsers(
                response?.getWaitersOfflineData?.waiters?.map((doc) => {
                    return doc?.user;
                })
            );
            if (!hasOrdersCache) {
                localStorage.setItem("hasOrdersCache", JSON.stringify(true));
            }
        }
    };

    const handleLogin = async () => {
        setLoginLoading(true);
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "waiterLogin",
                    responseData: `hasOpenShift user{
                        _id 
                        username 
                        displayName 
                        language 
                        role{
                            homeRoute 
                            isWaiter 
                            hasShift
                            permissions
                            hasShift
                        } 
                        posPoints
                        email
                    }`,
                    data: { userName: credentials.username, pin: credentials.pin },
                },
                {
                    endpoint: "pospoints",
                    responseData: POSPOINTS,
                },
            ],
        });
        if (!response?.waiterLogin) {
            setLoginLoading(false);
            return;
        }
        const { role = {} } = response?.waiterLogin?.user || {};
        if (!!role.permissions) role.permissions = JSON.parse(role.permissions);
        response.waiterLogin.user.posOptions = response?.pospoints;

        /**
         * If user has active shift log set user permisssions and proceed to pos redirect
         */
        if (response?.waiterLogin?.hasOpenShift) {
            const token = localStorage.getItem("token");
            setPermission(token, response?.waiterLogin?.user);
            redirectWaiter(response.waiterLogin.user);
            return;
        }

        /**
         * Ask user to activate shift to proceed to log into system
         */
        setWaiterShiftModal({ open: true, user: response.waiterLogin.user, token: response?.waiterLogin?.token });
        return;
    };

    const redirectToPos = (posId, posOptions) => {
        setLoginLoading(false);
        const selectedPos = posOptions?.find((pos) => pos._id === posId) || {};
        if (!!selectedPos?.hasTables) {
            navigate(`/pos/tables?pos=${selectedPos?._id?.split("pospoints_")[1]}`);
            return;
        }
        navigate(`/pos/sell?pos=${selectedPos?._id?.split("pospoints_")[1]}`);
    };

    const redirectWaiter = (user) => {
        const { posOptions = [] } = user;
        redirectToPos("pospoints_" + searchParams.get("pos"), posOptions);
    };

    const startUserShift = async ({ user }) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "openShift",
            data: {
                userId: user._id,
            },
            responseData: `_id starting{timestamp amount{amount currency}} closing{timestamp amount{amount currency} paymentsAmount{amount currency}} lastClosed`,
        });
        if (response?.openShift?._id) {
            enqueueSnackbar(t("user_shift_started"), { variant: "success" });
            const token = localStorage.getItem("token");
            setPermission(token, user);
            redirectWaiter(user);
        }
    };

    const passwordFieldRef = useRef(null);

    const handleUserButtonClick = () => {
        if (passwordFieldRef.current) {
            passwordFieldRef.current.focus();
        }
    };

    return (
        <>
            <AppBar position="fixed" elevation={0} sx={{ backgroundColor: "white" }}>
                <Toolbar>
                    <ConfirmLogout />
                    <div style={{ marginLeft: "auto" }}>
                        <NetworkStatusBar />
                    </div>
                </Toolbar>
            </AppBar>
            <div style={{ marginTop: "70px" }}>{loginLoading && <LinearProgress color="primary" />}</div>
            <Stack justifyContent="center" alignItems="center" sx={{ minHeight: "100vh" }}>
                <Form
                    values={credentials}
                    onValuesChange={setCredentials}
                    rules={rules}
                    onSubmit={() => {
                        handleLogin();
                    }}
                >
                    <Card>
                        <CardContent>
                            <InputControl
                                size="medium"
                                name="username"
                                label={t("username")}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AccountCircle sx={{ color: "primary.main" }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <InputControl
                                size="medium"
                                name="pin"
                                label={t("pin")}
                                type={hidePassword ? "password" : "text"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setHidePassword(!hidePassword)} edge="end">
                                                {hidePassword ? (
                                                    <Visibility sx={{ color: "primary.main" }} />
                                                ) : (
                                                    <VisibilityOff sx={{ color: "primary.main" }} />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                inputRef={passwordFieldRef}
                            />
                        </CardContent>
                        <CardActions>
                            <Button fullWidth size="large" variant="contained" color="primary" type="submit">
                                {t("login")}
                            </Button>
                        </CardActions>
                    </Card>
                </Form>
            </Stack>
            <Stack sx={{ position: "absolute", width: 300, top: 70, right: 0, padding: "20px" }} gap={2}>
                {users
                    ?.filter((user) => {
                        if (user?.posPoints?.includes("pospoints_" + searchParams.get("pos"))) {
                            return true;
                        }
                        return false;
                    })
                    ?.map((user) => {
                        return (
                            <Button
                                onClick={() => {
                                    setCredentials({ username: user.username });
                                    handleUserButtonClick();
                                }}
                                variant="outlined"
                            >
                                {user.username}
                            </Button>
                        );
                    })}
            </Stack>
            <WaiterModal
                open={waiterShiftModal?.open}
                t={t}
                onClose={() => {
                    setWaiterShiftModal({ open: false });
                }}
                onSave={() => {
                    setWaiterShiftModal({ open: false });
                    startUserShift(waiterShiftModal);
                }}
            />
            <BackgroundOfflineCacheSync />
        </>
    );
};

export default WaitersLogin;

const ConfirmLogout = () => {
    const [openModal, setOpenModal] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const confirmLogout = () => {
        localStorage.setItem("hasOrdersCache", JSON.stringify(false));

        const offlinePosAsString = localStorage.getItem("offlinePos") || "false";
        const offlinePos = Boolean(JSON.parse(offlinePosAsString));
        if (offlinePos) {
            cleanOrdersCache();
        }

        navigate("/login");
    };
    return (
        <>
            <IconButton color="primary" onClick={() => setOpenModal(true)}>
                <CloseIcon />
            </IconButton>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                yesText={t("confirm")}
                onSave={() => {
                    setOpenModal(false);
                    confirmLogout();
                }}
                maxWidth="xs"
            >
                <Typography>{t("waiter_logout_message")}</Typography>
            </Modal>
        </>
    );
};
