import { Button, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReportGenerator } from "../../components/classes";
import { DateRangeControl, TimeControl } from "../../components/dates";
import { AutoCompleteControl } from "../../components/form";
import { useApi } from "../../components/hooks";
import { ReportHeader } from "./components";

const LogsReport = () => {
    const { t } = useTranslation();
    const [params, setParams] = useSearchParams();
    const [report, setReport] = useState(null);
    const [extraData, setExtraData] = useState({
        users: [],
    });

    const { loading, fetch } = useApi();
    const navigate = useNavigate();

    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "users",
                    responseData: "_id displayName status",
                },
            ],
        });
        if (response)
            setExtraData({
                users: response?.users
                    ?.filter((user) => user.status === "ACTIVE")
                    ?.map((user) => {
                        return {
                            value: user._id?.split("users_")[1],
                            label: user.displayName || user._id?.split("users_")[1],
                        };
                    }),
            });
    };

    const columns = [
        {
            id: "reservationId",
            header: t("uuid"),
            displayValue: (row) => {
                return row?.reservationData?.uuid || "";
            },
        },
        {
            id: "reservationName",
            header: t("reservation"),
            displayValue: (row) => {
                const { firstName, lastName } =
                    row?.reservationData?.clientsData?.[0] || row?.reservation?.clientsData?.[0] || {};
                return `${firstName || ""} ${lastName || ""}`;
            },
        },

        {
            id: "date",
            header: t("date_time"),
            displayValue: (row) => {
                return (
                    DateTime.fromISO(row?.reservationData?._id?.split("_")[1]).toFormat("dd LLL yyyy HH:mm:ss") || ""
                );
            },
        },

        {
            id: "user",
            header: t("user"),
            displayValue: (row) => row?.log?.userId?.split("users_")?.[1] || "",
        },
        {
            id: "action",
            header: t("action"),
            displayValue: (row) => {
                return row?.log?.updatedFields?.length > 0 ? t("value_change") : t("reservation_created");
            },
        },
        {
            id: "field",
            header: t("field"),
            displayValue: (row) => {
                return row?.log?.updatedFields?.map((field) => t(field?.field))?.join(",") || "";
            },
        },
        {
            id: "previousValue",
            header: t("previous_value"),
            displayValue: (row) => {
                return row?.log?.updatedFields?.map((field) => t(field?.values?.previous))?.join(",") || "";
            },
        },
        {
            id: "newValue",
            header: t("new_value"),
            displayValue: (row) => {
                return row?.log?.updatedFields?.map((field) => t(field?.values?.current))?.join(",") || "";
            },
        },
        {
            id: "res",
            header: "",
            cell: ({ row: { original } }) => {
                return (
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            navigate(`/reservations/${original?.reservationData?._id?.split("reservations_")[1]}`);
                        }}
                        variant="outlined"
                    >
                        {t("view")}
                    </Button>
                );
            },
            displayValue: () => {
                return null;
            },
        },
    ];

    const loadData = async () => {
        if (!(params.get("startDate") && params.get("endDate"))) return;
        const start = !!params.get("startTime")
            ? new Date(`${params.get("startDate")} ${params.get("startTime")}:00`)
            : new Date(`${params.get("startDate")} 00:00:00`);
        const end = !!params.get("endTime")
            ? new Date(`${params.get("endDate")} ${params.get("endTime")}:59`)
            : new Date(`${params.get("endDate")} 23:59:59`);
        const selectedUser = params.get("user");

        let filtersText = "";
        if (!!start) filtersText += t("start_date") + ": " + DateTime.fromISO(start.toJSON()).toFormat("yyyy-LL-dd");
        if (!!end) filtersText += ", " + t("end_date") + ": " + DateTime.fromISO(end.toJSON()).toFormat("yyyy-LL-dd");
        if (!!selectedUser) filtersText += ", " + t("user") + ": " + selectedUser;

        const response = await fetch({
            operation: "query",
            endpoint: "getRecordLogsByDates",
            data: { startDate: start?.toJSON(), endDate: end?.toJSON() },
            responseData: `
               logs{timestamp userId title updatedFields{field values{previous current}}}
               reservationData{_id uuid clientsData{firstName lastName}}
            `,
        });

        if (!response?.getRecordLogsByDates) return;
        const data = [];
        response?.getRecordLogsByDates?.forEach((logData) => {
            if (!logData?.reservationData) {
                return;
            }
            logData?.logs?.forEach((log) => {
                if (!!params?.get("user")) {
                    if (log?.userId !== "users_" + params.get("user")) {
                        return;
                    }
                }
                data?.push({ log: log, reservationData: logData?.reservationData });
            });
        });

        const newRaportGenerator = new ReportGenerator({
            data: data,
            columns,
            displayTotal: false,
        });
        setReport(newRaportGenerator.generateTable({ title: t("logs"), headerInfo: [filtersText], landscape: true }));
    };

    const getAllParams = () => {
        const data = {};
        if (!!params.get("startDate")) data.startDate = params.get("startDate");
        if (!!params.get("endDate")) data.endDate = params.get("endDate");
        if (!!params.get("startTime")) data.startTime = params.get("startTime");
        if (!!params.get("endTime")) data.endTime = params.get("endTime");
        if (!!params.get("user")) data.user = params.get("user");
        return data;
    };

    return (
        <div>
            <ReportHeader
                title={t("logs_report")}
                loading={loading}
                onReportRun={() => {
                    loadData();
                }}
            >
                <Stack direction="row" alignItems="center" gap={2}>
                    <DateRangeControl
                        startLabel={t("start_date")}
                        endLabel={t("end_date")}
                        values={{ startDate: params.get("startDate"), endDate: params.get("endDate") }}
                        onChange={({ startDate, endDate }) =>
                            setParams({
                                ...getAllParams(),
                                startDate: startDate || "",
                                endDate: endDate || "",
                            })
                        }
                    />
                    <TimeControl
                        value={params.get("startTime")}
                        onChange={(e) =>
                            setParams({
                                ...getAllParams(),
                                startTime: e.target.value || "",
                            })
                        }
                        margin="none"
                        fullWidth={false}
                        label={t("start_date_time")}
                    />
                    <TimeControl
                        value={params.get("endTime")}
                        onChange={(e) =>
                            setParams({
                                ...getAllParams(),
                                endTime: e.target.value || "",
                            })
                        }
                        margin="none"
                        fullWidth={false}
                        label={t("end_date_time")}
                    />
                    <AutoCompleteControl
                        options={extraData?.users}
                        value={params.get("user")}
                        onChange={(e) =>
                            setParams({
                                ...getAllParams(),
                                user: e.target.value || "",
                            })
                        }
                        margin="none"
                        sx={{ width: "200px" }}
                        label={t("user")}
                    />
                </Stack>
            </ReportHeader>

            {report}
        </div>
    );
};

export default LogsReport;
