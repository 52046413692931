import { Box, Button, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState } from "react";
import styles from "./EditableTable.module.css";
import { useTranslation } from "react-i18next";

const EditableTable = ({ columns = [], data = [], onAddRow, onDeleteRow, disabled }) => {
    const { t } = useTranslation();
    if (!!onDeleteRow) {
        columns.push({
            header: "",
            id: "DELETE_ROW",
            cell: ({ rowIndex }) => (
                <IconButton
                    disabled={disabled}
                    onClick={() => {
                        onDeleteRow && onDeleteRow(rowIndex);
                    }}
                >
                    <DeleteIcon color="primary" />
                </IconButton>
            ),
        });
    }
    const getColumnsHeaders = () => {
        return columns.map((column, i) => (
            <Box
                component="div"
                className={styles.th}
                style={{
                    width: column.id === "DELETE_ROW" ? "50px" : `calc(100% - 50px / ${columns.length - 1}`,
                    minWidth: column.id === "DELETE_ROW" ? "50px" : "150px",
                    flexGrow: 1,
                }}
                sx={{
                    borderRightColor: "table.borderColor",
                }}
            >
                {column.header}
            </Box>
        ));
    };
    return (
        <div
            className={styles.table}
            style={{
                width: "100%",
            }}
        >
            <div className={styles.tableContent}>
                <div className={styles.tableHead}>
                    <Box
                        component="div"
                        className={styles.tr}
                        sx={{
                            borderBottomColor: "table.headBorderColor",
                            borderTopColor: "table.headBorderColor",
                            backgroundColor: "table.backgroundColor",
                        }}
                    >
                        {getColumnsHeaders()}
                    </Box>
                </div>
                <div className={styles.tableBody}>
                    {data.map((row, rowIndex) => (
                        <Box
                            component="div"
                            className={styles.tr}
                            sx={{
                                backgroundColor: "table.rowColor",
                                borderBottomColor: "table.borderColor",
                                "&:hover": { backgroundColor: "rgba(0 125 252 / 5%)" },
                            }}
                        >
                            {columns.map((column, columnIndex) => (
                                <div
                                    className={styles.td}
                                    style={{
                                        width:
                                            column.id === "DELETE_ROW"
                                                ? "50px"
                                                : `calc(100% - 50px / ${columns.length - 1}`,
                                        minWidth: column.id === "DELETE_ROW" ? "50px" : "150px",
                                        flexGrow: 1,
                                    }}
                                >
                                    {column.cell
                                        ? column.cell({
                                              row,
                                              rowIndex,
                                              columnIndex,
                                              column,
                                          })
                                        : " "}
                                </div>
                            ))}
                        </Box>
                    ))}
                </div>
                {onAddRow && (
                    <Button
                        disabled={disabled}
                        onClick={() => {
                            onAddRow && onAddRow();
                        }}
                        fullWidth
                        startIcon={<AddIcon />}
                    >
                        {t("add_row")}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default EditableTable;
