import jsPDF from "jspdf";
import { DateTime } from "luxon";
import { calculateTotalTax, exchangeCurrency, getLoggedUserData, toCurrency } from "../../utils";
import qz from "qz-tray";
import QRCode from "qrcode";

const calculateTotalDiscount = (totalPrice = 0, discountType, discountValue) => {
    if (!discountType || !discountValue) return 0;
    const discountAmount =
        discountType === "total" ? parseFloat(discountValue) : totalPrice * parseFloat(discountValue) * 0.01;
    if (isNaN(discountAmount)) return 0;
    return discountAmount;
};

const recentProducts = (orderProducts = [], previosOrderProducts = []) => {
    const newProducts = [];
    const products = !!orderProducts ? JSON.parse(JSON.stringify(orderProducts)) : [];
    products?.forEach((product) => {
        const previousProductData = previosOrderProducts?.find((p) => p.productId === product.productId);
        if (!previousProductData) {
            newProducts.push(product);
            return;
        }
        if (product.quantity - previousProductData.quantity === 0) return;
        const recentCourses = [];
        product?.courses?.forEach((course) => {
            const previousCourse = previousProductData?.courses?.find((c) => c.courseId === course.courseId);
            if (!previousCourse) {
                recentCourses.push(course);
                return;
            }
            if (course.quantity - previousCourse.quantity === 0) return;
            recentCourses.push({ ...course, quantity: course.quantity - previousCourse.quantity });
        });
        newProducts.push({
            ...product,
            quantity: product.quantity - previousProductData.quantity,
            courses: !!product?.courses ? recentCourses : null,
        });
    });
    return newProducts;
};

export const print = async ({ settingsProperty, order, t, pospoint, previousOrder, currencies }) => {
    const { currency } = pospoint?.currencyData || {};
    const { tableName } = pospoint?.tables?.find((table) => table?._id === order?.tableId) || {};

    const { address = "", city = "", zip = "", country = "", name = "" } = settingsProperty || {};
    const { optionalName, optionalCity, optionalCountry, optionalAddress } = pospoint;
    const {
        products = [],
        payments = {},
        customerName,
        createdAt,
        createdBy,
        number,
        hideNoteOnReceipt,
        note,
        discountType,
        discountValue,
    } = order;
    const isFinalReceipt = !!payments?.paymentMethod;
    const paymentMethodName = pospoint?.activeMethodsData?.find(
        (method) => method?._id === payments?.paymentMethod
    )?.name;

    const totalPrice = isFinalReceipt ? order.totalPrice : order.totalPrice - (previousOrder?.totalPrice || 0);
    const subtotal = isFinalReceipt ? order.subtotal : order.subtotal - (previousOrder?.subtotal || 0);
    const tax = parseFloat(totalPrice) - parseFloat(subtotal);
    const productsToDisplay = !isFinalReceipt ? recentProducts(products, previousOrder?.products) : products;
    const discountAmount = calculateTotalDiscount(totalPrice, discountType, discountValue);

    const documentHeight = 50 * productsToDisplay.length + 120;
    const doc = new jsPDF("p", "mm", [80, documentHeight]);

    const headerDoc = [
        [
            {
                content: !!optionalName ? optionalName : name,
                styles: { halign: "center", fontSize: 15, fontStyle: "bold" },
            },
        ],
        [
            {
                content: `${!!optionalAddress ? optionalAddress : address}, ${
                    !!optionalCity ? optionalCity : city
                }, ${zip}, ${!!optionalCountry ? optionalCountry : country}`,
                styles: { halign: "center" },
            },
        ],
        [`${t("receipt_number")}: ${!!number ? parseInt(number).toString() : t("offline_generated")}`],
        [`${t("user")}: ${createdBy || ""}`],
        [`${t("customer")}: ${customerName}`],
    ];

    if (!isFinalReceipt)
        headerDoc.unshift([{ content: t("Order"), styles: { halign: "center", fontSize: 15, fontStyle: "bold" } }]);
    if (tableName)
        headerDoc.push([
            { content: `${pospoint?.tableCustomName || t("table")}: ${tableName}`, styles: { fontStyle: "bold" } },
        ]);

    doc.autoTable({
        theme: "plain",
        styles: { fontSize: 10, cellPadding: { top: 1 } },
        margin: { left: 5, top: 0, right: 10 },
        body: headerDoc,
    });

    doc.setDrawColor(0, 0, 0);
    doc.line(5, doc.autoTable.previous.finalY + 4, 75, doc.autoTable.previous.finalY + 4);

    doc.autoTable({
        theme: "plain",
        startY: doc.autoTable.previous.finalY + 3,
        styles: { fontSize: 10, cellPadding: { top: 2 } },
        margin: { left: 5, right: 10 },
        body: [
            ...productsToDisplay
                .map((product) => {
                    const cost = parseFloat(product.quantity) * parseFloat(product.price);
                    let priceContent = parseFloat(product.price) > 0 ? `x ${toCurrency(product.price, currency)}` : " ";
                    let costContent = parseFloat(product.price) > 0 ? `${toCurrency(cost, currency)}` : " ";
                    return [
                        [
                            {
                                content: product.name || product?.productData?.name,
                                colSpan: 2,
                                styles: { cellPadding: { top: 2, bottom: 0 } },
                            },
                        ],
                        [
                            { content: `${product.quantity} ${priceContent}`, colSpan: 1 },
                            {
                                content: costContent,
                                colSpan: 1,
                                styles: { halign: "right" },
                            },
                        ],
                    ];
                })
                .flat(),
        ],
    });

    doc.setDrawColor(0, 0, 0);
    doc.setLineDash([2, 1], 0);
    doc.line(5, doc.autoTable.previous.finalY + 4, 75, doc.autoTable.previous.finalY + 4);
    doc.line(5, doc.autoTable.previous.finalY + 5, 75, doc.autoTable.previous.finalY + 5);
    doc.setLineDash([]);

    // const defaultCurrencyTotal = parseFloat(totalPrice) / parseFloat(posCurrency.data.rate);
    // let defaultCurrencyTotalContent;
    // if (defaultCurrencyTotal !== totalPrice) {
    //     defaultCurrencyTotalContent =
    //         parseFloat(totalPrice).toFixed(2) +
    //         ` ${suffix} / ${parseFloat(posCurrency.data.rate).toFixed(2)} = ${defaultCurrencyTotal.toFixed(2)} ${
    //             defaultCurrency.data.currency
    //         }`;
    // }

    let date = DateTime.now().toFormat("yyyy-LL-dd HH:mm:ss");
    if (createdAt) date = DateTime.fromISO(createdAt).toFormat("yyyy-LL-dd HH:mm:ss");

    const body = [
        [{ content: `${t("items_count")}: ` + productsToDisplay.length, colSpan: 2 }],
        [{ content: `${t("subtotal")}: ` }, { content: toCurrency(subtotal, currency), styles: { halign: "right" } }],
        [{ content: `${t("tax")}: ` }, { content: toCurrency(tax, currency), styles: { halign: "right" } }],
    ];
    if (discountAmount > 0) {
        body.push([
            { content: `${t("discount")}: ` },
            { content: toCurrency(discountAmount, currency), styles: { halign: "right" } },
        ]);
    }
    body.push(
        [
            { content: `${t("total")}: `, styles: { fontSize: 15, fontStyle: "bold", cellPadding: { top: 4 } } },
            {
                content: toCurrency(totalPrice, currency),
                styles: { halign: "right", fontSize: 15, fontStyle: "bold", cellPadding: { top: 4 } },
            },
        ],
        ...(pospoint?.differentTotalCurrencies || [])?.map((currencyCode) => {
            const convertedTotalAmount = exchangeCurrency({
                amount: totalPrice,
                fromCurrency: currency,
                toCurrency: currencyCode,
                currencies: currencies,
            });
            return [
                {
                    content: `${t("total")} ${currencyCode}: `,
                },
                {
                    content: toCurrency(convertedTotalAmount, currencyCode),
                    styles: { halign: "right" },
                },
            ];
        }),
        [
            // [{ content: defaultCurrencyTotalContent || " ", styles: { halign: "right" }, colSpan: 2 }],
            { content: `${t("date")}: `, styles: { cellPadding: { top: 4 } } },
            { content: date, styles: { halign: "right", cellPadding: { top: 4 } } },
        ]
    );

    doc.autoTable({
        theme: "plain",
        startY: doc.autoTable.previous.finalY + 6,
        styles: { fontSize: 10, cellPadding: { top: 0 } },
        margin: { left: 5, right: 10 },
        body: body,
    });
    if (payments?.paymentStatus !== "Open") {
        doc.setDrawColor(0, 0, 0);
        doc.setLineDash([2, 1], 0);
        doc.line(5, doc.autoTable.previous.finalY + 3, 75, doc.autoTable.previous.finalY + 3);
        doc.line(5, doc.autoTable.previous.finalY + 4, 75, doc.autoTable.previous.finalY + 4);
        doc.setLineDash([]);

        const { cashReceived } = payments || {};
        let change = parseFloat(cashReceived) - parseFloat(totalPrice);
        if (change > 0) change *= -1;
        if (isNaN(parseFloat(change))) change = 0;

        const footerDoc = [
            [
                { content: `${t("paid")}: ` },
                { content: toCurrency(cashReceived, currency), styles: { halign: "right" } },
            ],
        ];
        if (payments?.paymentMethod) {
            footerDoc.push([
                { content: `${t("method")}: ` },
                { content: paymentMethodName, styles: { halign: "right" } },
            ]);
        }
        if (!!note) {
            footerDoc.push([hideNoteOnReceipt ? "" : `${t("note")}\n${note || ""}`]);
        }
        footerDoc.push([{ content: "HotelBee", colSpan: 2, styles: { halign: "center" } }]);

        doc.autoTable({
            theme: "plain",
            startY: doc.autoTable.previous.finalY + 5,
            styles: { fontSize: 10, cellPadding: { top: 2 } },
            margin: { left: 5, right: 10 },
            body: footerDoc,
        });
    }
    const base64Doc = doc.output("datauristring").split("data:application/pdf;filename=generated.pdf;base64,")[1];

    const { printingOption, printer } = pospoint?.printOptions?.nonFiscal || {};
    if (printingOption === "default") {
        doc.autoPrint();
        doc.output("dataurlnewwindow");
    } else if (printingOption === "silent") {
        if (!qz.websocket.isActive()) {
            await qz.websocket.connect();
        }
        const defaultPrinter = await qz.printers.getDefault();
        if (defaultPrinter) {
            let config = qz.configs.create(defaultPrinter, {
                size: { width: 80, height: 120 + products.length * 50 },
                units: "mm",
                colorType: "grayscale",
                interpolation: "nearest-neighbor",
                scaleContent: "false",
                jobName: `Receipt ${parseInt(number).toString()}`,
            });
            await qz.print(config, [
                {
                    type: "pixel",
                    format: "pdf",
                    flavor: "base64",
                    data: base64Doc,
                },
            ]);
        }
    } else if (printingOption === "silent_to_printer") {
        if (!qz.websocket.isActive()) {
            await qz.websocket.connect();
        }
        if (printer) {
            let config = qz.configs.create(printer, {
                size: { width: 80, height: 120 + products.length * 50 },
                units: "mm",
                colorType: "grayscale",
                interpolation: "nearest-neighbor",
                scaleContent: "false",
                jobName: `Receipt ${parseInt(number).toString()}`,
            });
            await qz.print(config, [
                {
                    type: "pixel",
                    format: "pdf",
                    flavor: "base64",
                    data: base64Doc,
                },
            ]);
        }
    } else {
        doc.autoPrint();
        doc.output("dataurlnewwindow");
    }
};

export const kitchenReceipt = async ({
    settingsProperty,
    posplaces,
    order,
    t,
    pospoint,
    previousOrder,
    numberOfPersons,
}) => {
    const selectedCourse = undefined;
    const { tableName } = pospoint?.tables?.find((table) => table?._id === order?.tableId) || {};
    // const guestsNumber = "";
    const { name } = settingsProperty || {};
    const {
        customerName = "Walkin" /*courses = [],  selectedCourse, tableName, guestsNumber*/,
        number,
        createdByName,
    } = order;
    const products = recentProducts(order?.products, previousOrder?.products);
    const currentPlaces = posplaces
        ?.filter((p) => p.sendTo === "printer")
        ?.filter((place) => products.some((product) => product.posplaces && product.posplaces.includes(place._id)));
    for (let placeIndex = 0; placeIndex < currentPlaces.length; placeIndex++) {
        const place = currentPlaces[placeIndex];
        const currentProducts = products.filter(
            (product) => product.posplaces && product.posplaces.includes(place._id)
        );
        const courses = currentProducts?.reduce((acc, p) => {
            if (!p.courses) return acc;
            acc = acc.concat(p.courses?.filter((course) => !acc.some((c) => c.courseId === course.courseId)));
            return acc;
        }, []);
        const documentKitchenHeight = currentProducts.length * 30 + 70;
        const doc = new jsPDF("p", "mm", [80, documentKitchenHeight]);

        const courseIndex = courses.findIndex((course) => course.courseId === selectedCourse);
        const headerBody = [
            [{ content: name, styles: { halign: "center", fontSize: 15 } }],
            [`${t("receipt_number")}: ${parseInt(number)}`],
            [`${t("place")}: ${place.name}`],
            [
                `${t("user")}: ${
                    createdByName ||
                    getLoggedUserData()?.displayName ||
                    getLoggedUserData()?._id?.split("users_")[1] ||
                    ""
                }`,
            ],
            // [`${t("customer")}: ${customerName}`],
        ];
        if (tableName) headerBody.push([{ content: `${t("table")}: ${tableName}`, styles: { fontSize: 15 } }]);
        if (!isNaN(parseInt(numberOfPersons))) {
            headerBody.push([`${t("guests_number")}: ${numberOfPersons}`]);
        }
        doc.autoTable({
            theme: "plain",
            styles: { fontSize: 11, cellPadding: { top: 2 } },
            margin: { left: 5, top: 0, right: 5 },
            body: headerBody,
        });
        doc.setDrawColor(0, 0, 0);
        doc.line(5, doc.autoTable.previous.finalY + 3, 75, doc.autoTable.previous.finalY + 3);
        doc.autoTable({
            theme: "plain",
            startY: doc.autoTable.previous.finalY + 3,
            styles: { fontSize: 14, fontStyle: "bold", cellPadding: { top: 2 } },
            margin: { left: 5, right: 5 },
            willDrawCell: (data) => {
                const { raw } = data.row || {};
                if (typeof raw === "object" && raw[0] && raw[0].section && raw[0].section === "course") {
                    doc.setDrawColor(0, 0, 0);
                    doc.setLineWidth(0.5);
                    doc.setLineDash([2, 2], 0);
                    doc.line(
                        data.cell.x,
                        data.cell.y + data.cell.height,
                        data.cell.x + data.cell.width,
                        data.cell.y + data.cell.height
                    );
                    doc.setLineDash([]);
                }
            },
            body:
                courses.length > 1
                    ? [
                          ...courses
                              .filter((course) => (selectedCourse ? course === selectedCourse : true))
                              .map((course, index) => {
                                  const courseProducts = currentProducts.filter((product) =>
                                      product.courses?.some((c) => c.courseId === course.courseId)
                                  );
                                  if (courseProducts)
                                      return [
                                          [
                                              {
                                                  content: `${t("course")}: ${
                                                      courseIndex > -1 ? courseIndex + 1 : index + 1
                                                  }`,
                                                  styles: {
                                                      cellPadding: { top: 4 },
                                                      margin: { bottom: 5 },
                                                      fontStyle: "italic",
                                                  },
                                                  section: "course",
                                              },
                                          ],
                                          ...courseProducts
                                              .map((product) => {
                                                  const { quantity } =
                                                      product.courses?.find((c) => c.courseId === course.courseId) ||
                                                      {};
                                                  const productRows = [];
                                                  productRows.push([`${parseFloat(quantity)} x ${product.name}`]);
                                                  if (product.note)
                                                      productRows.push([
                                                          {
                                                              content: product.note,
                                                              styles: { fontSize: 10, cellPadding: { top: 0 } },
                                                          },
                                                      ]);
                                                  return productRows;
                                              })
                                              .flat(),
                                      ];
                                  else return [];
                              })
                              .flat(),
                      ]
                    : [
                          ...currentProducts
                              .map((product) => {
                                  const productRows = [];
                                  productRows.push([`${parseFloat(product.quantity)} x ${product.name}`]);
                                  if (product.note)
                                      productRows.push([
                                          {
                                              content: product.note,
                                              styles: { fontSize: 10, cellPadding: { top: 0 } },
                                          },
                                      ]);
                                  return productRows;
                              })
                              .flat(),
                      ],
        });

        doc.setDrawColor(0, 0, 0);
        doc.line(5, doc.autoTable.previous.finalY + 3, 75, doc.autoTable.previous.finalY + 3);
        doc.autoTable({
            theme: "plain",
            startY: doc.autoTable.previous.finalY + 5,
            styles: { fontSize: 11, cellPadding: { top: 2 } },
            margin: { left: 5, right: 5 },
            body: [
                [{ content: `${t("items_count")}: ` + currentProducts.length }],
                [DateTime.now().toFormat("yyyy-LL-dd HH:mm:ss")],
            ],
        });

        const { printers = [] } = place || {};

        const base64Doc = doc.output("datauristring").split("data:application/pdf;filename=generated.pdf;base64,")[1];
        if (printers?.length > 0) {
            if (!qz.websocket.isActive()) {
                await qz.websocket.connect();
            }
            const _PC_Printers = await qz.printers.find();
            const availablePrinters = printers?.filter((printer) => _PC_Printers?.includes(printer.printer));

            if (availablePrinters?.length > 0) {
                let config = qz.configs.create(availablePrinters[0]?.printer, {
                    jobName: `Kitchen Receipt`,
                });
                await qz.print(config, [
                    {
                        type: "pixel",
                        format: "pdf",
                        flavor: "base64",
                        data: base64Doc,
                    },
                ]);
            }
        } else {
            doc.autoPrint();
            doc.output("dataurlnewwindow");
        }
    }
};

export const printFiscal = async ({
    settingsProperty,
    order,
    t,
    pospoint,
    SameTaxes = [],
    currencies,
    previousOrder,
    discountType,
    discountValue,
}) => {
    const { currency } = pospoint?.currencyData || {};
    const { address = "", city = "", zip = "", country = "", name = "" } = settingsProperty || {};
    const { optionalName, optionalCity, optionalCountry, optionalAddress } = pospoint;
    const {
        products = [],
        subtotal,
        payments = {},
        customerName,
        createdAt,
        createdBy,
        fiscalData,
        hideNoteOnReceipt,
        note,
    } = order;
    let createByName = "";
    if (!!createdBy) {
        const response = await fetch({
            operation: "query",
            endpoint: "user",
            data: { _id: createdBy },
            responseData: "_id displayName",
        });
        if (!!response?.user?.displayName) {
            createByName = response.user.displayName;
        }
    }

    const { paymentMethod } = payments || {};
    const isFinalReceipt = !!payments?.paymentMethod;
    const paymentMethodData = pospoint?.activeMethodsData?.find((method) => method._id === paymentMethod);
    const { tableName } = pospoint?.tables?.find((table) => table?._id === order?.tableId) || {};

    const totalPrice = isFinalReceipt ? order.totalPrice : order.totalPrice - (previousOrder?.totalPrice || 0);
    const productsToDisplay = !isFinalReceipt ? recentProducts(products, previousOrder?.products) : products;
    const discountAmount = calculateTotalDiscount(totalPrice, discountType, discountValue);

    const { InvOrdNum, TCR, operator, nivf, nslf, IssueDateTime, nipt, BusinUnitCode, IICRef } = fiscalData || {}; //get nipt and businunitcode
    const documentHeight = products.length * 40 + SameTaxes.length * 30 + 170;
    const doc = new jsPDF("p", "mm", [80, documentHeight]);
    const title = !payments?.paymentMethod ? "POROSI" : "FATURË";
    doc.autoTable({
        theme: "plain",
        styles: { fontSize: 10, cellPadding: { top: 1 } },
        margin: { left: 5, top: 0, right: 5 },
        body: [
            [{ content: title, styles: { halign: "center", fontSize: 20, fontStyle: "bold" } }],
            [
                {
                    content: !!optionalName ? optionalName : name,
                    styles: { halign: "center", fontSize: 14, fontStyle: "bold" },
                },
            ],
            [{ content: nipt, styles: { halign: "center", fontSize: 9 } }],
            [
                {
                    content: `${!!optionalAddress ? optionalAddress : address}, ${
                        !!optionalCity ? optionalCity : city
                    }, ${zip}, ${!!optionalCountry ? optionalCountry : country}`,
                    styles: { halign: "center" },
                },
            ],
            [{ content: tableName || "", styles: { halign: "center", fontStyle: "bold" } }],
        ],
    });
    let date = DateTime.now().toFormat("yyyy-LL-dd HH:mm:ss");
    if (IssueDateTime) date = DateTime.fromISO(IssueDateTime).toFormat("yyyy-LL-dd HH:mm:ss");
    doc.autoTable({
        theme: "plain",
        startY: doc.autoTable.previous.finalY + 5,
        styles: { fontSize: 9, cellPadding: { top: 1 } },
        margin: { left: 5, top: 0, right: 5 },
        body: [
            [{ content: `Nr.: ${InvOrdNum}/${DateTime.now().toFormat("yyyy")}` }, { content: `Data: ${date}` }],
            [{ content: `KB: ${BusinUnitCode}` }, { content: `OP: ${operator}` }],
            [{ content: `TCR: ${TCR}` }, { content: `Monedha: ${currency || "ALL"}` }],
            [{ content: `Sherbyer nga: ${createdBy || ""}`, colSpan: 2 }],
        ],
    });
    doc.autoTable({
        theme: "plain",
        startY: doc.autoTable.previous.finalY + 2,
        styles: { fontSize: 9, cellPadding: { top: 1 } },
        margin: { left: 5, top: 0, right: 5 },
        willDrawCell: (data) => {
            const { index } = data.row || {};
            if (index && index === 1) {
                doc.setDrawColor(0, 0, 0);
                doc.line(
                    data.cell.x,
                    data.cell.y + data.cell.height + 1,
                    data.cell.x + data.cell.width,
                    data.cell.y + data.cell.height + 1
                );
            }
        },
        body: [
            [{ content: "Artikulli", colSpan: 5 }],
            ["Sasia", "Cmimi", "TVSH%", "Pa TVSH", "Total"],
            ...productsToDisplay
                .map((product) => {
                    const price = parseFloat(product.price);
                    let rate;
                    let priceWithoutVAT = price;
                    product?.taxData?.forEach((itemTax) => {
                        if (itemTax?.tax) {
                            rate = parseFloat(itemTax?.tax?.rate);
                            const { totalAmount } = calculateTotalTax({
                                taxData: [{ isIncluded: Boolean(itemTax.isIncluded), tax: itemTax.tax }],
                                price: price,
                            });
                            if (Boolean(itemTax.isIncluded)) {
                                priceWithoutVAT -= isNaN(parseFloat(totalAmount)) ? 0 : parseFloat(totalAmount);
                            }
                        }
                    });
                    let totPrice = price * parseFloat(product.quantity);
                    return [
                        [
                            {
                                content: product?.name || product?.productData?.name || "",
                                colSpan: 5,
                                styles: { cellPadding: { top: 2, bottom: 0 } },
                            },
                        ],
                        [
                            product.quantity,
                            price.toFixed(2),
                            isNaN(rate) ? "0" : rate.toFixed(2),
                            priceWithoutVAT.toFixed(2),
                            totPrice.toFixed(2),
                        ],
                    ];
                })
                .flat(),
        ],
    });
    doc.setDrawColor(0, 0, 0);
    doc.line(5, doc.autoTable.previous.finalY + 4, 75, doc.autoTable.previous.finalY + 4);

    const body = [];
    if (discountAmount > 0) {
        body.push([
            { content: "Zbritje", styles: { fontStyle: "bold", fontSize: 12 } },
            {
                content: toCurrency(discountAmount, currency),
                styles: { halign: "right", fontStyle: "bold", fontSize: 12 },
            },
        ]);
    }
    body.push(
        [
            { content: "Total", styles: { fontStyle: "bold", fontSize: 15 } },
            {
                content: toCurrency(totalPrice, currency),
                styles: { halign: "right", fontStyle: "bold", fontSize: 12 },
            },
        ],
        ...(pospoint?.differentTotalCurrencies || [])?.map((currencyCode) => {
            const convertedTotalAmount = exchangeCurrency({
                amount: totalPrice,
                fromCurrency: currency,
                toCurrency: currencyCode,
                currencies: currencies,
            });
            return [
                {
                    content: `${t("total")} ${currencyCode}: `,
                },
                {
                    content: toCurrency(convertedTotalAmount, currencyCode),
                    styles: { halign: "right" },
                },
            ];
        }),
        [
            { content: paymentMethodData?.name || "" },
            { content: toCurrency(totalPrice, currency), styles: { halign: "right" } },
        ]
        // [{ content: euroTotalContent || " ", styles: { halign: "right" }, colSpan: 2 }],
    );

    doc.autoTable({
        theme: "plain",
        startY: doc.autoTable.previous.finalY + 5,
        styles: { fontSize: 9, cellPadding: { top: 1 } },
        margin: { left: 5, top: 0, right: 8 },
        body: body,
    });
    doc.setDrawColor(0, 0, 0);
    doc.line(5, doc.autoTable.previous.finalY + 2, 75, doc.autoTable.previous.finalY + 2);
    doc.autoTable({
        theme: "plain",
        startY: doc.autoTable.previous.finalY + 5,
        styles: { fontSize: 9, cellPadding: { top: 1 }, fontStyle: "italic" },
        margin: { left: 5, top: 0, right: 5 },
        body: [
            ["Nr.", "Shk. TVSH %", "Pa TVSH", "Vl. TVSH"],
            ...SameTaxes.map((tax) => {
                return [tax.NumOfItems, tax.VATRate, tax.PriceBefVAT, tax.VATAmt];
            }),
        ],
    });
    doc.setDrawColor(0, 0, 0);
    doc.setLineDash([2, 1], 0);
    doc.line(5, doc.autoTable.previous.finalY + 4, 75, doc.autoTable.previous.finalY + 4);
    doc.line(5, doc.autoTable.previous.finalY + 5, 75, doc.autoTable.previous.finalY + 5);
    doc.setLineDash([]);
    let qrUrl = `https://efiskalizimi-app.tatime.gov.al/invoice-check/#/verify?iic=${IICRef}&tin=${nipt}&crtd=${DateTime.fromISO(
        IssueDateTime
    ).toFormat(`yyyy-MM-dd\'T\'HH:mm:ss`)}%2002:00&prc=${totalPrice}`;
    const url = await QRCode.toDataURL(qrUrl);
    if (url) {
        doc.addImage(url, "png", 25, doc.autoTable.previous.finalY + 10, 28, 28);
        doc.autoTable({
            theme: "plain",
            startY: doc.autoTable.previous.finalY + 40,
            styles: { fontSize: 8, cellPadding: { top: 1 } },
            margin: { left: 5, top: 0, right: 5 },
            body: [
                [`NSLF: ${nslf}`],
                [`NIVF: ${nivf}`],
                [hideNoteOnReceipt ? "" : !!note ? note : ""],
                [{ content: "HotelBee", styles: { halign: "center" } }],
            ],
        });
    }
    const base64Doc = doc.output("datauristring").split("data:application/pdf;filename=generated.pdf;base64,")[1];

    const { printingOption, printer } = pospoint?.printOptions?.fiscal || {};
    if (printingOption === "default") {
        doc.autoPrint();
        doc.output("dataurlnewwindow");
    } else if (printingOption === "silent") {
        if (!qz.websocket.isActive()) {
            await qz.websocket.connect();
        }
        const defaultPrinter = await qz.printers.getDefault();
        if (defaultPrinter) {
            let config = qz.configs.create(defaultPrinter, {
                size: { width: 80, height: products.length * 40 + SameTaxes.length * 30 + 170 },
                units: "mm",
                colorType: "grayscale",
                interpolation: "nearest-neighbor",
                scaleContent: "false",
                jobName: `Fiscal Receipt ${parseInt(InvOrdNum).toString()}`,
            });
            await qz.print(config, [
                {
                    type: "pixel",
                    format: "pdf",
                    flavor: "base64",
                    data: base64Doc,
                },
            ]);
        }
    } else if (printingOption === "silent_to_printer") {
        if (!qz.websocket.isActive()) {
            await qz.websocket.connect();
        }
        if (printer) {
            let config = qz.configs.create(printer, {
                size: { width: 80, height: products.length * 40 + SameTaxes.length * 30 + 170 },
                units: "mm",
                colorType: "grayscale",
                interpolation: "nearest-neighbor",
                scaleContent: "false",
                jobName: `Fiscal Receipt ${parseInt(InvOrdNum).toString()}`,
            });
            await qz.print(config, [
                {
                    type: "pixel",
                    format: "pdf",
                    flavor: "base64",
                    data: base64Doc,
                },
            ]);
        }
    } else {
        doc.autoPrint();
        doc.output("dataurlnewwindow");
    }
};
