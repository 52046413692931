import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ImageBackground } from "../../components/common";
import { useApi } from "../../components/hooks";
import LoginCard from "./LoginCard";
import { removePermission, setPermission } from "../../utils/setPermission";
import { Modal } from "../../components/modal";
import { SelectControl } from "../../components/form";
import i18n from "i18next";
import { Button, Stack } from "@mui/material";

const BACKGROUND_IMAGES_URLSOLD = [
    "https://res.cloudinary.com/strowberry-code/image/upload/v1611242793/hb/mountain_stock_qqyd5l.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1633882011/hb/pexels-mengliu-di-3064079_r9ftrx.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1633882006/hb/pexels-stein-egil-liland-3408744_fw5b6s.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1633965049/hb/pexels-ezra-comeau-2387418_i1m9ki.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1633965053/hb/pexels-lumn-167699_ppi3gz.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1633965120/hb/pexels-kaboompics-com-5836_tknew2.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1633965121/hb/pexels-moose-photos-1037993_p3xgca.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1633965123/hb/pexels-scott-webb-311458_tvespp.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1633965125/hb/pexels-francesco-ungaro-673648_fe0tni.jpg",
];

const BACKGROUND_IMAGES_URLSWinter = [
    "https://res.cloudinary.com/strowberry-code/image/upload/v1704665176/hb/pexels-stefan-stefancik-91216_sfw0cc.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1704665177/hb/pexels-dan-hamill-436792_hghbmu.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1704665178/hb/pexels-aj-povey-226400_ua8nxl.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1704665179/hb/pexels-ruvim-miksanskiy-1438761_gmd1pl.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1704665179/hb/pexels-patrick-doyle-7235325_mnnilc.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1704665730/hb/pexels-mvegas-221169_taf6bi.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1704665998/hb/pexels-eberhard-grossgasteiger-1367192_hurwuo.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1704666063/hb/pexels-egor-kamelev-813872_t92nst.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1704666069/hb/pexels-miguel-%C3%A1-padri%C3%B1%C3%A1n-745364_yvbdkk.jpg",
];

const BACKGROUND_IMAGES_URLS = [
    "https://res.cloudinary.com/strowberry-code/image/upload/v1711723057/hb/pexels-hassan-ouajbir-2125422_ulwd44.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1711723060/hb/pexels-pok-rie-697313_mhvnrt.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1711723112/hb/pexels-pixabay-414181_zmtjkm.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1711723116/hb/pexels-pixabay-164470_xnev6d.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1711723122/hb/pexels-fabian-wiktor-994605_sw845x.jpg",
    "https://res.cloudinary.com/strowberry-code/image/upload/v1711723126/hb/pexels-pixabay-46164_s4kmvu.jpg",
];

const randomUrl = BACKGROUND_IMAGES_URLS[Math.floor(Math.random() * BACKGROUND_IMAGES_URLS.length)];
const Login = () => {
    const navigate = useNavigate();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [waiterShiftModal, setWaiterShiftModal] = useState({ open: false, user: {}, token: null });
    const [posModal, setPosModal] = useState({ open: false, posOptions: [] });
    const [waiters, setWaiters] = useState([]);

    useEffect(() => {
        removePermission();
        loadWaiters();
    }, []);

    const setLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    const loadWaiters = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "waiterUsernames",
        });
        if (response?.waiterUsernames) setWaiters(response.waiterUsernames);
    };

    const handleLogin = async ({ userName, password }) => {
        const response = await fetch({
            operation: "query",
            endpoint: "login",
            multipleEndpoints: [
                {
                    endpoint: "pospoints",
                    responseData: "_id name isActive hasTables",
                },
                {
                    endpoint: "settingsgeneral",
                    responseData: "_id language offlinePos",
                    data: { _id: "settings_general" },
                },
                {
                    endpoint: "settingsproperty",
                    data: {
                        _id: "settings_property",
                    },
                    responseData: "name",
                },
                {
                    endpoint: "login",
                    responseData: `token hasOpenShift user{
                        _id 
                        username 
                        displayName 
                        language 
                        role{
                            homeRoute 
                            isWaiter 
                            hasShift
                            permissions
                            hasShift
                        } 
                        posPoints
                        email
                    }`,
                    data: { userName, password },
                },
            ],
        });
        if (!response?.login) return;
        const { role = {}, language } = response?.login?.user || {};
        if (!!role.permissions) role.permissions = JSON.parse(role.permissions);
        if (!!language) localStorage.setItem("language", language);
        if (response?.settingsgeneral?.language) {
            localStorage.setItem("settingsLanguage", response?.settingsgeneral?.language);
        }
        if (response?.settingsproperty?.name) {
            localStorage.setItem("propertyName", response?.settingsproperty?.name);
        }
        setLanguage(language || response?.settingsgeneral?.language || "sq");

        if (response?.settingsgeneral?.offlinePos) {
            localStorage.setItem("offlinePos", JSON.stringify(response?.settingsgeneral?.offlinePos || false));
        }

        /**
         * User role without shift management proceeds standard login
         */
        if (!role?.hasShift) {
            setPermission(response?.login?.token, response?.login?.user);
            if (role.homeRoute) navigate("/" + role.homeRoute);
            else navigate("/");
            return;
        }

        /**
         * Attach to user access pospoints to redirect later
         */
        let posOptions = [];
        if (response?.pospoints) {
            posOptions = response.pospoints
                ?.filter((pos) => response?.login?.user?.posPoints?.includes(pos._id))
                ?.map((pos) => {
                    return {
                        value: pos._id,
                        label: pos.name,
                        hasTables: pos.hasTables,
                    };
                });
        }
        response.login.user.posOptions = posOptions;

        /**
         * If user has active shift log set user permisssions and proceed to pos redirect
         */
        if (response?.login?.hasOpenShift) {
            setPermission(response?.login?.token, response?.login?.user);
            redirectWaiter(response.login.user);
            return;
        }
        /**
         * Ask user to activate shift to proceed to log into system
         */
        setWaiterShiftModal({ open: true, user: response.login.user, token: response?.login?.token });
        return;
    };

    const startUserShift = async ({ user, token }) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "openShift",
            data: {
                userId: user._id,
            },
            responseData: `_id`,
        });
        if (response?.openShift?._id) {
            enqueueSnackbar(t("user_shift_started"), { variant: "success" });
            setPermission(token, user);
            redirectWaiter(user);
        }
    };
    const redirectWaiter = (user) => {
        const { posPoints, role, posOptions = [] } = user;
        if (!posPoints || posPoints.length === 0) {
            navigate(role?.homeRoute ? "/" + role.homeRoute : "/");
            return;
        }
        if (posPoints.length === 1) {
            redirectToPos(posPoints[0], posOptions);
            return;
        }
        setPosModal({ open: true, posOptions });
    };

    const redirectToPos = (posId, posOptions) => {
        const selectedPos = posOptions?.find((pos) => pos.value === posId) || {};
        if (!!selectedPos?.hasTables) {
            navigate(`/pos/tables?pos=${selectedPos?.value?.split("pospoints_")[1]}`);
            return;
        }
        navigate(`/pos/sell?pos=${selectedPos?.value?.split("pospoints_")[1]}`);
    };

    return (
        <div>
            <ImageBackground image={randomUrl} />
            <LoginCard loading={loading} handleLogin={handleLogin} waiters={waiters} />
            <WaiterModal
                open={waiterShiftModal?.open}
                t={t}
                onClose={() => {
                    setWaiterShiftModal({ open: false });
                }}
                onSave={() => {
                    setWaiterShiftModal({ open: false });
                    startUserShift(waiterShiftModal);
                }}
            />
            <Modal
                titlelabel={t("select_pospoint")}
                maxWidth="xs"
                open={posModal?.open}
                yesText="none"
                noText="none"
                onClose={() => {}}
            >
                <Stack direction="row" justifyContent="center" gap={2}>
                    {posModal?.posOptions?.map((pos) => {
                        return (
                            <Button
                                sx={{
                                    width: 150,
                                    height: 150,
                                }}
                                onClick={() => redirectToPos(pos.value, posModal?.posOptions)}
                                variant="contained"
                            >
                                {pos.label}
                            </Button>
                        );
                    })}
                </Stack>
            </Modal>
        </div>
    );
};

export const WaiterModal = ({ t, open, onClose, onSave }) => {
    return (
        <Modal
            onSave={onSave}
            titlelabel={t("waiter_shift")}
            maxWidth="xs"
            open={open}
            yesText={t("open_shift")}
            onClose={onClose}
        >
            {t("do_you_want_to_open_shift_to_login")}
        </Modal>
    );
};

export default Login;
