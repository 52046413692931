import { Button, CardHeader } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import HotelIcon from "@mui/icons-material/Hotel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Table } from "../../../components/table";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { toCurrency } from "../../../utils";
const calculateNights = (checkin, checkout) => {
    if (!checkin || !checkout) return 0;
    return DateTime.fromISO(checkout).diff(DateTime.fromISO(checkin), "days").toObject().days;
};
const ReservationsTable = ({ data = [], currency, showPaymentColumn }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const columns = [
        {
            accessorKey: "clientName",
            header: t("client"),
            accessorFn: (row) =>
                (row?.clientsData?.[0]?.firstName || "") + " " + (row?.clientsData?.[0]?.lastName || ""),
        },
        { accessorKey: "checkin", header: t("checkin") },
        { accessorKey: "checkout", header: t("checkout") },
        {
            accessorKey: "nights",
            header: t("nights"),
            accessorFn: (row) => calculateNights(row?.checkin, row?.checkout),
        },
        {
            accessorKey: "totalPrice",
            header: t("total"),
            accessorFn: (row) => toCurrency(row?.totalPrice, currency),
        },
        // { accessorKey: "amountPaid", header: t("amount_paid") },
        {
            accessorKey: "roomsData",
            header: t("rooms"),
            accessorFn: (row) =>
                row?.rooms
                    ?.map((room) => {
                        return room?.roomData?.name;
                    })
                    ?.join(","),
        },
        {
            accessorKey: "bookedOn",
            header: t("booked_on"),
            accessorFn: (row) => DateTime.fromISO(row._id?.split("reservations_")?.[1])?.toFormat("yyyy LLL dd"),
        },
        { accessorKey: "bookingSource", header: t("booking_source") },
        { accessorKey: "status", header: t("status") },
    ];

    if (showPaymentColumn) {
        columns.push({
            accessorKey: "payments",
            header: t("payments"),
            accessorFn: (row) => {
                const paymentsByCurrency = {};
                if (row?.invoices) {
                    row?.invoices?.forEach((invoice) => {
                        const currency = invoice.invoiceCurrency || row.invoiceCurrency || currency || "ALL";
                        if (!paymentsByCurrency[currency]) {
                            paymentsByCurrency[currency] = {
                                currency: currency,
                                amount: 0,
                            };
                        }
                        invoice?.payments?.forEach((payment) => {
                            paymentsByCurrency[currency].amount += payment.amount;
                        });
                    });
                } else {
                    const currency = row.invoiceCurrency || currency || "ALL";
                    if (!paymentsByCurrency[currency]) {
                        paymentsByCurrency[currency] = {
                            currency: currency,
                            amount: 0,
                        };
                    }
                    row?.payments?.forEach((payment) => {
                        paymentsByCurrency[currency].amount += payment.amount;
                    });
                }
                const paymentsAmounts = Object.values(paymentsByCurrency)
                    ?.map(({ currency, amount }) => {
                        return toCurrency(amount, currency);
                    })
                    ?.join(", ");
                return paymentsAmounts;
            },
        });
    }
    columns.push({
        id: "link",
        header: "",
        cell: ({ row: { original } }) => {
            return (
                <Button
                    startIcon={<VisibilityIcon />}
                    fullWidth
                    onClick={() => navigate(`/reservations/${original?._id?.split("reservations_")[1]}`)}
                >
                    {t("view")}
                </Button>
            );
        },
    });

    return (
        <>
            <CardHeader
                avatar={<HotelIcon />}
                title={t("client_reservations")}
                titleTypographyProps={{ variant: "h5" }}
            />
            <Table
                onRowClick={(row) => navigate(`/reservations/${row?._id?.split("reservations_")[1]}`)}
                data={data}
                columns={columns}
                //disableHeader
                //disableSelection
                disableDelete
                disableFooter
                titleLabel={t("client_reservations")}
            />
        </>
    );
};

export default ReservationsTable;
