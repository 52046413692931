import React from "react";
import Draggable from "react-draggable";

const DraggableWrapper = ({
    defaultPosition,
    children,
    handleDrag,
    disabledMove = false,
    id,
    hasMoveEnabled,
    hide,
}) => {
    const position = { x: 0, y: 0 };
    if (hide) return null;
    if (!hasMoveEnabled) return children;
    return (
        <Draggable
            grid={[10, 10]}
            onStop={(e, data) => handleDrag(id, data)}
            bounds=".draggable-parent"
            defaultPosition={defaultPosition || position}
            position={null}
            handle=".handle"
            disabled={disabledMove}
            scale={1}
        >
            {React.createElement(
                "div",
                {
                    className: "handle",
                    style: {
                        display: "inline-block",
                        height: "20px",
                    },
                },
                React.cloneElement(children)
            )}
        </Draggable>
    );
};

export default DraggableWrapper;
