import { Typography } from "@mui/material";
import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckboxControl, RadioGroupControl } from "../../../../components/form";
import { useApi } from "../../../../components/hooks";
import { Modal } from "../../../../components/modal";
import { Table } from "../../../../components/table";
import { toCurrency } from "../../../../utils";

const PayOrdersModal = ({ reservation, open, onClose, onConfirm }) => {
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [selectedMethod, setSelectedMethod] = useState(null);
    const { t } = useTranslation();
    const orders = useMemo(() => {
        return reservation?.orders?.filter((order) => {
            if (["TRANSFERRED", "CANCELLED"].includes(order.status)) {
                return false;
            }
            return order?.payments?.paymentMethodData?.method === "roomCharge";
        });
    }, [reservation?.orders]);

    const columns = [
        {
            id: "customerName",
            header: t("customer"),
            accessorFn: (row) => row?.customerData?.firstName + " " + row?.customerData?.lastName,
        },
        {
            id: "dateCreated",
            header: t("date"),
            accessorFn: (row) => DateTime.fromISO(row.createdAt).toFormat("yyyy-LL-dd"),
        },
        {
            id: "timeCreated",
            header: t("time"),
            accessorFn: (row) => DateTime.fromISO(row.createdAt).toFormat("HH:mm:ss"),
        },
        {
            id: "totalPrice",
            header: t("amount"),
            accessorFn: (row) => {
                const currency = row?.pospointData?.currencyData?.currency || "ALL";
                return toCurrency(row?.totalPrice, currency);
            },
        },
        { id: "pospoint", header: t("pospoint"), accessorFn: (row) => row?.pospointData?.name || "" },
        {
            id: "select",
            header: "",
            cell: ({ row: { original } }) => (
                <CheckboxControl
                    value={selectedOrders?.includes(original?._id)}
                    onChange={(e) => {
                        if (e.target.checked) setSelectedOrders([...selectedOrders, original?._id]);
                        else setSelectedOrders(selectedOrders?.filter((i) => i !== original?._id));
                    }}
                />
            ),
        },
    ];
    const paymentMethodsOptions = useMemo(() => {
        return orders?.[0]?.pospointData?.activeMethodsData
            ?.filter((method) => {
                if (method.method === "roomCharge") {
                    return false;
                }
                return true;
            })
            ?.map((method) => {
                return { value: method._id, label: method.name };
            });
    }, [orders]);

    const totalAmount = useMemo(() => {
        const amounts = {};
        orders?.forEach((order) => {
            if (!selectedOrders.includes(order._id)) {
                return;
            }
            const currency = order?.pospointData?.currencyData?.currency || "ALL";
            if (!amounts[currency]) {
                amounts[currency] = 0;
            }
            amounts[currency] += order.totalPrice;
        });
        return Object.entries(amounts)
            ?.map(([currency, amount]) => {
                return toCurrency(amount, currency);
            })
            ?.join(", ");
    }, [selectedOrders, orders]);

    const closeHandler = () => {
        setSelectedOrders([]);
        setSelectedMethod(false);
        onClose();
    };

    const { loading, fetch } = useApi();

    const saveHandler = async () => {
        const ordersToPay = orders?.filter((order) => selectedOrders?.includes(order._id));
        const newReservationData = JSON.parse(JSON.stringify(reservation));
        for (const order of ordersToPay) {
            const data = {
                _id: order._id,
                payments: {
                    paymentMethod: selectedMethod,
                    cashReceived: order.totalPrice,
                },
            };
            const response = await fetch({
                operation: "mutation",
                endpoint: "updateOrderPayment",
                data,
                responseData:
                    "_id status payments{userName timestamp paymentMethod paymentMethodData{name method} paymentStatus cashReceived}",
            });
            if (response?.updateOrderPayment?._id) {
                newReservationData.orders = newReservationData?.orders?.map((doc) => {
                    if (doc._id === data._id) {
                        return { ...doc, ...response?.updateOrderPayment };
                    }
                    return doc;
                });
            }
        }
        onConfirm && onConfirm(newReservationData);
        closeHandler();
    };

    return (
        <Modal
            maxWidth="md"
            open={open}
            onClose={closeHandler}
            onSave={saveHandler}
            noText="none"
            yesText={t("add_payment")}
            yesDisabled={selectedOrders?.length < 1 || !selectedMethod || loading}
            titlelabel={t("orders")}
        >
            <Table data={orders} columns={columns} disableHeader disableSelection disableDelete disableFooter />
            {selectedOrders?.length > 0 && (
                <>
                    <Typography marginTop={2} variant="h6">
                        {t("payment_method")}
                    </Typography>
                    <RadioGroupControl
                        value={selectedMethod}
                        onChange={(e) => setSelectedMethod(e.target.value)}
                        options={paymentMethodsOptions || []}
                    />
                    <Typography marginTop={2} variant="h6">
                        {t("orders")} : {selectedOrders?.length}
                    </Typography>
                    <Typography variant="h6">
                        {t("total_amount")} : {totalAmount}
                    </Typography>
                </>
            )}
        </Modal>
    );
};

export default PayOrdersModal;
