import React from "react";
import { Drawer, IconButton, List, Typography } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import NotesIcon from "@mui/icons-material/Notes";
import { LanguageMenu } from "../../common";

const drawerWidth = 240;

const SideBar = ({ open, toggleOpen, hotelName = "", isSmallScreen, children }) => {
    const handleClick = () => {
        if (isSmallScreen && open) {
            toggleOpen();
        }
    };
    return (
        <Drawer
            PaperProps={{
                sx: {
                    backgroundColor: "sideBarColor.default",
                    height: "100%",
                    width: open ? drawerWidth : 60,
                    "@media screen and (max-width: 900px)": {
                        width: open ? drawerWidth : 0,
                    },
                    overflowX: "hidden",
                    transition: "width .3s ease-out",
                },
            }}
            variant="permanent"
            open={open}
        >
            <List onClick={handleClick}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        padding: "0 8px",
                    }}
                >
                    <Typography
                        style={{
                            color: "#ffffff",
                            maxHeight: 64,
                            transition: "all .3s",
                            whiteSpace: "nowrap",
                            opacity: open ? "100%" : "0",
                        }}
                        variant="h6"
                    >
                        {hotelName}
                    </Typography>
                    <div className="desktop-sidemenu-icon">
                        <IconButton onClick={toggleOpen} size="large" style={{ color: "#ffffff" }}>
                            {open ? <FirstPageIcon /> : <NotesIcon />}
                        </IconButton>
                    </div>
                </div>
                {children}
                {/* <LanguageMenu containerStyle={{ marginTop: 20 }} /> */}
            </List>
        </Drawer>
    );
};

export default SideBar;
