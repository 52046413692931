import { Button, ButtonGroup, Divider, Grid, Card, CardContent, Typography } from "@mui/material";

import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../components/modal";
import { ItemsTable } from "./components";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { AutoCompleteControl, Form, InputControl, SelectControl, SwitchControl } from "../../components/form";
import { DateRangeControl } from "../../components/dates";
import { ActionsPanel, ActionBox, AmountsCard, ClientsSearch } from "../../components/common";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useApi, useProducts } from "../../components/hooks";
import { languageOptions } from "../invoices/components/fiscalOptions";
import { calculateTotalTax, exchangeCurrency } from "../../utils";
import { DateTime } from "luxon";

import { exportEstimateToPdf } from "./components/estimatePdf";

const EstimateForm = () => {
    const [values, setValues] = useState({});
    const [posOptions, setPosOptions] = useState([]);
    const [roomtypes, setRoomtypes] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [taxes, setTaxes] = useState([]);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { products, loadingProducts } = useProducts();

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const { addRecordEstimate } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);
    useEffect(() => {
        loadExtraData();
    }, []);

    const productOptions = useMemo(() => {
        return products.map((p) => {
            return {
                _id: p?._id,
                name: p?.name,
                price: p?.price,
                taxData: p?.taxData,
                unit: p?.measureUnit || p?.purchaseUnit || "",
            };
        });
    }, [products]);

    const options = useMemo(() => {
        return [...products, ...roomtypes];
    }, [productOptions, roomtypes]);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "estimate",
            data: {
                _id: "estimates_" + params?.id,
            },
            responseData: `_id invoiceCurrency customer serial pdfLanguage salesUnit description date dueDate discountValue discountType number  
                    tableData{itemId price taxData{taxId isIncluded} description quantity unit}
                    clientsData{_id firstName lastName IDNumber IDType addressLine country city}
                    `,
        });
        if (response?.estimate) {
            setValues({
                ...values,
                ...response.estimate,
                tableData: !!response.estimate?.tableData ? response.estimate?.tableData : [],
                date: !!response.estimate.date ? response.estimate.date : DateTime.now().toFormat("yyyy-LL-dd"),
                dueDate: !!response.estimate.date
                    ? response.estimate.dueDate
                    : DateTime.now().plus({ days: 1 }).toFormat("yyyy-LL-dd"),
            });
        }
    };

    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                { endpoint: "pospoints", responseData: "_id name" },
                { endpoint: "currencies", responseData: "_id active currency currencyname rate" },
                {
                    endpoint: "roomtypes",
                    responseData: "_id name price taxData{taxId isIncluded}",
                },
                {
                    endpoint: "taxes",
                    responseData: "_id name rate taxCategory",
                },
            ],
        });

        if (!!response?.pospoints) {
            setPosOptions(response.pospoints.map((pos) => ({ value: pos._id, label: pos.name })));
        }
        if (response?.currencies) {
            setCurrencies(response.currencies);
        }
        if (response?.roomtypes) {
            setRoomtypes(response?.roomtypes);
        }
        if (!!response?.taxes) {
            setTaxes(response.taxes);
        }
    };

    const currenciesOptions = useMemo(() => {
        return currencies?.map((currency) => {
            return { value: currency.currency, label: currency.currencyname };
        });
    }, [currencies]);

    const calculateTotalDiscount = (totalPrice = 0) => {
        if (!values.discountType || !values.discountValue) return 0;
        const discountAmount =
            values.discountType === "total"
                ? parseFloat(values.discountValue)
                : totalPrice * parseFloat(values.discountValue) * 0.01;
        if (isNaN(discountAmount)) return 0;
        return discountAmount;
    };
    const amountInfo = () => {
        let totalPrice = 0.0;
        let subtotal = 0.0;
        let discount = 0.0;
        let taxTotal = 0.0;

        values?.tableData?.forEach((item) => {
            const { totalAmount, includedAmount } = calculateTotalTax({
                taxData: item.taxData?.map((t) => ({
                    isIncluded: t.isIncluded,
                    tax: taxes.find((tax) => tax._id === t.taxId),
                })),
                price: parseFloat(item.price),
            });
            taxTotal += totalAmount * item.quantity;
            totalPrice += (parseFloat(item.price) + totalAmount - includedAmount) * item.quantity;
        });
        subtotal = totalPrice - taxTotal;
        discount = calculateTotalDiscount(totalPrice);

        return {
            totalPrice: isNaN(totalPrice) ? 0.0 : totalPrice,
            subtotal: isNaN(subtotal) ? 0.0 : subtotal,
            taxTotal: isNaN(taxTotal) ? 0.0 : taxTotal,
            discount: isNaN(discount) ? 0.0 : discount,
        };
    };

    const saveHandler = async () => {
        const {
            invoiceCurrency,
            date,
            dueDate,
            customer,
            serial,
            pdfLanguage,
            salesUnit,
            tableData,
            description,
            discountValue,
            discountType,
            _id,
        } = values;
        tableData?.forEach((item) => {
            item.quantity = parseFloat(item.quantity);
            item.price = parseFloat(item.price);
            item.taxData = item?.taxData?.map(({ taxId, isIncluded }) => ({ taxId, isIncluded }));
        });
        const totalPrice = parseFloat(amountInfo()?.totalPrice - amountInfo()?.discount);
        const data = {
            invoiceCurrency,
            customer: values?.clientsData?._id || customer,
            serial,
            pdfLanguage,
            salesUnit,
            description,
            date,
            dueDate,
            discountValue: discountValue ? parseFloat(discountValue) : null,
            discountType,
            totalPrice: isNaN(totalPrice) ? 0.0 : parseFloat(totalPrice.toFixed(2)),
        };
        data.tableData = !!tableData ? tableData : [];
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateEstimate" : "createEstimate",
            data,
            responseData: "_id date dueDate description",
        });
        if (response?.createEstimate?._id || response?.updateEstimate?._id) {
            enqueueSnackbar(t("estimate_saved"), { variant: "default" });
            addRecordEstimate && addRecordEstimate(response.createEstimate || response?.updateEstimate);
            navigate(-1);
        }
    };
    const printPdf = async (printOptions = {}) => {
        exportEstimateToPdf({
            values,
            t,
            fetch,
            resource: "estimate",
            options: printOptions,
            itemsOptions: options,
            convertedCurrencies: [],
            hideReservationDetails: true,
        });
    };

    return (
        <Modal
            fullScreen
            open
            titlelabel={t("estimate_form")}
            onClose={() => navigate(-1)}
            loading={loading}
            FormProps={{ values, onValuesChange: setValues, onSubmit: saveHandler }}
        >
            <ActionsPanel>
                <ActionBox title={t("actions")} width={350}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Form values={values} onValuesChange={setValues}>
                                <SwitchControl label={t("estimate_for_reservation")} name="forReservation" />
                            </Form>
                        </Grid>
                        <Grid item xs={12}>
                            {values?.forReservation ? (
                                <DateRangeControl
                                    startLabel={t("checkin")}
                                    endLabel={t("checkout")}
                                    values={{ startDate: values.checkin || null, endDate: values.checkout || null }}
                                    onChange={({ startDate, endDate }) =>
                                        setValues({ ...values, checkin: startDate, checkout: endDate })
                                    }
                                />
                            ) : null}
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined" fullWidth>
                                {values?.forReservation ? t("create_reservation") : t("create_invoice")}
                            </Button>
                        </Grid>
                    </Grid>
                </ActionBox>
                <ActionBox width={350} title={t("status")}>
                    <ButtonGroup fullWidth disableElevation>
                        <Button disabled>{t("draft")}</Button>
                        <Button>{t("delivered")}</Button>
                        <Button>{t("paid")}</Button>
                    </ButtonGroup>
                </ActionBox>
                <ActionBox title={t("print")}>
                    <Button
                        variant="outlined"
                        sx={{ marginBottom: 1 }}
                        startIcon={<PictureAsPdfIcon />}
                        onClick={() => printPdf({ type: "save" })}
                        disabled={loadingProducts}
                    >
                        {t("download")}
                    </Button>
                    <Button
                        disabled={loadingProducts}
                        startIcon={<PrintIcon />}
                        onClick={() => printPdf({ type: "autoprint" })}
                    >
                        {t("print")}
                    </Button>
                </ActionBox>
                <ActionBox title={t("estimate_details")} width={300}>
                    <DateRangeControl
                        startLabel={t("date")}
                        endLabel={t("due_date")}
                        values={{ startDate: values.date || null, endDate: values.dueDate || null }}
                        onChange={({ startDate, endDate }) =>
                            setValues({ ...values, date: startDate, dueDate: endDate })
                        }
                    />
                    <SelectControl name={"invoiceCurrency"} label={t("currency")} options={currenciesOptions} />
                </ActionBox>
            </ActionsPanel>

            <div style={{ margin: "20px 0" }}>
                <Typography variant="h6">{t("estimate_details")}</Typography>
                <Divider />
            </div>
            <Form values={values} onValuesChange={setValues}>
                <Grid container spacing={2}>
                    <Grid container item xs={12} sm={5} md={4}>
                        <Grid item xs={12}>
                            <ClientsSearch
                                onClientSelect={(data) => {
                                    setValues({ ...values, clientsData: data });
                                }}
                                onNoOptionAction={() => navigate("addClient/create")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        {values?.clientsData?.IDNumber}
                                    </Typography>
                                    <Typography variant="h6" component="div">
                                        {(values?.clientsData?.firstName || "") +
                                            " " +
                                            (values?.clientsData?.lastName || "")}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        {values?.clientsData?.country}
                                    </Typography>
                                    <Typography variant="body2">
                                        {(values?.clientsData?.addressLine || "") +
                                            " " +
                                            (values?.clientsData?.city || "")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={1} md={4}></Grid>
                    <Grid container item xs={12} sm={6} md={4} spacing={2}>
                        <Grid item xs={6}>
                            <SelectControl name="pdfLanguage" label={t("pdf_language")} options={languageOptions} />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectControl name="salesUnit" label={t("sales_unit")} options={posOptions} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputControl multiline minRows={3} name="description" label={t("description")} />
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
            <div style={{ margin: "20px 0" }}>
                <Typography variant="h6">{t("items")}</Typography>
                <Divider />
            </div>
            <ItemsTable
                data={values?.tableData}
                options={options}
                taxesOptions={taxes}
                onChange={(tableData) => setValues({ ...values, tableData })}
                currency={values?.invoiceCurrency}
            />
            <Grid mt={5} container justifyContent="flex-end">
                <Grid item xs={6}>
                    <AmountsCard
                        total={amountInfo()?.totalPrice - amountInfo()?.discount}
                        tax={amountInfo()?.taxTotal}
                        subtotal={amountInfo()?.subtotal}
                        discount={amountInfo()?.discount}
                        discountInputValue={values?.discountValue}
                        discountType={values?.discountType}
                        onChange={(newData) => setValues({ ...values, ...newData })}
                        currency={values?.invoiceCurrency}
                    />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default EstimateForm;
