import { Button, Stack } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ReportGenerator } from "../../components/classes";
import { DateRangeControl } from "../../components/dates";
import { SelectControl } from "../../components/form";
import { useApi } from "../../components/hooks";

import { ReportHeader } from "./components";
import { Modal } from "../../components/modal";

const ShiftReport = () => {
    const { t } = useTranslation();
    const [params, setParams] = useSearchParams();

    const [reportData, setReportData] = useState(null);
    const { loading, fetch } = useApi();

    const [roleOptions, setRoleOptions] = useState([]);

    useEffect(() => {
        loadRoles();
    }, []);

    const loadRoles = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "roles",
            responseData: "_id name",
        });
        if (response) {
            setRoleOptions(
                response?.roles
                    ?.map((role) => ({ value: role._id, label: role.name }))
                    ?.concat({ value: "all", label: t("all") })
            );
        }
    };

    const collectHandler = async (waiterRevenueId) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "collectRevenue",
            data: {
                _id: waiterRevenueId,
            },
            responseData: "_id collectedBy",
        });
        if (response?.collectRevenue?._id) {
            setReportData(
                reportData?.map((record) => {
                    if (record._id !== waiterRevenueId) return record;
                    return { ...record, collected: true, collectedBy: response?.collectRevenue.collectedBy };
                })
            );
        }
    };

    const columns = [
        {
            id: "username",
            header: t("username"),
            displayValue: (row) => {
                return row?.userData?.displayName || row?.userData?.username || "";
            },
            totalValue: () => t("total"),
        },
        {
            id: "role",
            header: t("role"),
            displayValue: (row) => {
                return row?.userData?.role?.name || "";
            },
            totalValue: () => "",
        },
        {
            id: "shiftStart",
            header: t("shift_start"),
            displayValue: (row) => {
                return DateTime.fromISO(row?.starting?.timestamp).toFormat("yyyy-LL-dd HH:mm:ss") || "";
            },
            totalValue: () => "",
        },
        {
            id: "shiftEnd",
            header: t("shift_end"),
            displayValue: (row) => {
                return row?.closing?.timestamp
                    ? DateTime.fromISO(row?.closing?.timestamp).toFormat("yyyy-LL-dd HH:mm:ss")
                    : "Open";
            },
            totalValue: () => "",
        },
        {
            id: "shiftRevenue",
            header: t("shift_revenue"),
            displayValue: (row) => {
                return row?.closing?.paymentsAmount?.map((amount) => `${amount.amount} ${amount.currency}`).join(", ");
            },
            totalValue: () => "",
        },
        {
            id: "_id",
            header: "",
            displayValue: () => "",

            cell: ({ row: { original } }) => {
                return (
                    <ConfirmationModal
                        disabled={original?.collected}
                        collectHandler={() => collectHandler(original?._id)}
                    />
                );
            },
        },
    ];

    const loadData = async () => {
        if (!(params.get("startDate") && params.get("endDate"))) return;
        let start = params.get("startDate");
        let end = params.get("endDate");
        if (start === end) {
            start += "T00:00:00.000Z";
            end += "T23:59:59.999Z";
        }
        const selectedRole = params.get("role");

        let filtersText = "";
        if (!!start) filtersText += t("start_date") + ": " + start;
        if (!!end) filtersText += ", " + t("end_date") + ": " + end;
        if (!!selectedRole) {
            const roleName = roleOptions?.find((role) => role.value === selectedRole)?.label;
            if (roleName) filtersText += ", " + t("roles") + ": " + roleName;
        }

        const response = await fetch({
            operation: "query",
            endpoint: "waiterRevenuesByDate",
            data: { startDate: start, endDate: end },
            responseData: `
               _id 
               starting{timestamp amount{currency amount}} 
               closing{timestamp amount{currency amount} paymentsAmount{currency amount}} 
               userData{_id username displayName roleId role{_id name}}
               collected collectedBy
            `,
        });
        const data =
            selectedRole && selectedRole !== "all"
                ? response?.waiterRevenuesByDate?.filter((data) => data?.userData?.roleId === selectedRole)
                : response?.waiterRevenuesByDate;
        setReportData(data);
    };

    const report = useMemo(() => {
        if (!(params.get("startDate") && params.get("endDate"))) return null;
        const start = params.get("startDate");
        const end = params.get("endDate");
        const selectedRole = params.get("role");

        let filtersText = "";
        if (!!start) filtersText += t("start_date") + ": " + start;
        if (!!end) filtersText += ", " + t("end_date") + ": " + end;
        if (!!selectedRole) {
            const roleName = roleOptions?.find((role) => role.value === selectedRole)?.label;
            if (roleName) filtersText += ", " + t("roles") + ": " + roleName;
        }
        const newRaportGenerator = new ReportGenerator({
            data: reportData,
            columns,
            displayTotal: false,
        });
        return newRaportGenerator.generateTable({ title: t("shift"), headerInfo: [filtersText] });
    }, [reportData]);

    const getAllParams = () => {
        const data = {};
        if (!!params.get("startDate")) data.startDate = params.get("startDate");
        if (!!params.get("endDate")) data.endDate = params.get("endDate");
        if (!!params.get("role")) data.role = params.get("role");

        return data;
    };

    return (
        <div>
            <ReportHeader
                title={t("shift_report")}
                loading={loading}
                onReportRun={() => {
                    loadData();
                }}
            >
                <Stack direction="row" alignItems="center" gap={2}>
                    <DateRangeControl
                        startLabel={t("start_date")}
                        endLabel={t("end_date")}
                        values={{ startDate: params.get("startDate"), endDate: params.get("endDate") }}
                        onChange={({ startDate, endDate }) =>
                            setParams({
                                ...getAllParams(),
                                startDate: startDate || "",
                                endDate: endDate || "",
                            })
                        }
                    />
                    <SelectControl
                        label={t("role")}
                        options={roleOptions}
                        value={params.get("role")}
                        onChange={(e) =>
                            setParams({
                                ...getAllParams(),
                                role: e.target.value || "",
                            })
                        }
                        sx={{ width: "400px" }}
                    />
                </Stack>
            </ReportHeader>
            {report}
        </div>
    );
};

const ConfirmationModal = ({ collectHandler, disabled = false }) => {
    const [openModal, setOpenModal] = useState(false);

    const { t } = useTranslation();
    return (
        <>
            <Button disabled={disabled} onClick={() => setOpenModal(true)} variant="outlined">
                {t("collect")}
            </Button>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                yesText={t("confirm")}
                onSave={() => {
                    collectHandler();
                    setOpenModal(false);
                }}
                maxWidth="xs"
            >
                {t("please_confirm_revenue_collection")}
            </Modal>
        </>
    );
};

export default ShiftReport;
