import { Card, CardContent, CardHeader, Divider, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GuestSelect } from "../../../../../components/common";
import { CheckboxControl, SimpleQuantityControl, SelectControl } from "../../../../../components/form";
import { applyDiscount, getLoggedUserData, primaryGuestCategory, toCurrency } from "../../../../../utils";
import { CreateReservationContext } from "../CreateReservationContext";
import RoomDiscountModal from "./RoomDiscountModal";
import RoomtypeInfoModal from "./RoomtypeInfoModal";

const hasDifferentPrices = (recordDailyPrices = {}) => {
    const pricesAsArray = Object.values(recordDailyPrices).map(({ price }) => price);
    return pricesAsArray.some((price) => price !== pricesAsArray[0]);
};

const getRoomDailyCapacity = (roomData, dailyAvailabilities, day) => {
    let includedCapacity = parseInt(roomData?.includedCapacity);
    const roomtypeDailyAvailability = dailyAvailabilities?.roomtypes?.[roomData.roomtypeId];
    if (!!roomtypeDailyAvailability) {
        const roomtypeIncludedCapacity = parseInt(roomtypeDailyAvailability?.[day]?.includedCapacity);
        if (!isNaN(roomtypeIncludedCapacity)) {
            includedCapacity = roomtypeIncludedCapacity;
        }
    }
    const hasSelectedRate = Boolean(!!roomData.rate && roomData.rate !== "standard");
    if (hasSelectedRate) {
        const rateDailyAvailability = dailyAvailabilities?.rates?.[roomData.rate];
        const rateIncludedCapacity = parseInt(rateDailyAvailability?.[day]?.includedCapacity);
        if (!isNaN(rateIncludedCapacity)) {
            includedCapacity = rateIncludedCapacity;
        }
    }
    return includedCapacity;
};

const RoomtypeCard = ({ roomtype = {} }) => {
    const { t } = useTranslation();
    const { dailyData, data, values, setValues } = useContext(CreateReservationContext);
    const availableRooms = useMemo(() => {
        return roomtype?.rooms?.filter((room) => !dailyData?.blockedRoomsInfo?.blockedRooms?.includes(room._id));
    }, [dailyData, data]);

    const nights = useMemo(() => {
        if (!values.checkin || !values.checkout) return 0;
        return DateTime.fromISO(values.checkout).diff(DateTime.fromISO(values.checkin), "days").toObject().days;
    }, [values.checkin, values.checkout]);

    const ratesOptions = useMemo(() => {
        const roomtypeDailyPrices = dailyData?.dailyPricing?.roomtypes?.[roomtype._id];
        return [
            {
                value: "standard",
                label: t("standard_rate"),
                price: roomtypeDailyPrices?.[values?.checkin]?.price || roomtype.price || 0.0,
            },
        ].concat(
            roomtype?.rates
                ?.map((rate) => {
                    const dailyPrices = dailyData?.dailyPricing?.rates?.[rate._id];
                    const price = dailyPrices?.[values?.checkin]?.price;
                    return {
                        value: rate._id,
                        label: rate.name + " - " + (price !== undefined ? toCurrency(price, data?.currency) : ""),
                        price,
                        hasDifferentPrices: hasDifferentPrices(dailyPrices),
                        rateData: rate,
                    };
                })
                ?.filter((rate) => rate.price !== undefined && rate.price !== null)
        );
    }, [dailyData, data, nights]);

    const availableRatesOptions = useMemo(() => {
        return ratesOptions?.filter((rate) => {
            if (rate.value === "standard") return true;
            const { minimumNights, maximumNights } = rate?.rateData || {};
            if (minimumNights !== undefined && minimumNights !== null) {
                if (minimumNights > nights) return false;
            }
            if (maximumNights !== undefined && maximumNights !== null) {
                if (maximumNights < nights) return false;
            }
            return true;
        });
    }, [ratesOptions, nights]);

    const priceDetails = useMemo(() => {
        const dailyPrices = dailyData?.dailyPricing?.roomtypes?.[roomtype._id];
        return {
            dailyPrice: dailyPrices?.[values?.checkin]?.price,
            hasDifferentPrices: hasDifferentPrices(dailyPrices),
        };
    }, [dailyData, data]);

    const checkRoomtype = (details = {}) => {
        const checkedRoomtypes = { ...(values?.checkedRoomtypes || {}) };
        const defaultGuests = {};
        const defaultGuestCategory = primaryGuestCategory(roomtype.capacity, data?.guestcategories);
        if (!!defaultGuestCategory?._id && !!defaultGuestCategory?.name) {
            const includedCapacity = getRoomDailyCapacity(
                {
                    ...roomtype,
                    roomtypeId: roomtype._id,
                    rate: !!details?.rate ? details?.rate : checkedRoomtypes[roomtype._id]?.rate,
                },
                dailyData.dailyPricing,
                values.checkin
            );
            defaultGuests[defaultGuestCategory?._id] = {
                name: defaultGuestCategory?.name,
                number: includedCapacity || 0,
            };
        }
        if (!checkedRoomtypes[roomtype._id]) {
            checkedRoomtypes[roomtype._id] = {
                _id: roomtype._id,
                name: roomtype.name,
                capacity: roomtype.capacity,
                includedCapacity: roomtype.includedCapacity,
                price: roomtype.price,
                guests: defaultGuests,
                quantity: 1,
            };
        }
        checkedRoomtypes[roomtype._id] = {
            ...checkedRoomtypes[roomtype._id],
            guests: details?.guests
                ? details?.guests
                : checkedRoomtypes[roomtype._id] && details.quantity !== undefined
                ? checkedRoomtypes[roomtype._id].guests
                : defaultGuests,
            ...details,
        };
        setValues({ ...values, checkedRoomtypes });
    };

    const maxAvailableRooms = () => {
        let max = availableRooms?.length || 0;
        const blockedRoomtypeQuantity =
            dailyData?.blockedRoomsInfo?.blockedRoomtypesQuantity?.find((rt) => rt.id === roomtype._id)?.quantity || 0;
        max -= blockedRoomtypeQuantity;
        return max;
    };

    const assignedRoomsNumber = () => {
        if (values?.isSimpleReservation) return 0;
        return Object.values(values?.checkedRooms || {})?.filter((r) => r?.roomtypeId === roomtype._id)?.length || 0;
    };

    if (maxAvailableRooms() < 1) return null;
    if (availableRooms?.length < 1) return null;

    return (
        <Card sx={{ marginY: 2 }}>
            <CardHeader
                avatar={<RoomtypeInfoModal roomtypeData={roomtype} />}
                title={roomtype?.name}
                titleTypographyProps={{ variant: "h6" }}
                style={{ padding: 0 }}
            />
            {values?.isSimpleReservation ? (
                <CardContent>
                    <Stack direction="row" spacing={2} justifyContent="space-between">
                        <Grid item xs={2}>
                            <SimpleQuantityControl
                                min={0}
                                max={maxAvailableRooms()}
                                value={values?.checkedRoomtypes?.[roomtype._id]?.quantity}
                                onChange={(newValue) => {
                                    checkRoomtype({ quantity: newValue });
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            {availableRatesOptions?.length < 2 ? (
                                <Typography fontStyle="italic" variant="body2">
                                    {t("no_extra_rates")}
                                </Typography>
                            ) : (
                                <SelectControl
                                    options={availableRatesOptions}
                                    margin="none"
                                    label={t("rate")}
                                    value={values?.checkedRoomtypes?.[roomtype._id]?.rate || "standard"}
                                    onChange={(e) => {
                                        checkRoomtype({
                                            rate: e.target.value,
                                        });
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={2}>
                            {roomtype?.capacity?.length ? (
                                <GuestSelect
                                    categories={data?.guestcategories}
                                    capacity={roomtype?.capacity}
                                    maxCapacity={roomtype?.maxCapacity}
                                    value={values?.checkedRoomtypes?.[roomtype._id]?.guests || {}}
                                    onChange={(newGuestsValue) => {
                                        checkRoomtype({ guests: newGuestsValue });
                                    }}
                                />
                            ) : (
                                "--"
                            )}
                        </Grid>
                    </Stack>
                </CardContent>
            ) : (
                <CardContent>
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                        justifyContent="space-between"
                        style={{
                            fontSize: 18,
                            fontWeight: 600,
                            backgroundColor: "#ebeff4",
                            color: "black",
                            textAlign: "center",
                            padding: "5px",
                        }}
                    >
                        <Grid item xs={2} textAlign="left">
                            {t("room_name")}
                        </Grid>
                        <Grid item xs={2} textAlign="left">
                            {t("price")}
                        </Grid>
                        <Grid item xs={2} textAlign="left">
                            {t("rate")}
                        </Grid>
                        <Grid item xs={2}>
                            {t("guests")}
                        </Grid>
                        <Grid item xs={2}>
                            {t("select")}
                        </Grid>
                    </Stack>
                    {availableRooms?.map((room) => {
                        return (
                            <RoomRow
                                roomtype={roomtype}
                                t={t}
                                room={room}
                                key={room?._id}
                                ratesOptions={availableRatesOptions}
                                roomtypePriceDetails={priceDetails}
                                maxRooms={maxAvailableRooms()}
                                assignedRoomsNumber={assignedRoomsNumber()}
                            />
                        );
                    })}
                </CardContent>
            )}
        </Card>
    );
};

export default RoomtypeCard;

const RoomRow = ({
    room = {},
    roomtype = {},
    ratesOptions = [],
    t,
    roomtypePriceDetails,
    maxRooms,
    assignedRoomsNumber,
}) => {
    const [openDiscountModal, setOpenDiscountModal] = useState(false);
    const { values, setValues, data, dailyData } = useContext(CreateReservationContext);
    const checkRoom = (details = {}) => {
        const checkedRooms = { ...(values?.checkedRooms || {}) };
        const defaultGuests = {};
        const defaultGuestCategory = primaryGuestCategory(room.capacity, data?.guestcategories);
        if (!!defaultGuestCategory?._id && !!defaultGuestCategory?.name) {
            const includedCapacity = getRoomDailyCapacity(
                { ...room, rate: details?.rate },
                dailyData.dailyPricing,
                values.checkin
            );
            defaultGuests[defaultGuestCategory?._id] = {
                name: defaultGuestCategory?.name,
                number: includedCapacity || 0,
            };
        }
        if (!checkedRooms[room._id]) {
            checkedRooms[room._id] = {
                _id: room._id,
                name: room.name,
                capacity: room.capacity,
                includedCapacity: room.includedCapacity,
                price: room.price,
                roomtypeId: roomtype._id,
                roomtypeName: roomtype.name,
                guests: defaultGuests,
            };
        }
        checkedRooms[room._id] = {
            ...checkedRooms[room._id],
            guests: details?.guests ? details?.guests : defaultGuests,
            ...details,
        };
        setValues({ ...values, checkedRooms });
    };

    const priceDetails = useMemo(() => {
        const { rate, customPrice, discount } = values?.checkedRooms?.[room._id] || {};
        if (customPrice !== undefined && customPrice !== null)
            return { dailyPrice: customPrice, hasDifferentPrices: false };
        if (!rate || rate === "standard") {
            const { dailyPrice, hasDifferentPrices } = roomtypePriceDetails || {};
            const displayPrice = dailyPrice !== undefined && dailyPrice !== null ? dailyPrice : room.price;
            return {
                dailyPrice: !!discount ? applyDiscount(displayPrice, discount) : displayPrice,
                hasDifferentPrices,
            };
        }
        const { price, hasDifferentPrices } = ratesOptions?.find((r) => r.value === rate) || {};

        return {
            dailyPrice: !!discount ? applyDiscount(price, discount) : price,
            hasDifferentPrices,
        };
    }, [dailyData, data, values?.checkedRooms?.[room._id]]);

    const isRoomDisabled = () => {
        let isRoomDisabled = false;
        if (!values?.checkedRooms?.[room._id]) {
            isRoomDisabled = assignedRoomsNumber >= maxRooms;
        }
        return isRoomDisabled;
    };

    const onRoomPriceChange = () => {
        const { role } = getLoggedUserData() || {};
        if (!role?.permissions?.reservations?.price_change) return false;
        setOpenDiscountModal(true);
    };

    return (
        <>
            <Tooltip followCursor title={isRoomDisabled() && "maximum number of available rooms selected"}>
                <Stack
                    mt={2}
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    sx={{
                        "&:hover": {
                            fontWeight: "600",
                            backgroundColor: "#F2F6FC",
                        },
                        textAlign: "center",
                        padding: "5px",
                    }}
                >
                    <Grid item xs={2} textAlign="left">
                        {room?.name}
                    </Grid>
                    <Grid item xs={2} textAlign="left">
                        <Typography
                            sx={{ cursor: "pointer", color: priceDetails.hasDifferentPrices && "#f07c21" }}
                            onClick={() => {
                                if (isRoomDisabled()) return;
                                onRoomPriceChange();
                            }}
                        >
                            {toCurrency(priceDetails?.dailyPrice, data?.currency)}
                            {priceDetails.hasDifferentPrices ? "*" : ""}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} textAlign="left">
                        {ratesOptions?.length < 2 ? (
                            <Typography fontStyle="italic" variant="body2">
                                {t("no_extra_rates")}
                            </Typography>
                        ) : (
                            <SelectControl
                                disabled={isRoomDisabled()}
                                options={ratesOptions}
                                margin="none"
                                label={t("rate")}
                                value={values?.checkedRooms?.[room._id]?.rate || "standard"}
                                onChange={(e) => {
                                    checkRoom({ rate: e.target.value });
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        {room?.capacity?.length ? (
                            <GuestSelect
                                disabled={isRoomDisabled()}
                                categories={data?.guestcategories}
                                capacity={room?.capacity}
                                maxCapacity={room?.maxCapacity}
                                value={values?.checkedRooms?.[room._id]?.guests || {}}
                                onChange={(newGuestsValue) => {
                                    checkRoom({ guests: newGuestsValue });
                                }}
                            />
                        ) : (
                            "--"
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <CheckboxControl
                            disabled={isRoomDisabled()}
                            value={Boolean(values?.checkedRooms?.[room._id])}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    checkRoom();
                                    return;
                                }
                                const checkedRooms = { ...(values?.checkedRooms || {}) };
                                checkedRooms[room._id] = undefined;
                                setValues({ ...values, checkedRooms });
                            }}
                            color="primary"
                        />
                    </Grid>
                </Stack>
            </Tooltip>
            <RoomDiscountModal
                open={openDiscountModal}
                onClose={() => setOpenDiscountModal(false)}
                data={{
                    ...(values?.checkedRooms?.[room._id]?.discount || {}),
                    customPrice: values?.checkedRooms?.[room._id]?.customPrice,
                }}
                onSave={checkRoom}
                currency={data?.currency}
            />
        </>
    );
};
