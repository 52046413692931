import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { AutoCompleteControl, InputControl, RadioGroupControl, SelectControl } from "../../components/form";
import { useApi } from "../../components/hooks";
import { Modal } from "../../components/modal";
import i18n from "i18next";

const rules = {
    username: "string|required",
    password: "string|required",
    email: "string|required",
    roleId: "string|required",
};

const UserForm = () => {
    const [values, setValues] = useState({});
    const [rolesOptions, setRolesOption] = useState([]);
    const [pospointsOptions, setPospointsOptions] = useState([]);
    const [fiscalBusinesses, setFiscalBusinesses] = useState([]);

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { addRecordUser } = useOutletContext() || {};

    const statusOptions = [
        { value: "ACTIVE", label: t("active") },
        { value: "DEACTIVATED", label: t("deactivated") },
    ];
    const languageOptions = [
        { value: "en", label: t("english") },
        { value: "sq", label: t("albanian") },
        { value: "it", label: t("italian") },
        { value: "de", label: t("german") },
        { value: "fr", label: t("french") },
        { value: "no", label: t("norwegian") },
    ];

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                { endpoint: "roles", responseData: "_id name isWaiter" },
                {
                    endpoint: "pospoints",
                    responseData: "_id name isActive hasTables",
                },
                {
                    endpoint: "businesses",
                    responseData: "_id",
                },
            ],
        });
        if (!!response?.roles) {
            setRolesOption(
                response.roles.map((role) => ({ value: role._id, label: role.name, isWaiter: role.isWaiter }))
            );
        }
        if (!!response?.businesses) {
            setFiscalBusinesses(response.businesses);
        }
        if (!!response?.pospoints) {
            setPospointsOptions(
                response.pospoints.map((pos) => {
                    return {
                        value: pos._id,
                        label: pos.name,
                    };
                })
            );
        }
    };
    const showOperatorCodeField = useMemo(() => {
        if (fiscalBusinesses?.length > 0) return true;
        else return false;
    }, [fiscalBusinesses]);

    useEffect(() => {
        loadData();
    }, []);

    const hasWaiterRole = useMemo(() => {
        const currentRole = rolesOptions.find((r) => r.value === values?.roleId);
        return Boolean(currentRole?.isWaiter);
    }, [values?.roleId]);

    const saveHandler = async () => {
        const { username, password, displayName, email, roleId, posPoints, language, status, operatorCode } = values;
        const data = {
            username,
            password,
            displayName,
            email,
            roleId,
            posPoints,
            language,
            status,
            isWaiter: hasWaiterRole,
            operatorCode,
        };
        const response = await fetch({
            operation: "mutation",
            endpoint: "createUser",
            data,
            responseData: "_id username displayName email language roleId role{name} operatorCode status posPoints",
        });
        if (response?.createUser?._id) {
            enqueueSnackbar(t("user_saved"), { variant: "default" });
            if (!!language) {
                localStorage.setItem("language", language);
                i18n.changeLanguage(language);
            }
            addRecordUser && addRecordUser(response.createUser);

            navigate(-1);
        }
    };

    return (
        <div>
            <Modal
                loading={loading}
                fullWidth
                maxWidth="md"
                open
                titlelabel={t("users_form")}
                onClose={() => navigate(-1)}
                permission={{ users: { create: true } }}
                FormProps={{
                    values,
                    rules,
                    onValuesChange: setValues,
                    onSubmit: saveHandler,
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <InputControl name="username" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputControl name="password" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputControl name="displayName" label={t("display_name")} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputControl name="email" label={t("email")} />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectControl options={rolesOptions} name="roleId" label={t("role")} />
                    </Grid>
                    {showOperatorCodeField ? (
                        <Grid item xs={6}>
                            <InputControl name="operatorCode" label={t("opercator_code")} />
                        </Grid>
                    ) : null}
                    {hasWaiterRole && (
                        <Grid item xs={6}>
                            <AutoCompleteControl
                                multiple
                                name="posPoints"
                                label={t("pos_points")}
                                options={pospointsOptions}
                            />
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        <SelectControl options={languageOptions} name="language" label={t("language")} />
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroupControl options={statusOptions} name="status" label={t("status")} />
                    </Grid>
                </Grid>
            </Modal>
        </div>
    );
};

export default UserForm;
