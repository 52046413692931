import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditableTable } from "../../components/table";
import { Button, LinearProgress, Paper, Stack } from "@mui/material";
import { InputControl, SelectControl } from "../../components/form";
import { useApi, useProductCategories, useProducts } from "../../components/hooks";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";

const InventoryCorrect = () => {
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const [params, setParams] = useSearchParams();

    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [note, setNote] = useState("");
    const [data, setData] = useState([]);
    const { products } = useProducts();
    const { productCategories } = useProductCategories();

    const { enqueueSnackbar } = useSnackbar();

    const loadData = async () => {
        if (!params?.get("warehouse")) {
            enqueueSnackbar(t("please_select_warehouse"), { variant: "warning" });
            return;
        }
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [{ endpoint: "productsInventories", responseData: `_id warehouseId quantity` }],
        });

        if (response?.productsInventories) {
            setData(
                Object.values(
                    response?.productsInventories
                        ?.filter((inventory) => inventory?.warehouseId === params.get("warehouse"))
                        ?.reduce((acc, product) => {
                            const productId = "products_" + product?._id?.split("inventory_")?.[1]?.split("_")?.[0];
                            if (!productId || !product.warehouseId) return acc;
                            const { name, categoryId } = products.find((p) => p._id === productId) || {};
                            if (!!params.get("category") && categoryId !== params.get("category")) return acc;
                            if (!name) return acc;
                            const categoryName = productCategories.find((p) => p._id === categoryId)?.name || "";
                            if (!acc[productId]) {
                                acc[productId] = {
                                    _id: productId,
                                    name,
                                    oldValue: 0,
                                    newValue: null,
                                    categoryName,
                                };
                            }
                            acc[productId].oldValue += product.quantity || 0.0;
                            return acc;
                        }, {}) || {}
                )
            );
        }
    };

    const categoryOptions = useMemo(() => {
        return productCategories?.map((category) => ({ value: category._id, label: category.name }));
    }, [productCategories]);

    useEffect(() => {
        loadExtraData();
    }, []);

    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "warehouses",
            responseData: "_id name",
        });
        if (response) {
            setWarehouseOptions(
                response?.warehouses?.map((warehouse) => ({ value: warehouse._id, label: warehouse.name }))
            );
        }
    };

    const saveHandler = async () => {
        const products = data
            ?.filter((product) => product.newValue !== null && product.newValue !== undefined)
            ?.map((product) => {
                return {
                    _id: product._id,
                    oldValue: parseFloat(product.oldValue),
                    newValue: parseFloat(product.newValue),
                };
            });

        const body = { products: products, note: note, warehouseId: params.get("warehouse") };

        const response = await fetch({
            operation: "mutation",
            endpoint: "createInventoryAdjust",
            data: body,
        });

        if (response?.createInventoryAdjust === "success") {
            loadData();
        }
    };

    const columns = [
        {
            id: "product",
            header: t("product"),
            cell: ({ rowIndex }) => {
                return data[rowIndex]?.name || "";
            },
        },

        {
            id: "category",
            header: t("category"),
            cell: ({ rowIndex }) => {
                return data[rowIndex]?.categoryName || "";
            },
        },
        {
            id: "quantity",
            header: t("quantity"),
            cell: ({ rowIndex }) => {
                const quantity = data[rowIndex].oldValue;
                if (!!quantity) return quantity.toFixed(2);
                return "--";
            },
        },
        {
            id: "adjustedQuantity",
            header: t("adjusted_quantity"),
            cell: ({ rowIndex }) => {
                return (
                    <InputControl
                        type="number"
                        value={data[rowIndex].newValue || ""}
                        onChange={(e) => {
                            setData(
                                data?.map((product) => {
                                    if (product._id === data[rowIndex]?._id) {
                                        return { ...product, newValue: e.target.value };
                                    }
                                    return product;
                                })
                            );
                        }}
                    />
                );
            },
        },
    ];

    const getAllParams = () => {
        const data = {};
        if (!!params.get("warehouse")) data.warehouse = params.get("warehouse");
        if (!!params.get("category")) data.category = params.get("category");
        return data;
    };

    return (
        <>
            {loading && <LinearProgress color="primary" />}
            <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2 }}>
                <Stack direction="row" alignItems="center" gap={2}>
                    <SelectControl
                        options={warehouseOptions}
                        label={t("warehouse")}
                        sx={{ width: "300px" }}
                        value={params.get("warehouse")}
                        onChange={(e) =>
                            setParams({
                                ...getAllParams(),
                                warehouse: e.target.value || "",
                            })
                        }
                    />
                    <SelectControl
                        options={categoryOptions}
                        label={t("category")}
                        sx={{ width: "300px" }}
                        value={params.get("category")}
                        onChange={(e) =>
                            setParams({
                                ...getAllParams(),
                                category: e.target.value || "",
                            })
                        }
                    />
                    <Button onClick={loadData} variant="contained">
                        {t("load_products")}
                    </Button>
                </Stack>

                {!!data.length > 0 && (
                    <Stack marginTop={2} gap={2} alignItems="start">
                        <EditableTable data={data} columns={columns} />
                        <InputControl
                            multiline
                            minRows={3}
                            label={t("note")}
                            value={note || ""}
                            onChange={(e) => {
                                setNote(e.target.value);
                            }}
                        />
                        <Button
                            onClick={saveHandler}
                            variant="contained"
                            color="secondary"
                            style={{ alignSelf: "flex-end" }}
                        >
                            {t("save")}
                        </Button>
                    </Stack>
                )}
            </Paper>
        </>
    );
};

export default InventoryCorrect;
