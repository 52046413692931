import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AutoCompleteControl } from "../../../components/form";
import { useApi } from "../../../components/hooks";
import { CircularProgress } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const InvoicesSearch = ({ onSelect }) => {
    const { t } = useTranslation();
    const [invoiceOptions, setInvoiceOptions] = useState([]);
    const { loading, fetch } = useApi();

    const loadData = async (name) => {
        const response = await fetch({
            operation: "query",
            endpoint: "searchClients",
            data: {
                name,
            },
            responseData: `_id firstName lastName invoices{_id number fiscalData{IICRef IssueDateTime}}`,
        });
        if (response?.searchClients) {
            const options = [];

            response.searchClients?.forEach((client) => {
                const finalizedInvoices =
                    client?.invoices?.filter((invoice) => {
                        return !!invoice?.fiscalData?.IICRef && !!invoice?.fiscalData?.IssueDateTime;
                    }) || [];
                if (!(finalizedInvoices?.length > 0)) return;
                const label = (client.firstName || "") + " " + (client.lastName || "");
                finalizedInvoices?.forEach((invoice) => {
                    options.push({
                        value: invoice._id,
                        label: label + ` #${invoice.number}`,
                        invoiceData: {
                            _id: invoice._id,
                            number: invoice.number,
                            clientsData: client,
                            fiscalData: invoice.fiscalData,
                        },
                    });
                });
            });
            if (options?.length > 0) setInvoiceOptions(options);
        }
    };

    return (
        <AutoCompleteControl
            label={t("search_referrer_invoice")}
            options={invoiceOptions}
            loading={loading}
            onInputChange={(e, text) => {
                if (!!text && text.length === 3) loadData(text);
            }}
            onChange={(e) => {
                const { invoiceData } = invoiceOptions.find((options) => options.value === e.target.value) || {};
                if (!invoiceData) return;
                onSelect && onSelect(invoiceData);
            }}
            inputProps={{
                endAdornment: (
                    <>{loading ? <CircularProgress color="inherit" size={20} /> : <SearchIcon color="inherit" />}</>
                ),
            }}
        />
    );
};

export default InvoicesSearch;
