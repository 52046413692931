import { Button, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ReportGenerator } from "../../components/classes";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { DateRangeControl, TimeControl } from "../../components/dates";
import { AutoCompleteControl } from "../../components/form";
import { useApi } from "../../components/hooks";
import { getLoggedUserData, toCurrency } from "../../utils";
import { ReportHeader } from "./components";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
import { PaymentByMethodsTable } from "../../components/common";

const totalGuestsByCategories = (guests = []) => {
    return guests
        ?.map((room) => room?.guests)
        ?.flat()
        ?.reduce((acc, category) => {
            const existingCategory = acc.find((c) => c.name === category.name);
            if (!existingCategory) {
                acc.push({ name: category.name, number: category.number });
                return acc;
            }
            existingCategory.number += category.number;
            return acc;
        }, [])
        ?.map((category) => `${category.number} ${category.name}`)
        ?.join(" , ");
};

const PaymentsReport = () => {
    const { t } = useTranslation();
    const [params, setParams] = useSearchParams();
    const [extraData, setExtraData] = useState({
        users: [],
        currencyData: {},
    });
    const [report, setReport] = useState(null);
    const [ordersReport, setOrdersReport] = useState(null);
    const [payments, setPayments] = useState({ invoices: [], reservations: [] });

    const { loading, fetch } = useApi();

    const reportRef = useRef(null);
    const ordersReportRef = useRef(null);

    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "users",
                    responseData: "_id displayName status",
                },
                {
                    endpoint: "settingsgeneral",
                    responseData: "currencyData{currency} dailyUseCurrencyData{currency}",
                    data: { _id: "settings_general" },
                },
            ],
        });
        if (response)
            setExtraData({
                users: response?.users
                    ?.filter((user) => user.status === "ACTIVE")
                    ?.map((user) => {
                        return {
                            value: user._id?.split("users_")[1],
                            label: user.displayName || user._id?.split("users_")[1],
                        };
                    }),
                currencyData: {
                    defaultCurrency: response?.settingsgeneral?.currencyData?.currency,
                    dailyUseCurrency: response?.settingsgeneral?.dailyUseCurrencyData?.currency,
                },
            });
    };

    const columns = [
        {
            id: "rooms",
            header: t("rooms"),
            displayValue: (row) => {
                const { rooms } = row?.invoice?.reservationData || row?.reservation || {};
                if (!!rooms) {
                    return rooms?.map((room) => room?.roomData?.name || "").join(" , ") || "";
                } else {
                    return t("Invoice");
                }
            },
            totalValue: () => t("total"),
        },
        {
            id: "name",
            header: t("name"),
            displayValue: (row) => {
                const { firstName, lastName } =
                    row?.invoice?.reservationData?.clientsData?.[0] ||
                    row?.reservation?.clientsData?.[0] ||
                    row?.invoice?.clientsData ||
                    {};
                return `${firstName || ""} ${lastName || ""}`;
            },
            totalValue: () => "",
        },
        {
            id: "id",
            header: t("reservation"),
            displayValue: (row) => row?.invoice?.reservationData?.uuid || row?.reservation?.uuid || "",
            totalValue: () => "",
        },
        {
            id: "checkin",
            header: t("checkin"),
            displayValue: (row) => {
                if (!row?.reservation?.checkin && !row.invoice.reservationData?.checkin) return "";
                else
                    return (
                        DateTime.fromISO(
                            row?.reservation?.checkin || row.invoice.reservationData?.checkin || ""
                        ).toFormat("dd LLL yyyy") || ""
                    );
            },

            totalValue: () => "",
        },
        {
            id: "checkout",
            header: t("checkout"),
            displayValue: (row) => {
                if (!row?.reservation?.checkout && !row.invoice.reservationData?.checkout) return "";

                return (
                    DateTime.fromISO(
                        row?.reservation?.checkout || row.invoice.reservationData?.checkout || ""
                    ).toFormat("dd LLL yyyy") || ""
                );
            },
            totalValue: () => "",
        },
        {
            id: "diff",
            header: t("LOS"),
            displayValue: (row) => {
                const checkin = row?.reservation?.checkin || row.invoice.reservationData?.checkin || "";
                const checkout = row?.reservation?.checkout || row.invoice.reservationData?.checkout || "";
                if (!checkin || !checkout) return "";

                return parseInt(DateTime.fromISO(checkout).diff(DateTime.fromISO(checkin), "days").toObject().days);
            },
            totalValue: () => "",
        },
        {
            id: "guests",
            header: t("guests"),
            displayValue: (row) => {
                const guests = !!row?.invoice
                    ? row?.invoice?.reservationData?.guests || []
                    : row?.reservation?.guests || [];
                return totalGuestsByCategories(guests) || "0";
            },
            totalValue: () => "",
        },
        {
            id: "date",
            header: t("date"),
            displayValue: (row) => DateTime.fromISO(row?.timestamp)?.toFormat("dd LLL yyyy"),
            totalValue: () => "",
        },
        {
            id: "time",
            header: t("time"),
            displayValue: (row) => DateTime.fromISO(row?.timestamp)?.toFormat("HH:mm"),
            totalValue: () => "",
        },
        {
            id: "amount",
            header: t("amount"),
            displayValue: (row) => {
                const { invoiceCurrency } = row?.invoice || row?.reservation;
                if (invoiceCurrency) {
                    return toCurrency(row?.amount, invoiceCurrency);
                }

                if (row?.reservation?.isDailyUse) {
                    const currency =
                        extraData?.currencyData?.dailyUseCurrency || extraData?.currencyData?.defaultCurrency || "ALL";
                    return toCurrency(row?.amount, currency);
                }
                const currency = extraData?.currencyData?.defaultCurrency || "ALL";
                return toCurrency(row?.amount, currency);
            },
            totalValue: (rows) => {
                const totalByCurrencies = {};
                rows?.filter((row) => !row?.isTotal)?.forEach((row) => {
                    const { invoiceCurrency } = row?.invoice || row?.reservation;
                    let currency = !!invoiceCurrency ? invoiceCurrency : extraData?.currencyData?.defaultCurrency;
                    if (!currency) {
                        if (row?.reservation?.isDailyUse) {
                            currency =
                                extraData?.currencyData?.dailyUseCurrency ||
                                extraData?.currencyData?.defaultCurrency ||
                                "ALL";
                        } else {
                            currency = extraData?.currencyData?.defaultCurrency || "ALL";
                        }
                    }

                    if (!totalByCurrencies[currency]) {
                        totalByCurrencies[currency] = 0.0;
                    }
                    totalByCurrencies[currency] += row?.amount;
                });

                return Object.entries(totalByCurrencies)
                    ?.map(([currency, amount]) => {
                        return toCurrency(amount, currency);
                    })
                    ?.join(" , ");
            },
        },
        {
            id: "user",
            header: t("user"),
            displayValue: (row) => row?.userName?.split("users_")?.[1] || "",
            totalValue: () => "",
        },
        {
            id: "method",
            header: t("payment_method"),
            displayValue: (row) => row?.paymentMethodData?.name || "",
            totalValue: () => "",
        },
        {
            id: "notes",
            header: t("notes"),
            displayValue: (row) => row?.note || "",
            totalValue: () => "",
        },
    ];
    const orderPaymentsColumns = [
        {
            id: "rooms",
            header: t("rooms"),
            displayValue: (row) => {
                const { rooms } = row?.order?.reservationData || {};
                return rooms?.map((room) => room?.roomData?.name || "").join(" , ") || "";
            },
            totalValue: () => t("total"),
        },
        {
            id: "name",
            header: t("name"),
            displayValue: (row) => {
                const { firstName, lastName } = row?.order?.reservationData?.clientsData?.[0] || {};
                return `${firstName || ""} ${lastName || ""}`;
            },
            totalValue: () => "",
        },
        {
            id: "id",
            header: t("reservation"),
            displayValue: (row) => row?.order?.reservationData?.uuid || "",
            totalValue: () => "",
        },
        {
            id: "checkin",
            header: t("checkin"),
            displayValue: (row) => {
                if (!row.order?.reservationData?.checkin) return "";
                else return DateTime.fromISO(row.order.reservationData?.checkin || "").toFormat("dd LLL yyyy") || "";
            },

            totalValue: () => "",
        },
        {
            id: "checkout",
            header: t("checkout"),
            displayValue: (row) => {
                if (!row.order?.reservationData?.checkout) return "";

                return DateTime.fromISO(row.order.reservationData?.checkout || "").toFormat("dd LLL yyyy") || "";
            },
            totalValue: () => "",
        },
        {
            id: "diff",
            header: t("LOS"),
            displayValue: (row) => {
                const checkin = row.order.reservationData?.checkin || "";
                const checkout = row.order.reservationData?.checkout || "";
                if (!checkin || !checkout) return "";

                return parseInt(DateTime.fromISO(checkout).diff(DateTime.fromISO(checkin), "days").toObject().days);
            },

            totalValue: () => "",
        },

        {
            id: "date",
            header: t("date"),
            displayValue: (row) => DateTime.fromISO(row?.timestamp)?.toFormat("dd LLL yyyy"),
            totalValue: () => "",
        },
        {
            id: "time",
            header: t("time"),
            displayValue: (row) => DateTime.fromISO(row?.timestamp)?.toFormat("HH:mm"),
            totalValue: () => "",
        },
        {
            id: "amount",
            header: t("amount"),
            displayValue: (row) => {
                const { currency } = row?.order?.pospointData?.currencyData || {};
                if (row?.paymentMethodData?.method === "roomCharge") {
                    return toCurrency(row?.order.totalPrice, currency || "ALL");
                } else {
                    return toCurrency(row?.amount, currency || "ALL");
                }
            },
            totalValue: (rows) => {
                const totalByCurrencies = {};
                rows?.filter((row) => !row?.isTotal)?.forEach((row) => {
                    const currency = row?.order?.pospointData?.currencyData?.currency || "ALL";

                    if (!totalByCurrencies[currency]) {
                        totalByCurrencies[currency] = 0.0;
                    }
                    const amount =
                        row?.paymentMethodData?.method === "roomCharge" ? row?.order.totalPrice : row?.amount;
                    totalByCurrencies[currency] += amount;
                });

                return Object.entries(totalByCurrencies)
                    ?.map(([currency, amount]) => {
                        return toCurrency(amount, currency);
                    })
                    ?.join(" , ");
            },
        },
        {
            id: "number",
            header: t("order_number"),
            displayValue: (row) => row?.order?.number || "",
            totalValue: () => "",
        },
        {
            id: "pos",
            header: t("pospoint"),
            displayValue: (row) => row?.order?.pospointData?.name || "",
            totalValue: () => "",
        },
        {
            id: "user",
            header: t("user"),
            displayValue: (row) => row?.userName?.split("users_")?.[1] || "",
            totalValue: () => "",
        },
        {
            id: "method",
            header: t("payment_method"),
            displayValue: (row) => row?.paymentMethodData?.name || "",
            totalValue: () => "",
        },
        {
            id: "notes",
            header: t("notes"),
            displayValue: (row) => row?.note || "",
            totalValue: () => "",
        },
    ];

    const loadData = async () => {
        if (!(params.get("startDate") && params.get("endDate"))) return;
        const start = !!params.get("startTime")
            ? new Date(`${params.get("startDate")} ${params.get("startTime")}:00`)
            : new Date(`${params.get("startDate")} 00:00:00`);
        const end = !!params.get("endTime")
            ? new Date(`${params.get("endDate")} ${params.get("endTime")}:59`)
            : new Date(`${params.get("endDate")} 23:59:59`);
        const selectedUser = params.get("user");

        let filtersText = "";
        if (!!start) filtersText += t("start_date") + ": " + DateTime.fromISO(start.toJSON()).toFormat("yyyy-LL-dd");
        if (!!end) filtersText += ", " + t("end_date") + ": " + DateTime.fromISO(end.toJSON()).toFormat("yyyy-LL-dd");
        if (!!selectedUser) filtersText += ", " + t("user") + ": " + selectedUser;
        const response = await fetch({
            operation: "query",
            endpoint: "paymentsByDates",
            data: { start: start?.toJSON(), end: end?.toJSON() },
            responseData: `
                invoices{
                    timestamp
                    amount
                    paymentMethod
                    paymentMethodData{name method}
                    userName
                    note
                    invoice{
                        status
                        invoiceCurrency
                        clientsData{_id firstName lastName}
                        reservationData{
                            rooms{roomData{name}}
                            clientsData{_id firstName lastName}
                            uuid
                            checkin 
                            checkout
                            guests{guests{name number}}
                        }
                    }
                }
                reservations{
                    timestamp
                    amount
                    paymentMethod
                    paymentMethodData{name method}
                    userName
                    note
                    reservation{
                        rooms{roomData{name}}
                        clientsData{_id firstName lastName}
                        uuid
                        checkin
                        status
                        checkout
                        isDailyUse
                        invoiceCurrency
                        guests{guests{name number}}
                    }
                }
                orders{
                    timestamp
                    amount
                    paymentMethod
                    paymentMethodData{name method}
                    userName
                    note
                    order{
                        number
                        status
                        pospointData{
                            currencyData{currency} name
                        }
                        reservationId
                        reservationData{
                            rooms{roomData{name}}
                            clientsData{_id firstName lastName}
                            uuid
                            checkin 
                            checkout
                        }
                        totalPrice
                    }
                }
            `,
        });

        if (!response?.paymentsByDates) return;
        if (!!response?.paymentsByDates) {
            setPayments({
                invoices: response.paymentsByDates.invoices,
                reservations: response.paymentsByDates.reservations,
                orders: response.paymentsByDates.orders,
            });
        }
        const newRaportGenerator = new ReportGenerator({
            data: (response?.paymentsByDates?.invoices || [])
                ?.concat(response?.paymentsByDates?.reservations || [])
                ?.filter((payment) => {
                    if (
                        ["TRANSFERRED", "CANCELLED"]?.includes(payment?.invoice?.status || payment?.reservation?.status)
                    )
                        return false;
                    if (params.get("user")) return payment.userName === "users_" + params.get("user");
                    return true;
                }),
            columns,
            displayTotal: true,
        });
        const newOrdersRaportGenerator = new ReportGenerator({
            data: response?.paymentsByDates?.orders?.filter((payment) => {
                if (["TRANSFERRED", "CANCELLED"]?.includes(payment?.order?.status)) {
                    return false;
                }
                if (!payment?.order?.reservationId) {
                    return false;
                }
                if (params.get("user")) return payment.userName === "users_" + params.get("user");
                return true;
            }),
            columns: orderPaymentsColumns,
            displayTotal: true,
        });
        setReport(
            newRaportGenerator.generateTable({
                title: t("payments"),
                headerInfo: [filtersText],
                landscape: true,
                tableRef: reportRef,
            })
        );
        setOrdersReport(
            newOrdersRaportGenerator.generateTable({
                title: t("order_payments"),
                headerInfo: [filtersText],
                landscape: true,
                tableRef: ordersReportRef,
            })
        );
    };

    const paymentsTotalByMethods = useMemo(() => {
        const paymentsByMethods = {};
        payments?.invoices
            ?.filter((invoicePayment) => {
                if (["TRANSFERRED", "CANCELLED"]?.includes(invoicePayment?.invoice?.status)) {
                    return false;
                }
                if (params.get("user")) return invoicePayment.userName === "users_" + params.get("user");
                return true;
            })
            ?.forEach((invoicePayment) => {
                const method = invoicePayment?.paymentMethodData?.name || "cash";
                if (!paymentsByMethods[method]) {
                    paymentsByMethods[method] = {};
                }
                const currency = !!invoicePayment?.invoice?.invoiceCurrency
                    ? invoicePayment?.invoice?.invoiceCurrency
                    : extraData?.currencyData?.defaultCurrency;
                if (!currency) return;
                if (!paymentsByMethods[method][currency]) {
                    paymentsByMethods[method][currency] = 0.0;
                }
                paymentsByMethods[method][currency] += invoicePayment.amount;
            });
        payments?.reservations
            ?.filter((resPayment) => {
                if (["CANCELLED"]?.includes(resPayment?.reservation?.status)) {
                    return false;
                }
                if (params.get("user")) return resPayment.userName === "users_" + params.get("user");
                return true;
            })
            ?.forEach((reservationPayment) => {
                const method = reservationPayment?.paymentMethodData?.name || "cash";
                if (!paymentsByMethods[method]) {
                    paymentsByMethods[method] = {};
                }
                let currency =
                    reservationPayment?.reservation?.invoiceCurrency || extraData?.currencyData?.defaultCurrency;
                if (reservationPayment?.reservation?.isDailyUse) {
                    currency =
                        reservationPayment?.reservation?.invoiceCurrency || extraData?.currencyData?.dailyUseCurrency;
                }

                if (!currency) return;
                if (!paymentsByMethods[method][currency]) {
                    paymentsByMethods[method][currency] = 0.0;
                }
                paymentsByMethods[method][currency] += reservationPayment.amount;
            });
        payments?.orders
            ?.filter((orderPayment) => {
                if (["TRANSFERRED", "CANCELLED"]?.includes(orderPayment?.order?.status)) {
                    return false;
                }
                if (!orderPayment?.order?.reservationId) {
                    return false;
                }
                if (params.get("user")) return orderPayment.userName === "users_" + params.get("user");
                return true;
            })
            ?.forEach((orderPayment) => {
                const method = orderPayment?.paymentMethodData?.name || "cash";
                if (!paymentsByMethods[method]) {
                    paymentsByMethods[method] = {};
                }
                const currency = !!orderPayment?.order?.pospointData?.currencyData?.currency
                    ? orderPayment?.order?.pospointData?.currencyData?.currency
                    : "ALL";
                if (!currency) return;
                if (!paymentsByMethods[method][currency]) {
                    paymentsByMethods[method][currency] = 0.0;
                }
                const amount =
                    orderPayment?.paymentMethodData?.method === "roomCharge"
                        ? orderPayment?.order.totalPrice
                        : orderPayment?.amount;
                paymentsByMethods[method][currency] += amount;
            });

        return paymentsByMethods;
    }, [payments, extraData?.currencyData]);

    const exportToPdf = () => {
        const doc = new jsPDF("l", "mm", [297, 210]);
        const landscape = true;
        const xPosition = landscape === true ? 186 : 0;
        const { _id, displayName } = getLoggedUserData();
        const totalPagesExp = "{total_pages_count_string}";

        const start = !!params.get("startTime")
            ? new Date(`${params.get("startDate")} ${params.get("startTime")}:00`)
            : new Date(`${params.get("startDate")} 00:00:00`);
        const end = !!params.get("endTime")
            ? new Date(`${params.get("endDate")} ${params.get("endTime")}:59`)
            : new Date(`${params.get("endDate")} 23:59:59`);
        const selectedUser = params.get("user");

        let filtersText = "";
        if (!!start) filtersText += t("start_date") + ": " + DateTime.fromISO(start.toJSON()).toFormat("yyyy-LL-dd");
        if (!!end) filtersText += ", " + t("end_date") + ": " + DateTime.fromISO(end.toJSON()).toFormat("yyyy-LL-dd");
        if (!!selectedUser) filtersText += ", " + t("user") + ": " + selectedUser;

        const reportHeaders = reportRef?.current?.getTableData()?.[0]?.map((row) => row?.header);
        const reportRows = reportRef?.current?.getTableData()?.map((row) => row?.map((col) => col?.value));

        const ordersReportHeaders = ordersReportRef?.current?.getTableData()?.[0]?.map((row) => row?.header);
        const ordersReportRows = ordersReportRef?.current?.getTableData()?.map((row) => row?.map((col) => col?.value));

        const headerInfo = [filtersText];
        doc.autoTable({
            margin: { top: 50 },
            head: [reportHeaders],
            body: reportRows,
            didDrawPage: (data) => {
                doc.setFontSize(18);
                doc.setTextColor(40);
                doc.text(t("payments_report"), 200 - xPosition, 15, landscape === true ? "left" : "right");
                doc.setFontSize(10);
                doc.setFont("helvetica", "italic");
                doc.text(
                    "Time Created: " +
                        DateTime.now().toFormat("yyyy-LL-dd HH:mm") +
                        ` by ${displayName || _id?.split("users_")?.[1]}`,
                    data.settings.margin.left + 0,
                    21
                );
                if (headerInfo?.length) {
                    headerInfo?.forEach((row, i) => {
                        doc.text(row, data.settings.margin.left + 0, i * 5 + 26);
                    });
                }
                doc.setFontSize(18);
                doc.setTextColor(40);
                doc.text(t("reservation_payments"), 200 - xPosition, 45, landscape === true ? "left" : "right");
            },
        });

        doc.setFontSize(18);
        doc.setTextColor(40);
        doc.text(
            t("order_payments"),
            200 - xPosition,
            doc.autoTable.previous.finalY + 15,
            landscape === true ? "left" : "right"
        );

        doc.autoTable({
            startY: doc.autoTable.previous.finalY + 20,
            head: [ordersReportHeaders],
            body: ordersReportRows,
        });

        // autoTable(doc, {
        //     margin: { top: 40 },

        //     head: [
        //         [
        //             t("rooms"),
        //             t("name"),
        //             t("reservation"),
        //             t("date"),
        //             t("time"),
        //             t("amount"),
        //             t("user"),
        //             t("payment_method"),
        //             t("notes"),
        //         ],
        //     ],
        //     body: payments?.invoices
        //         ?.concat(payments?.reservations)
        //         ?.filter((payment) => {
        //             if (
        //                 ["TRANSFERRED", "CANCELLED"]?.includes(payment?.invoice?.status || payment?.reservation?.status)
        //             )
        //                 return false;
        //             if (!payment?.invoice?.reservationData && !payment?.reservation) return false;
        //             if (params.get("user")) return payment.userName === "users_" + params.get("user");
        //             return true;
        //         })
        //         ?.map((payment) => {
        //             const { rooms } = payment?.invoice?.reservationData || payment?.reservation || {};
        //             const r = rooms?.map((room) => room?.roomData?.name || "").join(" , ") || "";
        //             const { firstName, lastName } =
        //                 payment?.invoice?.reservationData?.clientsData?.[0] ||
        //                 payment?.reservation?.clientsData?.[0] ||
        //                 {};
        //             const clientName = `${firstName || ""} ${lastName || ""}`;
        //             const uuid = payment?.invoice?.reservationData?.uuid || payment?.reservation?.uuid || "";

        //             let amount = 0.0;
        //             const { invoiceCurrency } = payment?.invoice || payment?.reservation;
        //             if (invoiceCurrency) {
        //                 amount = toCurrency(payment?.amount, invoiceCurrency);
        //             }
        //             if (payment?.reservation?.isDailyUse) {
        //                 const currency =
        //                     extraData?.currencyData?.dailyUseCurrency ||
        //                     extraData?.currencyData?.defaultCurrency ||
        //                     "ALL";
        //                 amount = toCurrency(payment?.amount, currency);
        //             }
        //             const currency = extraData?.currencyData?.defaultCurrency || "ALL";
        //             amount = toCurrency(payment?.amount, currency);

        //             return [
        //                 r,
        //                 clientName,
        //                 uuid,
        //                 DateTime.fromISO(payment?.timestamp)?.toFormat("yyyy LLL dd"),
        //                 DateTime.fromISO(payment?.timestamp)?.toFormat("HH:mm"),
        //                 amount,
        //                 payment?.userName?.split("users_")?.[1] || "",
        //                 payment?.paymentMethodData?.name || "",
        //                 payment?.note || "",
        //             ];
        //         }),
        //     didDrawPage: (data) => {
        //         // Date
        //         doc.setFontSize(10);
        //         doc.setFont("helvetica", "italic");
        //         doc.text(
        //             `${t("time_created")}: ` + DateTime.now().toFormat("dd-LL-yyyy HH:mm"),
        //             data.settings.margin.left + 0,
        //             28
        //         );
        //         doc.text(filtersText, data.settings.margin.left + 0, 35);

        //         // Footer
        //         var str = "Page " + doc.internal.getNumberOfPages();
        //         // Total page number plugin only available in jspdf v1.0+
        //         if (typeof doc.putTotalPages === "function") {
        //             str = str + " of " + totalPagesExp;
        //         }
        //         doc.setFontSize(10);

        //         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        //         var pageSize = doc.internal.pageSize;
        //         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        //         doc.text(str, data.settings.margin.left, pageHeight - 10);

        //         // var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        //         var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        //         var footerText = "HB";

        //         doc.setTextColor(100);
        //         doc.setFontSize(10);
        //         doc.text(footerText, pageWidth / 2, pageHeight - 10, "center");
        //     },
        // });

        autoTable(doc, {
            margin: { top: 30 },
            head: [[t("payment_method"), t("amount")]],
            body: Object.entries(paymentsTotalByMethods)?.map(([method, currencies]) => [
                method,
                Object.entries(currencies)
                    ?.map(([currency, amount]) => toCurrency(amount, currency))
                    ?.join(" , "),
            ]),
        });
        if (typeof doc.putTotalPages === "function") {
            doc.putTotalPages(totalPagesExp);
        }

        doc.save(`paymentsReport.pdf`);
    };

    const getAllParams = () => {
        const data = {};
        if (!!params.get("startDate")) data.startDate = params.get("startDate");
        if (!!params.get("endDate")) data.endDate = params.get("endDate");
        if (!!params.get("startTime")) data.startTime = params.get("startTime");
        if (!!params.get("endTime")) data.endTime = params.get("endTime");
        if (!!params.get("user")) data.user = params.get("user");
        return data;
    };

    return (
        <div>
            <ReportHeader
                title={t("payments_report")}
                loading={loading}
                onReportRun={() => {
                    loadData();
                }}
            >
                <Stack direction="row" alignItems="center" gap={2}>
                    <DateRangeControl
                        startLabel={t("start_date")}
                        endLabel={t("end_date")}
                        values={{ startDate: params.get("startDate"), endDate: params.get("endDate") }}
                        onChange={({ startDate, endDate }) =>
                            setParams({
                                ...getAllParams(),
                                startDate: startDate || "",
                                endDate: endDate || "",
                            })
                        }
                    />
                    <TimeControl
                        value={params.get("startTime")}
                        onChange={(e) =>
                            setParams({
                                ...getAllParams(),
                                startTime: e.target.value || "",
                            })
                        }
                        margin="none"
                        fullWidth={false}
                        label={t("start_date_time")}
                    />
                    <TimeControl
                        value={params.get("endTime")}
                        onChange={(e) =>
                            setParams({
                                ...getAllParams(),
                                endTime: e.target.value || "",
                            })
                        }
                        margin="none"
                        fullWidth={false}
                        label={t("end_date_time")}
                    />
                    <AutoCompleteControl
                        options={extraData?.users}
                        value={params.get("user")}
                        onChange={(e) =>
                            setParams({
                                ...getAllParams(),
                                user: e.target.value || "",
                            })
                        }
                        margin="none"
                        sx={{ width: "200px" }}
                        label={t("user")}
                    />
                    {!!report && (
                        <Button startIcon={<PictureAsPdfIcon />} onClick={() => exportToPdf()} variant="outlined">
                            {t("print")}
                        </Button>
                    )}
                </Stack>
            </ReportHeader>
            {!!report && (
                <Stack marginBottom={2}>
                    <PaymentByMethodsTable data={paymentsTotalByMethods} />
                </Stack>
            )}

            {report}
            <div style={{ marginTop: 20 }}>{ordersReport}</div>
        </div>
    );
};

export default PaymentsReport;
