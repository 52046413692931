import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout, { NotFoundPage } from "./components/layout";
import { HomePage } from "./modules/home";
import Login from "./modules/login";
import {
    ForecastReport,
    GeneralManagerReport,
    GuestsReport,
    InventoryReport,
    LogsReport,
    MealsReport,
    PaymentsReport,
    ProductsInventoryReport,
    ProductsReport,
    ReportsDashboard,
    RoomTypeSalesReport,
    ShiftReport,
    UnpaidBalanceReport,
    UserSalesReport,
} from "./modules/reports";
import { PrivateRoute } from "./utils";
import { CurrencyForm, CurrencyList } from "./modules/currencies";
import { BillCategoryForm } from "./modules/billcategories";
import { FacilityForm, FacilityList } from "./modules/facilities";
import { GuestCategoryForm, GuestCategoryList } from "./modules/guestcategories";
import { BedTypeForm } from "./modules/bedtypes";
import { DiscountForm, DiscountList } from "./modules/discounts";
import { BookingSourceForm, BookingSourceList } from "./modules/bookingsources";
import { ProductCategoryForm, ProductCategoryList } from "./modules/products/productcategories";
import { ProductMainCategoryForm, ProductMainCategoryList } from "./modules/products/productmaincategories";
import { PaymentAccountList } from "./modules/paymentaccounts";
import { ExtraServiceForm, ExtraServiceList } from "./modules/extraservices";
import { TaxForm, TaxList } from "./modules/taxes";
import { PromoCodeForm, PromoCodeList } from "./modules/promocodes";
import BedTypeList from "./modules/bedtypes/BedTypeList";
import BillCategoryList from "./modules/billcategories/BillCategoryList";
import { PaymentMethodForm, PaymentMethodList } from "./modules/paymentmethods";
import PaymentAccountForm from "./modules/paymentaccounts/PaymentAccountForm";
import { RoleForm, RoleList } from "./modules/roles";
import { UserForm, UserList, UserProfile } from "./modules/users";
import { SettingsGeneralForm } from "./modules/settings/general";
import { SettingsPropertyForm } from "./modules/settings/property";
import { SubscriptionPage } from "./modules/settings/subscription";
import RoomtypeForm from "./modules/roomtypes/RoomtypeForm";
import { RoomtypeList } from "./modules/roomtypes";
import { RoomForm, RoomList } from "./modules/rooms";
import { RateForm, RateList } from "./modules/rates";
import HousekeepingForm from "./modules/housekeepings/HousekeepingForm";
import { HousekeepingList } from "./modules/housekeepings";
import { LostFoundForm, LostFoundList } from "./modules/lostfounds";
import { ProductForm, ProductImports, ProductList } from "./modules/products/products";
import { LiveOrdersPrinting, PospointForm, PospointList, PospointTables } from "./modules/pospoints";
import { Pos } from "./modules/sell";
import { WarehouseForm, WarehouseList } from "./modules/warehouses";
import { OrderList, OrderView } from "./modules/orders";
import { PosplaceDisplay, PosplaceForm, PosplaceList } from "./modules/posplaces";
import { PosDashboard, WaiterShift, HelpDashboard, NightAudit } from "./modules/dashboards";
import { ClientForm, ClientList, ClientProfile } from "./modules/clients";
import {
    ReservationList,
    EditReservationView,
    CreateReservation,
    ReservationsImport,
    ReservationsCalendar,
    DailyReservationsCalendar,
    BlockRoomReservationView,
} from "./modules/reservations";
import { InvoiceForm, InvoiceList, InvoicesMerge } from "./modules/invoices";
import ExpenseList from "./modules/expenses/ExpenseList";
import { BillForm, BillList } from "./modules/bills";
import { TransactionForm, TransactionList } from "./modules/transactions";
import ReservationView from "./modules/reservations/ReservationView";
import FrontdeskDashboard from "./modules/dashboards/FrontdeskDashboard";
import { AvailabilityForm, AvailabilityList } from "./modules/availabilities";
import { SupplierForm, SupplierList, SupplierProfile } from "./modules/suppliers";
import { ExpenseForm } from "./modules/expenses";
import { EstimateForm, EstimateList } from "./modules/estimates";
import { ArrivalsDepartures } from "./modules/reservations/components";
import BussinessList from "./integrations-modules/fiscalbusiness/BusinessList";
import { BusinessForm } from "./integrations-modules/fiscalbusiness";
import { CashdeskDepositForm, CashdeskForm, CashdeskList } from "./integrations-modules/fiscalcashdesk";
import { ServiceForm, ServiceList } from "./modules/services";
import { GroupList, GroupProfile } from "./modules/groups";
import { InventoryCorrect, InventoryForm, InventoryList } from "./modules/inventory";
import MigrationPage from "./modules/MigrationPage";
import { TagForm, TagList } from "./modules/tags";
import { Button } from "@mui/material";
import ConfirmOrderList from "./modules/orders/ConfirmOrderList";
import { SettingsEmailForm, SettingsEmailList } from "./modules/settings/email";
import ChannelManagerForm from "./modules/settings/beds24/ChannelManagerForm";
import WaitersLogin from "./modules/login/WaitersLogin";
import SettingsAlphawebForm from "./modules/settings/alphaweb/SettingsAlphaweb";
import PosDiscountList from "./modules/posdiscounts/PosDiscountList";
import PosDiscountForm from "./modules/posdiscounts/PosDiscountForm";
import SettingsBilancForm from "./modules/settings/bilanc/SettingsBilanc";

const OrderViewRoute = (
    <Route
        path=":id"
        element={
            <PrivateRoute permission={{ orders: { view: true } }}>
                <OrderView />
            </PrivateRoute>
        }
    />
);

const ClientCreateRoute = (
    <Route
        path="create"
        element={
            <PrivateRoute permission={{ clients: { view: true } }}>
                <ClientForm />
            </PrivateRoute>
        }
    />
);
const ClientEditRoute = (
    <Route
        path="edit"
        element={
            <PrivateRoute permission={{ clients: { view: true } }}>
                <ClientForm />
            </PrivateRoute>
        }
    />
);
const ClientProfileRoute = (
    <Route
        path="profile/:clientId"
        element={
            <PrivateRoute permission={{ clients: { view: true } }}>
                <ClientProfile />
            </PrivateRoute>
        }
    >
        {ClientEditRoute}
    </Route>
);

const InvoiceFormRoute = (
    <Route
        path=":invoiceId"
        element={
            <PrivateRoute permission={{ invoices: { view: true } }}>
                <InvoiceForm />
            </PrivateRoute>
        }
    >
        <Route path="client">{ClientCreateRoute}</Route>
    </Route>
);

const ReservationViewRoute = (
    <Route
        path=":id"
        element={
            <PrivateRoute permission={{ reservations: { view: true } }}>
                <ReservationView />
            </PrivateRoute>
        }
    >
        <Route
            path="edit"
            element={
                <PrivateRoute permission={{ reservations: { view: true } }}>
                    <EditReservationView />
                </PrivateRoute>
            }
        />
        <Route path="clients">{ClientProfileRoute}</Route>
        <Route path="clients/:clientId">{ClientEditRoute}</Route>
        <Route path="clients">{ClientCreateRoute}</Route>
        <Route path="orders">{OrderViewRoute}</Route>
        <Route path="invoices">{InvoiceFormRoute}</Route>
    </Route>
);

const AppRoutes = (props) => {
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path="*" element={<NotFoundPage />} />

                <Route path="/">
                    <Route
                        path=""
                        element={
                            <PrivateRoute permission={{ dashboard: { view: true } }}>
                                <FrontdeskDashboard />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="housekeeping"
                        element={
                            <PrivateRoute permission={{ housekeeping: { list: true } }}>
                                <HousekeepingList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ housekeeping: { view: true } }}>
                                    <HousekeepingForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="lostfounds"
                        element={
                            <PrivateRoute permission={{ lostfounds: { list: true } }}>
                                <LostFoundList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ lostfounds: { view: true } }}>
                                    <LostFoundForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="nightaudit"
                        element={
                            <PrivateRoute permission={{ reservations: { list: true } }}>
                                <NightAudit {...props} />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="reservationimports"
                        element={
                            <PrivateRoute permission={{ reservations: { view: true } }}>
                                <ReservationsImport {...props} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="reservations"
                        element={
                            <PrivateRoute permission={{ reservations: { list: true } }}>
                                <ReservationList {...props} />
                            </PrivateRoute>
                        }
                    >
                        {ReservationViewRoute}
                    </Route>
                    <Route
                        path="newreservation"
                        element={
                            <PrivateRoute permission={{ reservations: { view: true } }}>
                                <CreateReservation {...props} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="arrivals"
                        element={
                            <PrivateRoute permission={{ reservations: { view: true } }}>
                                <ArrivalsDepartures {...props} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="calendar"
                        element={
                            <PrivateRoute permission={{ reservations: { list: true } }}>
                                <ReservationsCalendar {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route path="reservations">{ReservationViewRoute}</Route>
                        <Route
                            path="blocked-rooms/:id"
                            element={
                                <PrivateRoute permission={{ reservations: { view: true } }}>
                                    <BlockRoomReservationView {...props} />
                                </PrivateRoute>
                            }
                        ></Route>
                    </Route>
                    <Route
                        path="dailycalendar"
                        element={
                            <PrivateRoute permission={{ reservations: { list: true } }}>
                                <DailyReservationsCalendar {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route path="reservations">{ReservationViewRoute}</Route>
                    </Route>
                </Route>
                <Route path="/">
                    <Route
                        path="clients"
                        element={
                            <PrivateRoute permission={{ clients: { list: true } }}>
                                <ClientList {...props} />
                            </PrivateRoute>
                        }
                    >
                        {ClientProfileRoute}
                        {ClientCreateRoute}
                    </Route>
                    <Route
                        path="groups"
                        element={
                            <PrivateRoute permission={{ groups: { list: true } }}>
                                <GroupList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path="profile/:id"
                            element={
                                <PrivateRoute permission={{ groups: { view: true } }}>
                                    <GroupProfile {...props} />
                                </PrivateRoute>
                            }
                        >
                            <Route
                                path="addClient/:clientId"
                                element={
                                    <PrivateRoute permission={{ clients: { view: true } }}>
                                        <ClientForm {...props} />
                                    </PrivateRoute>
                                }
                            />
                        </Route>
                    </Route>
                </Route>

                <Route path="/reports">
                    <Route
                        path=""
                        element={
                            <PrivateRoute permission={{ reports: { list: true } }}>
                                <ReportsDashboard />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="mealsreport"
                        element={
                            <PrivateRoute permission={{ reports: { list: true } }}>
                                <MealsReport />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="paymentsreport"
                        element={
                            <PrivateRoute permission={{ reports: { list: true } }}>
                                <PaymentsReport />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="productsreport"
                        element={
                            <PrivateRoute permission={{ reports: { list: true } }}>
                                <ProductsReport />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="shiftreport"
                        element={
                            <PrivateRoute permission={{ reports: { list: true } }}>
                                <ShiftReport />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="usersalesreport"
                        element={
                            <PrivateRoute permission={{ reports: { list: true } }}>
                                <UserSalesReport />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="logsreport"
                        element={
                            <PrivateRoute permission={{ reports: { list: true } }}>
                                <LogsReport />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="productsinventoryreport"
                        element={
                            <PrivateRoute permission={{ reports: { list: true } }}>
                                <ProductsInventoryReport />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="inventoryreport"
                        element={
                            <PrivateRoute permission={{ reports: { list: true } }}>
                                <InventoryReport />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="guestsreport"
                        element={
                            <PrivateRoute permission={{ reports: { list: true } }}>
                                <GuestsReport />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="generalmanagerreport"
                        element={
                            <PrivateRoute permission={{ reports: { list: true } }}>
                                <GeneralManagerReport />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="forecastreport"
                        element={
                            <PrivateRoute permission={{ reports: { list: true } }}>
                                <ForecastReport />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="roomtypesalesreport"
                        element={
                            <PrivateRoute permission={{ reports: { list: true } }}>
                                <RoomTypeSalesReport />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="unpaidbalancereport"
                        element={
                            <PrivateRoute permission={{ reports: { list: true } }}>
                                <UnpaidBalanceReport />
                            </PrivateRoute>
                        }
                    />
                </Route>
                <Route path="/pos">
                    <Route
                        path=""
                        element={
                            <PrivateRoute permission={{ pos: { view: true } }}>
                                <PosDashboard {...props} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="productcategories"
                        element={
                            <PrivateRoute permission={{ productcategories: { list: true } }}>
                                <ProductCategoryList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ productcategories: { view: true } }}>
                                    <ProductCategoryForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="productmaincategories"
                        element={
                            <PrivateRoute permission={{ productmaincategories: { list: true } }}>
                                <ProductMainCategoryList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ productmaincategories: { view: true } }}>
                                    <ProductMainCategoryForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="products"
                        element={
                            <PrivateRoute permission={{ products: { list: true } }}>
                                <ProductList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ products: { view: true } }}>
                                    <ProductForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="productimports"
                        element={
                            <PrivateRoute permission={{ products: { view: true } }}>
                                <ProductImports {...props} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="extraservices"
                        element={
                            <PrivateRoute permission={{ services: { list: true } }}>
                                <ExtraServiceList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ services: { view: true } }}>
                                    <ExtraServiceForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="suppliers"
                        element={
                            <PrivateRoute permission={{ suppliers: { list: true } }}>
                                <SupplierList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path="profile/:id"
                            element={
                                <PrivateRoute permission={{ suppliers: { view: true } }}>
                                    <SupplierProfile {...props} />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ suppliers: { view: true } }}>
                                    <SupplierForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="posdiscounts"
                        element={
                            <PrivateRoute permission={{ posdiscounts: { list: true } }}>
                                <PosDiscountList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ posdiscounts: { view: true } }}>
                                    <PosDiscountForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="pospoints"
                        element={
                            <PrivateRoute permission={{ pospoints: { list: true } }}>
                                <PospointList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ pospoints: { view: true } }}>
                                    <PospointForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="live-print"
                        element={
                            <PrivateRoute permission={{ orders: { live_print: true } }}>
                                <LiveOrdersPrinting />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="inventory-correct"
                        element={
                            <PrivateRoute permission={{ inventories: { adjust: true } }}>
                                <InventoryCorrect />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="confirm-orders"
                        element={
                            <PrivateRoute permission={{ orders: { list: true } }}>
                                <ConfirmOrderList {...props} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="warehouses"
                        element={
                            <PrivateRoute permission={{ warehouses: { list: true } }}>
                                <WarehouseList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ warehouses: { view: true } }}>
                                    <WarehouseForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="orders"
                        element={
                            <PrivateRoute permission={{ orders: { list: true } }}>
                                <OrderList {...props} />
                            </PrivateRoute>
                        }
                    >
                        {OrderViewRoute}
                    </Route>
                    <Route
                        path="posplaces"
                        element={
                            <PrivateRoute permission={{ posplaces: { list: true } }}>
                                <PosplaceList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ posplaces: { view: true } }}>
                                    <PosplaceForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="inventory"
                        element={
                            // <PrivateRoute permission={{ posplaces: { list: true } }}>
                            <InventoryList {...props} />
                            // </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                // <PrivateRoute permission={{ posplaces: { view: true } }}>
                                <InventoryForm {...props} />
                                // </PrivateRoute>
                            }
                        />
                    </Route>
                </Route>

                <Route path="/backoffice">
                    <Route
                        path="availabilities"
                        element={
                            <PrivateRoute permission={{ availability: { list: true } }}>
                                <AvailabilityList />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ availability: { view: true } }}>
                                    <AvailabilityForm />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="facilities"
                        element={
                            <PrivateRoute permission={{ facilities: { list: true } }}>
                                <FacilityList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ facilities: { view: true } }}>
                                    <FacilityForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="tags"
                        element={
                            <PrivateRoute permission={{ tags: { list: true } }}>
                                <TagList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ tags: { view: true } }}>
                                    <TagForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="roomtypes"
                        element={
                            <PrivateRoute permission={{ roomtypes: { list: true } }}>
                                <RoomtypeList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ roomtypes: { view: true } }}>
                                    <RoomtypeForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="services"
                        element={
                            <PrivateRoute permission={{ services: { list: true } }}>
                                <ServiceList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ services: { view: true } }}>
                                    <ServiceForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="rooms"
                        element={
                            <PrivateRoute permission={{ rooms: { list: true } }}>
                                <RoomList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ rooms: { view: true } }}>
                                    <RoomForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="roomrates"
                        element={
                            <PrivateRoute permission={{ roomrates: { list: true } }}>
                                <RateList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ roomrates: { view: true } }}>
                                    <RateForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="promocodes"
                        element={
                            <PrivateRoute permission={{ discounts: { list: true } }}>
                                <PromoCodeList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ discounts: { view: true } }}>
                                    <PromoCodeForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="guestcategories"
                        element={
                            <PrivateRoute permission={{ guestcategories: { list: true } }}>
                                <GuestCategoryList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ guestcategories: { view: true } }}>
                                    <GuestCategoryForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="bedtypes"
                        element={
                            <PrivateRoute permission={{ bedtypes: { list: true } }}>
                                <BedTypeList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ bedtypes: { view: true } }}>
                                    <BedTypeForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="discounts"
                        element={
                            <PrivateRoute permission={{ discounts: { list: true } }}>
                                <DiscountList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ discounts: { view: true } }}>
                                    <DiscountForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="bookingsources"
                        element={
                            <PrivateRoute permission={{ bookingsources: { list: true } }}>
                                <BookingSourceList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ bookingsources: { view: true } }}>
                                    <BookingSourceForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                </Route>
                <Route path="/finance">
                    <Route
                        path="invoices-merge"
                        element={
                            <PrivateRoute permission={{ invoices: { list: true } }}>
                                <InvoicesMerge {...props} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="invoices"
                        element={
                            <PrivateRoute permission={{ invoices: { list: true } }}>
                                <InvoiceList {...props} />
                            </PrivateRoute>
                        }
                    >
                        {InvoiceFormRoute}
                    </Route>
                    <Route
                        path="expenses"
                        element={
                            <PrivateRoute permission={{ expenses: { list: true } }}>
                                <ExpenseList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ expenses: { view: true } }}>
                                    <ExpenseForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="bills"
                        element={
                            <PrivateRoute permission={{ bills: { list: true } }}>
                                <BillList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ bills: { view: true } }}>
                                    <BillForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="estimates"
                        element={
                            <PrivateRoute permission={{ estimates: { list: true } }}>
                                <EstimateList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ estimates: { view: true } }}>
                                    <EstimateForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="transactions"
                        element={
                            <PrivateRoute permission={{ transactions: { list: true } }}>
                                <TransactionList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ transactions: { view: true } }}>
                                    <TransactionForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="billcategories"
                        element={
                            <PrivateRoute permission={{ billcategories: { list: true } }}>
                                <BillCategoryList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ billcategories: { view: true } }}>
                                    <BillCategoryForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="paymentaccounts"
                        element={
                            <PrivateRoute permission={{ payments: { list: true } }}>
                                <PaymentAccountList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ payments: { view: true } }}>
                                    <PaymentAccountForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="taxes"
                        element={
                            <PrivateRoute permission={{ taxes: { list: true } }}>
                                <TaxList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ taxes: { view: true } }}>
                                    <TaxForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="paymentmethods"
                        element={
                            <PrivateRoute permission={{ payments: { list: true } }}>
                                <PaymentMethodList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ payments: { view: true } }}>
                                    <PaymentMethodForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                </Route>
                <Route path="/settings">
                    <Route
                        path=""
                        element={
                            <PrivateRoute permission={{ settings: { view: true } }}>
                                <SettingsGeneralForm {...props} />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="property"
                        element={
                            <PrivateRoute permission={{ property: { view: true } }}>
                                <SettingsPropertyForm {...props} />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="subscription"
                        element={
                            <PrivateRoute permission={{ subscription: { view: true } }}>
                                <SubscriptionPage {...props} />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path="email"
                        element={
                            <PrivateRoute permission={{ property: { list: true } }}>
                                <SettingsEmailList {...props} />
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route path="fiscal">
                        <Route
                            path="business"
                            element={
                                <PrivateRoute permission={{ cashdesks: { list: true } }}>
                                    <BussinessList />
                                </PrivateRoute>
                            }
                        >
                            <Route path=":id" element={<BusinessForm />}></Route>
                        </Route>
                        <Route
                            path="cashdesk"
                            element={
                                <PrivateRoute permission={{ cashdesks: { list: true } }}>
                                    <CashdeskList />
                                </PrivateRoute>
                            }
                        >
                            <Route path=":id" element={<CashdeskForm />}></Route>
                            <Route path="deposit/:id" element={<CashdeskDepositForm />}></Route>
                        </Route>
                    </Route>
                    <Route
                        path="currencies"
                        element={
                            <PrivateRoute permission={{ currencies: { list: true } }}>
                                <CurrencyList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ currencies: { view: true } }}>
                                    <CurrencyForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="channel-manager"
                        element={
                            <PrivateRoute permission={{ settings: { view: true } }}>
                                <ChannelManagerForm {...props} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="alphaweb"
                        element={
                            <PrivateRoute permission={{ settings: { list: true } }}>
                                <SettingsAlphawebForm {...props} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="bilanc"
                        element={
                            <PrivateRoute permission={{ settings: { list: true } }}>
                                <SettingsBilancForm {...props} />
                            </PrivateRoute>
                        }
                    />
                </Route>
                <Route path="/accounts">
                    <Route
                        path="roles"
                        element={
                            <PrivateRoute permission={{ roles: { list: true } }}>
                                <RoleList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ roles: { view: true } }}>
                                    <RoleForm {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="users"
                        element={
                            <PrivateRoute permission={{ users: { list: true } }}>
                                <UserList {...props} />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path="create"
                            element={
                                <PrivateRoute permission={{ users: { view: true } }}>
                                    <UserForm {...props} />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path=":id"
                            element={
                                <PrivateRoute permission={{ users: { view: true } }}>
                                    <UserProfile {...props} />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                </Route>
                <Route
                    path="/settings/email/:id"
                    element={
                        <PrivateRoute permission={{ property: { view: true } }}>
                            <SettingsEmailForm {...props} />
                        </PrivateRoute>
                    }
                />
                <Route path="/help" element={<HelpDashboard />} />
            </Route>

            <Route path="/login" element={<Login {...props} />} />
            <Route path="/pos/sell-login" element={<WaitersLogin {...props} />} />
            <Route
                path="/pos/tables"
                element={
                    <PrivateRoute permission={{ sell: { view: true } }}>
                        <PospointTables {...props} />
                    </PrivateRoute>
                }
            />
            <Route
                path="/pos/sell"
                element={
                    <PrivateRoute permission={{ sell: { view: true } }}>
                        <Pos {...props} />
                    </PrivateRoute>
                }
            />
            <Route
                path="/pos/posplaces-display/:id"
                element={
                    <PrivateRoute permission={{ posplaces: { view: true } }}>
                        <PosplaceDisplay {...props} />
                    </PrivateRoute>
                }
            />
            <Route
                path="/pos/myshift"
                element={
                    <PrivateRoute permission={{ my_shift: { view: true } }}>
                        <WaiterShift {...props} />
                    </PrivateRoute>
                }
            >
                <Route path="order">{OrderViewRoute}</Route>
            </Route>
            <Route path="/migration" element={<MigrationPage {...props} />} />
        </Routes>
    );
};

export default AppRoutes;
