import { Button, Stack, Typography, Card, CardContent } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../components/hooks";
import { Modal } from "../../../components/modal";
import PaymentsIcon from "@mui/icons-material/Payments";
import { RadioGroupControl, AutoCompleteControl } from "../../../components/form";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { getLoggedUserData } from "../../../utils";

const EditPaymentMethodModal = ({ order, onConfirm, disabled = false }) => {
    const { t } = useTranslation();
    const [state, setState] = useState({
        openModal: false,
        openConfirmationModal: false,
        selectedMethod: null,
        selectedRoom: null,
        reservation: null,
    });
    const [roomsOptions, setRoomsOptions] = useState([]);

    const { fetch, loading } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    const paymentMethodsOptions = useMemo(() => {
        return order?.pospointData?.activeMethodsData
            ?.filter((method) => {
                if (method.method === "roomCharge" && order?.payments?.paymentMethod === method._id) {
                    return false;
                }
                return true;
            })
            ?.map((method) => {
                return { value: method._id, label: method.name };
            });
    }, [order?.pospointData, order?.payments]);

    const isRoomChargeOrder = useMemo(() => {
        const roomChargeIdPaymentMethod = order?.pospointData?.activeMethodsData?.find(
            (method) => method.method === "roomCharge"
        )?._id;
        if (order?.payments?.paymentMethod === roomChargeIdPaymentMethod) return true;
        return false;
    }, [order?.pospointData, order?.payments]);

    const isRoomChargeSelected = useMemo(() => {
        const { method } =
            order?.pospointData?.activeMethodsData?.find((method) => method._id === state.selectedMethod) || {};
        return method === "roomCharge";
    }, [state.selectedMethod]);

    const loadRooms = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "rooms",
            responseData: "_id name",
        });
        if (response?.rooms) {
            setRoomsOptions(
                response?.rooms?.map((room) => {
                    return { value: room._id, label: room.name };
                })
            );
        }
    };

    useEffect(() => {
        if (!isRoomChargeSelected) return;
        if (roomsOptions?.length) return;
        loadRooms();
    }, [isRoomChargeSelected, roomsOptions]);

    const loadStayovers = async (selectedRoom) => {
        const response = await fetch({
            operation: "query",
            endpoint: "activeReservations",
            responseData: "_id uuid checkin checkout status rooms{roomId} clientsData{firstName lastName}",
            data: { date: DateTime.now().toFormat("yyyy-LL-dd") },
        });

        if (response?.activeReservations) {
            const roomReservations = response.activeReservations?.filter(
                (res) => res.rooms.some((room) => room.roomId === selectedRoom) && res.status === "CHECKIN"
            );

            if (roomReservations?.length > 0) {
                setState({ ...state, reservation: roomReservations?.[0] });
            } else {
                enqueueSnackbar(t("no_reservation"), { variant: "warning" });
            }
        }
    };

    useEffect(() => {
        if (!state.selectedRoom) return;
        loadStayovers(state.selectedRoom);
    }, [state.selectedRoom]);

    const updateOrder = async () => {
        const data = {
            _id: order._id,
            payments: {
                paymentMethod: state.selectedMethod,
                cashReceived: isRoomChargeSelected ? 0 : order.totalPrice,
            },
        };
        if (isRoomChargeSelected) {
            data.reservationId = state.reservation._id;
            if (order?.fiscalData?.nivf) {
                data.disableTransfer = true;
            }
        }

        const response = await fetch({
            operation: "mutation",
            endpoint: "updateOrderPayment",
            data,
            responseData:
                "_id disableTransfer payments{timestamp paymentMethod paymentMethodData{name method} paymentStatus cashReceived}",
        });
        if (response?.updateOrderPayment?._id) {
            setState({
                ...state,
                openModal: false,
                openConfirmationModal: false,
                selectedMethod: null,
                selectedRoom: null,
                reservation: null,
            });
            onConfirm(response?.updateOrderPayment);
        }
    };

    const onPaymentChangeConfirmRequest = () => {
        const { _id } = getLoggedUserData();
        if (order?.createdBy !== _id) {
            setState({ ...state, openConfirmationModal: true });
        } else {
            updateOrder();
        }
    };

    return (
        <>
            <Button
                startIcon={<PaymentsIcon />}
                variant="outlined"
                disabled={disabled}
                onClick={() => setState({ ...state, openModal: true })}
            >
                {isRoomChargeOrder ? t("add_payment") : t("edit_payment_method")}
            </Button>
            <Modal
                loading={loading}
                maxWidth="sm"
                open={state.openModal}
                onClose={() =>
                    setState({ ...state, openModal: false, selectedMethod: null, openConfirmationModal: false })
                }
                onSave={() => onPaymentChangeConfirmRequest()}
                noText="none"
                yesText={t("confirm")}
                yesDisabled={loading || !state.selectedMethod || (isRoomChargeSelected && !state.reservation)}
                titlelabel={t("payment_method")}
            >
                <RadioGroupControl
                    value={state.selectedMethod}
                    onChange={(e) => setState({ ...state, selectedMethod: e.target.value })}
                    options={paymentMethodsOptions || []}
                />
                {isRoomChargeSelected && (
                    <Stack marginTop={2}>
                        <Typography variant="caption">*{t("choose_room_to_transfer_order")}</Typography>
                        <AutoCompleteControl
                            value={state.selectedRoom}
                            onChange={(e) => setState({ ...state, selectedRoom: e.target.value })}
                            label={t("room")}
                            options={roomsOptions}
                        />
                        {state.reservation && (
                            <Card>
                                <CardContent>
                                    <Typography variant="caption">
                                        {t("reservation")}: {state.reservation.uuid}
                                    </Typography>
                                    <Typography variant="h6">
                                        {state.reservation.clientsData?.[0].firstName +
                                            " " +
                                            state.reservation.clientsData?.[0].lastName}
                                    </Typography>
                                    <Typography>
                                        {DateTime.fromISO(state.reservation.checkin).toFormat("dd LLL yyyy")} -{" "}
                                        {DateTime.fromISO(state.reservation.checkout).toFormat("dd LLL yyyy")}
                                    </Typography>
                                </CardContent>
                            </Card>
                        )}
                    </Stack>
                )}
            </Modal>
            <ConfirmationModal
                open={state.openConfirmationModal}
                onConfirm={updateOrder}
                onClose={() => setState({ ...state, openConfirmationModal: false, openModal: false })}
            />
        </>
    );
};

const ConfirmationModal = ({ open, onConfirm, onClose }) => {
    const { t } = useTranslation();
    return (
        <>
            <Modal
                open={open}
                onClose={() => onClose()}
                yesText={t("confirm")}
                onSave={() => {
                    onConfirm();
                    onClose();
                }}
                maxWidth="xs"
            >
                {t("order_amount_will_be_transferred_to_your_balance_after_payment_method_change")}
            </Modal>
        </>
    );
};

export default EditPaymentMethodModal;
