import { Button, Grid, LinearProgress, Tab, Tabs, Typography, Divider, useMediaQuery, Stack } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import HotelIcon from "@mui/icons-material/Hotel";
import ReceiptIcon from "@mui/icons-material/Receipt";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CancelIcon from "@mui/icons-material/Cancel";
import HubIcon from "@mui/icons-material/Hub";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
    AutoCompleteControl,
    CheckboxControl,
    Form,
    InputControl,
    SelectControl,
    SwitchControl,
} from "../../../components/form";
import { useApi } from "../../../components/hooks";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { SettingsCard, SubmitButton } from "./components";
import {
    invoiceTypeCodeOptions,
    paymentMeansCodeOptions,
    profileOptions,
} from "../../invoices/components/fiscalOptions";
import { ProtectedAction } from "../../../utils";

const languageOptions = [
    { value: "en", label: "english" },
    { value: "sq", label: "albanian" },
    { value: "it", label: "italian" },
    { value: "de", label: "german" },
    { value: "fr", label: "french" },
    { value: "no", label: "norwegian" },
];
const checkinOptions = [
    { value: "personalInformation", label: "personal_information" },
    { value: "contactInformation", label: "contact_information" },
    { value: "documentInformation", label: "document_information" },
];

const SettingsGeneralForm = () => {
    const [values, setValues] = useState({});
    const [tabValue, setTabValue] = useState(0);
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [guestCategoriesOptions, setGuestCategoriesOptions] = useState([]);
    const [settingsProperty, setSettingsProperty] = useState({});
    const [fiscalBusinesses, setFiscalBusinesses] = useState([]);
    const [newFieldType, setNewFieldType] = useState("");

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

    const calendarOrderOptions = [
        { value: "default", label: t("default") },
        { value: "alphabetical", label: t("alphabetical") },
        { value: "roomOrder", label: t("roomOrder") },
    ];

    useEffect(() => {
        loadData();
    }, []);
    useEffect(() => {
        loadExtraData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "settingsgeneral",
            data: {
                _id: "settings_general",
            },
            responseData: `_id language footerInvoiceNotes adminEmails calendarOrder requiredCheckinInfo currencyRateAuto defaultAdultCategory defaultChildrenCategory offlinePos
            paymentMeansCode currency dailyReservationsCurrency limitCheckout limitCancel profileID invoiceTypeCode reservationExtraDetails silentPrintWaiterShift`,
        });

        if (response?.settingsgeneral) {
            setValues(response.settingsgeneral);
        }
    };
    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                { endpoint: "currencies", responseData: "_id currency" },
                { endpoint: "guestcategories", responseData: "_id name" },
                { endpoint: "businesses", responseData: "_id" },
                { endpoint: "settingsproperty", data: { _id: "settings_property" }, responseData: "_id country" },
            ],
        });
        if (!!response?.currencies) {
            setCurrencyOptions(
                response.currencies.map((currency) => ({ value: currency._id, label: currency.currency }))
            );
        }
        if (!!response?.guestcategories) {
            setGuestCategoriesOptions(
                response.guestcategories.map((category) => ({ value: category._id, label: category.name }))
            );
        }
        if (!!response?.settingsproperty) {
            setSettingsProperty(response.settingsproperty);
        }
        if (!!response?.businesses) {
            setFiscalBusinesses(response.businesses);
        }
    };

    const saveHandler = async () => {
        const {
            language,
            currency,
            dailyReservationsCurrency,
            currencyRateAuto,
            calendarOrder,
            profileID,
            invoiceTypeCode,
            paymentMeansCode,
            adminEmails,
            limitCheckout,
            limitCancel,
            defaultAdultCategory,
            defaultChildrenCategory,
            reservationExtraDetails,
            silentPrintWaiterShift,
            offlinePos,
            requiredCheckinInfo,
            footerInvoiceNotes,
        } = values;
        const data = {
            language,
            adminEmails,
            calendarOrder,
            currencyRateAuto: currencyRateAuto,
            profileID,
            invoiceTypeCode,
            paymentMeansCode,
            currency,
            dailyReservationsCurrency,
            limitCheckout,
            limitCancel,
            defaultAdultCategory,
            defaultChildrenCategory,
            reservationExtraDetails,
            silentPrintWaiterShift,
            offlinePos,
            requiredCheckinInfo,
            footerInvoiceNotes,
        };
        const response = await fetch({
            operation: "mutation",
            endpoint: values?._id ? "updateSettingsGeneral" : "createSettingsGeneral",
            data,
            responseData: "_id",
        });
        localStorage.setItem("offlinePos", JSON.stringify(offlinePos || false));
        if (response?.createSettingsGeneral?._id || response?.updateSettingsGeneral?._id)
            enqueueSnackbar(t("settings_general_saved"), { variant: "default" });
    };
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const addDynamicField = () => {
        setValues({ ...values, reservationExtraDetails: [...(values?.reservationExtraDetails || []), newFieldType] });
        setNewFieldType("");
    };

    const showFiscalSection = useMemo(() => {
        if (settingsProperty?.country === "ALBANIA") return true;
        else return false;
    }, [settingsProperty]);

    const showFiscalInvoiceFields = useMemo(() => {
        if (fiscalBusinesses?.length > 0) return true;
        else return false;
    }, [fiscalBusinesses]);

    const renderTabs = () => {
        switch (tabValue) {
            case 0:
                return (
                    <SettingsCard title={t("general")} icon={<SettingsIcon />}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <SelectControl name="language" label={t("language")} options={languageOptions} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectControl name="currency" label={t("currency")} options={currencyOptions} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SwitchControl name="currencyRateAuto" label={t("currency_rate_auto")} />
                            </Grid>
                        </Grid>
                    </SettingsCard>
                );
            case 1:
                return (
                    <SettingsCard title={t("reservation")} icon={<HotelIcon />}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <SelectControl
                                    options={calendarOrderOptions}
                                    name="calendarOrder"
                                    label={t("calendar_order")}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectControl
                                    name="dailyReservationsCurrency"
                                    label={t("daily_reservations_currency")}
                                    options={currencyOptions}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SwitchControl
                                    name="limitCancel"
                                    label={t("limit_cancel_if_reservation_status_is_checkin")}
                                />
                                <SwitchControl
                                    name="limitCheckout"
                                    label={t("limit_checkout_if_reservation_is_not_fully_paid")}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6">{t("required_checkin_info")}</Typography>
                                <Divider />
                                {checkinOptions.map((option) => (
                                    <CheckboxControl
                                        value={values?.requiredCheckinInfo?.includes(option.value) || false}
                                        onChange={(e) => {
                                            if (e.target.checked)
                                                setValues({
                                                    ...values,
                                                    requiredCheckinInfo: [
                                                        ...(values.requiredCheckinInfo || []),
                                                        option.value,
                                                    ],
                                                });
                                            else
                                                setValues({
                                                    ...values,
                                                    requiredCheckinInfo: values.requiredCheckinInfo.filter(
                                                        (op) => op !== option.value
                                                    ),
                                                });
                                        }}
                                        label={t(option.label)}
                                    />
                                ))}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">{t("default_guests_categories_channel_manager")}</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body2">{t("default_adult_category")}</Typography>
                                <SelectControl
                                    name="defaultAdultCategory"
                                    options={guestCategoriesOptions}
                                    label={t("guest_categories")}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body2">{t("default_child_category")}</Typography>
                                <SelectControl
                                    name="defaultChildrenCategory"
                                    options={guestCategoriesOptions}
                                    label={t("guest_categories")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">{t("other_configurations")}</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="subtitle1">{t("custom_fields")}</Typography>
                                <Stack direction="row" gap={2} justifyContent="start" alignItems="center">
                                    <InputControl
                                        label={t("field_name")}
                                        value={newFieldType || ""}
                                        onChange={(e) => setNewFieldType(e.target.value)}
                                    />

                                    <Button
                                        fullWidth
                                        variant="contained"
                                        onClick={() => {
                                            if (
                                                !!newFieldType &&
                                                !values?.reservationExtraDetails?.includes(newFieldType)
                                            ) {
                                                addDynamicField();
                                            }
                                        }}
                                    >
                                        {t("add_field")}
                                    </Button>
                                </Stack>
                                {values?.reservationExtraDetails?.map((field, index) => (
                                    <Stack marginTop={2} gap={1} direction="row" alignItems="center">
                                        <Typography variant="subtitle2">{field}</Typography>
                                        <Button
                                            startIcon={<CancelIcon />}
                                            onClick={() => {
                                                setValues({
                                                    ...values,
                                                    reservationExtraDetails: values?.reservationExtraDetails?.filter(
                                                        (value) => value !== values?.reservationExtraDetails[index]
                                                    ),
                                                });
                                            }}
                                        />
                                    </Stack>
                                ))}
                            </Grid>
                        </Grid>
                    </SettingsCard>
                );
            case 2:
                return (
                    <SettingsCard title={t("invoice")} icon={<ReceiptIcon />}>
                        <Grid container spacing={2}>
                            {showFiscalInvoiceFields ? (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <SelectControl
                                            name="profileID"
                                            label={t("profileId")}
                                            options={profileOptions}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SelectControl
                                            name="invoiceTypeCode"
                                            label={t("invoiceTypeCode")}
                                            options={invoiceTypeCodeOptions}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SelectControl
                                            name="paymentMeansCode"
                                            label={t("paymentMeansCode")}
                                            options={paymentMeansCodeOptions}
                                        />
                                    </Grid>
                                </>
                            ) : null}
                            <Grid item xs={12} sm={12}>
                                <InputControl
                                    name="footerInvoiceNotes"
                                    label={t("footer_invoice_notes")}
                                    multiline
                                    minRows={2}
                                />
                            </Grid>
                        </Grid>
                    </SettingsCard>
                );
            case 3:
                return (
                    <SettingsCard title={t("pos")} icon={<NotificationsIcon />}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <SwitchControl
                                    name="silentPrintWaiterShift"
                                    label={t("print_waiter_shift_to_default_printer")}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SwitchControl name="offlinePos" label={t("offline_pos")} />
                            </Grid>
                        </Grid>
                    </SettingsCard>
                );
            case 4:
                return (
                    <SettingsCard title={t("communication")} icon={<NotificationsIcon />}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AutoCompleteControl name="adminEmails" label={t("admin_emails")} multiple />
                            </Grid>
                        </Grid>
                    </SettingsCard>
                );
            case 5:
                return (
                    <SettingsCard
                        title={t("integrations")}
                        icon={<HubIcon />}
                        action={
                            <SubmitButton
                                onClick={saveHandler}
                                size="medium"
                                variant="outlined"
                                permission={{ settings: values?._id ? { edit: true } : { create: true } }}
                            />
                        }
                    >
                        <Typography variant="h6" sx={{ marginTop: "10px" }}>
                            {t("paypal_settings")}
                        </Typography>
                        <Divider sx={{ marginBottom: "30px" }} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <InputControl name="paypalEmail" label={t("paypalEmail")} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputControl name="paypalPercentage" type="number" label={t("paypalPercentage")} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SwitchControl name="activatePaypal" label={t("activate_paypal")} />
                            </Grid>
                        </Grid>
                        <Typography variant="h6" sx={{ marginTop: "10px" }}>
                            {t("channel_manager")}
                        </Typography>
                        <Divider sx={{ marginBottom: "30px" }} />
                        <Grid item xs={12} sm={6}>
                            <Stack direction="row" gap={2}>
                                <Button
                                    component={Link}
                                    to="/settings/channel-manager"
                                    sx={{ borderRadius: "25px" }}
                                    variant="contained"
                                    color="link"
                                >
                                    {t("connection_settings")}
                                </Button>
                            </Stack>
                        </Grid>
                        {showFiscalSection ? (
                            <>
                                <Typography variant="h6" sx={{ marginTop: "30px" }}>
                                    {t("fiscal_settings")}
                                </Typography>
                                <Divider sx={{ marginBottom: "30px" }} />
                                <Stack direction="row" gap={2}>
                                    <Button
                                        component={Link}
                                        to="/settings/fiscal/business"
                                        sx={{ borderRadius: "25px" }}
                                        variant="contained"
                                        color="link"
                                    >
                                        {t("fiscal_settings")}
                                    </Button>
                                    <Button
                                        component={Link}
                                        to="/settings/fiscal/cashdesk"
                                        sx={{ borderRadius: "25px" }}
                                        variant="contained"
                                        color="link"
                                    >
                                        {t("fiscal_cashdesk")}
                                    </Button>
                                </Stack>
                            </>
                        ) : null}
                        <Typography variant="h6" sx={{ marginTop: "30px" }}>
                            {t("other")}
                        </Typography>
                        <Divider sx={{ marginBottom: "30px" }} />
                        <Stack direction="row" gap={2}>
                            <Button
                                component={Link}
                                to="/settings/email"
                                sx={{ borderRadius: "25px" }}
                                variant="contained"
                                color="link"
                            >
                                {t("email_settings")}
                            </Button>
                            <Button
                                component={Link}
                                to="/settings/alphaweb"
                                sx={{ borderRadius: "25px" }}
                                variant="contained"
                                color="link"
                            >
                                {t("alphaweb_settings")}
                            </Button>
                            <Button
                                component={Link}
                                to="/settings/bilanc"
                                sx={{ borderRadius: "25px" }}
                                variant="contained"
                                color="link"
                            >
                                {t("bilanc_settings")}
                            </Button>
                        </Stack>
                    </SettingsCard>
                );
            default:
                return null;
        }
    };

    const settingsTabs = [
        "general",
        "reservation_settings",
        "invoice_settings",
        "pos",
        "communication",
        "integrations",
    ];

    return (
        <Form values={values} onValuesChange={setValues}>
            {loading && <LinearProgress color="primary" />}
            <div style={{ minHeight: "calc(100vh - 150px)" }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={3}>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            orientation={isSmallScreen ? "horizontal" : "vertical"}
                            variant="scrollable"
                        >
                            {settingsTabs.map((tab) => (
                                <Tab sx={{ alignItems: "flex-start" }} label={t(tab)} />
                            ))}
                        </Tabs>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        {renderTabs(tabValue)}
                    </Grid>
                </Grid>
            </div>
            <Grid container xs={12} justifyContent="end" style={{ marginTop: 20 }}>
                <SubmitButton
                    onClick={saveHandler}
                    permission={{ settings: values?._id ? { edit: true } : { create: true } }}
                />
            </Grid>
        </Form>
    );
};

export default SettingsGeneralForm;
