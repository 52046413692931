import {
    AppBar,
    Badge,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    Typography,
    SwipeableDrawer,
    ButtonBase,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Divider,
    useMediaQuery,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import MenuIcon from "@mui/icons-material/Menu";
import CachedIcon from "@mui/icons-material/Cached";
import PersonIcon from "@mui/icons-material/Person";
import React, { useEffect, useMemo, useState } from "react";
import { OrdersDrawer, PaymentConfirmationModal, ProductsView, ReceiptPreview, SelectClientModal } from "./components";
import { InputControl } from "../../components/form";
import { useProducts, useProductCategories, useApi } from "../../components/hooks";
import { useTranslation } from "react-i18next";
import { calculateTotalTax, getLoggedUserData } from "../../utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { sendFiscalInvoice } from "../../components/integrations/fiscal";
import { kitchenReceipt, print, printFiscal } from "../../components/pdf/printReceipt";
import { DateTime } from "luxon";
import { Logo } from "../../components/common";
import { toCurrency } from "../../utils";
import { isMobileDevice } from "./isMobileDevice";
import { CURRENCIES, ORDER } from "../../utils/DataTypes";

const calculateTotalDiscount = (totalPrice = 0, discount = {}) => {
    if (!discount.type || !discount.value) return 0;
    const discountAmount =
        discount.type === "total" ? parseFloat(discount.value) : totalPrice * parseFloat(discount.value) * 0.01;
    if (isNaN(discountAmount)) return 0;
    return discountAmount;
};

const Pos = () => {
    const [lastOrderVersion, setLastOrderVersion] = useState(null);
    const [orderedItems, setOrderedItems] = useState([]);
    const [courses, setCourses] = useState(null);
    const [activeCourse, setActiveCourse] = useState(null);
    const [openOrders, setOpenOrders] = useState([]);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [openOrdersDrawer, setOpenOrdersDrawer] = useState(false);
    const [openClientModal, setClientModal] = useState(false);
    const [disableActions, setDisableActions] = useState(false); // disable all order actions (save, pay, ...) once pressed
    const [searchedProductName, setSearchedProductName] = useState();
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [note, setNote] = useState("");
    const [hideNoteOnReceipt, setHideNoteOnReceipt] = useState(false);
    const [numberOfPersons, setNumberOfPersons] = useState();
    const [clientReservations, setClientReservations] = useState();
    const [selectedReservation, setSelectedReservation] = useState();
    const [selectedRoom, setSelectedRoom] = useState();
    const [rooms, setRooms] = useState([]);
    const [reservationCustomer, setReservationCustomer] = useState({});
    const [discount, setDiscount] = useState({});

    const [settingsProperty, setSettingsProperty] = useState({});
    const [settingsGeneral, setSettingsGeneral] = useState({});
    const [posplaces, setPosplaces] = useState([]);
    const [currencies, setCurrencies] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const _id = searchParams.get("order");
    const posId = searchParams.get("pos");
    const reservationId = searchParams.get("reservationId");

    const [pospoint, setPospoint] = useState({});
    const { productCategories, productMainCategories, reloadProductCategories } = useProductCategories();
    const { products, loadingProducts, reloadProducts } = useProducts();
    const [openReceiptDrawer, setOpenReceiptDrawer] = useState(false);
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const [anchorMenu, setAnchorMenu] = useState(null);

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (products.length > 0 && _id) {
            loadOrder();
        }
    }, [products, _id]);

    useEffect(() => {
        loadOpenOrders();
    }, []);

    useEffect(() => {
        loadPospoint();
    }, []);

    useEffect(() => {
        if (!!selectedCustomer?.customerId) loadClientReservations();
    }, [selectedCustomer?.customerId]);
    useEffect(() => {
        if (!!selectedRoom) loadStayovers();
    }, [selectedRoom]);

    const loadClientReservations = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "client",
            data: {
                _id: selectedCustomer?.customerId,
            },
            responseData: `_id  
                reservations{
                    _id 
                    checkin 
                    checkout 
                    clientsData{firstName lastName} 
                }`,
        });
        if (response?.client?._id)
            setClientReservations(
                response.client?.reservations?.map((res) => ({
                    value: res._id,
                    label: res.checkin + " " + res.checkout,
                }))
            );
    };

    const loadStayovers = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "activeReservations",
            responseData: "_id checkin checkout status rooms{roomId}",
            data: { date: DateTime.now().toFormat("yyyy-LL-dd") },
        });

        if (response?.activeReservations) {
            const roomReservations = response.activeReservations?.filter(
                (res) => res.rooms.some((room) => room.roomId === selectedRoom) && res.status === "CHECKIN"
            );

            if (roomReservations?.length > 0) {
                setSelectedReservation(roomReservations?.[0]._id);
                setClientModal(false);
            } else {
                enqueueSnackbar(t("no_reservation"), { variant: "warning" });
                setSelectedReservation(null);
            }
        }
    };

    const disableProductRemove = useMemo(() => {
        if (!_id) return false;
        const { role } = getLoggedUserData() || {};
        if (role?.isWaiter) return true;
        if (role?.permissions?.orders?.correct) return false;
        return true;
    }, [_id]);

    const disablePriceChange = useMemo(() => {
        const { role } = getLoggedUserData() || {};

        if (role?.permissions?.orders?.change_price_in_sell) return false;
        return true;
    }, []);

    const disableAddCourse = useMemo(() => {
        if (!_id) return false;
        if (lastOrderVersion?.products?.some((p) => p?.courses?.length > 0)) return false;
        return true;
    });

    // Disable user products click or order save when sell has open order that is not loaded at the moment
    const disableOpenOrderActions = useMemo(() => {
        if (!_id) return false;
        if (!lastOrderVersion) return true;
        return false;
    }, [_id, lastOrderVersion]);

    const addItem = (item) => {
        if (disableOpenOrderActions) return;
        if (!!courses) {
            addItemToCourse(item, !!activeCourse ? activeCourse : courses[0]);
            return;
        }
        if (orderedItems.some((i) => i.itemId === item.itemId)) {
            setOrderedItems(
                orderedItems.map((i) => {
                    if (i.itemId === item.itemId) i.quantity += 1;
                    return i;
                })
            );
            return;
        }
        setOrderedItems([
            ...orderedItems,
            {
                itemId: item.itemId,
                quantity: 1,
                price: item.price,
                name: item.name,
                taxData: item.taxData,
                note: item.note || "",
            },
        ]);
    };

    const editItem = (item) => {
        if (!!courses) {
            editCourseItem(item);
            return;
        }
        if (!item) return;
        if (item.quantity > 0) {
            setOrderedItems(
                orderedItems.map((i) => {
                    if (i.itemId === item.itemId) {
                        const editedItem = JSON.parse(JSON.stringify(item));
                        const previousOrderProductState = lastOrderVersion?.products?.find(
                            (product) => product.productId === i.itemId
                        );
                        if (!previousOrderProductState) {
                            editedItem.price = disablePriceChange ? i.price : item.price;
                            return editedItem;
                        }
                        editedItem.price = i.price;
                        if (previousOrderProductState.quantity > item.quantity) {
                            editedItem.quantity = disableProductRemove ? i.quantity : item.quantity;
                        }
                        return editedItem;
                    }
                    return i;
                })
            );
            return;
        }
        removeItem(item.itemId);
    };

    const removeItem = (itemId) => {
        if (!!courses) {
            removeItemFromCourse(itemId, !!activeCourse ? activeCourse : courses[0]);
            return;
        }
        if (disableProductRemove) {
            if (lastOrderVersion?.products?.some((product) => product.productId === itemId)) return;
        }
        setOrderedItems(orderedItems.filter((i) => i.itemId !== itemId));
    };

    const addCourse = () => {
        if (disableProductRemove) {
            if (!lastOrderVersion?.products?.some((product) => !!product?.courses)) return;
        }
        const courseId = Date.now();
        if (!courses) {
            const courseId = Date.now();
            setOrderedItems(
                orderedItems.map((item) => {
                    if (!item.courseId) item.courseId = courseId;
                    return item;
                })
            );
        }
        setCourses(!!courses ? [...courses, courseId] : [courseId]);
        setActiveCourse(courseId);
    };
    const addItemToCourse = (item, courseId) => {
        if (orderedItems.some((i) => i.itemId === item.itemId && i.courseId === courseId)) {
            setOrderedItems(
                orderedItems.map((i) => {
                    if (i.itemId === item.itemId && i.courseId === courseId) i.quantity += 1;
                    return i;
                })
            );
            return;
        }
        setOrderedItems([
            ...orderedItems,
            {
                itemId: item.itemId,
                quantity: 1,
                price: item.price,
                name: item.name,
                taxData: item.taxData,
                note: item.note || "",
                courseId,
            },
        ]);
    };
    const removeItemFromCourse = (itemId, courseId) => {
        if (disableProductRemove) {
            if (
                lastOrderVersion?.products?.some(
                    (product) => product.productId === itemId && product?.courses?.some((c) => c.courseId === courseId)
                )
            )
                return;
        }
        setOrderedItems(orderedItems.filter((i) => !(i.itemId === itemId && i.courseId === courseId)));
    };
    const editCourseItem = (item) => {
        if (!item) return;
        if (item.quantity > 0) {
            if (disableProductRemove) {
                if (
                    lastOrderVersion?.products?.some(
                        (product) =>
                            product.productId === item.itemId &&
                            product?.courses?.some(
                                (c) =>
                                    c.courseId === item.courseId &&
                                    (c.quantity > item.quantity || product.price !== item.price)
                            )
                    )
                )
                    return;
            }
            setOrderedItems(
                orderedItems.map((i) => {
                    if (i.itemId === item.itemId) {
                        if (i.courseId === item.courseId) {
                            i = item;
                        }
                        if (parseFloat(i.price) !== parseFloat(item.price)) {
                            i.price = item.price;
                        }
                    }

                    return i;
                })
            );
            return;
        }
        removeItemFromCourse(item.itemId, item.courseId);
    };
    const removeCourse = (courseId) => {
        if (disableProductRemove) {
            if (lastOrderVersion?.products?.some((product) => product?.courses?.some((c) => c.courseId === courseId)))
                return;
        }
        setOrderedItems(orderedItems.filter((item) => item.courseId !== courseId));
        setCourses(courses.length === 1 ? null : courses.filter((course) => course !== courseId));
        setActiveCourse(null);
    };
    const onActiveCourseChange = (courseId) => {
        if (activeCourse === courseId) {
            setActiveCourse(null);
            return;
        }
        setActiveCourse(courseId);
    };
    const reduceCourseItemsToOrderItems = (items) => {
        if (!courses)
            return items.map((item) => {
                const { posplaces } = products?.find((p) => p._id === item.itemId) || {};
                return {
                    productId: item.itemId,
                    price: item.price,
                    name: item.name,
                    quantity: item.quantity,
                    note: item.note,
                    posplaces,
                };
            });

        return items.reduce((acc, item) => {
            if (acc.some((i) => i.productId === item.itemId)) {
                return acc.map((i) => {
                    if (i.productId === item.itemId) {
                        i.courses?.push({ courseId: item.courseId?.toString(), quantity: item.quantity });
                        i.quantity += item.quantity;
                    }
                    return i;
                });
            }
            const { posplaces } = products?.find((p) => p._id === item.itemId) || {};
            acc.push({
                productId: item.itemId,
                price: item.price,
                name: item.name,
                note: item.note,
                posplaces,
                quantity: item.quantity,
                courses: [{ courseId: item.courseId?.toString(), quantity: item.quantity }],
            });
            return acc;
        }, []);
    };
    const loadPospoint = async () => {
        const response = await fetch({
            operation: "query",

            multipleEndpoints: [
                {
                    endpoint: "settingsproperty",
                    responseData: "_id address city zip country name",
                    data: { _id: "settings_property" },
                },
                {
                    endpoint: "settingsgeneral",
                    responseData: "_id currencyData{currency rate}",
                    data: { _id: "settings_general" },
                },
                {
                    endpoint: "currencies",
                    responseData: CURRENCIES,
                },
                {
                    endpoint: "posdiscounts",
                    responseData: "_id name pospoints discountValue discountType",
                },
                {
                    endpoint: "pospoint",
                    responseData: `_id cashdeskData{fiscalBusinessId} disableReceiptOnPay currencyData{currency rate} waiterLogout courseManagement alwaysFinalize liveOrderPrintingEnabled saveOptions tables{tableName _id} 
                        activeMethodsData{_id name method} differentTotalCurrencies tableCustomName optionalName optionalCity optionalCountry optionalAddress
                        printOptions{
                            fiscal{printingOption printer} 
                            nonFiscal{printingOption printer} 
                            waiterReport{printingOption printer} 
                        }`,
                    data: { _id: "pospoints_" + posId },
                },
                {
                    endpoint: "posplaces",
                    responseData: "_id name sendTo printers{printer device}",
                },
                {
                    endpoint: "rooms",
                    responseData: `_id name`,
                },
                {
                    endpoint: "paymentmethods",
                    responseData: "_id name description fiscalMethod method",
                },
            ],
        });
        if (response?.pospoint) {
            setPospoint(response.pospoint);
        }

        if (response?.settingsproperty) {
            setSettingsProperty(response.settingsproperty);
        }
        if (response?.settingsgeneral) {
            setSettingsGeneral(response.settingsgeneral);
        }
        if (response?.currencies) {
            setCurrencies(response.currencies);
        }
        if (response?.posplaces) {
            setPosplaces(response.posplaces);
        }
        if (response?.rooms) {
            setRooms(
                response.rooms?.map((room) => ({
                    value: room._id,
                    label: room.name,
                }))
            );
        }
    };
    const { currency } = pospoint?.currencyData || {};
    const loadOrder = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "order",
            data: { _id: "orders_" + _id },
            responseData: ORDER,
        });
        if (response?.order?.products) {
            const items = [];
            const courses = [];

            response.order.products.forEach((product) => {
                const productData = products.find((p) => p._id === product.productId);
                if (!!product.courses) {
                    product.courses.forEach((course) => {
                        items.push({
                            itemId: product.productId,
                            quantity: course.quantity,
                            price: product.price,
                            name: productData?.name || "",
                            taxData: productData?.taxData || [],
                            note: product.note || "",
                            courseId: course.courseId,
                        });
                        if (!courses.includes(course.courseId)) courses.push(course.courseId);
                    });
                    return;
                }
                items.push({
                    itemId: product.productId,
                    quantity: product.quantity,
                    price: product.price,
                    name: productData?.name || "",
                    taxData: productData?.taxData || [],
                    note: product.note || "",
                });
            });

            setOrderedItems(items);
            if (courses.length > 0) {
                setCourses(courses);
                setActiveCourse(courses[courses.length - 1]);
            }
            setLastOrderVersion(response.order);
            setNote(response.order.note);
            setNumberOfPersons(response.order.numberOfPersons);
            setHideNoteOnReceipt(response.order.hideNoteOnReceipt);
            setDiscount({
                type: response.order.discountType,
                value: response.order.discountValue,
                discountId: response.order.discountId,
            });
        }
    };

    useEffect(() => {
        if (reservationId || selectedReservation) {
            assignReservationCustomerToOrder();
        }
    }, [reservationId, selectedReservation]);

    const assignReservationCustomerToOrder = async () => {
        const id = reservationId || selectedReservation;
        if (!id) return;
        const response = await fetch({
            operation: "query",
            endpoint: "reservation",
            data: { _id: id },
            responseData: `_id clientsData{_id firstName lastName}`,
        });

        if (response?.reservation?._id) {
            const { _id, firstName, lastName } = response?.reservation?.clientsData?.[0] || {};
            setReservationCustomer({
                clientId: _id,
                clientName: (firstName || "") + " " + (lastName || ""),
            });
        }
    };

    const loadOpenOrders = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "openOrders",
            data: { pospointId: "pospoints_" + searchParams.get("pos") },
            responseData: `orders{${ORDER}}`,
        });
        if (response?.openOrders)
            setOpenOrders(response.openOrders?.orders?.filter((order) => order?.tableId === searchParams.get("table")));
    };

    const finalizeOrder = async ({ orderData, paymentData }) => {
        const recentProducts = [];
        orderData?.products?.forEach((product) => {
            if (!!orderData?.payments?.paymentMethod && orderData?.payments?.paymentMethod !== "roomCharge") {
                recentProducts.push(product);
                return;
            }
            const previousProductData = lastOrderVersion?.products?.find((p) => p.productId === product.productId);
            if (!previousProductData) {
                recentProducts.push(product);
                return;
            }
            if (product.quantity - previousProductData.quantity === 0) return;
            recentProducts.push({ ...product, quantity: product.quantity - previousProductData.quantity });
        });
        if (recentProducts?.length === 0) return {};
        if (paymentData?.paymentMethod === "roomCharge" && !lastOrderVersion?.fiscalData?.SumInvIICRefs) return {};
        const items = recentProducts?.map((product) => {
            const productData = products?.find((p) => p._id === product.productId) || {};
            return {
                name: productData?.name,
                price: product.price,
                quantity: product.quantity,
                unit: productData?.measureUnit ? productData.measureUnit : productData.purchaseUnit || "",
                taxData: productData?.taxData?.map((t) => {
                    return {
                        isIncluded: t.isIncluded,
                        taxId: t.taxId,
                    };
                }),
            };
        });
        const payments = paymentData?.paymentMethod
            ? [{ paymentMethod: paymentData?.paymentMethod, amount: paymentData?.cashReceived }]
            : [];
        let SumInvIICRefs = null;
        if (
            !!lastOrderVersion?.fiscalData?.SumInvIICRefs &&
            !!orderData?.payments?.paymentMethod &&
            orderData?.payments?.paymentMethod !== "roomCharge"
        ) {
            SumInvIICRefs = lastOrderVersion?.fiscalData?.SumInvIICRefs;
        }

        const data = {
            fetch,
            fiscalBusinessId: pospoint?.cashdeskData?.fiscalBusinessId,
            items: items,
            discount: {
                type: orderData?.discountType,
                value: orderData?.discountValue,
            },
            totalPrice: paymentData?.totalPrice,
            payments: payments,
            paymentMethod: "ORDER",
            SumInvIICRefs: SumInvIICRefs,
            isFiscalCurrencyRateSpecified: false,
        };
        if (!!pospoint?.currencyData) {
            data.invoiceCurrency = pospoint?.currencyData?.currency;
        } else if (!!settingsGeneral?.currencyData) {
            data.invoiceCurrency = settingsGeneral?.currencyData?.currency;
        } else {
            data.invoiceCurrency = "ALL";
        }
        data.fiscalCurrency = data.invoiceCurrency;

        return await sendFiscalInvoice(data);
    };

    const onCloseHandler = () => {
        const { role } = getLoggedUserData() || {};
        const isWaiter = !!role?.isWaiter;
        const { waiterLogout } = pospoint || {};
        if (!!waiterLogout && isWaiter) {
            navigate("/pos/sell-login");
        } else navigate(-1);
    };

    const saveOrder = async ({ withPayment = false, paymentData = {} }) => {
        setDisableActions(true);
        const loggedUser = getLoggedUserData() || {};
        const orderData = {
            products: reduceCourseItemsToOrderItems(orderedItems)?.map((p) => ({
                productId: p.productId,
                price: parseFloat(p.price),
                quantity: parseFloat(p.quantity),
                courses: p.courses,
                note: p.note,
            })),
            customerId: reservationCustomer?.clientId || selectedCustomer?.customerId || "walkin",
            discountValue: isNaN(parseFloat(discount?.value)) ? 0.0 : parseFloat(discount?.value),
            discountType: discount?.type,
            discountId: discount?.discountId,
            totalPrice: amountInfo.totalPrice,
            subtotal: amountInfo.totalPrice - amountInfo.tax,
            pospointId: "pospoints_" + searchParams.get("pos"),
            note: note,
            hideNoteOnReceipt: hideNoteOnReceipt,
            numberOfPersons: isNaN(parseInt(numberOfPersons)) ? null : parseInt(numberOfPersons),
            tableId: searchParams.get("table"),
            livePrintingEnabled: !!pospoint?.liveOrderPrintingEnabled && isMobileDevice(),
        };

        if (!!lastOrderVersion?.offlineCreated) {
            orderData.offlineCreated = true;
        }
        if (!!lastOrderVersion?.offlineEdited) {
            orderData.offlineEdited = true;
        }

        if (_id) orderData._id = "orders_" + _id;
        if (reservationId) orderData.reservationId = reservationId;
        if (!_id) {
            orderData.createdBy = loggedUser._id;
        }

        const roomChargePaymentId = pospoint?.activeMethodsData?.find((method) => method.method === "roomCharge")?._id;
        if (withPayment) {
            orderData.payments = { paymentMethod: paymentData.paymentMethod, cashReceived: paymentData.cashReceived };
            orderData.products?.forEach((product) => {
                const { warehouseIds } = products.find((p) => p._id === product.productId) || {};
                product.warehouseIds = warehouseIds;
            });
            if (paymentData.paymentMethod === roomChargePaymentId) {
                orderData.reservationId = selectedReservation || reservationId;
            }
        }
        /**
         * Finalize if order is paid with finalize option true
         * Finalize if order is open and pos save actions includes finalize
         */
        let OrderTaxes;
        if (paymentData.finalize || (!withPayment && pospoint?.saveOptions?.includes("finalize"))) {
            if (!orderData.payments?.paymentMethod || orderData.payments?.paymentMethod !== roomChargePaymentId) {
                try {
                    const { SameTaxes, ...fiscalData } = await finalizeOrder({ orderData, paymentData });
                    if (!!fiscalData?.IICRef) {
                        orderData.fiscalData = fiscalData;
                        OrderTaxes = SameTaxes;
                        if (
                            !orderData.payments?.paymentMethod ||
                            orderData.payments?.paymentMethod === roomChargePaymentId
                        ) {
                            orderData.fiscalData.SumInvIICRefs = !!lastOrderVersion?.fiscalData?.SumInvIICRefs
                                ? lastOrderVersion.fiscalData.SumInvIICRefs
                                : [];
                            orderData.fiscalData.SumInvIICRefs.push({
                                IIC: fiscalData.IICRef,
                                IssueDateTime: fiscalData.IssueDateTime,
                            });
                        }
                        enqueueSnackbar(t("order_finalized"), { variant: "info" });
                    }
                } catch (err) {
                    console.log(err);
                    enqueueSnackbar(t("finalize_failed"), { variant: "error" });
                }
            }
        }

        const saveOrderResponse = await fetch({
            operation: "mutation",
            endpoint: withPayment ? "payOrder" : _id ? "updateOrder" : "saveOrder",
            data: orderData,
            responseData: ORDER,
        });
        if (
            saveOrderResponse?.saveOrder?._id ||
            saveOrderResponse?.updateOrder?._id ||
            saveOrderResponse?.payOrder?._id
        ) {
            enqueueSnackbar(t("order_saved"), { variant: "default" });
            orderData.number =
                saveOrderResponse?.saveOrder?.number ||
                saveOrderResponse?.updateOrder?.number ||
                saveOrderResponse?.payOrder?.number;
            orderData.products = reduceCourseItemsToOrderItems(orderedItems);
            orderData.customerName = reservationCustomer?.clientName || "Walkin";
            orderData.createdBy = getLoggedUserData()?.displayName || getLoggedUserData()?._id?.split("users_")?.[1];
            orderData.createdByName =
                getLoggedUserData()?.displayName || getLoggedUserData()?._id?.split("users_")?.[1];

            const hasReceiptAutoPrint = !!withPayment
                ? !Boolean(pospoint.disableReceiptOnPay)
                : pospoint?.saveOptions?.includes("print");

            /**
             * Print receipt if pos save options includes print
             */
            if (hasReceiptAutoPrint) {
                try {
                    if (orderData?.fiscalData?.nivf) {
                        await printFiscal({
                            settingsProperty,
                            t,
                            order: {
                                ...orderData,
                                products: orderData?.products?.map((product) => {
                                    const { taxData } = products?.find((p) => p._id === product.productId) || {};
                                    return { ...product, taxData };
                                }),
                            },
                            pospoint: pospoint,
                            SameTaxes: OrderTaxes,
                            currencies: currencies,
                            previousOrder: lastOrderVersion,
                        });
                    } else
                        await print({
                            settingsProperty,
                            t,
                            order: orderData,
                            pospoint: pospoint,
                            previousOrder: lastOrderVersion,
                            currencies: currencies,
                        });
                } catch (error) {
                    console.log(error);
                    enqueueSnackbar(t("print_receipt_failed"), { variant: "error" });
                }
            }
            /**
             * Send order to kitchen if pos save options includes sendToKitchen
             */
            if (pospoint?.saveOptions?.includes("sendToKitchen")) {
                sendToKitchen(orderData);
            }
            onCloseHandler();
        }
        setDisableActions(false);
    };

    const sendToKitchen = async (order) => {
        try {
            kitchenReceipt({
                settingsProperty,
                posplaces,
                t,
                order,
                pospoint: pospoint,
                previousOrder: lastOrderVersion,
                numberOfPersons: numberOfPersons,
            });
        } catch (error) {
            enqueueSnackbar(t("print_kitchen_receipt_failed"), { variant: "error" });
        }
        const receipts = [];
        const recentProducts = [];
        order?.products?.forEach((product) => {
            const previousProductData = lastOrderVersion?.products?.find((p) => p.productId === product.productId);
            if (!previousProductData) {
                recentProducts.push(product);
                return;
            }
            if (product.quantity - previousProductData.quantity === 0) return;
            recentProducts.push({ ...product, quantity: product.quantity - previousProductData.quantity });
        });
        recentProducts?.forEach((product) => {
            const productData = products.find((p) => p._id === product.productId);
            if (!productData?.posplaces?.[0]) return;
            const { sendTo } = posplaces.find((p) => p._id === productData?.posplaces?.[0]) || {};
            if (sendTo === "printer") return;
            const sameReceiptIndex = receipts.findIndex(
                (receipt) => receipt.posplaceId === productData?.posplaces?.[0]
            );
            if (sameReceiptIndex > -1) {
                receipts[sameReceiptIndex].products.push({
                    productId: product.productId,
                    productName: productData.name,
                    quantity: product.quantity,
                    note: product.note || "",
                });
                return;
            }
            receipts.push({
                tableName: order.tableId,
                products: [
                    {
                        productId: product.productId,
                        productName: productData.name,
                        quantity: product.quantity,
                        note: product.note || "",
                    },
                ],
                posplaceId: productData.posplaces[0],
                number: order.number,
            });
        });
        const sendReceiptsResponse = await fetch({
            operation: "mutation",
            endpoint: "createMultiplePosplaceReceipts",
            data: { receipts },
            responseData: "",
        });
        if (sendReceiptsResponse?.createMultiplePosplaceReceipts === "success") {
            enqueueSnackbar(t("sent_to_kitchen"), { variant: "default" });
        }
    };

    const deleteOpenOrder = async (id) => {
        if (!id) return;
        const data = { _id: id };
        const response = await fetch({
            operation: "mutation",
            endpoint: "deleteOrder",
            data,
        });
        if (response?.deleteOrder === "success") {
            setOpenOrders(openOrders.filter((order) => id !== order._id));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    const amountInfo = useMemo(() => {
        const totals = orderedItems.reduce(
            (total, item) => {
                const taxAmount = calculateTotalTax({ taxData: item.taxData, price: item.price });
                const itemTotalPrice = item.quantity * (taxAmount.totalAmount - taxAmount.includedAmount + item.price);
                total.tax += taxAmount.totalAmount * item.quantity;
                if (isNaN(itemTotalPrice)) return total;
                total.totalPrice += itemTotalPrice;
                return total;
            },
            { totalPrice: 0.0, tax: 0.0 }
        );
        const discountAmount = calculateTotalDiscount(totals.totalPrice, discount);
        if (totals?.totalPrice > 0) {
            totals.totalPrice -= discountAmount;
        }
        totals.discount = discountAmount;
        return totals;
    }, [orderedItems, discount]);

    const posProducts = useMemo(() => {
        const posId = "pospoints_" + searchParams.get("pos");
        const filteredProducts = products.filter(
            (product) => product.pospoints?.some((pos) => pos.posPointId === posId) && !product.isVariant
        );
        const variantProducts = products.filter((product) => !!product.isVariant);
        variantProducts.forEach((variantProduct) => {
            const parentProductIndex = filteredProducts.findIndex((p) => p._id === variantProduct.parentProduct);
            if (parentProductIndex < 0) return;
            if (!filteredProducts[parentProductIndex].variants) filteredProducts[parentProductIndex].variants = [];
            filteredProducts[parentProductIndex].variants.push(variantProduct);
        });
        return filteredProducts;
    }, [products]);

    const handleMenuClose = () => {
        setAnchorMenu(null);
    };

    const applyDiscount = (discountData) => {
        if (discount) setDiscount(discountData);
    };

    return (
        <div>
            <AppBar
                position="fixed"
                elevation={0}
                sx={{
                    backgroundColor: "navbarColor.default",
                }}
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center" paddingX={2}>
                    <Stack direction="row" justifyContent="start" alignItems="center" flex={1}>
                        {isSmallScreen ? (
                            ""
                        ) : (
                            <IconButton onClick={() => setOpenOrdersDrawer(!openOrdersDrawer)}>
                                <Badge badgeContent={openOrders.length} color="secondary">
                                    <AssignmentIcon color="primary" />
                                </Badge>
                            </IconButton>
                        )}
                        <InputControl
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon sx={{ color: "primary.main" }} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setSearchedProductName("")}>
                                            <CloseIcon sx={{ color: "primary.main" }} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            size="large"
                            variant="filled"
                            fullWidth={isSmallScreen ? true : false}
                            placeholder={t("search")}
                            style={{ marginLeft: isSmallScreen ? "5px" : "70px", minWidth: 250 }}
                            value={searchedProductName}
                            onChange={(e) => setSearchedProductName(e.target.value)}
                            onFocus={(e) => e?.target?.select()}
                        />
                    </Stack>
                    {isSmallScreen ? (
                        <>
                            <IconButton
                                onClick={(event) => {
                                    setAnchorMenu(event.currentTarget);
                                }}
                            >
                                <MenuIcon color="primary" />
                            </IconButton>
                            <Menu
                                id="actions-menu"
                                anchorEl={anchorMenu}
                                open={Boolean(anchorMenu)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem>
                                    <ListItemIcon>
                                        <PersonIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        {!!getLoggedUserData()?.displayName
                                            ? getLoggedUserData()?.displayName
                                            : getLoggedUserData()?.username || ""}
                                    </ListItemText>
                                </MenuItem>
                                <Divider />
                                <MenuItem
                                    onClick={() => {
                                        reloadProducts();
                                        reloadProductCategories();
                                        handleMenuClose();
                                    }}
                                >
                                    <ListItemIcon>
                                        <CachedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>{t("update_products")}</ListItemText>
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => navigate(-1)}>
                                    <ListItemIcon>
                                        <CloseIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>{t("close")}</ListItemText>
                                </MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <>
                            <Logo width="40px" height="40px" />
                            <Stack direction="row" gap={3} justifyContent="end" alignItems="center" flex={1}>
                                <Button
                                    sx={{ marginRight: 2 }}
                                    variant="outlined"
                                    startIcon={<CachedIcon />}
                                    onClick={() => {
                                        reloadProducts();
                                        reloadProductCategories();
                                    }}
                                >
                                    {t("update_products")}
                                </Button>

                                <Typography variant="h6" color="primary">
                                    {!!getLoggedUserData()?.displayName
                                        ? getLoggedUserData()?.displayName
                                        : getLoggedUserData()?.username || ""}
                                </Typography>

                                <IconButton onClick={() => navigate(-1)}>
                                    <CloseIcon color="primary" />
                                </IconButton>
                            </Stack>
                        </>
                    )}
                </Stack>
            </AppBar>
            <Grid style={{ marginTop: "70px" }} container spacing={1} alignItems="start">
                <Grid container item xs={12} md={9}>
                    <ProductsView
                        loading={loadingProducts}
                        productCategories={productCategories}
                        productMainCategories={productMainCategories}
                        products={posProducts}
                        searchedProductName={searchedProductName}
                        addItem={addItem}
                        currency={currency}
                        clearProductSearch={() => setSearchedProductName("")}
                        isSmallScreen={isSmallScreen}
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    sx={{ borderLeft: "1px solid", borderLeftColor: "table.borderColor" }}
                >
                    {isSmallScreen ? (
                        <>
                            <ButtonBase
                                onClick={() => setOpenReceiptDrawer(!openReceiptDrawer)}
                                style={{
                                    width: "100%",
                                    height: 50,
                                    bottom: 0,
                                    backgroundColor: "#1EB280",
                                    position: "fixed",
                                    display: "flex",
                                }}
                            >
                                <Typography variant="h6">
                                    {t("this_order")} {toCurrency(amountInfo.totalPrice, currency)}
                                </Typography>
                                <ArrowUpwardIcon style={{ position: "absolute", right: 15 }} />
                            </ButtonBase>
                            <SwipeableDrawer
                                anchor="bottom"
                                open={openReceiptDrawer}
                                onClose={() => setOpenReceiptDrawer(!openReceiptDrawer)}
                            >
                                <ReceiptPreview
                                    loading={loading}
                                    disableActions={disableOpenOrderActions || disableActions}
                                    orderedItems={orderedItems}
                                    courses={courses}
                                    activeCourse={activeCourse}
                                    removeItem={removeItem}
                                    editItem={editItem}
                                    onPayment={() => setOpenPaymentModal(true)}
                                    amountInfo={amountInfo}
                                    onSave={() => saveOrder({ withPayment: false })}
                                    onAddCourse={() => addCourse()}
                                    onActiveCourseChange={onActiveCourseChange}
                                    removeCourse={removeCourse}
                                    onClientClick={() => setClientModal(true)}
                                    pospoint={pospoint}
                                    disableAddCourse={disableAddCourse}
                                    removeSaveButton={!!reservationId ? true : false}
                                    isSmallScreen={isSmallScreen}
                                    applyDiscount={(discount) => applyDiscount(discount)}
                                    discount={discount}
                                />
                            </SwipeableDrawer>
                        </>
                    ) : (
                        <ReceiptPreview
                            loading={loading}
                            disableActions={disableOpenOrderActions || disableActions}
                            orderedItems={orderedItems}
                            courses={courses}
                            activeCourse={activeCourse}
                            removeItem={removeItem}
                            editItem={editItem}
                            onPayment={() => setOpenPaymentModal(true)}
                            amountInfo={amountInfo}
                            onSave={() => saveOrder({ withPayment: false })}
                            onAddCourse={() => addCourse()}
                            onActiveCourseChange={onActiveCourseChange}
                            removeCourse={removeCourse}
                            onClientClick={() => setClientModal(true)}
                            pospoint={pospoint}
                            disableAddCourse={disableAddCourse}
                            removeSaveButton={!!reservationId ? true : false}
                            isSmallScreen={isSmallScreen}
                            applyDiscount={(discount) => applyDiscount(discount)}
                            discount={discount}
                        />
                    )}
                </Grid>
            </Grid>
            <PaymentConfirmationModal
                open={openPaymentModal}
                onCancel={() => setOpenPaymentModal(false)}
                amountInfo={amountInfo}
                orderedItems={orderedItems}
                onPay={(paymentData) => saveOrder({ withPayment: true, paymentData })}
                pospoint={pospoint}
                currency={currency}
                clientReservations={clientReservations}
                selectedCustomer={selectedCustomer}
                onReservationSelect={(resId) => setSelectedReservation(resId)}
                onRoomSelect={(roomId) => setSelectedRoom(roomId)}
                rooms={rooms}
                onClientSelect={(customer) =>
                    setSelectedCustomer({
                        customerId: customer?._id,
                        customerName: customer?.firstName + " " + customer?.lastName,
                    })
                }
                selectedRoom={selectedRoom}
                disableRoomCharge={!(!!selectedReservation || !!reservationId)}
                isSmallScreen={isSmallScreen}
            />
            <OrdersDrawer
                open={openOrdersDrawer}
                orders={openOrders}
                onClose={() => setOpenOrdersDrawer(!openOrdersDrawer)}
                onOrderClick={(orderId) => {
                    let newSellUrl = `/pos/sell?pos=${searchParams.get("pos")}`;
                    if (searchParams.get("table")) newSellUrl += `&table=${searchParams.get("table")}`;
                    newSellUrl += `&order=${orderId}`;
                    setOpenOrdersDrawer(false);
                    navigate(newSellUrl, { replace: true });
                }}
                onOrderDelete={deleteOpenOrder}
            />
            <SelectClientModal
                open={openClientModal}
                onClose={() => setClientModal(false)}
                onClientSelect={(customer) =>
                    setSelectedCustomer({
                        customerId: customer?._id,
                        customerName: customer?.firstName + " " + customer?.lastName,
                    })
                }
                onNoteChange={(note) => setNote(note)}
                note={note}
                personsNumber={numberOfPersons}
                hideNoteOnReceipt={hideNoteOnReceipt}
                onCheckBoxChange={(value) => setHideNoteOnReceipt(value)}
                onNumberChange={(nr) => setNumberOfPersons(nr)}
                clientReservations={clientReservations}
                selectedCustomer={selectedCustomer}
                onReservationSelect={(resId) => setSelectedReservation(resId)}
                onRoomSelect={(roomId) => setSelectedRoom(roomId)}
                rooms={rooms}
                selectedRoom={selectedRoom}
            />
        </div>
    );
};

export default Pos;
