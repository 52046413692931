import { Button, Grid, LinearProgress, Chip, Paper, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";
import { toCurrency } from "../../utils";
import { DateRangeControl } from "../../components/dates";
import { DateTime } from "luxon";
import { ProtectedAction } from "../../utils";

const statusColors = {
    ORDERED: "#1FB6FF", //default status
    DELIVERED: "#59C086", //fully paid
    CANCELLED: "#FF4949", //canceled
    DRAFT: "#C0CCDA", // draft
};

const InventoryList = () => {
    const [inventories, setInventories] = useState([]);
    const [filters, setFilters] = useState({
        startDate: DateTime.now().minus({ days: 7 }).toFormat("yyyy-LL-dd"),
        endDate: DateTime.now().toFormat("yyyy-LL-dd"),
    });
    const { t } = useTranslation();
    const columns = [
        { accessorKey: "type", header: t("type"), accessorFn: (row) => t(row?.type) || "" },
        {
            id: "name",
            header: t("supplier"),
            accessorFn: (row) => row?.supplierData?.name || "",
        },
        { accessorKey: "date", header: t("date") },
        { accessorKey: "dueDate", header: t("dueDate") },
        {
            accessorKey: "status",
            header: t("status"),
            cell: ({ row: { original } }) => {
                const status = !original?.status || original?.status === "UNPAID" ? original?.status : original?.status;
                return <Chip sx={{ backgroundColor: statusColors[status] }} label={t(`${status}`)} />;
            },
        },
        {
            accessorKey: "number",
            header: t("number"),
            accessorFn: (row) => row?.number || "",
        },
        {
            accessorKey: "totalPrice",
            header: t("total"),
            accessorFn: (row) => row?.totalPrice && toCurrency(row?.totalPrice, row?.currency || ""),
        },
    ];
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const loadData = async () => {
        const { startDate, endDate } = filters || {};
        if (!startDate) return;
        let end = "end";
        if (!!endDate) end = endDate + `T23:59:59`;
        const response = await fetch({
            operation: "query",
            endpoint: "inventories",
            data: {
                startDate: startDate,
                endDate: end,
            },
            responseData: "_id date dueDate number status totalPrice currency supplier type supplierData{name}",
        });
        if (response?.inventories) setInventories(response.inventories);
    };

    const addRecord = (data) => {
        if (inventories?.some((r) => r._id === data._id)) {
            setInventories(
                inventories?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setInventories([...inventories, data]);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteInventories" : "deleteInventory",
            data,
        });
        if (response?.deleteInventories === "success" || response?.deleteInventory === "success") {
            setInventories(inventories.filter((i) => !rows.some((row) => row._id === i._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    return (
        <div>
            <Grid container style={{ margin: "20px 0" }}>
                <ProtectedAction permission={{ warehouses: { list: true } }}>
                    <Button
                        component={Link}
                        to="/pos/warehouses"
                        sx={{ borderRadius: "25px", marginLeft: "20px" }}
                        variant="contained"
                        color="link"
                    >
                        {t("warehouses")}
                    </Button>
                </ProtectedAction>
                <ProtectedAction permission={{ inventories: { adjust: true } }}>
                    <Button
                        component={Link}
                        to="/pos/inventory-correct"
                        sx={{ borderRadius: "25px", marginLeft: "20px" }}
                        variant="contained"
                        color="link"
                    >
                        {t("adjust_inventory")}
                    </Button>
                </ProtectedAction>
            </Grid>
            {loading && <LinearProgress color="primary" />}
            <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2 }}>
                <Stack direction="row" gap={2} flexWrap="wrap">
                    <DateRangeControl
                        startLabel={t("start")}
                        endLabel={t("end")}
                        values={{ startDate: filters.startDate, endDate: filters.endDate }}
                        onChange={({ startDate, endDate }) => setFilters({ ...filters, startDate, endDate })}
                    />
                    <Button onClick={loadData} size="small" variant="contained">
                        {t("load_records")}
                    </Button>
                </Stack>
            </Paper>
            <Table
                titleLabel={t("inventories_list")}
                moduleType="pos"
                data={inventories}
                columns={columns}
                onRowClick={(row) => navigate(`${row?._id?.split("inventories_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ addRecord }} />
        </div>
    );
};

export default InventoryList;
