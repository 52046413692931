import { Divider, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { EditableTaxesTable, PriceInformationCard } from "../../../components/common";
import {
    AutoCompleteControl,
    CheckboxControl,
    Form,
    InputControl,
    RadioGroupControl,
    SelectControl,
    SwitchControl,
} from "../../../components/form";
import { useApi, useProductCategories, useProducts } from "../../../components/hooks";
import { Modal } from "../../../components/modal";
import { calculateTotalTax } from "../../../utils";
import { EditablePospointsTable, EditableProductsTable, EditableWarehousesTable } from "./components";

const ProductForm = () => {
    const [values, setValues] = useState({
        isVariant: false,
        productType: "standard",
        ignoreStock: false,
        taxData: [],
        tableData: [],
        pospoints: [],
    });

    const [taxes, setTaxes] = useState([]);
    const [pospointsOptions, setPospointsOptions] = useState([]);
    const [posplacesOptions, setPosplacesOptions] = useState([]);
    const [warehouseOptions, setWarehousesOptions] = useState([]);

    const { productCategories } = useProductCategories();
    const { products } = useProducts();

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation();
    const { addRecord } = useOutletContext() || {};

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    useEffect(() => {
        loadExtraData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "product",
                    data: { _id: "products_" + params?.id },
                    responseData: `_id name categoryId category{name} code barcode alphacode supplierId description parentProduct 
                    modifiers isVariant productType measureUnit price deductionFactor ignoreStock purchaseUnit posplaces cost warehouseIds
                    pospoints{ 
                        posPointId 
                        price 
                    }  
                    taxData{
                        taxId
                        isIncluded
                        tax{rate taxCategory}
                    }   
                    tableData{
                        productId
                        price
                        quantity
                        unit
                    } `,
                },
                {
                    endpoint: "productInventory",
                    data: { _id: "products_" + params?.id },
                    responseData: "warehouseId quantity",
                },
            ],
        });
        if (response?.product) {
            if (response?.productInventory) {
                response.product.productInventory = response.productInventory;
            }
            setValues(response.product);
        }
    };

    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "taxes",
                    responseData: "_id name rate taxCategory",
                },
                { endpoint: "pospoints", responseData: "_id name" },
                { endpoint: "posplaces", responseData: "_id name" },
                { endpoint: "warehouses", responseData: "_id name" },
            ],
        });

        if (!!response?.taxes) setTaxes(response.taxes);
        if (!!response?.pospoints)
            setPospointsOptions(response.pospoints.map((pos) => ({ value: pos._id, label: pos.name })));
        if (!!response?.posplaces)
            setPosplacesOptions(response.posplaces.map((pos) => ({ value: pos._id, label: pos.name })));
        if (!!response?.warehouses)
            setWarehousesOptions(
                response.warehouses.map((warehouse) => ({ value: warehouse._id, label: warehouse.name }))
            );
    };

    const saveHandler = async () => {
        if (!values?.name) {
            enqueueSnackbar(t("name required"), { variant: "warning" });
            return;
        }
        const newWarehousesData = values?.warehouseData?.filter(
            (warehouse) => !!warehouse.warehouseId && !!warehouse.quantity
        );
        const data = {
            name: values.name,
            categoryId: values.categoryId,
            code: values.code,
            barcode: values.barcode,
            alphacode: values.alphacode,
            supplierId: values.supplierId,
            modifiers: values.modifiers,
            isVariant: values.isVariant,
            productType: values.productType,
            measureUnit: values.measureUnit,
            price: !!values?.price ? parseFloat(values.price) : null,
            deductionFactor: !!values?.deductionFactor ? parseFloat(values.deductionFactor) : null,
            ignoreStock: values.ignoreStock,
            purchaseUnit: values.purchaseUnit,
            pospoints: values.pospoints
                ?.filter((pos) => !!pos.posPointId)
                ?.map((pos) => {
                    return { ...pos, price: isNaN(parseFloat(pos?.price)) ? null : parseFloat(pos?.price) };
                }),
            posplaces: values.posplaces,
            taxData: values.taxData?.map((tax) => {
                return { taxId: tax.taxId, isIncluded: tax.isIncluded };
            }),
            description: values.description,
            cost: !!values?.cost ? parseFloat(values.cost) : null,
        };
        if (values._id) {
            data._id = values._id;
        } else {
            data.warehouseIds = newWarehousesData?.map((warehouse) => {
                return warehouse.warehouseId;
            });
        }
        if (!!values.isVariant) data.parentProduct = values.parentProduct;

        if (values.productType === "composed") {
            data.tableData = values?.tableData?.map((prod) => {
                return {
                    ...prod,
                    price: isNaN(parseFloat(prod?.price)) ? null : parseFloat(prod?.price),
                    quantity: isNaN(parseFloat(prod?.quantity)) ? null : parseFloat(prod?.quantity),
                };
            });
        }
        const response = await fetch({
            operation: "mutation",
            endpoint: values._id ? "updateProduct" : "createProduct",
            data,
            responseData: `_id name category{name} categoryId code barcode alphacode supplierId description parentProduct 
            modifiers isVariant productType measureUnit price deductionFactor ignoreStock purchaseUnit posplaces cost warehouseIds
            pospoints{ 
                posPointId 
                price 
            }  
            taxData{
                taxId
                isIncluded
                tax{rate taxCategory}
            }   
            tableData{
                productId
                price
                quantity
                unit
            } `,
        });
        if (response?.createProduct?._id || response?.updateProduct?._id) {
            enqueueSnackbar(t("product_saved"), { variant: "default" });
            addRecord && addRecord(values?._id ? response.updateProduct : response.createProduct);
            if (response?.createProduct?._id) {
                const productsInventoryDocs = newWarehousesData?.map((warehouse, index) => {
                    const timeStamp = new Date();
                    timeStamp.setMilliseconds(timeStamp.getMilliseconds() + index);
                    return {
                        _id: `inventory_${response?.createProduct?._id.split("products_")[1]}_${timeStamp.toJSON()}`,
                        quantity: parseFloat(warehouse.quantity),
                        warehouseId: warehouse.warehouseId,
                    };
                });
                if (productsInventoryDocs?.length > 0) {
                    const productInventoryResponse = await fetch({
                        operation: "mutation",
                        endpoint: "updateProductsInventory",
                        data: {
                            inventories: productsInventoryDocs,
                        },
                    });
                    if (productInventoryResponse?.updateProductsInventory === "suceess")
                        enqueueSnackbar(t("inventory_added"), { variant: "default" });
                }
            }
            navigate(-1);
        }
    };

    const productCategoriesOptions = useMemo(() => {
        return productCategories.map((c) => ({ value: c._id, label: c.name }));
    }, [productCategories]);

    const productOptions = useMemo(() => {
        const options = products.map((option) => ({ value: option._id, label: option.name, data: option }));
        options.forEach((option) => {
            let sameProducts = options.filter((c) => c.label === option.label);
            if (sameProducts.length > 1) option.label += " " + sameProducts.length;
        });
        if (!values._id) return options;
        return options?.filter((option) => option.value !== values?._id);
    }, [products, values._id]);

    const calculateComposedProductCost = (tableData) => {
        let totalCost = tableData?.reduce((acc, product) => {
            if (product.price && product.quantity) {
                acc += (product.price || 0) * product.quantity;
            }
            return acc;
        }, 0.0);
        setValues({ ...values, tableData, cost: isNaN(totalCost) ? null : totalCost });
    };

    const amountInfo = useMemo(() => {
        const { totalAmount, includedAmount } = calculateTotalTax({
            taxData: values.taxData.map((t) => ({
                isIncluded: t.isIncluded,
                tax: taxes.find((tax) => tax._id === t.taxId),
            })),
            price: parseFloat(values.price),
        });
        const totalPrice = parseFloat(values.price) + totalAmount - includedAmount;
        const subtotal = totalPrice - totalAmount;
        return {
            totalPrice: isNaN(totalPrice) ? 0.0 : totalPrice.toFixed(2),
            subtotal: isNaN(subtotal) ? 0.0 : subtotal.toFixed(2),
            tax: isNaN(totalAmount) ? 0.0 : totalAmount.toFixed(2),
        };
    }, [values.taxData, values.price, taxes]);

    return (
        <div>
            <Modal
                open
                fullScreen
                loading={loading}
                titlelabel={t("products_form")}
                onSave={saveHandler}
                onClose={() => navigate(-1)}
                permission={{ products: params?.id === "create" ? { create: true } : { edit: true } }}
            >
                <Typography variant="h6">{t("product_information")}</Typography>
                <Form values={values} onValuesChange={setValues}>
                    <Grid container spacing={2}>
                        <Grid container spacing={2} item xs={12} sm={6}>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <InputControl name="name" label={t("name")} />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectControl
                                    options={productCategoriesOptions}
                                    name="categoryId"
                                    label={t("category")}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputControl name="code" label={t("code")} />
                            </Grid>
                            <Grid item xs={6}>
                                <InputControl name="barcode" label={t("barcode")} />
                            </Grid>
                            <Grid alignItems="center" container item xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <RadioGroupControl
                                        labelPlacement="start"
                                        name="productType"
                                        label={t("product_type")}
                                        options={[
                                            { value: "standard", label: t("standard") },
                                            { value: "composed", label: t("composed") },
                                        ]}
                                    />
                                </Grid>
                                {values?.productType === "composed" && (
                                    <Grid item xs={12}>
                                        <EditableProductsTable
                                            products={productOptions}
                                            data={values?.tableData || [{}]}
                                            onChange={(productsData) => {
                                                calculateComposedProductCost(productsData);
                                            }}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>

                <Form values={values} onValuesChange={setValues}>
                    <Grid style={{ marginTop: "20px" }} container spacing={8} alignItems="flex-start">
                        <Grid container spacing={2} item xs={12} sm={6}>
                            <Grid item xs={12}>
                                <Typography variant="h6">{t("sales_information")}</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={6}>
                                <InputControl name="measureUnit" label={t("unit_of_measure")} />
                            </Grid>
                            <Grid item xs={6}>
                                <InputControl name="price" label={t("price")} />
                            </Grid>
                            <Grid item xs={6}>
                                <InputControl name="deductionFactor" label={t("deduction_factor")} />
                            </Grid>
                            <Grid item xs={6}>
                                <SwitchControl name="ignoreStock" label={t("ignore_stock")} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} item xs={12} sm={6}>
                            <Grid item xs={12}>
                                <Typography variant="h6">{t("purchase_information")}</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={6}>
                                <InputControl name="purchaseUnit" label={t("purchase_unit_of_measure")} />
                            </Grid>
                            <Grid item xs={6}>
                                <InputControl name="cost" label={t("cost")} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>

                <Grid style={{ marginTop: "20px" }} container spacing={8} alignItems="start">
                    <Grid container spacing={2} item xs={12} sm={6}>
                        <Grid item xs={12}>
                            <Typography variant="h6">{t("pos_information")}</Typography>
                            <Divider />
                        </Grid>
                        <Grid container item xs={12} spacing={4}>
                            <Grid item xs={12}>
                                <EditablePospointsTable
                                    pospoints={pospointsOptions}
                                    data={values?.pospoints || []}
                                    onChange={(posData) => setValues({ ...values, pospoints: posData })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Form values={values} onValuesChange={setValues}>
                                    <AutoCompleteControl
                                        multiple
                                        options={posplacesOptions || []}
                                        size="large"
                                        margin="none"
                                        name="posplaces"
                                        label={t("posplaces")}
                                    />
                                </Form>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} item xs={12} sm={6}>
                        <Grid item xs={12}>
                            <Typography variant="h6">{t("taxes")}</Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <EditableTaxesTable
                                taxes={taxes}
                                data={values?.taxData || []}
                                onChange={(taxData) => setValues({ ...values, taxData })}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <PriceInformationCard
                                tax={amountInfo?.tax}
                                subtotal={amountInfo?.subtotal}
                                total={amountInfo?.totalPrice}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid style={{ marginTop: "30px" }} container spacing={8} alignItems="flex-start">
                    <Grid item xs={12} sm={6}></Grid>
                </Grid>

                <Grid container spacing={8} alignItems="flex-start">
                    <Grid container spacing={2} item xs={12} sm={6}>
                        <Form values={values} onValuesChange={setValues}>
                            <Grid container spacing={2} item xs={12}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">{t("other_information")}</Typography>
                                    <Divider />
                                </Grid>

                                <Grid item xs={6}>
                                    <InputControl name="alphacode" label={t("alpha_web_code")} />
                                </Grid>
                                <Grid item xs={6}>
                                    <AutoCompleteControl multiple name="modifiers" label={t("extras_and_modifiers")} />
                                </Grid>
                                <Grid item xs={6}>
                                    <CheckboxControl name="isVariant" label={t("is_variant")} />
                                </Grid>
                                {values?.isVariant && (
                                    <Grid item xs={6}>
                                        <AutoCompleteControl
                                            options={productOptions || []}
                                            label={t("parent_product")}
                                            value={values?.parentProduct}
                                            onChange={(e) => {
                                                const parentProductData = products.find(
                                                    (product) => product._id === e.target.value
                                                );
                                                if (!parentProductData) return;
                                                setValues({
                                                    ...values,
                                                    parentProduct: e.target.value,
                                                    categoryId: parentProductData.categoryId,
                                                    supplierId: parentProductData.supplierId,
                                                    productType: parentProductData.productType,
                                                    measureUnit: parentProductData.measureUnit,
                                                    price: parentProductData.price,
                                                    ignoreStock: parentProductData.ignoreStock,
                                                    purchaseUnit: parentProductData.purchaseUnit,
                                                    posplaces: parentProductData.posplaces,
                                                    cost: parentProductData.cost,
                                                    pospoints: parentProductData.pospoints,
                                                    taxData: parentProductData.taxData.map((t) => ({
                                                        taxId: t.taxId,
                                                        isIncluded: t.isIncluded,
                                                    })),
                                                });
                                            }}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <InputControl multiline minRows={4} name="description" label={t("description")} />
                                </Grid>
                            </Grid>
                        </Form>
                    </Grid>
                    {values?.productType === "standard" ? (
                        <Grid container spacing={2} item xs={12} sm={6}>
                            <Grid item xs={12}>
                                <Typography variant="h6">{t("inventory_information")}</Typography>
                                <Divider />
                            </Grid>

                            <Grid container spacing={2} item xs={12}>
                                <Grid item xs={12}>
                                    {values?._id ? (
                                        <EditableWarehousesTable
                                            warehouses={warehouseOptions}
                                            data={values?.productInventory || []}
                                        />
                                    ) : (
                                        <EditableWarehousesTable
                                            warehouses={warehouseOptions}
                                            data={values?.warehouseData}
                                            onChange={(warehouseData) => setValues({ ...values, warehouseData })}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                </Grid>
            </Modal>
        </div>
    );
};
export default ProductForm;
