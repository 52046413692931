import { LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { Table } from "../../components/table";

const SupplierList = () => {
    const [suppliers, setSuppliers] = useState([]);
    const { t } = useTranslation();
    const [columns] = useState([
        { accessorKey: "name", header: t("name") },
        { accessorKey: "phone", header: t("phone") },
        { accessorKey: "email", header: t("email") },
        { accessorKey: "addressLine", header: t("address") },
        { accessorKey: "city", header: t("city") },
        { accessorKey: "country", header: t("country") },
    ]);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "suppliers",
            responseData: "_id name phone email addressLine city country",
        });
        if (response?.suppliers) setSuppliers(response.suppliers);
    };

    const addRecord = (data) => {
        if (suppliers?.some((r) => r._id === data._id)) {
            setSuppliers(
                suppliers?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setSuppliers([...suppliers, data]);
    };

    const onDeleteHandler = async (rows = []) => {
        if (rows.length === 0) return;
        const data = {};
        if (rows.length > 1) data._ids = rows.map((row) => row._id);
        else data._id = rows[0]._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: rows.length > 1 ? "deleteSuppliers" : "deleteSupplier",
            data,
        });
        if (response?.deleteSuppliers === "success" || response?.deleteSupplier === "success") {
            setSuppliers(suppliers.filter((supplier) => !rows.some((row) => row._id === supplier._id)));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    useEffect(() => {
        if (!!params.id) return; //Skip fetching data when single client page is rendered
        loadData();
    }, []);

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Table
                titleLabel={t("suppliers")}
                moduleType="pos"
                data={suppliers}
                columns={columns}
                onRowClick={(row) => navigate(`profile/${row?._id?.split("suppliers_")[1]}`)}
                onCreateClick={() => navigate(`create`)}
                onDeleteClick={onDeleteHandler}
            />
            <Outlet context={{ addRecord }} />
        </div>
    );
};

export default SupplierList;
