import jsPDF from "jspdf";
import "jspdf-autotable";
import { calculateTotalTax, toCurrency } from "../../../utils";

const findColumnWidth = (columns, numberOfColumns) => {
    let totalWidth = 0;
    let counter = 0;

    columns.forEach((columnObject) => {
        totalWidth += columnObject.width;
        if (counter == numberOfColumns - 1) {
            return totalWidth;
        }
        counter++;
    });
    return totalWidth;
};

const titles = {
    estimate: "ESTIMATE",
};

const calculateAmount = (product, taxes = []) => {
    const { totalAmount, includedAmount } = calculateTotalTax({
        taxData: product.taxData?.map((t) => ({
            isIncluded: t.isIncluded,
            tax: taxes.find((tax) => tax._id === t.taxId),
        })),
        price: parseFloat(product.price),
    });
    const totalPrice = (parseFloat(product.price) + totalAmount - includedAmount) * product.quantity;
    return isNaN(totalPrice) ? 0.0 : totalPrice;
};

const calculateTotalDiscount = ({ totalPrice = 0, discountType, discountValue }) => {
    if (!discountType || !discountValue) return 0;
    const discountAmount =
        discountType === "total" ? parseFloat(discountValue) : totalPrice * parseFloat(discountValue) * 0.01;
    if (isNaN(discountAmount)) return 0;
    return discountAmount;
};
const amountInfo = ({ items = [], taxes = [], discountType, discountValue }) => {
    let totalPrice = 0.0;
    let subtotal = 0.0;
    let discount = 0.0;
    let taxTotal = 0.0;

    items?.forEach((item) => {
        const { totalAmount, includedAmount } = calculateTotalTax({
            taxData: item.taxData?.map((t) => ({
                isIncluded: t.isIncluded,
                tax: taxes.find((tax) => tax._id === t.taxId),
            })),
            price: parseFloat(item.price),
        });
        taxTotal += totalAmount * item.quantity;
        totalPrice += (parseFloat(item.price) + totalAmount - includedAmount) * item.quantity;
    });
    totalPrice = parseFloat(parseFloat(totalPrice).toFixed(2));
    subtotal = totalPrice - taxTotal;
    discount = calculateTotalDiscount({ totalPrice, discountType, discountValue });

    return {
        totalPrice: isNaN(totalPrice) ? 0.0 : totalPrice,
        subtotal: isNaN(subtotal) ? 0.0 : subtotal,
        taxTotal: isNaN(taxTotal) ? 0.0 : taxTotal,
        discount: isNaN(discount) ? 0.0 : discount,
    };
};

export const exportEstimateToPdf = async ({
    values = {},
    itemsOptions = [],
    t,
    options = {},
    fetch,
    resource = "estimate",
}) => {
    const data = await fetch({
        operation: "query",
        multipleEndpoints: [
            {
                endpoint: "settingsgeneral",
                responseData: "_id currencyData{currency currencyname rate} language footerInvoiceNotes",
                data: { _id: "settings_general" },
            },
            {
                endpoint: "settingsproperty",
                responseData: "_id name logo phone address city zip registrationNumber email website",
                data: { _id: "settings_property" },
            },
            {
                endpoint: "taxes",
                responseData: "_id name rate taxCategory isVat",
            },
            {
                endpoint: "paymentmethods",
                responseData: "_id name",
            },
            {
                endpoint: "paymentaccounts",
                responseData: "_id name currency bank accountId",
            },
            {
                endpoint: "currencies",
                responseData: "_id active currency currencyname rate",
            },
        ],
    });

    const propertyData = data?.settingsproperty || {};
    const settingsData = data?.settingsgeneral || {};
    const taxData = data?.taxes || [];
    const translate = (text) => t(text, { lngs: [values?.pdfLanguage || settingsData?.language || "en"] });
    let { logo } = propertyData || {};

    const pdf = new jsPDF();
    pdf.setFont("Helvetica", "bold");
    pdf.setTextColor(0, 0, 0);
    pdf.setFontSize(20);

    /**
     * Invoice HEADER
     * display property logo or property name
     * show title (invoice,bill,fiscal invoices,...)
     */

    pdf.setFontSize(22);
    pdf.text(translate(titles[resource] || "INVOICE"), 207, 15, "right");

    /**
     * Show property Details
     * Show property address if address specified in header in settings
     */
    pdf.setFontSize(11);
    pdf.text(propertyData?.name || "", 207, 22, "right");
    pdf.setFontSize(10);
    pdf.setFont("Helvetica", "normal");

    const addressString = [
        !!propertyData?.registrationNumber ? `VAT: ${propertyData?.registrationNumber}` : undefined,
        propertyData?.address,
        propertyData?.city,
        propertyData?.zip,
        propertyData?.phone,
    ]
        ?.filter((detail) => !!detail)
        ?.join(`\n`);
    pdf.text(addressString, 207, 27, "right");

    /**
     * Business Details
     */
    let businessString = [
        `${translate("number")}: ${values?.number || ""}`,
        `${translate("date")}: ${values?.date || ""}`,
        `${translate("dueDate")}: ${values?.dueDate || ""}`,
        `${translate("status")}: ${`${translate(values?.status)}` || translate("DRAFT")}`,
    ]?.join(`\n`);

    /**
     * Bill to Details
     */
    const billTo = values?.clientsData || values?.supplierData || {};
    const billToString = [
        billTo?.name ? billTo?.name : billTo?.firstName ? `${billTo?.firstName} ${billTo.lastName || ""}` : undefined,
        billTo?.addressLine,
        billTo?.city,
        billTo?.country,
        billTo?.company,
        billTo?.email,
        billTo?.phone,
        billTo?.vat,
        billTo?.IDNumber,
    ]
        ?.filter((detail) => !!detail)
        ?.join(`\n`);

    pdf.autoTable({
        theme: "plain",
        startY: 50,
        styles: { fontSize: 10, textColor: "#000", cellPadding: { top: 1 } },
        headStyles: {
            minCellWidth: 75,
            fillColor: [220, 233, 241],
            textColor: [13, 24, 33],
            cellPadding: 1,
            lineWidth: 0,
        },
        margin: { left: 7, top: 0, right: 7 },
        tableWidth: "wrap",
        head: [[translate("estimate_details"), translate("bill_to")]],
        body: [[businessString, billToString]],
    });

    if (values?.description) {
        pdf.autoTable({
            theme: "grid",
            startY: pdf.autoTable.previous.finalY + 10,
            styles: { fontSize: 10, textColor: "#000", cellPadding: { top: 1 } },
            margin: { left: 7, top: 0, right: 7 },
            body: [[values?.description]],
            bodyStyles: { lineWidth: 0.1, cellPadding: 1 },
        });
    }

    /**
     * Items Table
     */

    const itemsDetails = values?.tableData?.map((item) => {
        let quantity = parseFloat(item.quantity);
        if (isNaN(quantity)) quantity = 0;
        let price = parseFloat(item.price);
        if (isNaN(price)) price = 0;
        const taxes =
            taxData?.filter((tax) => item.taxData?.some((t) => t.taxId === tax._id))?.map((tax) => tax.name) || [];
        return [
            itemsOptions?.find((option) => option._id === item.itemId)?.name || "",
            item.description || "",
            quantity?.toFixed(2),
            item.unit || "",
            toCurrency(price, values?.invoiceCurrency || settingsData?.currencyData?.currency || ""),
            taxes?.join(","),
            toCurrency(calculateAmount(item), values?.invoiceCurrency || settingsData?.currencyData?.currency || ""),
        ];
    });

    pdf.autoTable({
        theme: "grid",
        startY: pdf.autoTable.previous.finalY + 10,
        styles: { fontSize: 10, textColor: "#000", cellPadding: 1 },
        headStyles: {
            fillColor: [220, 233, 241],
            textColor: [13, 24, 33],
            cellPadding: 1,
            lineWidth: 0,
        },
        margin: { left: 7, top: 0, right: 7 },
        head: [
            [
                translate("item"),
                translate("description"),
                translate("quantity"),
                translate("unit"),
                translate("price"),
                translate("tax"),
                translate("amount"),
            ],
        ],
        body: itemsDetails,
        bodyStyles: { lineWidth: 0.1, cellPadding: 1 },
    });

    /**
     * Detailed amounts table
     */

    const detailedAmount = amountInfo({
        items: values?.tableData,
        taxes: taxData,
        discountType: values?.discountType,
        discountValue: values?.discountValue,
    });

    pdf.autoTable({
        theme: "grid",
        tableWidth: "wrap",
        startY: pdf.autoTable.previous.finalY,
        styles: { fontSize: 10, textColor: [13, 24, 33], cellPadding: { top: 1 } },
        margin: {
            left:
                findColumnWidth(pdf.autoTable.previous.columns, pdf.autoTable.previous.columns.length) -
                pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 4].width -
                pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 3].width -
                pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 2].width -
                pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 1].width +
                7,
        },
        columnStyles: {
            0: {
                cellWidth:
                    pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 4].width +
                    pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 3].width,
            },
            1: {
                cellWidth:
                    pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 2].width +
                    pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 1].width,
            },
        },
        body: [
            [
                translate("subtotal"),
                toCurrency(
                    detailedAmount?.subtotal,
                    values?.invoiceCurrency || settingsData?.currencyData?.currency || ""
                ),
            ],
            [
                translate("discount"),
                toCurrency(
                    detailedAmount?.discount,
                    values?.invoiceCurrency || settingsData?.currencyData?.currency || ""
                ),
            ],
            [
                translate("tax"),
                toCurrency(
                    detailedAmount?.taxTotal,
                    values?.invoiceCurrency || settingsData?.currencyData?.currency || ""
                ),
            ],
            // ...(fiscalPdf
            //     ? groupTaxes({ tableData: tableDataDetailed, taxes: taxes }).map((tax) => {
            //           return [
            //               `${pdfLang("TVSH")} ${tax.rate}%`,
            //               toCurrency(tax.total, values?.invoiceCurrency || settingsData?.currencyData?.currency || ""),
            //           ];
            //       })
            //     : []),
            [
                translate("total"),
                toCurrency(
                    detailedAmount?.totalPrice - detailedAmount?.discount,
                    values?.invoiceCurrency || settingsData?.currencyData?.currency || ""
                ),
            ],
        ],
        bodyStyles: { lineWidth: 0.1, cellPadding: 1 },
    });

    const pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
    const pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();

    /**
     * Invoice Footer
     */
    if (values?.clientsData?.firstName) {
        pdf.setFontSize(11);
        pdf.text(
            `${translate("client")}:\n${values?.clientsData?.firstName} ${
                values?.clientsData?.lastName || ""
            }${"\n\n_____________"}`,
            140,
            pageHeight - 70
        );
    }

    const footerString = [
        settingsData?.footerInvoiceNotes ? `${translate("notes")}: ${settingsData?.footerInvoiceNotes}` : undefined,
        propertyData?.email ? `${translate("email")}: ${propertyData?.email}` : undefined,
        propertyData?.website ? `${translate("web")}: ${propertyData?.website}` : undefined,
    ]
        ?.filter((detail) => !!detail)
        ?.join(`\n`);
    pdf.setTextColor(100);
    pdf.setFontSize(10);
    pdf.text(footerString, pageWidth / 2, pageHeight - 10, "center");

    /**
     * Save pdf
     */
    pdf.setPage(1);
    if (!logo) {
        pdf.text(propertyData?.name || "", 7, 15);
        printPdf({
            pdf,
            type: options?.type,
            title: `estimates_${new Date().toJSON()}`,
        });
    } else {
        const img = new Image();
        img.src = logo;
        img.onload = () => {
            const imageWidth = img.width;
            const imageHeight = img.height;

            pdf.addImage(img, "JPEG", 7, 5, (30 * imageWidth) / imageHeight, 30);
            printPdf({
                pdf,
                type: options?.type,
                title: `estimates_${new Date().toJSON()}`,
            });
        };
    }
};

const printPdf = ({ pdf, type, title }) => {
    if (type === "autoprint") {
        pdf.autoPrint();
        pdf.output("dataurlnewwindow");
    } else {
        pdf.save(title + ".pdf");
    }
};
