import jsPDF from "jspdf";
import "jspdf-autotable";
import { calculateTotalTax, toCurrency } from "../../../utils";
import { DateTime } from "luxon";
import QRCode from "qrcode";
import { exchangeCurrency } from "../../../utils";

const findColumnWidth = (columns, numberOfColumns) => {
    let totalWidth = 0;
    let counter = 0;

    columns.forEach((columnObject) => {
        totalWidth += columnObject.width;
        if (counter == numberOfColumns - 1) {
            return totalWidth;
        }
        counter++;
    });
    return totalWidth;
};

const titles = {
    invoice: "INVOICE",
    bills: "BILL",
    fiscalInvoice: "fiscal_invoice",
    generatedInvoice: "proforma_invoice",
};

const calculateAmount = (product, taxes = []) => {
    const { totalAmount, includedAmount } = calculateTotalTax({
        taxData: product.taxData?.map((t) => ({
            isIncluded: t.isIncluded,
            tax: taxes.find((tax) => tax._id === t.taxId),
        })),
        price: parseFloat(product.price),
    });
    const totalPrice = (parseFloat(product.price) + totalAmount - includedAmount) * product.quantity;
    return isNaN(totalPrice) ? 0.0 : totalPrice;
};

const calculateTotalDiscount = ({ totalPrice = 0, discountType, discountValue }) => {
    if (!discountType || !discountValue) return 0;
    const discountAmount =
        discountType === "total" ? parseFloat(discountValue) : totalPrice * parseFloat(discountValue) * 0.01;
    if (isNaN(discountAmount)) return 0;
    return discountAmount;
};
const amountInfo = ({ items = [], taxes = [], discountType, discountValue }) => {
    let totalPrice = 0.0;
    let subtotal = 0.0;
    let discount = 0.0;
    let taxTotal = 0.0;

    items?.forEach((item) => {
        const { totalAmount, includedAmount } = calculateTotalTax({
            taxData: item.taxData?.map((t) => ({
                isIncluded: t.isIncluded,
                tax: taxes.find((tax) => tax._id === t.taxId),
            })),
            price: parseFloat(item.price),
        });
        taxTotal += totalAmount * item.quantity;
        totalPrice += (parseFloat(item.price) + totalAmount - includedAmount) * item.quantity;
    });
    totalPrice = parseFloat(parseFloat(totalPrice).toFixed(2));
    subtotal = totalPrice - taxTotal;
    discount = calculateTotalDiscount({ totalPrice, discountType, discountValue });

    return {
        totalPrice: isNaN(totalPrice) ? 0.0 : totalPrice,
        subtotal: isNaN(subtotal) ? 0.0 : subtotal,
        taxTotal: isNaN(taxTotal) ? 0.0 : taxTotal,
        discount: isNaN(discount) ? 0.0 : discount,
    };
};

export const exportToPdf = async ({
    values = {},
    itemsOptions = [],
    t,
    options = {},
    fetch,
    resource = "invoice",
    convertedCurrencies,
    hideReservationDetails = false,
}) => {
    const data = await fetch({
        operation: "query",
        multipleEndpoints: [
            {
                endpoint: "settingsgeneral",
                responseData: "_id currencyData{currency currencyname rate} language footerInvoiceNotes",
                data: { _id: "settings_general" },
            },
            {
                endpoint: "settingsproperty",
                responseData: "_id name logo phone address city zip registrationNumber email website",
                data: { _id: "settings_property" },
            },
            {
                endpoint: "taxes",
                responseData: "_id name rate taxCategory isVat",
            },
            {
                endpoint: "paymentmethods",
                responseData: "_id name",
            },
            {
                endpoint: "paymentaccounts",
                responseData: "_id name currency bank accountId",
            },
            {
                endpoint: "currencies",
                responseData: "_id active currency currencyname rate",
            },
        ],
    });

    const propertyData = data?.settingsproperty || {};
    const settingsData = data?.settingsgeneral || {};
    const taxData = data?.taxes || [];
    const translate = (text) => t(text, { lngs: [values?.pdfLanguage || settingsData?.language || "en"] });
    let { logo } = propertyData || {};

    const isFiscal = Boolean(values?.fiscalData?.nivf);
    const pdf = new jsPDF();
    pdf.setFont("Helvetica", "bold");
    pdf.setTextColor(0, 0, 0);
    pdf.setFontSize(20);

    /**
     * Invoice HEADER
     * display property logo or property name
     * show title (invoice,bill,fiscal invoices,...)
     */
    // if (!!logo) {
    //     pdf.addImage(logo, "JPEG", 7, 5, 30, 30);
    // } else {
    //     pdf.text(propertyData?.name || "", 7, 15);
    // }
    pdf.setFontSize(22);
    pdf.text(translate(titles[resource] || "INVOICE"), 207, 15, "right");

    /**
     * Show property Details
     * Show property address if address specified in header in settings
     */
    pdf.setFontSize(11);
    pdf.text(propertyData?.name || "", 207, 22, "right");
    pdf.setFontSize(10);
    pdf.setFont("Helvetica", "normal");

    const addressString = [
        !!propertyData?.registrationNumber ? `VAT: ${propertyData?.registrationNumber}` : undefined,
        propertyData?.address,
        propertyData?.city,
        propertyData?.zip,
        propertyData?.phone,
    ]
        ?.filter((detail) => !!detail)
        ?.join(`\n`);
    pdf.text(addressString, 207, 27, "right");

    /**
     * Business Details
     */
    let businessString = [
        `${translate("number")}: ${values?.number || ""}`,
        `${translate("date")}: ${values?.date || ""}`,
        `${translate("dueDate")}: ${values?.dueDate || ""}`,
        `${translate("status")}: ${`${translate(values?.status)}` || translate("DRAFT")}`,
    ]?.join(`\n`);

    if (isFiscal) {
        businessString = [
            `${translate("invNum")}: ${values?.fiscalData?.InvOrdNum || ""}`,
            `${translate("date")}: ${values?.date || ""}`,
            `${translate("dueDate")}: ${values?.dueDate || ""}`,
            `${translate("BU")}: ${values?.fiscalData?.BusinUnitCode || ""}`,
            `${translate("TCR")}: ${values?.fiscalData?.TCR || ""}`,
            `${translate("OP")}: ${values?.fiscalData?.operator || ""}`,
            `${translate("currency")}: ${values?.invoiceCurrency || ""}`,
        ]?.join(`\n`);
    }

    /**
     * Bill to Details
     */
    const billTo = values?.clientsData || values?.supplierData || {};
    const billToString = [
        billTo?.name ? billTo?.name : billTo?.firstName ? `${billTo?.firstName} ${billTo.lastName || ""}` : undefined,
        billTo?.addressLine,
        billTo?.city,
        billTo?.country,
        billTo?.company,
        billTo?.email,
        billTo?.phone,
        billTo?.vat,
        billTo?.IDNumber,
    ]
        ?.filter((detail) => !!detail)
        ?.join(`\n`);

    pdf.autoTable({
        theme: "plain",
        startY: 50,
        styles: { fontSize: 10, textColor: "#000", cellPadding: { top: 1 } },
        headStyles: {
            minCellWidth: 75,
            fillColor: [220, 233, 241],
            textColor: [13, 24, 33],
            cellPadding: 1,
            lineWidth: 0,
        },
        margin: { left: 7, top: 0, right: 7 },
        tableWidth: "wrap",
        head: [
            [
                translate(resource === "bills" ? "bill_details" : "invoice_details"),
                translate(resource === "bills" ? "supplier" : "bill_to"),
            ],
        ],
        body: [[businessString, billToString]],
    });

    if (values?.description) {
        pdf.autoTable({
            theme: "grid",
            startY: pdf.autoTable.previous.finalY + 10,
            styles: { fontSize: 10, textColor: "#000", cellPadding: { top: 1 } },
            margin: { left: 7, top: 0, right: 7 },
            body: [[values?.description]],
            bodyStyles: { lineWidth: 0.1, cellPadding: 1 },
        });
    }

    /**
     * Items Table
     */

    const itemsDetails = values?.tableData?.map((item) => {
        let quantity = parseFloat(item.quantity);
        if (isNaN(quantity)) quantity = 0;
        let price = parseFloat(item.price);
        if (isNaN(price)) price = 0;
        const taxes =
            taxData?.filter((tax) => item.taxData?.some((t) => t.taxId === tax._id))?.map((tax) => tax.name) || [];
        return [
            itemsOptions?.find((option) => option._id === item.itemId)?.name || "",
            item.description || "",
            quantity?.toFixed(2),
            item.unit || "",
            toCurrency(price, values?.invoiceCurrency || settingsData?.currencyData?.currency || ""),
            taxes?.join(","),
            toCurrency(calculateAmount(item), values?.invoiceCurrency || settingsData?.currencyData?.currency || ""),
        ];
    });

    pdf.autoTable({
        theme: "grid",
        startY: pdf.autoTable.previous.finalY + 10,
        styles: { fontSize: 10, textColor: "#000", cellPadding: 1 },
        headStyles: {
            fillColor: [220, 233, 241],
            textColor: [13, 24, 33],
            cellPadding: 1,
            lineWidth: 0,
        },
        margin: { left: 7, top: 0, right: 7 },
        head: [
            [
                translate("item"),
                translate("description"),
                translate("quantity"),
                translate("unit"),
                translate("price"),
                translate("tax"),
                translate("amount"),
            ],
        ],
        body: itemsDetails,
        bodyStyles: { lineWidth: 0.1, cellPadding: 1 },
    });

    /**
     * Detailed amounts table
     */

    const detailedAmount = amountInfo({
        items: values?.tableData,
        taxes: taxData,
        discountType: values?.discountType,
        discountValue: values?.discountValue,
    });

    pdf.autoTable({
        theme: "grid",
        tableWidth: "wrap",
        startY: pdf.autoTable.previous.finalY,
        styles: { fontSize: 10, textColor: [13, 24, 33], cellPadding: { top: 1 } },
        margin: {
            left:
                findColumnWidth(pdf.autoTable.previous.columns, pdf.autoTable.previous.columns.length) -
                pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 4].width -
                pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 3].width -
                pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 2].width -
                pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 1].width +
                7,
        },
        columnStyles: {
            0: {
                cellWidth:
                    pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 4].width +
                    pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 3].width,
            },
            1: {
                cellWidth:
                    pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 2].width +
                    pdf.autoTable.previous.columns[pdf.autoTable.previous.columns.length - 1].width,
            },
        },
        body: [
            [
                translate("subtotal"),
                toCurrency(
                    detailedAmount?.subtotal,
                    values?.invoiceCurrency || settingsData?.currencyData?.currency || ""
                ),
            ],
            [
                translate("discount"),
                toCurrency(
                    detailedAmount?.discount,
                    values?.invoiceCurrency || settingsData?.currencyData?.currency || ""
                ),
            ],
            [
                translate("tax"),
                toCurrency(
                    detailedAmount?.taxTotal,
                    values?.invoiceCurrency || settingsData?.currencyData?.currency || ""
                ),
            ],
            // ...(fiscalPdf
            //     ? groupTaxes({ tableData: tableDataDetailed, taxes: taxes }).map((tax) => {
            //           return [
            //               `${pdfLang("TVSH")} ${tax.rate}%`,
            //               toCurrency(tax.total, values?.invoiceCurrency || settingsData?.currencyData?.currency || ""),
            //           ];
            //       })
            //     : []),
            [
                translate("total"),
                toCurrency(
                    detailedAmount?.totalPrice - detailedAmount?.discount,
                    values?.invoiceCurrency || settingsData?.currencyData?.currency || ""
                ),
            ],
        ],
        bodyStyles: { lineWidth: 0.1, cellPadding: 1 },
    });
    if (convertedCurrencies && convertedCurrencies?.length > 0) {
        pdf.autoTable({
            theme: "grid",
            tableWidth: "wrap",
            startY: pdf.autoTable.previous.finalY,
            styles: { fontSize: 10, textColor: [13, 24, 33], cellPadding: { top: 1 } },
            margin: {
                left:
                    pdf.internal.pageSize.width -
                    (pdf.autoTable.previous.columns[0].width + pdf.autoTable.previous.columns[1].width) -
                    7,
            },
            columnStyles: {
                0: {
                    cellWidth: pdf.autoTable.previous.columns[0].width,
                },
                1: {
                    cellWidth: pdf.autoTable.previous.columns[1].width,
                },
            },
            body: convertedCurrencies.map((currency) => {
                return [translate("total") + " " + currency.currency, toCurrency(currency.amount, currency.currency)];
            }),
            bodyStyles: { lineWidth: 0.1, cellPadding: 1 },
        });
    }

    /**
     * Payments Table
     */

    if (values?.payments?.length > 0) {
        pdf.autoTable({
            theme: "grid",
            startY: pdf.autoTable.previous.finalY + 10,
            styles: { fontSize: 10, textColor: "#000", cellPadding: { top: 1 } },
            headStyles: {
                fillColor: [220, 233, 241],
                textColor: [13, 24, 33],
                cellPadding: 1,
                lineWidth: 0,
            },
            margin: { left: 7, top: 0, right: 7 },
            head: [[translate("amount"), translate("payment_method"), translate("date")]],
            body: values?.payments?.map((payment) => {
                const paymentMethodName = data?.paymentmethods?.find(
                    (method) => method._id === payment.paymentMethod
                )?.name;
                return [
                    toCurrency(payment.amount, values?.invoiceCurrency || settingsData?.currencyData?.currency || ""),
                    paymentMethodName || "",
                    DateTime.fromISO(payment.timestamp).toFormat("yyyy-LL-dd HH:mm:ss"),
                ];
            }),
            bodyStyles: { lineWidth: 0.1, cellPadding: 1 },
        });
    }
    let paidAmount = values?.payments?.reduce((acc, payment) => (acc += payment.amount), 0.0) || 0.0;
    paidAmount = parseFloat(parseFloat(paidAmount).toFixed(2));
    pdf.text(
        translate("balance_due") +
            ": " +
            toCurrency(
                detailedAmount.totalPrice - paidAmount - detailedAmount.discount,
                values?.invoiceCurrency || settingsData?.currencyData?.currency || ""
            ),
        7,
        pdf.autoTable.previous.finalY + 10
    );

    if (values?.reservationData) {
        if (!hideReservationDetails) {
            const { checkin, checkout, clientsData } = values?.reservationData || {};
            const { firstName, lastName } = clientsData?.[0];
            pdf.autoTable({
                theme: "grid",
                startY: pdf.autoTable.previous.finalY + 20,
                styles: { fontSize: 10, textColor: "#000", cellPadding: { top: 1 } },
                headStyles: {
                    fillColor: [220, 233, 241],
                    textColor: [13, 24, 33],
                    cellPadding: 1,
                    lineWidth: 0,
                },
                margin: { left: 7, top: 0, right: 7 },
                head: [[translate("checkin"), translate("checkout"), translate("guest")]],
                body: [[checkin, checkout, (firstName || "") + " " + (lastName || "")]],
                bodyStyles: { lineWidth: 0.1, cellPadding: 1 },
            });
        }
    }

    const pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
    const pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();

    /**
     * Fiscal Details
     * Show payment methods
     * Show QR, nivf, nslf
     */
    const convertedTotalAmount = exchangeCurrency({
        amount: detailedAmount.totalPrice,
        fromCurrency: values?.invoiceCurrency || "ALL",
        toCurrency: "ALL",
        currencies: data?.currencies || [],
    });
    if (isFiscal) {
        if (pageHeight - 70 < pdf.autoTable.previous.finalY) {
            pdf.addPage();
        }
        const paymentMethods = (
            values?.payments?.map((payment) => {
                const paymentMethodName = data?.paymentmethods?.find(
                    (method) => method._id === payment.paymentMethod
                )?.name;
                return paymentMethodName || "";
            }) || []
        )?.join(",");

        const bankAccountData = data?.paymentaccounts?.find((acc) => acc._id === values?.account);
        let QRUrl = `https://efiskalizimi-app.tatime.gov.al/invoice-check/#/verify?iic=${
            values?.fiscalData?.IICRef
        }&tin=${values?.fiscalData?.nipt}&crtd=${DateTime.fromISO(values?.fiscalData?.IssueDateTime).toFormat(
            `yyyy-MM-dd\'T\'HH:mm:ss`
        )}%2002:00&prc=${(convertedTotalAmount || 0)?.toFixed(2)}`;
        const QR = await QRCode.toDataURL(QRUrl);
        pdf.text(translate("payment_method") + ": " + paymentMethods, 7, pageHeight - 60);
        let fiscalTextsHeight = pageHeight;
        if (bankAccountData?.bank && values?.fiscalData?.eic) {
            pdf.text(translate("payment_account") + ": " + bankAccountData?.name, 7, pageHeight - 55);
            pdf.text(bankAccountData?.bank, 7, pageHeight - 45);
            pdf.text(bankAccountData?.accountId, 7, pageHeight - 40);
            fiscalTextsHeight += 15;
        }

        pdf.text(translate("NIVF") + ": " + (values?.fiscalData?.nivf || ""), 7, fiscalTextsHeight - 50);
        pdf.text(translate("NSLF") + ": " + (values?.fiscalData?.nslf || ""), 7, fiscalTextsHeight - 45);
        if (!!QR) pdf.addImage(QR, "png", 160, pageHeight - 70, 40, 40);
        if (bankAccountData?.bank && values?.fiscalData?.eic) {
            const bankDetailsQRURL = `${values?.fiscalData?.nipt || ""};${bankAccountData?.name || ""};${
                values?.fiscalData?.nivf || ""
            };${values?.fiscalData?.IssueDateTime};${values?.totalPrice?.toFixed(2)};${
                bankAccountData?.currency || "ALL"
            };${bankAccountData?.accountId || ""};;${bankAccountData?.bank}`;

            const bankDetailsQR = await QRCode.toDataURL(bankDetailsQRURL);
            pdf.addImage(bankDetailsQR, "png", 120, pageHeight - 70, 40, 40);
        }
    }

    /**
     * Invoice Footer
     */
    if (!isFiscal && values?.clientsData?.firstName) {
        pdf.setFontSize(11);
        pdf.text(
            `${translate("client")}:\n${values?.clientsData?.firstName} ${
                values?.clientsData?.lastName || ""
            }${"\n\n_____________"}`,
            140,
            pageHeight - 70
        );
    }

    const footerString = [
        settingsData?.footerInvoiceNotes ? `${settingsData?.footerInvoiceNotes}` : undefined,
        propertyData?.email ? `${translate("email")}: ${propertyData?.email}` : undefined,
        propertyData?.website ? `${translate("web")}: ${propertyData?.website}` : undefined,
    ]
        ?.filter((detail) => !!detail)
        ?.join(`\n`);
    pdf.setTextColor(100);
    pdf.setFontSize(10);
    pdf.text(footerString, pageWidth / 2, pageHeight - 10, "center");

    /**
     * Save pdf
     */
    pdf.setPage(1);
    if (!logo) {
        pdf.text(propertyData?.name || "", 7, 15);
        printPdf({
            pdf,
            type: options?.type,
            title: isFiscal ? `invoice_${values?.fiscalData?.nivf}` : values?._id || `invoices_${new Date().toJSON()}`,
        });
    } else {
        const img = new Image();
        img.src = logo;
        img.onload = () => {
            const imageWidth = img.width;
            const imageHeight = img.height;

            pdf.addImage(img, "JPEG", 7, 5, (30 * imageWidth) / imageHeight, 30);
            printPdf({
                pdf,
                type: options?.type,
                title: isFiscal
                    ? `invoice_${values?.fiscalData?.nivf}`
                    : values?._id || `invoices_${new Date().toJSON()}`,
            });
        };
    }
};

const groupTaxes = ({ tableData = [], taxes = [] }) => {
    const taxesAmounts = {};
    tableData.forEach((item) => {
        const { taxData = [] } = item;
        taxData
            ?.filter((tax) => tax.taxId)
            ?.forEach((tax) => {
                const taxInfo = taxes?.find((t) => t.doc._id === tax.taxID);
                if (taxInfo) {
                    const { totalAmount } = calculateTotalTax({
                        taxData: [{ isIncluded: tax.isIncluded, tax: taxInfo }],
                        price: item.price,
                    });

                    const amount = totalAmount * parseFloat(item.quantity);
                    const { total = 0, name = taxInfo.name, rate = taxInfo.rate } = taxesAmounts[taxInfo._id] || {};
                    const updatedTotal = total + amount;
                    taxesAmounts[taxInfo.id] = { name, rate, total: isNaN(updatedTotal) ? 0.0 : updatedTotal };
                }
            });
    });
    return Object.values(taxesAmounts);
};

const printPdf = ({ pdf, type, title }) => {
    if (type === "autoprint") {
        pdf.autoPrint();
        pdf.output("dataurlnewwindow");
    } else {
        pdf.save(title + ".pdf");
    }
};
