import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interaction from "@fullcalendar/interaction";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "./components/calendar/FullCalendar.css";
import { apiSubscription, useApi } from "../../components/hooks";
import { CreateReservationDrawer, ReservationEditConfirm, UnassignedReservationsList } from "./components/calendar";
import { DateTime } from "luxon";
import { Outlet, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { applyDiscount, generateDatesBetween, primaryGuestCategory, ProtectedAction } from "../../utils";
import {
    Backdrop,
    Button,
    CircularProgress,
    Stack,
    Tooltip,
    IconButton,
    Chip,
    ButtonGroup,
    Typography,
    Alert,
} from "@mui/material";
import { DateControl } from "../../components/dates";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircleIcon from "@mui/icons-material/Circle";
import NoteIcon from "@mui/icons-material/Note";
import { t } from "i18next";
import { CurrencyConverter } from "../../components/classes";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import TableChartIcon from "@mui/icons-material/TableChart";
import GroupsIcon from "@mui/icons-material/Groups";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

const statusColors = {
    APPROVED: "#1FB6FF", //default status
    CHECKIN: "#59C086", //checked in
    CHECKOUT: "#FFC82C", //checked out
    CANCELLED: "#FF4949", //canceled
    NO_SHOW: "#E91E63", // no show

    OUT_OF_ORDER: "#808080", // OOO Reservations
    ON_HOLD: "#513394", // on hold
};

const housekeepingStatusColor = {
    clean: "#ADF0CC",
    inspected: "#E4C1F9",
    dirty: "#EDBCB8",
    occupied: "#B3E4F5",
    roomService: "#B6A61C",
};

const calculatePaymentStatus = (reservation = {}, currencyConvert) => {
    let totalPrice = 0.0;
    let paid = 0.0;
    const invoices = reservation?.invoices?.filter((invoice) => {
        return !["CANCELLED", "TRANSFERRED"].includes(invoice?.status);
    });
    const orders = reservation?.orders?.filter((order) => !["CANCELLED", "TRANSFERRED"].includes(order?.status));
    if (invoices?.length > 0) {
        invoices?.forEach((invoice) => {
            totalPrice += invoice.totalPrice;
            paid += invoice?.payments?.reduce((acc, payment) => acc + payment.amount, 0.0) || 0.0;
        });
    } else {
        totalPrice += reservation?.totalPrice || 0.0;
        paid += reservation?.payments?.reduce((acc, payment) => acc + payment.amount, 0.0) || 0.0;
    }
    orders?.forEach((order) => {
        totalPrice += currencyConvert?.convertAmount({
            amount: order.totalPrice,
            from: order?.pospointData?.defaultCurrency,
            to: reservation?.invoiceCurrency,
        });
        if (!order?.payments?.paymentMethod) return;
        if (order?.payments?.paymentMethod === "roomCharge") return;
        paid += currencyConvert?.convertAmount({
            amount: order?.payments?.cashReceived,
            from: order?.pospointData?.defaultCurrency,
            to: reservation?.invoiceCurrency,
        });
    });
    const parsedPaidAmount = parseFloat(parseFloat(paid).toFixed(2));
    const parsedTotalAmount = parseFloat(parseFloat(totalPrice).toFixed(2));
    if (parsedPaidAmount === 0.0) return "UNPAID";
    if (parsedPaidAmount < parsedTotalAmount) return "PARTLY_PAID";
    return "PAID";
};

const reservationsToEvents = (reservations = [], currencyConvert) => {
    return (
        reservations
            ?.filter((reservation) => {
                if (reservation.status && reservation.status === "CANCELLED") {
                    return false;
                }
                if (reservation.isDailyUse) {
                    return false;
                }
                if (reservation?.isBlockedRooms && reservation?.status === "DONE") {
                    return false;
                }
                return true;
            })
            ?.map((reservation) => {
                const { firstName, lastName } = reservation?.clientsData?.[0] || {};
                const clientName = (firstName || "") + " " + (lastName || "");
                const paymentStatus = calculatePaymentStatus(reservation, currencyConvert);
                return reservation?.rooms?.map((room, i) => ({
                    id: reservation._id + "R" + i,
                    title: !!reservation?.isBlockedRooms ? reservation?.blockedName || "" : clientName,
                    start: reservation?.checkin,
                    end: reservation?.checkout,
                    resourceId: room.roomId,
                    roomtypeId: room.roomtypeId,
                    backgroundColor: !!reservation?.isBlockedRooms
                        ? statusColors[reservation?.type]
                        : statusColors[reservation.status],
                    reservationData: reservation,
                    paymentStatus: paymentStatus,
                    hasNote: reservation?.notes?.length > 0,
                    viewMore: !!reservation?.isBlockedRooms
                        ? `blocked-rooms/${reservation._id?.split("reservations_")?.[1]}`
                        : `reservations/${reservation._id?.split("reservations_")?.[1]}`,
                }));
            })
            ?.flat() || []
    );
};

const getRoomsWithDailyUse = (reservations = []) => {
    const rooms = [];
    reservations
        ?.filter(
            (reservation) =>
                reservation.status &&
                reservation.status !== "CANCELLED" &&
                reservation.isDailyUse &&
                (reservation.checkin === DateTime.now().toFormat("yyyy-LL-dd") ||
                    reservation.checkin === DateTime.now().plus({ days: 1 }).toFormat("yyyy-LL-dd"))
        )
        ?.forEach((reservation) => {
            reservation?.rooms?.forEach((room) => {
                rooms.push(room.roomId);
            });
        });

    return rooms;
};

const RESERVATION_RESPONSE_DATA = `_id checkin checkout isDailyUse bookingSource status clients uuid invoiceCurrency totalPrice
isBlockedRooms type blockedName
clientsData{_id firstName lastName} 
notes{note}
rooms{roomtypeId roomData{name} roomId customPrice prices{price rateId date discount{type value}}} 
guests{roomId guests{name number price extra}} 
payments{amount paymentMethodData{name fiscalMethod method}}`;

const ReservationsCalendar = () => {
    const [displayDate, setDisplayDates] = useState({
        currentDate: DateTime.now()?.toFormat("yyyy-LL-dd"),
        startDate: DateTime.now()?.minus({ days: 2 })?.toFormat("yyyy-LL-dd"),
        endDate: DateTime.now()?.plus({ days: 28 })?.toFormat("yyyy-LL-dd"),
    });
    const [resources, setResources] = useState({
        rooms: [],
        roomtypes: [],
        housekeepings: [],
        guestcategories: [],
        currencyConvert: new CurrencyConverter({}),
    });
    const [reservations, setReservations] = useState([]);
    const [newReservationDrawer, setNewReservationDrawer] = useState(null);
    const [editedEvent, setEditedEvent] = useState(null);
    const [unAssignedReservationsModal, setUnAssignedReservationsModal] = useState({
        open: false,
        reservations: [],
    });

    const calendarRef = useRef();

    const events = useMemo(() => {
        return reservationsToEvents(reservations, resources?.currencyConvert);
    }, [reservations, resources?.currencyConvert]);

    const roomsWithDailyUse = useMemo(() => {
        return getRoomsWithDailyUse(reservations);
    }, [reservations]);

    useEffect(() => {
        loadRoomData();
    }, []);

    useEffect(() => {
        if (!resources?.rooms?.length > 0) return;
        loadReservations();
    }, [displayDate, resources.rooms, resources.roomtypes, resources.guestcategories, resources.currencyConvert]);

    useEffect(() => {
        const { disconnect } = apiSubscription().openConnection(
            {
                operation: "subscription",
                endpoint: "reservationsRefresh",
                responseData: `
                    newReservations{${RESERVATION_RESPONSE_DATA}}
                    updatedReservations{${RESERVATION_RESPONSE_DATA}}
                    deletedReservations
                `,
            },
            (newData) => {
                const { newReservations, updatedReservations, deletedReservations } =
                    newData?.reservationsRefresh || {};
                if (!!newReservations) {
                    setReservations((prevReservations) => {
                        let reservationsRefreshed = prevReservations || [];
                        newReservations?.forEach((reservation) => {
                            const changedRoomtypes = [];
                            reservation?.rooms?.forEach((room) => {
                                if (!changedRoomtypes?.includes(room.roomtypeId)) {
                                    changedRoomtypes.push(room.roomtypeId);
                                }
                            });
                            changedRoomtypesInformationRef.current = { roomtypes: changedRoomtypes };

                            if (reservationsRefreshed?.some((r) => r._id === reservation?._id)) return;
                            reservationsRefreshed = reservationsRefreshed.concat([reservation]);
                        });
                        return [...reservationsRefreshed];
                    });
                }
                if (!!deletedReservations) {
                    setReservations((prevReservations) => {
                        const reservationsRefreshed = prevReservations?.filter(
                            (r) => !deletedReservations?.includes(r?._id)
                        );
                        return [...reservationsRefreshed];
                    });
                }
                if (!!updatedReservations) {
                    setReservations((prevReservations) => {
                        let reservationsRefreshed = prevReservations;
                        updatedReservations?.forEach((reservation) => {
                            let prevReservation = reservationsRefreshed?.find((r) => r._id === reservation?._id);

                            const changedRoomtypes = [];
                            reservation?.rooms?.concat(prevReservation?.rooms || [])?.forEach((room) => {
                                if (!changedRoomtypes?.includes(room.roomtypeId)) {
                                    changedRoomtypes.push(room.roomtypeId);
                                }
                            });
                            changedRoomtypesInformationRef.current = { roomtypes: changedRoomtypes };

                            if (!!prevReservation) {
                                reservationsRefreshed = reservationsRefreshed?.map((r) => {
                                    if (r._id !== prevReservation._id) return r;
                                    const { invoices, orders } = prevReservation;
                                    return { ...reservation, invoices, orders };
                                });
                            } else reservationsRefreshed = reservationsRefreshed.concat([reservation]);
                        });
                        return [...reservationsRefreshed];
                    });
                }
            }
        );
        return () => {
            disconnect();
        };
    }, []);
    useEffect(() => {
        const { disconnect } = apiSubscription().openConnection(
            {
                operation: "subscription",
                endpoint: "invoicesRefresh",
                responseData: `
                    newInvoices{_id totalPrice payments{amount paymentMethodData{name fiscalMethod method}} invoiceCurrency status reservationIds}
                    updatedInvoices{_id totalPrice payments{amount paymentMethodData{name fiscalMethod method}} invoiceCurrency status reservationIds}
                    deletedInvoices
                `,
            },
            (newData) => {
                setReservations((prevReservations) => {
                    let reservationsRefreshed = prevReservations || [];
                    const { newInvoices, updatedInvoices, deletedInvoices } = newData?.invoicesRefresh || {};
                    if (!!newInvoices) {
                        newInvoices?.forEach((invoice) => {
                            invoice?.reservationIds.forEach((reservationId) => {
                                reservationsRefreshed = reservationsRefreshed?.map((reservation) => {
                                    if (reservation?._id !== reservationId) return reservation;
                                    reservation.invoices = [...(reservation?.invoices || []), invoice];
                                    return reservation;
                                });
                            });
                        });
                    }
                    if (!!deletedInvoices) {
                        deletedInvoices?.forEach((invoice) => {
                            reservationsRefreshed = reservationsRefreshed?.map((reservation) => {
                                if (reservation?.invoices?.some((inv) => inv._id === invoice)) {
                                    return {
                                        ...reservation,
                                        invoices: reservation.invoices?.filter((i) => i._id !== invoice),
                                    };
                                }
                                return reservation;
                            });
                        });
                    }
                    if (!!updatedInvoices) {
                        updatedInvoices?.forEach((invoice) => {
                            invoice?.reservationIds.forEach((reservationId) => {
                                reservationsRefreshed = reservationsRefreshed?.map((reservation) => {
                                    if (reservation?._id !== reservationId) return reservation;
                                    let prevInvoice = reservation?.invoices?.find((inv) => inv._id === invoice._id);
                                    if (!!prevInvoice) {
                                        return {
                                            ...reservation,
                                            invoices: reservation?.invoices?.map((inv) => {
                                                if (inv._id !== prevInvoice._id) return inv;
                                                return invoice;
                                            }),
                                        };
                                    } else {
                                        return {
                                            ...reservation,
                                            invoices: [...(reservation?.invoices || []), invoice],
                                        };
                                    }
                                });
                            });
                        });
                    }
                    return [...reservationsRefreshed];
                });
            }
        );
        return () => {
            disconnect();
        };
    }, []);
    useEffect(() => {
        const { disconnect } = apiSubscription().openConnection(
            {
                operation: "subscription",
                endpoint: "housekeepingsRefresh",
                responseData: `updatedHousekeepings{_id status}`,
            },
            (newData) => {
                setResources((prevResourcesState) => {
                    const { housekeepings } = prevResourcesState || {};
                    const { updatedHousekeepings } = newData?.housekeepingsRefresh || {};
                    if (!!updatedHousekeepings) {
                        updatedHousekeepings?.forEach((housekeeping) => {
                            const housekeepingIndex = housekeepings?.findIndex((h) => h._id === housekeeping._id);
                            if (housekeepingIndex >= 0) {
                                housekeepings[housekeepingIndex].status = housekeeping.status;
                            }
                        });
                    }
                    return { ...prevResourcesState, housekeepings };
                });
            }
        );
        return () => {
            disconnect();
        };
    }, []);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const roomsFetch = useApi();
    const loadRoomData = async () => {
        const response = await roomsFetch.fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "roomtypes",
                    responseData: "_id name price roomtypeOrder",
                },
                {
                    endpoint: "rooms",
                    responseData: "_id name roomtypeId price maxCapacity includedCapacity capacity{id price}",
                },
                {
                    endpoint: "housekeepings",
                    responseData: "_id status",
                },
                {
                    endpoint: "guestcategories",
                    responseData: "_id name priorityOrder",
                },
                {
                    endpoint: "settingsgeneral",
                    responseData: "_id currency currencyData{currency currencyname rate} calendarOrder",
                    data: { _id: "settings_general" },
                },
                {
                    endpoint: "currencies",
                    responseData: "_id currency rate",
                },
            ],
        });
        if (response?.rooms) {
            setResources({
                rooms: response?.rooms
                    ?.map((room) => {
                        const { name, roomtypeOrder } =
                            response?.roomtypes?.find((rt) => rt._id === room?.roomtypeId) || {};
                        return {
                            id: room._id,
                            roomtypeId: room.roomtypeId,
                            roomtypeName: name,
                            title: room.name,
                            roomData: room,
                            roomtypeOrder: roomtypeOrder,
                        };
                    })
                    ?.sort((a, b) => {
                        return a.title - b.title;
                    })
                    ?.sort((a, b) => {
                        switch (response?.settingsgeneral?.calendarOrder) {
                            case "alphabetical":
                                return a.roomtypeName?.toLowerCase().localeCompare(b?.roomtypeName?.toLowerCase());
                            case "roomOrder":
                                return a.roomtypeOrder - b.roomtypeOrder;
                            default:
                                return -1;
                        }
                    }),
                roomtypes: response?.roomtypes || [],
                housekeepings: response?.housekeepings || [],
                guestcategories: response?.guestcategories,
                currencyConvert: new CurrencyConverter({
                    currencies: response?.currencies,
                    settings: response?.settingsgeneral,
                }),
            });
        }
    };

    const reservationsFetch = useApi();
    const firstLoadReservations = useApi();
    const loadReservations = async () => {
        const response = await firstLoadReservations.fetch({
            operation: "query",
            endpoint: "reservationsByDates",
            data: { startDate: displayDate.startDate, endDate: displayDate.endDate },
            responseData: RESERVATION_RESPONSE_DATA,
        });
        if (response?.reservationsByDates) {
            setReservations(response?.reservationsByDates);
            loadReservationsInvoices(response?.reservationsByDates);
        }
    };

    const reservationsInvoicesFetch = useApi();
    const loadReservationsInvoices = async (reservations = []) => {
        if (!reservations?.length > 0) return;
        const response = await reservationsInvoicesFetch.fetch({
            operation: "query",
            multipleEndpoints: [
                {
                    endpoint: "invoicesByReservations",
                    data: { reservationIds: reservations?.map((r) => r._id) },
                    responseData:
                        "_id totalPrice payments{amount paymentMethodData{name fiscalMethod method}} invoiceCurrency status reservationIds",
                },
                {
                    endpoint: "ordersByReservations",
                    data: { reservationIds: reservations?.map((r) => r._id) },
                    responseData:
                        "reservationId totalPrice status pospointData{defaultCurrency} payments{paymentMethod cashReceived}",
                },
            ],
        });
        if (response) {
            setReservations(
                reservations?.map((reservation) => {
                    const invoices = response?.invoicesByReservations?.filter((invoice) =>
                        invoice?.reservationIds?.includes(reservation?._id)
                    );
                    const orders = response?.ordersByReservations?.filter(
                        (order) => order?.reservationId === reservation?._id
                    );
                    reservation.invoices = invoices;
                    reservation.orders = orders;
                    return reservation;
                })
            );
        }
    };

    const isRoomFree = ({ checkin, checkout, roomId, skipEvent }) => {
        let skipedEventRoomtype = null;
        const sameDaysEvents = events?.filter((event) => {
            if (!!skipEvent) {
                if (event?.id === skipEvent) {
                    skipedEventRoomtype = event.roomtypeId;
                    return false;
                }
            }
            return (
                DateTime.fromISO(checkout) > DateTime.fromISO(event.start) &&
                DateTime.fromISO(checkin) < DateTime.fromISO(event.end)
            );
        });
        if (sameDaysEvents?.some((event) => event.resourceId === roomId)) {
            enqueueSnackbar("room_not_available", { variant: "warning" });
            return false;
        }
        const { roomtypeId } = resources?.rooms?.find((room) => room.id === roomId) || {};
        const roomtypeName = resources?.roomtypes?.find((rt) => rt._id === roomtypeId)?.name;
        const roomtypeDailyAvailability = roomtypesDailyAvailability[roomtypeName];
        if (!roomtypeDailyAvailability) return true;
        if (
            roomtypeDailyAvailability
                ?.filter(
                    (daily) =>
                        DateTime.fromISO(checkin) <= DateTime.fromISO(daily.day) &&
                        DateTime.fromISO(checkout) > DateTime.fromISO(daily.day)
                )
                ?.some((daily) => {
                    let freeQuantity = daily.roomsQuantity;
                    if (!!skipedEventRoomtype && skipedEventRoomtype === roomtypeId) {
                        freeQuantity += 1;
                    }
                    return freeQuantity === 0;
                })
        ) {
            enqueueSnackbar("room_not_available", { variant: "warning" });
            return false;
        }
        return true;
    };

    const roomtypeDayAvailability = (roomtypeId, day) => {
        const sameDaysEvents = events?.filter((event) => {
            return (
                DateTime.fromISO(day) >= DateTime.fromISO(event.start) &&
                DateTime.fromISO(day) < DateTime.fromISO(event.end)
            );
        });
        const maxRoomtypeQuantity = resources?.rooms?.filter((room) => room.roomtypeId === roomtypeId)?.length || 0;
        const bookedRoomtypeQuantity = sameDaysEvents?.filter((event) => event?.roomtypeId === roomtypeId)?.length || 0;
        const unAssignedRoomtypeQuantity =
            sameDaysEvents?.filter((event) => event?.roomtypeId === roomtypeId && event?.resourceId === "UNASSIGNED")
                ?.length || 0;
        const unAssignedReservations = [];
        sameDaysEvents
            ?.filter((event) => event?.roomtypeId === roomtypeId && event?.resourceId === "UNASSIGNED")
            ?.forEach((event) => {
                const reservation = event?.reservationData;
                if (unAssignedReservations?.some((r) => r._id === reservation._id)) return;
                unAssignedReservations.push(reservation);
            });
        return {
            day,
            roomsQuantity: maxRoomtypeQuantity - bookedRoomtypeQuantity,
            unAssignedQuantity: unAssignedRoomtypeQuantity,
            unAssignedReservations,
            maxRoomtypeQuantity,
        };
    };

    const changedRoomtypesInformationRef = useRef({});
    const roomtypesDailyAvailabilityRef = useRef({});

    const roomtypesDailyAvailability = useMemo(() => {
        const dates = generateDatesBetween(displayDate?.startDate, displayDate?.endDate, "[]");
        const roomtypeInfo = {};
        resources?.roomtypes?.forEach((rt) => {
            if (
                changedRoomtypesInformationRef?.current?.roomtypes?.length > 0 &&
                !changedRoomtypesInformationRef?.current?.roomtypes?.includes(rt._id)
            ) {
                if (!!roomtypesDailyAvailabilityRef?.current?.[rt.name]) {
                    roomtypeInfo[rt.name] = roomtypesDailyAvailabilityRef?.current?.[rt.name];
                    return;
                }
            }
            roomtypeInfo[rt.name] = dates?.map((day) => {
                return roomtypeDayAvailability(rt._id, day);
            });
        });
        roomtypesDailyAvailabilityRef.current = roomtypeInfo;
        changedRoomtypesInformationRef.current = {};
        return roomtypeInfo;
    }, [events, resources?.rooms, resources?.roomtypes, displayDate]);

    const onDatesSelect = ({ checkin, checkout, roomId }) => {
        if (checkin === checkout) return;
        if (!isRoomFree({ checkin, checkout, roomId })) return;
        const { id, title, roomtypeId, roomtypeName, roomData } = resources?.rooms?.find((r) => r.id === roomId) || {};
        if (!id) return;
        const defaultGuests = {};
        const defaultGuestCategory = primaryGuestCategory(roomData.capacity, resources?.guestcategories);
        if (!!defaultGuestCategory?._id && !!defaultGuestCategory?.name) {
            defaultGuests[defaultGuestCategory?._id] = {
                name: defaultGuestCategory?.name,
                number: roomData.includedCapacity || 0,
            };
        }
        setNewReservationDrawer({
            defaultStep: 1,
            checkin,
            checkout,
            checkedRooms: {
                [roomId]: {
                    _id: roomId,
                    name: title,
                    maxCapacity: roomData?.maxCapacity,
                    includedCapacity: roomData?.includedCapacity,
                    capacity: roomData?.capacity,
                    price: roomData.price,
                    roomtypeId,
                    roomtypeName,
                    guests: defaultGuests,
                },
            },
        });
    };

    const convertOnHoldToNewReservation = ({ checkin, checkout, roomIds }) => {
        const newReservationData = { defaultStep: 1, checkin, checkout };
        if (roomIds?.length > 0) {
            roomIds?.forEach((roomId) => {
                const { id, title, roomtypeId, roomtypeName, roomData } =
                    resources?.rooms?.find((r) => r.id === roomId) || {};
                if (!id) return;
                if (!newReservationData?.checkedRooms) {
                    newReservationData.checkedRooms = {};
                }
                newReservationData.checkedRooms[roomId] = {
                    _id: roomId,
                    name: title,
                    maxCapacity: roomData?.maxCapacity,
                    includedCapacity: roomData?.includedCapacity,
                    capacity: roomData?.capacity,
                    price: roomData.price,
                    roomtypeId,
                    roomtypeName,
                };
            });
            setNewReservationDrawer(newReservationData);
        }
        return;
    };

    const onRoomMove = async ({ keepPrice = false, updatedPrice }) => {
        const { _id, rooms, guests, discount, checkin, checkout } = editedEvent?.reservation || {};
        const reservationData = {
            _id,
            checkin,
            checkout,
            rooms: rooms?.map((r) => {
                return {
                    roomId: r.roomId,
                    roomtypeId: r.roomtypeId,
                    prices: r.prices?.map((day) => {
                        return { price: day.price, rateId: day.rateId, date: day.date, discount: day.discount };
                    }),
                    customPrice: r.customPrice,
                };
            }),
            guests,
        };
        /**
         * Replace the old room guests with the new room
         */
        reservationData.guests?.forEach((roomGuests) => {
            if (roomGuests.roomId === editedEvent?.currentRoom?._id) {
                roomGuests.roomId = editedEvent?.newRoom?._id;
            }
        });

        /**
         * If user checks keep price in confirm modal only replace current roomId and roomtypeId to the new room values
         * Totalprice stays the same
         */
        if (keepPrice) {
            reservationData?.rooms?.forEach((room) => {
                if (room.roomId === editedEvent?.currentRoom?._id) {
                    room.roomId = editedEvent?.newRoom?._id;
                    room.roomtypeId = editedEvent?.newRoom?.roomtypeId;
                }
            });
            const response = await reservationsFetch.fetch({
                operation: "mutation",
                endpoint: "updateReservation",
                data: reservationData,
                responseData: RESERVATION_RESPONSE_DATA,
            });
            if (response?.updateReservation) {
                setReservations(
                    reservations?.map((r) => {
                        if (r._id !== response?.updateReservation?._id) return r;
                        const { orders = [], invoices = [] } = r || {};
                        return { ...response?.updateReservation, orders, invoices };
                    })
                );
                setEditedEvent(null);
            } else {
                editedEvent?.revert();
                setEditedEvent(null);
            }
            return;
        }

        /**
         * If user checks update price in confirm modal replace current roomId and roomtypeId to the new room values
         * Replace room price details
         * Recalculate reservation totalPrice
         */
        reservationData?.rooms?.forEach((room) => {
            if (room.roomId === editedEvent?.currentRoom?._id) {
                room.roomId = editedEvent?.newRoom?._id;
                room.roomtypeId = editedEvent?.newRoom?.roomtypeId;
                room.prices?.forEach((day) => {
                    day.price = parseFloat(updatedPrice);
                    day.rateId = "standard";
                    day.discount = null;
                });
                room.customPrice = null;
            }
        });
        const guestsDailyExtraPrice =
            reservationData.guests?.reduce((acc, roomGuests) => {
                const roomGuestsDailyExtraPrice =
                    roomGuests?.guests?.reduce((roomAcc, category) => {
                        return roomAcc + category.price * category.extra;
                    }, 0.0) || 0.0;
                return acc + roomGuestsDailyExtraPrice;
            }, 0.0) || 0.0;

        const totalPrice =
            guestsDailyExtraPrice * reservationData.rooms?.[0]?.prices.length +
                reservationData.rooms?.reduce((acc, room) => {
                    const roomTotalPrice =
                        room.prices?.reduce((roomAcc, day) => {
                            const roomDailyPrice = day.price;
                            return roomAcc + roomDailyPrice;
                        }, 0.0) || 0.0;
                    return acc + roomTotalPrice;
                }, 0.0) || 0.0;

        reservationData.totalPrice = applyDiscount(totalPrice, discount);
        const response = await reservationsFetch.fetch({
            operation: "mutation",
            endpoint: "updateReservation",
            data: reservationData,
            responseData: RESERVATION_RESPONSE_DATA,
        });
        if (response?.updateReservation) {
            setReservations(
                reservations?.map((r) => {
                    if (r._id !== response?.updateReservation?._id) return r;
                    const { orders = [], invoices = [] } = r || {};
                    return { ...response?.updateReservation, orders, invoices };
                })
            );
            setEditedEvent(null);
        } else {
            editedEvent?.revert();
            setEditedEvent(null);
        }
    };

    const onReservationResize = async ({ keepPrice = false, updatedPrice }) => {
        const { _id, rooms, guests, checkin, checkout, discount, clients, uuid } = editedEvent?.reservation || {};

        /**
         * Case when reservation has closed invoice and is allowed only extend
         * Don't change the old reservation
         * Create new reservation where checkin checkout is the extended part
         */
        if (editedEvent.hasInvoices) {
            const newReservationData = {
                checkin: editedEvent?.changes?.field === "checkin" ? editedEvent?.changes?.newValue : checkout,
                checkout: editedEvent?.changes?.field === "checkout" ? editedEvent?.changes?.newValue : checkin,
                rooms: rooms
                    ?.filter((r) => r.roomId === editedEvent?.currentRoom?._id)
                    ?.map((r) => {
                        return {
                            roomId: r.roomId,
                            roomtypeId: r.roomtypeId,
                            prices: r.prices?.map((day) => {
                                return { price: day.price, rateId: day.rateId, date: day.date, discount: day.discount };
                            }),
                            customPrice: r.customPrice,
                        };
                    }),
                guests: guests?.filter((g) => g.roomId === editedEvent?.currentRoom?._id),
                clients,
                notes: [],
                uuid,
            };
            const dailyPriceData = newReservationData?.rooms?.[0]?.prices?.[0];
            if (!keepPrice) dailyPriceData.customPrice = null;
            newReservationData.rooms[0].prices = generateDatesBetween(
                newReservationData?.checkin,
                newReservationData?.checkout,
                "[)"
            )?.map((day) => {
                return {
                    price: keepPrice ? dailyPriceData.price : updatedPrice,
                    rateId: keepPrice ? dailyPriceData.rateId : "standard",
                    date: day,
                    discount: keepPrice ? dailyPriceData.discount : null,
                };
            });
            const newReservationGuestsDailyExtraPrice =
                newReservationData.guests?.[0]?.guests?.reduce(
                    (acc, category) => acc + category.price * category.extra,
                    0.0
                ) || 0.0;
            const newReservationTotalPrice = newReservationData.rooms[0].prices?.reduce((acc, day) => {
                const roomDailyPrice = day.price;
                return acc + roomDailyPrice + newReservationGuestsDailyExtraPrice;
            }, 0.0);
            newReservationData.totalPrice = applyDiscount(newReservationTotalPrice, discount);
            const response = await reservationsFetch.fetch({
                operation: "mutation",
                endpoint: "createReservation",
                data: newReservationData,
                responseData: RESERVATION_RESPONSE_DATA,
            });
            if (response?.createReservation) {
                setReservations([...reservations, response?.createReservation]);
                editedEvent?.revert();
                setEditedEvent(null);
                return;
            }
        }
        /**
         * Case when reservation has no closed invoice and can be edited freely
         */
        if (rooms?.length === 1) {
            const reservationData = {
                _id,
                rooms: rooms?.map((r) => {
                    return {
                        roomId: r.roomId,
                        roomtypeId: r.roomtypeId,
                        prices: r.prices?.map((day) => {
                            return { price: day.price, rateId: day.rateId, date: day.date, discount: day.discount };
                        }),
                        customPrice: r.customPrice,
                    };
                }),
                guests,
                checkin: editedEvent?.changes?.field === "checkin" ? editedEvent?.changes?.newValue : checkin,
                checkout: editedEvent?.changes?.field === "checkout" ? editedEvent?.changes?.newValue : checkout,
            };
            const dailyPriceData = reservationData?.rooms?.[0]?.prices?.[0];
            if (!keepPrice) dailyPriceData.customPrice = null;
            reservationData.rooms[0].prices = generateDatesBetween(
                reservationData?.checkin,
                reservationData?.checkout,
                "[)"
            )?.map((day) => {
                return {
                    price: keepPrice ? dailyPriceData.price : updatedPrice,
                    rateId: keepPrice ? dailyPriceData.rateId : "standard",
                    date: day,
                    discount: keepPrice ? dailyPriceData.discount : null,
                };
            });
            const guestsDailyExtraPrice =
                reservationData.guests?.[0]?.guests?.reduce(
                    (acc, category) => acc + category.price * category.extra,
                    0.0
                ) || 0.0;
            const totalPrice = reservationData.rooms[0].prices?.reduce((acc, day) => {
                const roomDailyPrice = day.price;
                return acc + roomDailyPrice + guestsDailyExtraPrice;
            }, 0.0);
            reservationData.totalPrice = applyDiscount(totalPrice, discount);
            const response = await reservationsFetch.fetch({
                operation: "mutation",
                endpoint: "updateReservation",
                data: reservationData,
                responseData: RESERVATION_RESPONSE_DATA,
            });
            if (response?.updateReservation) {
                setReservations(
                    reservations?.map((r) => {
                        if (r._id !== response?.updateReservation?._id) return r;
                        const { orders = [], invoices = [] } = r || {};
                        return { ...response?.updateReservation, orders, invoices };
                    })
                );
                setEditedEvent(null);
            } else {
                editedEvent?.revert();
                setEditedEvent(null);
            }
            return;
        }
        /**
         * If reservation has more than 1 room split reservations in two separate reservation
         * The old reservation keeps all the unchanged rooms
         * The new reservation has the resized room
         */
        const oldReservation = {
            _id,
            rooms: rooms
                ?.filter((r) => r.roomId !== editedEvent?.currentRoom?._id)
                ?.map((r) => {
                    return {
                        roomId: r.roomId,
                        roomtypeId: r.roomtypeId,
                        prices: r.prices?.map((day) => {
                            return { price: day.price, rateId: day.rateId, date: day.date, discount: day.discount };
                        }),
                        customPrice: r.customPrice,
                    };
                }),
            guests: guests?.filter((g) => g.roomId !== editedEvent?.currentRoom?._id),
            checkin,
            checkout,
        };
        let guestsDailyExtraPrice = 0.0;
        oldReservation.guests?.forEach((room) => {
            room?.guests?.forEach((category) => {
                guestsDailyExtraPrice += category.price * category.extra;
            });
        });
        let totalPrice = 0.0;
        oldReservation.rooms?.forEach((room) => {
            room?.prices?.forEach((day) => {
                const roomDailyPrice = day.price;
                totalPrice += roomDailyPrice + guestsDailyExtraPrice;
            });
        });
        oldReservation.totalPrice = applyDiscount(totalPrice, discount);

        const newReservation = {
            checkin: editedEvent?.changes?.field === "checkin" ? editedEvent?.changes?.newValue : checkin,
            checkout: editedEvent?.changes?.field === "checkout" ? editedEvent?.changes?.newValue : checkout,
            rooms: rooms
                ?.filter((r) => r.roomId === editedEvent?.currentRoom?._id)
                ?.map((r) => {
                    return {
                        roomId: r.roomId,
                        roomtypeId: r.roomtypeId,
                        prices: r.prices?.map((day) => {
                            return { price: day.price, rateId: day.rateId, date: day.date, discount: day.discount };
                        }),
                        customPrice: r.customPrice,
                    };
                }),
            guests: guests?.filter((g) => g.roomId === editedEvent?.currentRoom?._id),
            clients,
            notes: [],
            uuid,
        };
        const dailyPriceData = newReservation?.rooms?.[0]?.prices?.[0];
        if (!keepPrice) dailyPriceData.customPrice = null;
        newReservation.rooms[0].prices = generateDatesBetween(
            newReservation?.checkin,
            newReservation?.checkout,
            "[)"
        )?.map((day) => {
            return {
                price: keepPrice ? dailyPriceData.price : updatedPrice,
                rateId: keepPrice ? dailyPriceData.rateId : "standard",
                date: day,
                discount: keepPrice ? dailyPriceData.discount : null,
            };
        });
        const newReservationGuestsDailyExtraPrice =
            newReservation.guests?.[0]?.guests?.reduce((acc, category) => acc + category.price * category.extra, 0.0) ||
            0.0;
        const newReservationTotalPrice =
            newReservation.rooms[0].prices?.reduce((acc, day) => {
                const roomDailyPrice = day.price;
                return acc + roomDailyPrice + newReservationGuestsDailyExtraPrice;
            }, 0.0) || 0.0;
        newReservation.totalPrice = applyDiscount(newReservationTotalPrice, discount);
        const response = await reservationsFetch.fetch({
            operation: "mutation",
            multipleEndpoints: [
                {
                    endpoint: "updateReservation",
                    data: oldReservation,
                    responseData: RESERVATION_RESPONSE_DATA,
                },
                {
                    endpoint: "createReservation",
                    data: newReservation,
                    responseData: RESERVATION_RESPONSE_DATA,
                },
            ],
        });
        if (response?.updateReservation && response?.createReservation) {
            setReservations(
                reservations
                    ?.map((r) => {
                        if (r._id !== response?.updateReservation?._id) return r;
                        const { orders = [], invoices = [] } = r || {};
                        return { ...response?.updateReservation, orders, invoices };
                    })
                    ?.concat([response.createReservation])
            );
            setEditedEvent(null);
        } else {
            editedEvent?.revert();
            setEditedEvent(null);
        }
    };

    const isRoomMoveAllowed = ({ reservation, newRoom, oldRoom }) => {
        if (!reservation?.invoices?.length > 0) return true;
        if (newRoom.roomtypeId === oldRoom.roomtypeId) return true;
        enqueueSnackbar(`${t("closed_invoice")}\n${t("no_roomtype_change_allowed")}`, { variant: "warning" });
        return false;
    };

    const onReservationChange = (reservationData) => {
        setReservations(
            reservations?.map((r) => {
                if (r._id !== reservationData?._id) return r;
                const { orders = [], invoices = [] } = r || {};
                const changedRoomtypes = [];
                reservationData?.rooms?.concat(r?.rooms || [])?.forEach((room) => {
                    if (!changedRoomtypes?.includes(room.roomtypeId)) {
                        changedRoomtypes.push(room.roomtypeId);
                    }
                });
                changedRoomtypesInformationRef.current = { roomtypes: changedRoomtypes };
                return { ...reservationData, orders, invoices };
            })
        );
    };

    const unassignedReservations = useMemo(() => {
        return reservations
            ?.filter((reservation) => reservation.status !== "CANCELLED" && reservation.status !== "CHECKOUT")
            ?.filter((reservation) => reservation?.rooms?.some((room) => room?.roomId === "UNASSIGNED"));
    }, [reservations]);

    return (
        <>
            <Stack direction="row" alignItems="center" style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }} gap={0}>
                <IconButton
                    onClick={() => {
                        const newCurrentDate = DateTime.fromISO(displayDate.currentDate).minus({ days: 10 });
                        setDisplayDates({
                            currentDate: newCurrentDate.toFormat("yyyy-LL-dd"),
                            startDate: newCurrentDate.minus({ days: 2 }).toFormat("yyyy-LL-dd"),
                            endDate: newCurrentDate.plus({ days: 28 }).toFormat("yyyy-LL-dd"),
                        });
                    }}
                >
                    <ArrowBackIosIcon />
                </IconButton>
                <DateControl
                    fullWidth={false}
                    size="small"
                    label={t("date")}
                    value={displayDate.currentDate}
                    onChange={(e) => {
                        if (!e.target.value) return;
                        setDisplayDates({
                            currentDate: e.target.value,
                            startDate: DateTime.fromISO(e.target.value).minus({ days: 2 }).toFormat("yyyy-LL-dd"),
                            endDate: DateTime.fromISO(e.target.value).plus({ days: 28 }).toFormat("yyyy-LL-dd"),
                        });
                    }}
                />
                <IconButton
                    onClick={() => {
                        const newCurrentDate = DateTime.fromISO(displayDate.currentDate).plus({ days: 10 });
                        setDisplayDates({
                            currentDate: newCurrentDate.toFormat("yyyy-LL-dd"),
                            startDate: newCurrentDate.minus({ days: 2 }).toFormat("yyyy-LL-dd"),
                            endDate: newCurrentDate.plus({ days: 28 }).toFormat("yyyy-LL-dd"),
                        });
                    }}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
                {unassignedReservations?.length > 0 && (
                    <Alert severity="error" style={{ position: "absolute", right: 270, padding: "0px 5px" }}>{`${t(
                        "unassigned_reservations"
                    )}: ${unassignedReservations?.length}`}</Alert>
                )}
                <ButtonGroup
                    size="small"
                    aria-label="calendar-actions"
                    style={{ marginLeft: 10, position: "absolute", right: 30 }}
                >
                    <ProtectedAction permission={{ reservations: { create: true } }}>
                        <Tooltip title={t("new_reservation")}>
                            <Button onClick={() => navigate(`/newreservation`)} variant="outlined">
                                <ControlPointIcon />
                            </Button>
                        </Tooltip>
                    </ProtectedAction>
                    <ProtectedAction permission={{ availability: { view: true } }}>
                        <Tooltip title={t("pricing")}>
                            <Button onClick={() => navigate(`/backoffice/availabilities`)} variant="outlined">
                                <CurrencyExchangeIcon />
                            </Button>
                        </Tooltip>
                    </ProtectedAction>
                    <ProtectedAction permission={{ reservations: { view: true } }}>
                        <Tooltip title={t("day_report")}>
                            <Button onClick={() => navigate(`/arrivals`)} variant="outlined">
                                <TableChartIcon />
                            </Button>
                        </Tooltip>
                    </ProtectedAction>
                    <ProtectedAction permission={{ groups: { view: true } }}>
                        <Tooltip title={t("groups")}>
                            <Button onClick={() => navigate(`/groups`)} variant="outlined">
                                <GroupsIcon />
                            </Button>
                        </Tooltip>
                    </ProtectedAction>
                    <ProtectedAction permission={{ reservations: { view: true } }}>
                        <Tooltip title={t("day_use_view")}>
                            <Button onClick={() => navigate(`/dailycalendar`)} variant="outlined">
                                <QueryBuilderIcon />
                            </Button>
                        </Tooltip>
                    </ProtectedAction>
                </ButtonGroup>
            </Stack>
            <FullCalendar
                ref={calendarRef}
                height="auto"
                selectable
                editable
                eventStartEditable={false}
                headerToolbar={false}
                plugins={[resourceTimelinePlugin, interaction]}
                initialView="customMonth"
                views={{
                    customMonth: {
                        type: "resourceTimeline",
                        visibleRange: { start: displayDate.startDate, end: displayDate.endDate },
                        initialDate: displayDate.currentDate,
                    },
                }}
                stickyHeaderDates
                resourceAreaHeaderContent={`${DateTime.fromISO(displayDate.startDate).toFormat(
                    "LLL dd"
                )} - ${DateTime.fromISO(displayDate.endDate).toFormat("LLL dd")}`}
                resourceGroupField="roomtypeName"
                resourceAreaWidth={"200px"}
                slotMinWidth={100}
                resources={resources?.rooms || []}
                resourceOrder={null}
                events={events || []}
                eventMaxStack={1}
                eventOverlap={false}
                eventBorderColor="transparent"
                resourceGroupLaneContent={(args) => {
                    const roomtypeData = roomtypesDailyAvailability?.[args.groupValue] || [];
                    return (
                        <div style={{ display: "inline-flex" }}>
                            {roomtypeData?.map((day) => {
                                return (
                                    <div
                                        style={{
                                            width: "100px",
                                            textAlign: "center",
                                            fontSize: 14,
                                            fontWeight: 600,
                                            zIndex: 10,
                                            cursor: !!day.unAssignedQuantity ? "pointer" : "auto",
                                        }}
                                        onClick={() => {
                                            if (!day.unAssignedQuantity) return;
                                            setUnAssignedReservationsModal({
                                                open: true,
                                                reservations: day.unAssignedReservations,
                                            });
                                        }}
                                    >
                                        <Stack direction="row" style={{ display: "block", marginTop: 3 }} spacing={0.5}>
                                            <Chip
                                                label={day.unAssignedQuantity}
                                                color="warning"
                                                size="small"
                                                clickable={!day.unAssignedQuantity ? false : true}
                                                sx={{
                                                    backgroundColor: "#EADDFF",
                                                    color: "#543886",
                                                    borderRadius: 2,
                                                }}
                                            />
                                            <Chip
                                                label={day.roomsQuantity}
                                                color="success"
                                                clickable //on click show modal to update price
                                                size="small"
                                                sx={{
                                                    backgroundColor: "#E9FBF0",
                                                    color: "#0F5C2E",
                                                    borderRadius: 2,
                                                }}
                                            />
                                        </Stack>
                                    </div>
                                );
                            })}
                        </div>
                    );
                }}
                resourceLabelContent={(args) => {
                    const { _id } = args?.resource?.extendedProps?.roomData || {};
                    const { status } = resources?.housekeepings?.find((h) => h._id === "housekeepings_" + _id) || {};
                    const hasDailyUse = roomsWithDailyUse?.includes(_id);
                    return (
                        <>
                            <span
                                style={{
                                    backgroundColor: hasDailyUse ? "rgba(252, 232, 5,0.7)" : "transparent",
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                }}
                            >
                                {args?.fieldValue}
                            </span>
                            <span
                                style={{
                                    float: "right",
                                    width: "30px",
                                    height: "20px",
                                    borderRadius: 5,
                                    backgroundColor: housekeepingStatusColor[status],
                                }}
                            />
                        </>
                    );
                }}
                slotLabelFormat={{
                    weekday: "short",
                    day: "2-digit",
                    month: "2-digit",
                    omitCommas: true,
                }}
                eventContent={(args) => {
                    const status = args?.event?.extendedProps?.paymentStatus || "UNPAID";
                    return (
                        <>
                            <div className="event-title-with-icon">{args?.event?.title}</div>
                            {status === "UNPAID" ? null : (
                                <CircleIcon
                                    style={{
                                        color: status === "PAID" ? "green" : "orange",
                                        fontSize: 14,
                                        position: "absolute",
                                        bottom: 13,
                                        right: 20,
                                    }}
                                />
                            )}
                            {!!args?.event?.extendedProps?.hasNote ? (
                                <NoteIcon
                                    style={{
                                        color: "black",
                                        fontSize: 14,
                                        position: "absolute",
                                        bottom: 13,
                                        right: 0,
                                    }}
                                />
                            ) : null}
                        </>
                    );
                }}
                eventDrop={(event) => {
                    if (!event.newResource) {
                        event.revert();
                        return;
                    }
                    if (
                        !isRoomMoveAllowed({
                            reservation: event?.oldEvent?._def?.extendedProps?.reservationData || {},
                            oldRoom: event?.oldResource?._resource?.extendedProps?.roomData,
                            newRoom: event?.newResource?._resource?.extendedProps?.roomData,
                        })
                    ) {
                        event.revert();
                        return;
                    }
                    const reservation = event?.oldEvent?._def?.extendedProps?.reservationData || {};
                    if (
                        !isRoomFree({
                            checkin: reservation?.checkin,
                            checkout: reservation?.checkout,
                            roomId: event?.newResource?._resource?.extendedProps?.roomData?._id,
                            skipEvent: event?.oldEvent?.id,
                        })
                    ) {
                        event.revert();
                        return;
                    }
                    setEditedEvent({
                        reservation,
                        currentRoom: event?.oldResource?._resource?.extendedProps?.roomData,
                        newRoom: event?.newResource?._resource?.extendedProps?.roomData,
                        changes: {
                            field: "room",
                            currentValue: event?.oldResource?._resource?.title,
                            newValue: event?.newResource?._resource?.title,
                        },
                        revert: event.revert,
                        action: "move",
                        startDate: reservation?.checkin,
                        endDate: reservation?.checkout,
                    });
                }}
                eventResize={(event) => {
                    const oldStart = event?.oldEvent?.startStr;
                    const oldEnd = event?.oldEvent?.endStr;
                    const newStart = event?.event?.startStr;
                    const newEnd = event?.event?.endStr;
                    const hasInvoices = event?.oldEvent?._def?.extendedProps?.reservationData?.invoices?.length > 0;
                    if (
                        DateTime.fromISO(oldStart) < DateTime.fromISO(newStart) ||
                        DateTime.fromISO(oldEnd) > DateTime.fromISO(newEnd)
                    ) {
                        if (hasInvoices) {
                            event.revert();
                            enqueueSnackbar(`${t("closed_invoice")}\n${t("change_not_allowed")}`, {
                                variant: "warning",
                            });
                            return;
                        }
                    }

                    const room = resources?.rooms?.find((r) => r.id === event?.event?._def?.resourceIds?.[0])?.roomData;
                    if (
                        !isRoomFree({
                            checkin: oldStart !== newStart ? newStart : oldEnd,
                            checkout: oldEnd !== newEnd ? newEnd : oldStart,
                            roomId: room?._id,
                            skipEvent: event?.oldEvent?.id,
                        })
                    ) {
                        event.revert();
                        return;
                    }
                    setEditedEvent({
                        reservation: event?.oldEvent?._def?.extendedProps?.reservationData || {},
                        currentRoom: room,
                        newRoom: room,
                        changes:
                            oldStart !== newStart
                                ? {
                                      field: "checkin",
                                      currentValue: oldStart,
                                      newValue: newStart,
                                  }
                                : {
                                      field: "checkout",
                                      currentValue: oldEnd,
                                      newValue: newEnd,
                                  },
                        revert: event.revert,
                        action: "resize",
                        hasInvoices,
                        startDate: newStart,
                        endDate: newEnd,
                    });
                }}
                eventClick={({ event }) => {
                    const { viewMore } = event?.extendedProps || {};
                    navigate(viewMore);
                }}
                select={(data) => {
                    onDatesSelect({
                        checkin: data?.startStr,
                        checkout: DateTime.fromISO(data?.endStr).minus({ days: 1 })?.toFormat("yyyy-LL-dd"),
                        roomId: data?.resource?._resource?.id,
                    });
                }}
            />
            <CreateReservationDrawer
                open={Boolean(newReservationDrawer)}
                onClose={() => setNewReservationDrawer(null)}
                data={newReservationDrawer}
                onNewReservation={(newReservation) => {
                    if (!newReservation.isGroupReservation) {
                        const changedRoomtypes = [];
                        newReservation?.rooms?.forEach((room) => {
                            if (!changedRoomtypes?.includes(room.roomtypeId)) {
                                changedRoomtypes.push(room.roomtypeId);
                            }
                        });
                        changedRoomtypesInformationRef.current = { roomtypes: changedRoomtypes };
                        setReservations([...reservations, newReservation]);
                    }
                    setNewReservationDrawer(null);
                }}
            />
            <ReservationEditConfirm
                open={Boolean(editedEvent)}
                reservation={editedEvent?.reservation}
                currentRoom={editedEvent?.currentRoom}
                newRoom={editedEvent?.newRoom}
                changes={editedEvent?.changes}
                onConfirm={(data) => {
                    if (editedEvent?.action === "move") onRoomMove(data);
                    else onReservationResize(data);
                }}
                onCancel={() => {
                    editedEvent?.revert();
                    setEditedEvent(null);
                }}
                startDate={editedEvent?.startDate}
                endDate={editedEvent?.endDate}
            />
            <UnassignedReservationsList
                open={unAssignedReservationsModal?.open}
                onClose={() => setUnAssignedReservationsModal({ open: false, reservations: [] })}
                reservations={unAssignedReservationsModal?.reservations}
            />
            <Backdrop sx={{ zIndex: 10000 }} open={roomsFetch.loading || firstLoadReservations.loading}>
                <CircularProgress size={60} sx={{ color: "white" }} />
            </Backdrop>
            <Outlet context={{ onReservationChange, convertOnHoldToNewReservation }} />
        </>
    );
};

export default ReservationsCalendar;
