import { Button, CardHeader } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useMemo, useState } from "react";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Table } from "../../../components/table";
import { DateTime } from "luxon";
import { CheckboxControl } from "../../../components/form";
import { TransferOrderToInvoiceModal } from "./view";
import { toCurrency } from "../../../utils";

const OrdersTable = ({ data = [], reservation, addOrderToInvoice }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [selectedOrders, setSelectedOrders] = useState([]);
    const columns = [
        {
            id: "select",
            header: "",
            cell: ({ row: { original } }) => (
                <CheckboxControl
                    disabled={
                        original?.status === "TRANSFERRED" ||
                        original?.status === "CANCELLED" ||
                        original?.payments?.paymentStatus === "paid" ||
                        !!original?.disableTransfer
                    }
                    value={selectedOrders?.includes(original?._id)}
                    onChange={(e) => {
                        if (e.target.checked) setSelectedOrders([...selectedOrders, original?._id]);
                        else setSelectedOrders(selectedOrders?.filter((i) => i !== original?._id));
                    }}
                />
            ),
        },
        {
            id: "customerName",
            header: t("customer"),
            accessorFn: (row) => row?.customerData?.firstName + " " + row?.customerData?.lastName,
        },
        {
            id: "dateCreated",
            header: t("date"),
            accessorFn: (row) => DateTime.fromISO(row.createdAt).toFormat("yyyy-LL-dd"),
        },
        {
            id: "timeCreated",
            header: t("time"),
            accessorFn: (row) => DateTime.fromISO(row.createdAt).toFormat("HH:mm:ss"),
        },
        {
            id: "paymentStatus",
            header: t("payment_status"),
            accessorFn: (row) => {
                if (["TRANSFERRED", "CANCELLED"].includes(row.status)) {
                    return t(row.status);
                }
                let paymentStatus = row?.payments?.paymentStatus;
                if (!paymentStatus) return t("open");
                if (paymentStatus === "partly_paid" && row?.payments?.cashReceived === 0) {
                    paymentStatus = "unpaid";
                }
                return t(paymentStatus);
            },
        },
        {
            id: "totalPrice",
            header: t("amount"),
            accessorFn: (row) => toCurrency(row.totalPrice || 0.0, row?.pospointData?.currencyData?.currency || ""),
        },
        { id: "pospoint", header: t("pospoint"), accessorFn: (row) => row?.pospointData?.name || "" },
        {
            id: "link",
            header: "",
            cell: ({ row: { original } }) => (
                <Button
                    startIcon={<VisibilityIcon />}
                    fullWidth
                    onClick={() => {
                        navigate(`orders/${original?._id?.split("orders_")[1]}`);
                    }}
                >
                    {t("view")}
                </Button>
            ),
        },
    ];

    const invoicesOptions = useMemo(() => {
        let options = [];
        if (reservation?.invoices?.length === 0) {
            options?.push({
                value: "TO_ISSUED_INVOICE",
                label: t("current_invoice"),
            });
            options?.push({
                value: "ISSUE_BOTH_INVOICES", // when reservation have no generated invoice and user want to transfer order to new invoice
                label: t("new_invoice"), // issue generated invoice and create a new invoice to transfer orders
            });
        } else {
            options = reservation?.invoices
                ?.filter((invoice) => {
                    if (invoice?.fiscalData?.IICRef) {
                        return false;
                    }
                    return !["CANCELLED", "TRANSFERRED"]?.includes(invoice?.status);
                })
                ?.map((invoice) => {
                    return { value: invoice._id, label: invoice.number };
                });
            options?.push({
                value: "NEW_INVOICE",
                label: t("new_invoice"),
            });
        }
        return options;
    }, [reservation?.invoices]);

    return (
        <>
            <CardHeader
                action={
                    selectedOrders?.length > 0 && (
                        <div style={{ width: "100%" }}>
                            <TransferOrderToInvoiceModal
                                invoicesOptions={invoicesOptions}
                                transferOrderToInvoice={(invoice) => {
                                    addOrderToInvoice &&
                                        addOrderToInvoice(
                                            invoice,
                                            data?.filter((order) => selectedOrders.includes(order._id))
                                        );
                                }}
                            />
                        </div>
                    )
                }
                avatar={<PointOfSaleIcon />}
                title={t("orders")}
                titleTypographyProps={{ variant: "h5" }}
            />
            <Table data={data} columns={columns} disableHeader disableSelection disableDelete disableFooter />
        </>
    );
};

export default OrdersTable;
