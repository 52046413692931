import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { useMemo } from "react";
import { capitalize } from "../../utils";

const AutoCompleteControl = ({
    options = [],
    label,
    name,
    fullWidth = true,
    size = "small",
    margin = "dense",
    variant = "outlined",
    onChange,
    value,
    error,
    checkDuplicates = false,
    helperText,
    inputProps = {},
    ...otherProps
}) => {
    const getSelectedOption = () => {
        if (otherProps.freeSolo) return value;
        if (!value) return otherProps.multiple ? [] : null;

        if (otherProps.multiple)
            return options.filter((option) => {
                if (typeof option === "object") return value.includes(option.value);
                return value.includes(option);
            });

        return (
            options.find((option) => {
                if (typeof option === "object") return option.value === value;
                return option === value;
            }) || null
        );
    };

    options = useMemo(() => {
        if (checkDuplicates) {
            options.forEach((option) => {
                let sameOptions = options.filter((c) => c.label === option.label);
                if (sameOptions.length > 1) option.label += " " + sameOptions.length;
            });
        }
        return options.filter((option) => !!option.value && !!option.label);
    }, [options, checkDuplicates]);

    const toEventFormatValue = (result) => {
        return { target: { value: result } };
    };

    const handleChange = (e, newValue) => {
        if (newValue === null || typeof newValue !== "object") {
            onChange && onChange(toEventFormatValue(newValue));
            return;
        }

        if (!otherProps.multiple) {
            onChange && onChange(toEventFormatValue(newValue.value));
            return;
        }

        onChange &&
            onChange(
                toEventFormatValue(
                    newValue.map((v) => {
                        if (typeof v === "object") return v.value;
                        return v;
                    })
                )
            );
        return;
    };

    const labelText = label || capitalize(name);

    return (
        <Autocomplete
            size={size}
            fullWidth={fullWidth}
            options={options}
            value={getSelectedOption()}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        ...inputProps,
                    }}
                    margin={margin}
                    variant={variant}
                    error={error}
                    helperText={helperText}
                    label={labelText}
                />
            )}
            {...otherProps}
        />
    );
};

export default AutoCompleteControl;
