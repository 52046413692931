import { Divider, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { InfoComponent } from "../../components/common";
import {
    AutoCompleteControl,
    CheckboxControl,
    InputControl,
    SelectControl,
    SwitchControl,
} from "../../components/form";
import { useApi } from "../../components/hooks";
import { Modal } from "../../components/modal";
import qz from "qz-tray";
import currency from "../../assests/currency";

const rules = {
    name: "string|required",
};
const countriesOptions = currency.map((c) => c.CtryNm);

const PospointForm = () => {
    const [values, setValues] = useState({});
    const [saveOptions, setSaveOptions] = useState([]);

    const [consvertCurrencyOptions, setConvertCurrencyOptions] = useState([]);
    const [warehousesOptions, setWarehousesOptions] = useState([]);
    const [paymentMethodsOptions, setPaymentMethodsOptions] = useState([]);
    const [currenciesOptions, setCurrenciesOptions] = useState([]);
    const [cashdesksOptions, setCashdesksOptions] = useState([]);
    const [printerOptions, setPrinterOptions] = useState([]);
    const [settingsAlphaWeb, setSettingsAlphaweb] = useState({});
    const [settingsBilanc, setSettingsBilanc] = useState({});
    const { t } = useTranslation();
    const { addRecordPosPoint } = useOutletContext() || {};

    var userAgent = navigator.userAgent.toLowerCase();
    var isAndroid = userAgent.indexOf("android") > -1;

    const saveActions = [
        { action: "save", label: t("save") },
        { action: "finalize", label: t("finalize") },
        { action: "print", label: t("print") },
        { action: "sendToKitchen", label: t("send_to_kitchen") },
    ];
    const printingOptions = [
        {
            value: "default",
            label: t("default"),
        },
        {
            value: "silent",
            label: t("silent_print"),
        },
        {
            value: "silent_to_printer",
            label: t("to_printer"),
        },
    ];

    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (params?.id !== "create") loadData();
    }, []);

    useEffect(() => {
        loadExtraData();
    }, []);

    useEffect(() => {
        _PC_Printers();
    }, []);

    const _PC_Printers = async () => {
        if (!qz.websocket.isActive()) {
            await qz.websocket.connect();
        }
        qz.printers.find().then((printers) => {
            setPrinterOptions(
                printers.map((printer) => ({
                    value: printer,
                    label: printer,
                }))
            );
        });
    };

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "pospoint",
            data: {
                _id: "pospoints_" + params?.id,
            },
            responseData: `_id name cashdeskId disableReceiptOnPay defaultCurrency warehouses activeMethods hasTables isActive waiterLogout alphawebClient bilancClient optionalName optionalCity optionalCountry optionalAddress
                courseManagement alwaysFinalize differentUserOrder differentTotalCurrencies liveOrderPrintingEnabled saveOptions tablesMoveEnabled tableCustomName 
                printOptions{
                    fiscal{printingOption printer} 
                    nonFiscal{printingOption printer} 
                    waiterReport{printingOption printer} 
                }`,
        });
        if (response?.pospoint) {
            setValues(response.pospoint);
            if (!!response.pospoint?.saveOptions) setSaveOptions(response.pospoint?.saveOptions);
        }
    };

    const loadExtraData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                { endpoint: "warehouses", responseData: "_id name" },
                { endpoint: "currencies", responseData: "_id currencyname currency" },
                { endpoint: "cashdesks", responseData: "_id tcrCode" },
                { endpoint: "paymentmethods", responseData: "_id name isActive fiscalMethod method" },
                { endpoint: "settingsalphaweb", data: { _id: "settings_alphaweb" }, responseData: "_id" },
                { endpoint: "settingsbilanc", data: { _id: "settings_bilanc" }, responseData: "_id" },
            ],
        });

        if (response?.warehouses) {
            setWarehousesOptions(response.warehouses.map((w) => ({ value: w._id, label: w.name })));
        }
        if (response?.currencies) {
            setCurrenciesOptions(response.currencies.map((c) => ({ value: c._id, label: c.currencyname })));
            setConvertCurrencyOptions(response.currencies.map((c) => ({ value: c.currency, label: c.currency })));
        }
        if (response?.cashdesks) {
            setCashdesksOptions(
                response.cashdesks?.filter((c) => !!c.tcrCode).map((c) => ({ value: c._id, label: c.tcrCode }))
            );
        }
        if (response?.settingsalphaweb) {
            setSettingsAlphaweb(response?.settingsalphaweb);
        }
        if (response?.settingsbilanc) {
            setSettingsBilanc(response?.settingsbilanc);
        }
        if (!!response?.paymentmethods)
            setPaymentMethodsOptions(
                response.paymentmethods
                    ?.filter((p) => p.isActive)
                    .map((c) => {
                        return {
                            value: c._id,
                            label: c.name,
                        };
                    })
            );
    };

    const saveHandler = async () => {
        const { _id } = values;
        const data = {
            name: values.name,
            cashdeskId: values.cashdeskId,
            defaultCurrency: values.defaultCurrency,
            warehouses: values.warehouses,
            activeMethods: values.activeMethods,
            hasTables: values.hasTables,
            isActive: values.isActive,
            waiterLogout: values.waiterLogout,
            courseManagement: values.courseManagement,
            alwaysFinalize: values.alwaysFinalize,
            differentUserOrder: values.differentUserOrder,
            liveOrderPrintingEnabled: values.liveOrderPrintingEnabled,
            tablesMoveEnabled: values.tablesMoveEnabled,
            printOptions: values.printOptions,
            alphawebClient: values.alphawebClient,
            bilancClient: values.bilancClient,
            tableCustomName: values.tableCustomName,
            saveOptions,
            differentTotalCurrencies: values.differentTotalCurrencies,
            optionalName: values?.optionalName,
            optionalCity: values?.optionalCity,
            optionalCountry: values?.optionalCountry,
            optionalAddress: values?.optionalAddress,
            disableReceiptOnPay: values?.disableReceiptOnPay,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updatePosPoint" : "createPosPoint",
            data,
            responseData: `_id name cashdeskId defaultCurrency warehouses activeMethods hasTables isActive waiterLogout alphawebClient bilancClient
            courseManagement alwaysFinalize differentUserOrder liveOrderPrintingEnabled tablesMoveEnabled saveOptions differentTotalCurrencies tableCustomName
            printOptions{
                fiscal{printingOption printer} 
                nonFiscal{printingOption printer} 
                waiterReport{printingOption printer} 
            }`,
        });
        if (response?.createPosPoint?._id || response?.updatePosPoint?._id) {
            enqueueSnackbar(t("pos_saved"), { variant: "default" });
            addRecordPosPoint && addRecordPosPoint(response.createPosPoint || response?.updatePosPoint);
            navigate(-1);
        }
    };

    const showAlphaWebField = useMemo(() => {
        if (settingsAlphaWeb?._id) return true;
        else return false;
    }, [settingsAlphaWeb]);

    const showBilancField = useMemo(() => {
        if (settingsBilanc?._id) return true;
        else return false;
    }, [settingsBilanc]);

    return (
        <div>
            <Modal
                titlelabel={t("pospoints_form")}
                open
                onClose={() => navigate(-1)}
                maxWidth="md"
                FormProps={{ values, rules, onValuesChange: setValues, onSubmit: saveHandler }}
                loading={loading}
                permission={{ pospoints: params?.id === "create" ? { create: true } : { edit: true } }}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <InputControl name="name" label={t("name")} />
                    </Grid>

                    <Grid item xs={6}>
                        <SelectControl options={currenciesOptions} name="defaultCurrency" label={t("main_currency")} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputControl name="tableCustomName" label={t("name_for_tables")} />
                    </Grid>
                    {showAlphaWebField ? (
                        <Grid item xs={6}>
                            <InputControl name="alphawebClient" label={t("alphaweb_client_code")} />
                        </Grid>
                    ) : null}
                    {showBilancField ? (
                        <Grid item xs={6}>
                            <InputControl name="bilancClient" label={t("bilanc_client_code")} />
                        </Grid>
                    ) : null}
                    {cashdesksOptions?.length > 0 ? (
                        <Grid item xs={6}>
                            <SelectControl name="cashdeskId" options={cashdesksOptions} label={t("tcr")} />
                        </Grid>
                    ) : null}

                    <Grid item xs={12}>
                        <AutoCompleteControl
                            multiple
                            options={consvertCurrencyOptions}
                            size="large"
                            name="differentTotalCurrencies"
                            label={t("convert_currencies")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AutoCompleteControl
                            multiple
                            options={warehousesOptions}
                            size="large"
                            name="warehouses"
                            label={t("warehouses")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AutoCompleteControl
                            multiple
                            options={paymentMethodsOptions}
                            size="large"
                            name="activeMethods"
                            label={t("payment_methods")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SwitchControl name="isActive" label={t("is_active")} />
                    </Grid>
                    <Grid item xs={6}>
                        <SwitchControl name="hasTables" label={t("table_layout")} />
                    </Grid>
                    <Grid item xs={6}>
                        <SwitchControl name="tablesMoveEnabled" label={t("tables_move_enabled")} />
                    </Grid>
                    <Grid container item xs={6}>
                        <Grid item xs={9}>
                            <SwitchControl name="waiterLogout" label={t("waiter_logout")} />
                        </Grid>
                        <Grid item xs={3}>
                            <InfoComponent text={t("logout_waiter_users_after_saving_order")} />
                        </Grid>
                    </Grid>
                    <Grid container item xs={6}>
                        <Grid item xs={9}>
                            <SwitchControl name="alwaysFinalize" label={t("finalize_on_by_default")} />
                        </Grid>
                        <Grid item xs={3}>
                            <InfoComponent text={t("always_finalize_info")} />
                        </Grid>
                    </Grid>
                    <Grid container item xs={6}>
                        <Grid item xs={9}>
                            <SwitchControl name="differentUserOrder" label={t("limit_user_order_access")} />
                        </Grid>
                        <Grid item xs={3}>
                            <InfoComponent text={t("restrict_users_to_open_same_table_with_an_order")} />
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <SwitchControl name="courseManagement" label={t("course_management")} />
                    </Grid>
                    <Grid item xs={6}>
                        <SwitchControl name="disableReceiptOnPay" label={t("disable_receipt_on_pay")} />
                    </Grid>
                    <Grid item xs={6}>
                        <SwitchControl name="liveOrderPrintingEnabled" label={t("live_order_printing")} />
                    </Grid>
                    <Grid container spacing={2} sx={{ marginTop: 2 }}>
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    {t("save_order_actions")}{" "}
                                    <InfoComponent text={t("select_what_actions_should_save_order_do")} />
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            {saveActions.map(({ action, label }) => (
                                <Grid item xs={6}>
                                    <CheckboxControl
                                        label={label}
                                        value={saveOptions.includes(action)}
                                        onChange={(e) => {
                                            if (e.target.checked) setSaveOptions([...saveOptions, action]);
                                            else setSaveOptions(saveOptions.filter((option) => option !== action));
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    {t("custom_receipt_information")}
                                    <InfoComponent
                                        text={t("leave_blank_if_you_want_to_use_main_information_from_settings")}
                                    />
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>

                            <Grid item xs={6}>
                                <InputControl name="optionalName" label={t("business_name_on_receipt")} />
                            </Grid>
                            <Grid item xs={6}>
                                <InputControl name="optionalAddress" label={t("address")} />
                            </Grid>
                            <Grid item xs={6}>
                                <InputControl name="optionalCity" label={t("city")} />
                            </Grid>
                            <Grid item xs={6}>
                                <AutoCompleteControl
                                    options={countriesOptions}
                                    name="optionalCountry"
                                    label={t("country")}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    {t("printing_options")} <InfoComponent text={t("printing_options")} />
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={6}>
                                    <SelectControl
                                        label={t("fiscal_receipt")}
                                        options={printingOptions}
                                        value={values?.printOptions?.fiscal?.printingOption || ""}
                                        onChange={(e) => {
                                            setValues({
                                                ...values,
                                                printOptions: {
                                                    ...(values?.printOptions || {}),
                                                    fiscal: { printingOption: e.target.value },
                                                },
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {values?.printOptions?.fiscal?.printingOption === "silent_to_printer" && (
                                        <SelectControl
                                            options={printerOptions}
                                            value={values?.printOptions?.fiscal?.printer || ""}
                                            onChange={(e) => {
                                                setValues({
                                                    ...values,
                                                    printOptions: {
                                                        ...(values?.printOptions || {}),
                                                        fiscal: {
                                                            ...values?.printOptions?.fiscal,
                                                            printer: e.target.value,
                                                        },
                                                    },
                                                });
                                            }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={6}>
                                    <SelectControl
                                        label={t("nonFiscal_receipt")}
                                        options={printingOptions}
                                        value={values?.printOptions?.nonFiscal?.printingOption || ""}
                                        onChange={(e) => {
                                            setValues({
                                                ...values,
                                                printOptions: {
                                                    ...(values?.printOptions || {}),
                                                    nonFiscal: { printingOption: e.target.value },
                                                },
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {values?.printOptions?.nonFiscal?.printingOption === "silent_to_printer" && (
                                        <SelectControl
                                            options={printerOptions}
                                            value={values?.printOptions?.nonFiscal?.printer || ""}
                                            onChange={(e) => {
                                                setValues({
                                                    ...values,
                                                    printOptions: {
                                                        ...(values?.printOptions || {}),
                                                        nonFiscal: {
                                                            ...values?.printOptions?.nonFiscal,
                                                            printer: e.target.value,
                                                        },
                                                    },
                                                });
                                            }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={6}>
                                    <SelectControl
                                        label={t("waiterReport")}
                                        options={printingOptions}
                                        value={values?.printOptions?.waiterReport?.printingOption || ""}
                                        onChange={(e) => {
                                            setValues({
                                                ...values,
                                                printOptions: {
                                                    ...(values?.printOptions || {}),
                                                    waiterReport: { printingOption: e.target.value },
                                                },
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {values?.printOptions?.waiterReport?.printingOption === "silent_to_printer" && (
                                        <SelectControl
                                            options={printerOptions}
                                            value={values?.printOptions?.waiterReport?.printer || ""}
                                            onChange={(e) => {
                                                setValues({
                                                    ...values,
                                                    printOptions: {
                                                        ...(values?.printOptions || {}),
                                                        waiterReport: {
                                                            ...values?.printOptions?.waiterReport,
                                                            printer: e.target.value,
                                                        },
                                                    },
                                                });
                                            }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
        </div>
    );
};

export default PospointForm;
