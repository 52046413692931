import {
    AppBar,
    Avatar,
    Button,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemText,
    Stack,
    Tab,
    Tabs,
    Toolbar,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import LockIcon from "@mui/icons-material/Lock";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { SwitchControl } from "../../components/form";
import { apiSubscription, useApi } from "../../components/hooks";
import { BottomBarLegend, PospointTable, TableForm, TableViewForm } from "./components";
import { useSnackbar } from "notistack";
import { BackgroundOfflineCacheSync, Logo } from "../../components/common";
import { getLoggedUserData, ProtectedAction } from "../../utils";
import { ORDER } from "../../utils/DataTypes";
import { DraggableContainer, DraggableWrapper } from "../../components/draggable";

const PospointTables = () => {
    const [posData, setPosData] = useState({});
    const [openOrders, setOpenOrders] = useState({
        orders: [],
    });

    const [tables, setTables] = useState([]);

    const [views, setViews] = useState([]);
    const tableViewsOptions = useMemo(() => {
        return views.map((view) => ({ value: view._id, label: view.name }));
    }, [views]);

    const [activeView, setActiveView] = useState(0);

    const [viewFormMode, setViewFormMode] = useState("close");
    const [viewFormValues, setViewFormValues] = useState({});

    const [tableFormMode, setTableFormMode] = useState("close");
    const [tableFormValues, setTableFormValues] = useState({});

    const [enableEdit, setEnableEdit] = useState(false);
    const [enableMove, setEnableMove] = useState(false);

    const [searchParams] = useSearchParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { displayName, username } = getLoggedUserData();

    const { loading, fetch } = useApi();
    const { t } = useTranslation();
    const tablesRef = useRef(null);

    useEffect(() => {
        loadPosData();
    }, []);

    useEffect(() => {
        loadOpenOrders();
    }, []);

    useEffect(() => {
        tablesRef.current = tables;
    }, [tables]);

    useEffect(() => {
        const { disconnect } = apiSubscription().openConnection(
            {
                operation: "subscription",
                endpoint: "ordersRefresh",
                responseData: `
                    newOrders{${ORDER}} 
                    updatedOrders{${ORDER}} 
                    deletedOrders
                `,
            },
            (newData) => {
                const { newOrders, updatedOrders, deletedOrders } = newData?.ordersRefresh || {};
                if (!!newOrders) {
                    setOpenOrders((prevOpenOrders) => {
                        let { orders } = prevOpenOrders || {};
                        newOrders?.forEach((order) => {
                            if (
                                order?.status === "OPEN" &&
                                order?.pospointId === "pospoints_" + searchParams.get("pos")
                            ) {
                                orders?.push(order);
                            }
                        });
                        return { ...prevOpenOrders, orders };
                    });
                }
                if (!!deletedOrders) {
                    setOpenOrders((prevOpenOrders) => {
                        let { orders } = prevOpenOrders || {};
                        orders = orders?.filter((order) => !deletedOrders?.includes(order?._id));
                        return { ...prevOpenOrders, orders };
                    });
                }
                if (!!updatedOrders) {
                    setOpenOrders((prevOpenOrders) => {
                        let { orders } = prevOpenOrders || {};
                        updatedOrders?.forEach((order) => {
                            if (order?.pospointId !== "pospoints_" + searchParams.get("pos")) return;
                            if (["PAID", "CANCELLED", "PARTLYPAID"].includes(order?.status)) {
                                orders = orders?.filter((o) => o._id !== order?._id);
                                return;
                            }
                            let prevOrder = orders?.find((o) => o._id === order?._id);
                            if (!!prevOrder) prevOrder = order;
                            else orders?.push(order);
                        });
                        return { ...prevOpenOrders, orders };
                    });
                }
            }
        );
        return () => {
            disconnect();
        };
    }, []);

    const loadPosData = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "pospoint",
            data: {
                _id: "pospoints_" + searchParams.get("pos"),
            },
            responseData: `_id name isActive waiterLogout differentUserOrder views{_id name description} tablesMoveEnabled 
                tables{
                    _id 
                    tableView
                    tableName
                    tableColor
                    tableBorder
                    tableShape
                    tableSize
                    position{x y}
                }  `,
        });
        if (response?.pospoint) {
            setPosData(response.pospoint);
            if (!!response.pospoint.views) setViews(response.pospoint.views);
            if (!!response.pospoint.tables) {
                setTables(response.pospoint.tables);
            }
        }
    };

    const loadOpenOrders = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "openOrders",
            data: { pospointId: "pospoints_" + searchParams.get("pos") },
            responseData: `orders{${ORDER}}`,
        });
        if (response?.openOrders) {
            setOpenOrders(response.openOrders);
        }
    };

    const activeTables = useMemo(() => {
        return tables.filter((t) => t.tableView === views[activeView]?._id);
    }, [tables, activeView]);

    const onViewChangeHandler = () => {
        if (viewFormMode === "new") {
            setViewFormMode("close");
            setViews([...views, { ...viewFormValues, _id: Date.now().toString() }]);
            setViewFormValues({});
            return;
        }
        setViewFormMode("close");
        const editedViewIndex = views.findIndex((v) => v._id === viewFormValues._id);
        views[editedViewIndex] = viewFormValues;
        setViews(views);
        setViewFormValues({});
    };

    const onPospointTableChange = () => {
        if (tableFormMode === "new") {
            setTableFormMode("close");
            setTables([...tables, { ...tableFormValues, _id: Date.now().toString() }]);
            setTableFormValues({});
            return;
        }
        setTableFormMode("close");
        const posTables = JSON.parse(JSON.stringify(tables));
        const editedTableIndex = posTables.findIndex((t) => t._id === tableFormValues._id);
        posTables[editedTableIndex] = tableFormValues;
        setTables(posTables);
        setTableFormValues({});
    };

    const onSave = async () => {
        const data = {
            views,
            tables: !!enableMove ? tablesRef.current : tables,
            _id: "pospoints_" + searchParams.get("pos"),
        };
        const response = await fetch({
            operation: "mutation",
            endpoint: "updatePosPoint",
            data,
            responseData: `_id tables{ _id tableView tableName tableColor tableBorder tableShape tableSize position{x y}}`,
        });
        if (response?.updatePosPoint?._id) {
            if (response?.updatePosPoint?.tables) {
                setTables(response?.updatePosPoint?.tables);
            }
            enqueueSnackbar(t("pos_saved"), { variant: "default" });
        }
    };
    const onSellLogin = () => {
        navigate(`/pos/sell-login?pos=${searchParams.get("pos")}`);
    };

    const handleTableDrag = (tableId, dragData) => {
        const { x, y } = dragData || {};
        tablesRef.current = tablesRef?.current?.map((table) => {
            if (table._id !== tableId) {
                return table;
            }
            return { ...table, position: { x, y } };
        });
    };

    return (
        <div>
            <AppBar
                position="fixed"
                elevation={2}
                sx={{
                    backgroundColor: "background.default",
                }}
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center" paddingY={1} paddingX={2}>
                    <Stack flex={1} direction="row" justifyContent="start">
                        <Typography variant="h6" color="primary">
                            {posData?.name}
                        </Typography>
                    </Stack>

                    <Logo width="40px" height="40px" />

                    <Stack direction="row" gap={3} justifyContent="end" alignItems="center" flex={1}>
                        <ProtectedAction permission={{ pospoints: { edit: true } }}>
                            <SwitchControl
                                value={enableEdit}
                                onChange={(e) => setEnableEdit(e.target.checked)}
                                label={t("edit")}
                                formProps={{ sx: { color: "primary.main" } }}
                            />
                            {posData?.tablesMoveEnabled ? (
                                <SwitchControl
                                    value={enableMove}
                                    onChange={(e) => setEnableMove(e.target.checked)}
                                    label={t("move")}
                                    formProps={{ sx: { color: "primary.main" } }}
                                />
                            ) : null}
                        </ProtectedAction>
                        <Typography variant="h6" color="primary">
                            {!!displayName ? displayName : username || ""}
                        </Typography>
                        <IconButton onClick={() => navigate(-1)} size="large">
                            <CloseIcon color="primary" />
                        </IconButton>
                    </Stack>
                </Stack>
            </AppBar>
            {/* {loading && <LinearProgress style={{ marginTop: "70px" }} color="primary" />} */}
            <Tabs
                style={{ margin: "70px 20px 0 20px" }}
                value={activeView}
                onChange={(e, value) => setActiveView(value)}
            >
                {views.map((view) =>
                    enableEdit ? (
                        <div style={{ marginRight: "20px" }}>
                            {view.name}
                            <Button
                                style={{ marginLeft: "20px" }}
                                startIcon={<EditIcon />}
                                variant="outlined"
                                onClick={() => {
                                    setViewFormMode("edit");
                                    setViewFormValues({ ...view });
                                }}
                            >
                                {t("edit")}
                            </Button>
                            <IconButton
                                color="primary"
                                onClick={() => setViews(views.filter((v) => v._id !== view._id))}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    ) : (
                        <Tab label={view.name} />
                    )
                )}
                <div style={{ marginLeft: "auto", marginTop: 5 }}>
                    <ProtectedAction permission={{ users: { waiter_login: true } }}>
                        <Button onClick={onSellLogin} variant="outlined" fullWidth startIcon={<LockIcon />}>
                            {t("waiter_login")}
                        </Button>
                    </ProtectedAction>
                </div>
                <div style={{ marginLeft: 10, marginTop: 5 }}>
                    <ProtectedAction permission={{ my_shift: { view: true } }}>
                        <Button variant="outlined" component={Link} to={"/pos/myshift"}>
                            {t("my_shift")}
                        </Button>
                    </ProtectedAction>
                </div>
            </Tabs>

            <Divider />

            <DraggableContainer hasMoveEnabled={posData.tablesMoveEnabled}>
                {tables.map((table) => (
                    <DraggableWrapper
                        defaultPosition={table.position}
                        id={table._id}
                        handleDrag={handleTableDrag}
                        disabledMove={!enableMove}
                        hasMoveEnabled={posData.tablesMoveEnabled}
                        hide={table.tableView !== views[activeView]?._id}
                    >
                        <PospointTable
                            onClick={(orderId) => {
                                if (enableMove) {
                                    return;
                                }
                                if (enableEdit) {
                                    setTableFormValues({ ...table });
                                    setTableFormMode("edit");
                                    return;
                                }
                                let sellUrl = `/pos/sell?pos=${searchParams.get("pos")}&table=${table._id}`;
                                if (!!orderId) sellUrl += `&order=${orderId.split("orders_")?.[1]}`;
                                navigate(sellUrl);
                            }}
                            style={{ margin: "8px" }}
                            table={table}
                            order={openOrders?.orders?.find((order) => order.tableId === table._id)}
                        />
                    </DraggableWrapper>
                ))}
            </DraggableContainer>

            <BottomBarLegend />

            <div style={{ right: "10px", top: "auto", bottom: "0", position: "fixed", zIndex: "9999" }}>
                <ProtectedAction permission={{ pospoints: { edit: true } }}>
                    <Button startIcon={<AddIcon />} onClick={() => setViewFormMode("new")}>
                        {t("add_view")}
                    </Button>
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => setTableFormMode("new")}
                        style={{ marginLeft: "20px" }}
                    >
                        {t("add_table")}
                    </Button>
                    <Button
                        startIcon={<SaveIcon />}
                        onClick={onSave}
                        variant="contained"
                        color="secondary"
                        style={{ marginLeft: "20px" }}
                    >
                        {t("save")}
                    </Button>
                </ProtectedAction>
            </div>
            <TableForm
                tableViews={tableViewsOptions}
                values={tableFormValues}
                setValues={setTableFormValues}
                open={tableFormMode !== "close"}
                onCancel={() => {
                    setTableFormMode("close");
                    setTableFormValues({});
                }}
                onSave={onPospointTableChange}
            />
            <TableViewForm
                values={viewFormValues}
                setValues={setViewFormValues}
                open={viewFormMode !== "close"}
                onCancel={() => {
                    setViewFormMode("close");
                    setViewFormValues({});
                }}
                onSave={onViewChangeHandler}
            />
            <BackgroundOfflineCacheSync />
        </div>
    );
};

export default PospointTables;
