import React, { useState, useMemo, useRef, useEffect, useImperativeHandle, forwardRef } from "react";
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import styles from "./Table.module.css";
import { useTranslation, withTranslation } from "react-i18next";
import TableToolbar from "./TableToolbar";
import { Box, Button, Grid, Toolbar, Typography } from "@mui/material";
import { InputControl, SelectControl } from "../form";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { exportDataToCSV, exportDataToPdf } from "./exportData";
import { useSnackbar } from "notistack";
import { NoRecordsFound } from "../../components/common";

const moduleColors = {
    default: "#30aabc",
    accounting: "#007B44",
    frontdesk: "#03363D",
    backoffice: "#832700",
    pos: "#86B3BB",
    users: "#DF7323",
};

const totalRowsOptions = [
    { value: 5, label: "5 rows" },
    { value: 10, label: "10 rows" },
    { value: 20, label: "20 rows" },
    { value: 50, label: "50 rows" },
    { value: 100, label: "100 rows" },
];

const IndeterminateCheckbox = ({ indeterminate, className = "", ...rest }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (typeof indeterminate === "boolean") {
            ref.current.indeterminate = !rest.checked && indeterminate;
        }
    }, [ref, indeterminate]);

    return (
        <input
            style={{
                transform: "scale(1.5)",
                msTransform: "scale(1.5)",
                WebkitTransform: "scale(1.5)",
            }}
            type="checkbox"
            ref={ref}
            {...rest}
        />
    );
};

const Filter = ({ column, table }) => {
    const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

    const columnFilterValue = column.getFilterValue();
    return (
        <input
            type={typeof firstValue === "number" ? "number" : "text"}
            value={columnFilterValue ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            placeholder={`Search...`}
            style={{
                outline: "none",
                border: "none",
                width: "100%",
                height: "25px",
                // backgroundColor: "transparent",
            }}
        />
    );
};

const globalFilterFn = (row, columnId, filterValue) => {
    const search = filterValue.toLowerCase();
    let value = row.getValue(columnId);
    if (typeof value === "number") value = String(value);

    return value?.toLowerCase().includes(search);
};

const Table = forwardRef(
    ({
        data = [],
        columns = [],
        defaultColumnVisibility,
        moduleType,
        titleLabel,
        onRowClick,
        onCreateClick,
        onDeleteClick,
        onColumnsSaveClick,
        printAll = false,
        disableSelection = false,
        disableHeader = false,
        disableFooter = false,
        disableDelete = false,
        disableColumnsSave = false,
        disableEmptyTableInfo = false,
        tableRef,
        landscape = false,
        headerInfo = [],
    }) => {
        const [selectedRows, setSelectedRows] = useState({});
        const [columnVisibility, setColumnVisibility] = useState({});
        const [enableFilters, setEnableFilters] = useState(false);

        const { t } = useTranslation();
        const { enqueueSnackbar } = useSnackbar();
        const defaultColumns = useMemo(() => {
            columns.forEach((col) => (col.filterFn = "globalFilterFn"));
            if (disableSelection) return columns;
            return [
                {
                    id: "select",
                    header: ({ table }) => (
                        <IndeterminateCheckbox
                            {...{
                                checked: table.getIsAllRowsSelected(),
                                indeterminate: table.getIsSomeRowsSelected(),
                                onChange: table.getToggleAllRowsSelectedHandler(),
                            }}
                        />
                    ),
                    cell: ({ row }) => (
                        <IndeterminateCheckbox
                            {...{
                                checked: row.getIsSelected(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: row.getToggleSelectedHandler(),
                            }}
                        />
                    ),
                },
                ...columns,
            ];
        }, [columns, disableSelection]);

        const table = useReactTable({
            data: data,
            columns: defaultColumns,
            columnResizeMode: "onChange",
            enableFilters: enableFilters,
            filterFns: {
                globalFilterFn,
            },
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            initialState: disableFooter && { pagination: { pageSize: 500000 } },
            getSortedRowModel: getSortedRowModel(),
            state: { rowSelection: selectedRows, columnVisibility },
            onRowSelectionChange: setSelectedRows,
            onColumnVisibilityChange: setColumnVisibility,
        });

        useEffect(() => {
            if (!table?.getState()) {
                return;
            }
            table.setPageIndex(table.getState().pagination.pageIndex);
        }, [data]);

        useEffect(() => {
            if (!defaultColumnVisibility) return;
            setColumnVisibility(
                table
                    .getAllLeafColumns()
                    .filter((col) => col.id !== "select")
                    .reduce((acc, col) => {
                        if (!defaultColumnVisibility.includes(col.id)) acc[col.id] = false;
                        return acc;
                    }, {})
            );
        }, [defaultColumnVisibility]);

        useImperativeHandle(tableRef, () => ({
            getTableData() {
                const selectedRows = printAll ? table.getRowModel() : table.getSelectedRowModel();
                return selectedRows?.rows?.map((row) =>
                    row
                        .getVisibleCells()
                        ?.filter((cell) => cell?.getValue() !== undefined && cell?.getValue() !== null)
                        ?.map((cell) => ({ header: cell.column.columnDef.header, value: cell.getValue() }))
                );
            },
            getSelectedRows() {
                return table.getSelectedRowModel().rows.map((r) => r.original);
            },
        }));

        const exportTo = (to = "pdf") => {
            const hasAllRowsSelected =
                printAll || table.getSelectedRowModel()?.rows?.length === table.getRowModel()?.rows?.length;
            const selectedRows = hasAllRowsSelected ? table.getSortedRowModel() : table.getSelectedRowModel();
            if (selectedRows?.rows?.length === 0) {
                enqueueSnackbar(t("select_rows_to_export"), { variant: "warning" });
                return;
            }
            const data = selectedRows?.rows?.map((row) =>
                row
                    .getVisibleCells()
                    ?.filter((cell) => cell?.getValue() !== undefined && cell?.getValue() !== null)
                    ?.map((cell) => ({ header: cell.column.columnDef.header, value: cell.getValue() }))
            );
            if (to === "pdf") {
                exportDataToPdf({ title: titleLabel, tableData: data, landscape: landscape, headerInfo });
                return;
            }
            if (to === "csv") {
                exportDataToCSV({ title: titleLabel, tableData: data });
                return;
            }
        };

        return (
            <Box
                component="div"
                sx={{ backgroundColor: "table.backgroundColor", borderColor: "table.headBorderColor" }}
                className={styles.tableContainer}
            >
                {!disableHeader && (
                    <TableToolbar
                        enableFilters={enableFilters}
                        setEnableFilters={setEnableFilters}
                        columns={table.getAllLeafColumns()}
                        moduleType={moduleType}
                        titleLabel={titleLabel}
                        onCreateClick={onCreateClick}
                        onDeleteClick={() => {
                            onDeleteClick && onDeleteClick(table.getSelectedRowModel().rows.map((r) => r.original));
                            setSelectedRows({});
                        }}
                        onColumnsSaveClick={() =>
                            onColumnsSaveClick &&
                            onColumnsSaveClick(
                                table
                                    .getVisibleFlatColumns()
                                    .map((col) => col.id)
                                    .filter((col) => col !== "select")
                            )
                        }
                        disableDelete={disableDelete}
                        disableColumnsSave={disableColumnsSave}
                        exportTo={exportTo}
                    />
                )}
                <div
                    className={styles.table}
                    style={{
                        width: "100%",
                    }}
                >
                    <div className={styles.tableContent}>
                        <div className={styles.tableHead}>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <Box
                                    className={styles.tr}
                                    key={headerGroup.id}
                                    sx={{
                                        borderBottomColor: "table.headBorderColor",
                                        borderTopColor: "table.headBorderColor",
                                    }}
                                >
                                    {headerGroup.headers.map((header) => (
                                        <Box
                                            component="div"
                                            className={styles.th}
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            style={{
                                                width: header.id === "select" ? "50px" : header.getSize(),
                                                minWidth: header.id === "select" ? "50px" : "150px",
                                                flexGrow: header.id === "select" ? 0 : 1,
                                            }}
                                            sx={{
                                                borderRightColor: "table.borderColor",
                                            }}
                                        >
                                            {header.column.getCanFilter() ? (
                                                <div>
                                                    <Filter column={header.column} table={table} />
                                                </div>
                                            ) : header.isPlaceholder ? null : (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={header.column.getToggleSortingHandler()}
                                                >
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                    {{
                                                        asc: <ArrowUpwardIcon color="primary" fontSize="small" />,
                                                        desc: <ArrowDownwardIcon color="primary" fontSize="small" />,
                                                    }[header.column.getIsSorted()] ?? null}
                                                </div>
                                            )}
                                            {header.column.getCanResize() && (
                                                <div
                                                    onMouseDown={header.getResizeHandler()}
                                                    onTouchStart={header.getResizeHandler()}
                                                    className={`${styles.resizer} ${
                                                        header.column.getIsResizing() ? `${styles.isResizing}` : ""
                                                    }`}
                                                ></div>
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                            ))}
                        </div>
                        {/* !disableHeader */}
                        {table?.getRowModel()?.rows?.length === 0 && !disableEmptyTableInfo && (
                            <Box
                                component="div"
                                className={styles.tr}
                                sx={{
                                    borderBottomColor: "table.borderColor",
                                    height: "calc(100vh - 300px)",
                                    alignItems: "center",
                                }}
                            >
                                <div style={{ width: "100%" }}>
                                    <NoRecordsFound />
                                    <Typography style={{ width: "100%" }} textAlign="center" variant="body1">
                                        {t("no_records_available")}
                                    </Typography>
                                </div>
                            </Box>
                        )}
                        <div className={styles.tableBody}>
                            {table.getRowModel().rows.map((row) => (
                                <Box
                                    component="div"
                                    className={styles.tr}
                                    key={row.id}
                                    sx={{
                                        borderBottomColor: "table.borderColor",
                                        "&:hover": { backgroundColor: "rgb(0 125 252 / 5%)" },
                                    }}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <Box
                                            component="div"
                                            onClick={() => {
                                                if (cell.id.includes("_select")) return;
                                                onRowClick && onRowClick(row.original);
                                            }}
                                            className={styles.td}
                                            key={cell.id}
                                            style={{
                                                width: cell.id.includes("_select") ? "50px" : cell.column.getSize(),
                                                minWidth: cell.id.includes("_select") ? "50px" : "150px",
                                                flexGrow: cell.id.includes("_select") ? 0 : 1,
                                            }}
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </Box>
                                    ))}
                                </Box>
                            ))}
                        </div>
                    </div>

                    {!disableFooter && (
                        <Toolbar>
                            <Grid alignItems="center" container spacing={1}>
                                <Grid item xs={3}>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        sx={{
                                            color: moduleColors[moduleType] || moduleColors.default,
                                            borderColor: moduleColors[moduleType] || moduleColors.default,
                                        }}
                                        onClick={() => table.previousPage()}
                                        disabled={!table.getCanPreviousPage()}
                                    >
                                        {t("previous")}
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid alignItems="center" justifyContent="center" container spacing={0}>
                                        <Grid item xs={12} sm={8}>
                                            <Grid alignItems="center" container spacing={1}>
                                                <Grid item xs={4}>
                                                    <Typography textAlign="end" fontSize="13px">
                                                        {t("page")}{" "}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <InputControl
                                                        type="number"
                                                        InputProps={{
                                                            inputProps: { min: 1, max: table.getPageCount() },
                                                        }}
                                                        value={table.getState().pagination.pageIndex + 1}
                                                        onChange={(e) => {
                                                            const page = e.target.value
                                                                ? Number(e.target.value) - 1
                                                                : 0;
                                                            table.setPageIndex(page);
                                                        }}
                                                        variant="standard"
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography fontSize="13px">
                                                        {t("of")} {table.getPageCount()}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={8} sm={4}>
                                            <SelectControl
                                                fullWidth={false}
                                                variant="outlined"
                                                options={totalRowsOptions}
                                                value={table.getState().pagination.pageSize}
                                                onChange={(e) => {
                                                    table.setPageSize(Number(e.target.value));
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        sx={{
                                            color: moduleColors[moduleType] || moduleColors.default,
                                            borderColor: moduleColors[moduleType] || moduleColors.default,
                                        }}
                                        onClick={() => table.nextPage()}
                                        disabled={!table.getCanNextPage()}
                                    >
                                        {t("next")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    )}
                </div>
            </Box>
        );
    }
);

export default Table;
