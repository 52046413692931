import { Avatar, Button, Card, CardContent, CardHeader, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { AutoCompleteControl, Form, InputControl, RadioGroupControl, SelectControl } from "../../components/form";
import { useApi } from "../../components/hooks";
import { Modal } from "../../components/modal";
import i18n from "i18next";

const UserProfile = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [values, setValues] = useState({});
    const [rolesOptions, setRolesOptions] = useState([]);
    const [pospointsOptions, setPospointsOptions] = useState([]);
    const [fiscalBusinesses, setFiscalBusinesses] = useState([]);

    const navigate = useNavigate();
    const params = useParams();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { addRecordUser } = useOutletContext() || {};

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",
            multipleEndpoints: [
                { endpoint: "roles", responseData: "_id name isWaiter" },
                {
                    endpoint: "user",
                    data: {
                        _id: "users_" + params?.id,
                    },
                    responseData: "_id username displayName email language roleId operatorCode status posPoints",
                },
                {
                    endpoint: "pospoints",
                    responseData: "_id name isActive hasTables",
                },
                {
                    endpoint: "businesses",
                    responseData: "_id",
                },
            ],
        });
        if (response?.user) setValues(response.user);
        if (!!response?.roles) {
            setRolesOptions(
                response.roles.map((role) => ({ value: role._id, label: role.name, isWaiter: role.isWaiter }))
            );
        }
        if (!!response?.pospoints) {
            setPospointsOptions(
                response.pospoints.map((pos) => {
                    return {
                        value: pos._id,
                        label: pos.name,
                    };
                })
            );
        }
        if (!!response?.businesses) {
            setFiscalBusinesses(response.businesses);
        }
    };

    const saveHandler = async () => {
        const { _id, username, displayName, email, roleId, posPoints, language, status, operatorCode } = values;
        const data = {
            _id,
            username,
            displayName,
            email,
            roleId,
            posPoints,
            language,
            status,
            operatorCode,
        };
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateUser",
            data,
            responseData: "_id username displayName email language roleId role{name} operatorCode status posPoints",
        });
        if (response?.updateUser?._id) {
            enqueueSnackbar(t("user_updated"), { variant: "default" });
            if (!!language) {
                localStorage.setItem("language", language);
                i18n.changeLanguage(language);
            }
            addRecordUser(response.updateUser);
            navigate(-1);
        }
    };
    const showOperatorCodeField = useMemo(() => {
        if (fiscalBusinesses?.length > 0) return true;
        else return false;
    }, [fiscalBusinesses]);

    return (
        <div>
            <Modal
                loading={loading}
                open
                fullScreen
                titlelabel={t("users_profile")}
                onClose={() => navigate(-1)}
                onSave={saveHandler}
                permission={{ users: { edit: true } }}
            >
                <Tabs value={activeTab} onChange={(e, value) => setActiveTab(value)}>
                    <Tab label={t("general")} />
                    <Tab label={t("security")} />
                </Tabs>
                <div style={{ marginTop: "20px" }}>
                    {activeTab === 0 ? (
                        <GeneralPanel
                            values={values}
                            onValuesChange={setValues}
                            rolesOptions={rolesOptions}
                            pospointsOptions={pospointsOptions}
                            t={t}
                            showOperatorCodeField={showOperatorCodeField}
                        />
                    ) : (
                        <SecurityPanel t={t} id={values?._id} fetch={fetch} />
                    )}
                </div>
            </Modal>
        </div>
    );
};

const GeneralPanel = ({
    values,
    onValuesChange,
    rolesOptions = [],
    pospointsOptions = [],
    t,
    showOperatorCodeField,
}) => {
    const statusOptions = [
        { value: "ACTIVE", label: t("active") },
        { value: "DEACTIVATED", label: t("deactivated") },
    ];
    const languageOptions = [
        { value: "en", label: t("english") },
        { value: "sq", label: t("albanian") },
        { value: "it", label: t("italian") },
        { value: "de", label: t("german") },
        { value: "fr", label: t("french") },
        { value: "no", label: t("norwegian") },
    ];
    const hasWaiterRole = useMemo(() => {
        const currentRole = rolesOptions.find((r) => r.value === values?.roleId);
        return Boolean(currentRole?.isWaiter);
    }, [values?.roleId, rolesOptions]);
    return (
        <div>
            <Card>
                <CardHeader title={t("basic_details")} titleTypographyProps={{ variant: "h6" }} />
                <CardContent>
                    <Form values={values} onValuesChange={onValuesChange}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid container item xs={12} md={4}>
                                <Avatar sx={{ width: 100, height: 100, margin: "20px auto" }}>
                                    {values?.username ? values.username.charAt(0) : "HB"}
                                </Avatar>

                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{ textAlign: "center" }}>
                                        {values?.username}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} md={8} spacing={2}>
                                <Grid item xs={6}>
                                    <InputControl name="displayName" label={t("display_name")} />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputControl name="email" label={t("email")} />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectControl options={languageOptions} name="language" label={t("language")} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                </CardContent>
            </Card>

            <Card style={{ marginTop: "50px" }}>
                <CardHeader title={t("extra_details")} titleTypographyProps={{ variant: "h6" }} />
                <CardContent>
                    <Form values={values} onValuesChange={onValuesChange}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={4}>
                                <SelectControl options={rolesOptions} name="roleId" label={t("role")} />
                            </Grid>
                            {showOperatorCodeField ? (
                                <Grid item xs={6} md={4}>
                                    <InputControl name="operatorCode" label={t("opercator_code")} />
                                </Grid>
                            ) : null}
                            <Grid item xs={12} md={8}>
                                {hasWaiterRole && (
                                    <Grid item xs={6}>
                                        <AutoCompleteControl
                                            multiple
                                            name="posPoints"
                                            label={t("pos_points")}
                                            options={pospointsOptions}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <RadioGroupControl options={statusOptions} name="status" label={t("status")} />
                            </Grid>
                        </Grid>
                    </Form>
                </CardContent>
            </Card>
        </div>
    );
};

const SecurityPanel = ({ t, id, fetch }) => {
    const [values, setValues] = useState({});
    const { enqueueSnackbar } = useSnackbar();

    const updateUserPassword = async () => {
        const { oldPassword, newPassword, confirmedPassword } = values;

        if (newPassword !== confirmedPassword) {
            enqueueSnackbar(t("passwords_does_not_match"), { variant: "warning" });
            return;
        }
        const data = {
            _id: id,
            oldPassword,
            newPassword,
        };
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateUserPassword",
            data,
        });
        if (response?.updateUserPassword === "success") {
            enqueueSnackbar(t("password_changed_succesfully"), { variant: "success" });
        } else {
            enqueueSnackbar(t("old_password_may_be_incorrect"), { variant: "error" });
        }
    };

    const updateWaiterPin = async () => {
        const { confirmPin, pin } = values;
        if (pin !== confirmPin) {
            enqueueSnackbar(t("pin_does_not_match"), { variant: "warning" });
            return;
        }
        const data = {
            _id: id,
            pin,
        };
        const response = await fetch({
            operation: "mutation",
            endpoint: "updateUserPin",
            data,
        });
        if (response?.updateUserPin === "success") {
            enqueueSnackbar(t("pin_changed_succesfully"), { variant: "success" });
        }
    };

    return (
        <div>
            <Grid style={{ marginTop: "50px" }} container xs={12} sm={6}>
                <Form values={values} onValuesChange={setValues}>
                    <Grid item xs={12}>
                        <InputControl name="oldPassword" label={t("old_password")} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputControl name="newPassword" label={t("new_password")} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputControl name="confirmedPassword" label={t("confirm_password")} />
                    </Grid>
                </Form>
                <Grid item xs={12}>
                    <Button style={{ marginTop: "20px" }} variant="contained" onClick={() => updateUserPassword()}>
                        {t("change_password")}
                    </Button>
                </Grid>
            </Grid>

            <Grid style={{ marginTop: "50px" }} item container xs={12} sm={6}>
                <Typography variant="h6">{t("waiter_roles_have_to_setup_login_pin_to_use_pos_offline")}</Typography>
                <Form values={values} onValuesChange={setValues}>
                    <Grid item xs={12}>
                        <InputControl name="pin" label={t("new_pin")} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputControl name="confirmPin" label={t("confirm_new_pin")} />
                    </Grid>
                </Form>
                <Grid item xs={12}>
                    <Button style={{ marginTop: "20px" }} variant="contained" onClick={() => updateWaiterPin()}>
                        {t("set_pin")}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default UserProfile;
