import React from "react";
import { Table } from "../table";
import { useTranslation } from "react-i18next";
import { Modal } from "../modal";
import { DateTime } from "luxon";
import { toCurrency } from "../../utils";
import { Button } from "@mui/material";

const PaymentsView = ({ data = [], open, onClose, currency, editPayment }) => {
    const { t } = useTranslation();
    const columns = [
        {
            id: "amount",
            header: t("amount"),
            cell: ({ row: { original } }) => {
                const paymentCurrency = !!currency ? currency : original?.currency;
                return toCurrency(original?.amount, paymentCurrency || "");
            },
        },
        {
            id: "paymentMethod",
            header: t("payment_method"),
            cell: ({ row: { original } }) => original?.paymentMethodData?.name || "",
        },
        {
            id: "userName",
            header: t("username"),
            cell: ({ row: { original } }) => original?.userName?.split("users_")?.[1] || "",
        },
        {
            id: "timestamp",
            header: t("date"),
            cell: ({ row: { original } }) =>
                original?.timestamp ? DateTime.fromISO(original.timestamp)?.toFormat("yyyy LLL dd HH:mm:ss") : "",
        },
        { id: "note", header: t("note"), cell: ({ row: { original } }) => original?.note || "" },
        {
            id: "edit",
            header: t(""),
            cell: ({ row: { original } }) => {
                if (!original.uniqueId) return null;
                return (
                    <Button
                        fullWidth
                        variant="outlined"
                        size="small"
                        onClick={() => editPayment && editPayment(original)}
                    >
                        {t("edit")}
                    </Button>
                );
            },
        },
    ];
    return (
        <Modal
            titlelabel={t("payments")}
            maxWidth="m"
            open={open}
            onClose={() => onClose(false)}
            yesText={"none"}
            noText={"none"}
        >
            <Table
                data={data}
                columns={columns}
                disableColumnsSave
                disableHeader
                disableSelection
                disableDelete
                disableFooter
            />
        </Modal>
    );
};

export default PaymentsView;
