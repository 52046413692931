export const paymentOptions = [
    { value: "BANKNOTE", label: "BANKNOTE" },
    { value: "CARD", label: "CARD" },
    { value: "CHECK", label: "CHECK" },
    { value: "SVOUCHER", label: "SVOUCHER" },
    { value: "COMPANY", label: "COMPANY" },
    { value: "ORDER", label: "ORDER" },
    { value: "ACCOUNT", label: "ACCOUNT" },
    { value: "FACTORING", label: "FACTORING" },
    { value: "COMPENSATION", label: "COMPENSATION" },
    { value: "TRANSFER", label: "TRANSFER" },
    { value: "WAIVER", label: "WAIVER" },
    { value: "KIND", label: "KIND" },
    { value: "OTHER", label: "OTHER" },
];
export const invoiceTypeCodeOptions = [
    { value: "80", label: "Debit note related to goods or services type" },
    { value: "82", label: "Metered services invoice type" },
    { value: "84", label: "Debit note related to financial adjustments type" },
    { value: "325", label: "Proforma invoice" },
    { value: "380", label: "Commercial invoice" },
    { value: "381", label: "Approval" },
    { value: "383", label: "Debit note type" },
    { value: "386", label: "Prepayment invoice type" },
    { value: "388", label: "Tax Invoice" },
    { value: "389", label: "Self-billed invoice" },
    { value: "393", label: "Factored invoice type" },
    { value: "395", label: "Consignment invoice type" },
    { value: "575", label: "Insurer's invoice type" },
    { value: "623", label: "Forwarder's invoice type" },
    { value: "780", label: "Freight invoice type" },
];
export const profileOptions = [
    {
        value: "P1",
        label: "P1 Faturimi i dërgesave të mallrave dhe shërbimeve kundrejt porosive të blerjes, bazuar në një kontratë",
    },
    {
        value: "P2",
        label: "P2 Faturimi i dërgesave të mallrave dhe shërbimeve bazuar në një kontratë",
    },
    { value: "P3", label: "P3 Faturimi i dorëzimit të porosisë së blerjes së rastësishme" },
    { value: "P4", label: "P4 Pagesa paraprake" },
    { value: "P5", label: "P5 Pagesa në vend" },
    { value: "P6", label: "P6 Pagesa para dorëzimit" },
    { value: "P7", label: "P7 Faturat me referenca në shënimin e dërgimit" },
    { value: "P8", label: "P8 Faturat me referenca në shënimin e dërgimit dhe shënimin e marrjes" },
    {
        value: "P9",
        label: "P9 Notë kredie ose fatura  me shuma negative, të lëshuara për një sërë arsyesh, përfshirë kthimin e paketimit bosh",
    },
    { value: "P10", label: "P10 Faturimi korrigjues (anulimi / korrigjimi i një fature)" },
    { value: "P11", label: "P11 Faturimi i pjesshëm dhe përfundimtar" },
    { value: "P12", label: "P12 Vete faturim" },
];

export const paymentMeansCodeOptions = [
    { value: "1", name: "Instrument not defined", label: "Instrument not defined" },
    { value: "42", name: "Payment to bank account", label: "Payment to bank account" },
    { value: "10", name: "In cash", label: "In cash" },
    { value: "54", name: "Credit card", label: "Credit card" },
    { value: "55", name: "Debit card", label: "Debit card" },
    { value: "30", name: "Credit transfer", label: "Credit transfer" },
    { value: "31", name: "Debit transfer", label: "Debit transfer" },
    { value: "48", name: "Bank card", label: "Bank card" },
    { value: "49", name: "Direct debit", label: "Direct debit" },
    {
        value: "-",
        name: "----------------------------------------------------------",
        label: "----------------------------------------------------------",
    },
    { value: "2", name: "Automated clearing house credit", label: "Automated clearing house credit" },
    { value: "3", name: "Automated clearing house debit", label: "Automated clearing house debit" },
    { value: "4", name: "ACH demand debit reversal", label: "ACH demand debit reversal" },
    { value: "5", name: "ACH demand credit reversal", label: "ACH demand credit reversal" },
    { value: "6", name: "ACH demand credit", label: "ACH demand credit" },
    { value: "7", name: "ACH demand debit", label: "ACH demand debit" },
    { value: "8", name: "Hold", label: "Hold" },
    { value: "9", name: "National or regional clearing", label: "National or regional clearing" },
    { value: "11", name: "ACH savings credit reversal", label: "ACH savings credit reversal" },
    { value: "12", name: "ACH savings debit reversal", label: "ACH savings debit reversal" },
    { value: "13", name: "ACH savings credit", label: "ACH savings credit" },
    { value: "14", name: "ACH savings debit", label: "ACH savings debit" },
    { value: "15", name: "Bookentry credit", label: "Bookentry credit" },
    { value: "16", name: "Bookentry debit", label: "Bookentry debit" },
    {
        value: "17",
        name: "ACH demand cash concentration/disbursement (CCD) credit",
        label: "ACH demand cash concentration/disbursement (CCD) credit",
    },
    {
        value: "18",
        name: "ACH demand cash concentration/disbursement (CCD) debit",
        label: "ACH demand cash concentration/disbursement (CCD) debit",
    },
    {
        value: "19",
        name: "ACH demand corporate trade payment (CTP) credit",
        label: "ACH demand corporate trade payment (CTP) credit",
    },
    { value: "20", name: "Cheque", label: "Cheque" },
    { value: "21", name: "Banker's draft", label: "Banker's draft" },
    { value: "22", name: "Certified banker's draft", label: "Certified banker's draft" },
    {
        value: "23",
        name: "Bank cheque (issued by a banking or similar establishment)",
        label: "Bank cheque (issued by a banking or similar establishment)",
    },
    { value: "24", name: "Bill of exchange awaiting acceptance", label: "Bill of exchange awaiting acceptance" },
    { value: "25", name: "Certified cheque", label: "Certified cheque" },
    { value: "26", name: "Local cheque", label: "Local cheque" },
    {
        value: "27",
        name: "ACH demand corporate trade payment (CTP) debit",
        label: "ACH demand corporate trade payment (CTP) debit",
    },
    {
        value: "28",
        name: "ACH demand corporate trade exchange (CTX) credit",
        label: "ACH demand corporate trade exchange (CTX) credit",
    },
    {
        value: "29",
        name: "ACH demand corporate trade exchange (CTX) debit",
        label: "ACH demand corporate trade exchange (CTX) debit",
    },
    {
        value: "32",
        name: "ACH demand cash concentration/disbursement plus (CCD+) credit",
        label: "ACH demand cash concentration/disbursement plus (CCD+) credit",
    },
    {
        value: "33",
        name: "ACH demand cash concentration/disbursement plus (CCD+) debit",
        label: "ACH demand cash concentration/disbursement plus (CCD+) debit",
    },
    {
        value: "34",
        name: "ACH prearranged payment and deposit (PPD)",
        label: "ACH prearranged payment and deposit (PPD)",
    },
    {
        value: "35",
        name: "ACH savings cash concentration/disbursement (CCD) credit",
        label: "ACH savings cash concentration/disbursement (CCD) credit",
    },
    {
        value: "36",
        name: "ACH savings cash concentration/disbursement (CCD) debit",
        label: "ACH savings cash concentration/disbursement (CCD) debit",
    },
    {
        value: "37",
        name: "ACH savings corporate trade payment (CTP) credit",
        label: "ACH savings corporate trade payment (CTP) credit",
    },
    {
        value: "38",
        name: "ACH savings corporate trade payment (CTP) debit",
        label: "ACH savings corporate trade payment (CTP) debit",
    },
    {
        value: "39",
        name: "ACH savings corporate trade exchange (CTX) credit",
        label: "ACH savings corporate trade exchange (CTX) credit",
    },
    {
        value: "40",
        name: "ACH savings corporate trade exchange (CTX) debit",
        label: "ACH savings corporate trade exchange (CTX) debit",
    },
    {
        value: "41",
        name: "ACH savings cash concentration/disbursement plus (CCD+) credit",
        label: "ACH savings cash concentration/disbursement plus (CCD+) credit",
    },
    {
        value: "43",
        name: "ACH savings cash concentration/disbursement plus (CCD+) debit",
        label: "ACH savings cash concentration/disbursement plus (CCD+) debit",
    },
    { value: "44", name: "Accepted bill of exchange", label: "Accepted bill of exchange" },
    {
        value: "45",
        name: "Referenced home-banking credit transfer",
        label: "Referenced home-banking credit transfer",
    },
    { value: "46", name: "Interbank debit transfer", label: "Interbank debit transfer" },
    { value: "47", name: "Home-banking debit transfer", label: "Home-banking debit transfer" },
    { value: "50", name: "Payment by postgiro", label: "Payment by postgiro" },
    {
        value: "51",
        name: "FR, norme 6 97-Telereglement CFONB (French Organisation for Banking Standards)",
        label: "FR, norme 6 97-Telereglement CFONB (French Organisation for Banking Standards)",
    },
    { value: "52", name: "Urgent commercial payment", label: "Urgent commercial payment" },
    { value: "53", name: "Urgent Treasury Payment", label: "Urgent Treasury Payment" },

    { value: "56", name: "Bankgiro", label: "Bankgiro" },
    { value: "57", name: "Standing agreement", label: "Standing agreement" },
    { value: "58", name: "SEPA credit transfer", label: "SEPA credit transfer" },
    { value: "59", name: "SEPA direct debit", label: "SEPA direct debit" },
    { value: "60", name: "Promissory note", label: "Promissory note" },
    { value: "61", name: "Promissory note signed by the debtor", label: "Promissory note signed by the debtor" },
    {
        value: "62",
        name: "Promissory note signed by the debtor and endorsed by a bank",
        label: "Promissory note signed by the debtor and endorsed by a bank",
    },
    {
        value: "63",
        name: "Promissory note signed by the debtor and endorsed by a third party",
        label: "Promissory note signed by the debtor and endorsed by a third party",
    },
    { value: "64", name: "Promissory note signed by a bank", label: "Promissory note signed by a bank" },
    {
        value: "65",
        name: "Promissory note signed by a bank and endorsed by another bank",
        label: "Promissory note signed by a bank and endorsed by another bank",
    },
    {
        value: "66",
        name: "Promissory note signed by a third party",
        label: "Promissory note signed by a third party",
    },
    {
        value: "67",
        name: "Promissory note signed by a third party and endorsed by a bank",
        label: "Promissory note signed by a third party and endorsed by a bank",
    },
    { value: "68", name: "Online payment service", label: "Online payment service" },
    {
        value: "70",
        name: "Bill drawn by the creditor on the debtor",
        label: "Bill drawn by the creditor on the debtor",
    },
    { value: "74", name: "Bill drawn by the creditor on a bank", label: "Bill drawn by the creditor on a bank" },
    {
        value: "75",
        name: "Bill drawn by the creditor, endorsed by another bank",
        label: "Bill drawn by the creditor, endorsed by another bank",
    },
    {
        value: "76",
        name: "Bill drawn by the creditor on a bank and endorsed by a third party",
        label: "Bill drawn by the creditor on a bank and endorsed by a third party",
    },
    {
        value: "77",
        name: "Bill drawn by the creditor on a third party",
        label: "Bill drawn by the creditor on a third party",
    },
    {
        value: "78",
        name: "Bill drawn by creditor on third party, accepted and endorsed by bank",
        label: "Bill drawn by creditor on third party, accepted and endorsed by bank",
    },
    { value: "91", name: "Not transferable banker's draft", label: "Not transferable banker's draft" },
    { value: "92", name: "Not transferable local cheque", label: "Not transferable local cheque" },
    { value: "93", name: "Reference giro", label: "Reference giro" },
    { value: "94", name: "Urgent giro", label: "Urgent giro" },
    { value: "95", name: "Free format giro", label: "Free format giro" },
    {
        value: "96",
        name: "Requested method for payment was not used",
        label: "Requested method for payment was not used",
    },
    { value: "97", name: "Clearing between partners", label: "Clearing between partners" },
    { value: "ZZZ", name: "Mutually defined", label: "Mutually defined" },
];
export const selfIssueOptions = [
    { value: "none", label: "none" },
    { value: "agreement", label: "agreement" },
    { value: "domestic", label: "domestic" },
    { value: "abroad", label: "abroad" },
    { value: "self", label: "self" },
    { value: "other", label: "other" },
];
export const languageOptions = [
    { value: "en", label: "english" },
    { value: "sq", label: "albanian" },
    { value: "it", label: "italian" },
    { value: "de", label: "german" },
    { value: "fr", label: "french" },
    { value: "no", label: "norwegian" },
];
export const paymentMethodOptions = [
    { value: "bank", label: "bank" },
    { value: "cash", label: "cash" },
    { value: "card", label: "card" },
    { value: "other", label: "other" },
];
