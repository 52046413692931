import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReportHeader } from "./components";
import { DateControl } from "../../components/dates";
import { useApi } from "../../components/hooks";
import { ReportGenerator } from "../../components/classes";
import { useSearchParams } from "react-router-dom";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import { DateTime } from "luxon";

const totalGuestsByCategories = (guests) => {
    return guests
        ?.map((room) => room.guests)
        ?.flat()
        ?.reduce((acc, category) => {
            const existingCategory = acc.find((c) => c.name === category.name);
            if (!existingCategory) {
                acc.push({ name: category.name, number: category.number });
                return acc;
            }
            existingCategory.number += category.number;
            return acc;
        }, [])
        ?.map((category) => `${category.number} ${category.name}`)
        ?.join(" , ");
};

const MealsReport = () => {
    const { t } = useTranslation();
    const [params, setParams] = useSearchParams();
    const [report, setReport] = useState(null);
    const [data, setData] = useState(null);
    const { loading, fetch } = useApi();
    const mealsReportRef = useRef();

    const columns = [
        {
            id: "id",
            header: t("reservation"),
            displayValue: (row) => row?.uuid || "",
            totalValue: () => t("total"),
        },
        {
            id: "dates",
            header: t("date"),
            displayValue: (row) => row?.checkin + " - " + row?.checkout,
            totalValue: () => "",
        },
        {
            id: "name",
            header: t("name"),
            displayValue: (row) => {
                const { firstName, lastName } = row?.clientsData?.[0] || {};
                return `${firstName || ""} ${lastName || ""}`;
            },
            totalValue: () => "",
        },
        {
            id: "room",
            header: t("room"),
            displayValue: (row) => {
                return row?.rooms?.map((room) => room?.roomData?.name || "").join(" , ");
            },
            totalValue: () => "",
        },
        {
            id: "breakfast",
            header: t("breakfast"),
            displayValue: (row) => {
                if (row?.checkin === params.get("date")) return `0 ${t("persons")}`;
                const guests = [];
                row?.rooms?.map((room) => {
                    let roomGuests;
                    if (room.roomId === "UNASSIGNED") {
                        roomGuests = row?.guests?.find((g) => g.roomId === room.roomtypeId);
                    } else {
                        roomGuests = row?.guests?.find((g) => g.roomId === room.roomId || g.roomId === room.roomtypeId);
                    }
                    const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;
                    if (meals?.includes("breakfast") && roomGuests) guests.push(roomGuests);
                });
                return totalGuestsByCategories(guests) || `0 ${t("persons")}`;
            },
            totalValue: (rows) => {
                let guests = [];
                rows?.forEach((row) => {
                    if (row?.checkin === params.get("date")) return;
                    row?.rooms?.map((room) => {
                        let roomGuests;
                        if (room.roomId === "UNASSIGNED") {
                            roomGuests = row?.guests?.find((g) => g.roomId === room.roomtypeId);
                        } else {
                            roomGuests = row?.guests?.find(
                                (g) => g.roomId === room.roomId || g.roomId === room.roomtypeId
                            );
                        }
                        const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;
                        if (meals?.includes("breakfast") && roomGuests) guests.push(roomGuests);
                    });
                });
                return totalGuestsByCategories(guests) || `0 ${t("persons")}`;
            },
        },
        {
            id: "lunch",
            header: t("lunch"),
            displayValue: (row) => {
                const guests = [];
                row?.rooms?.map((room) => {
                    let roomGuests;
                    if (room.roomId === "UNASSIGNED") {
                        roomGuests = row?.guests?.find((g) => g.roomId === room.roomtypeId);
                    } else {
                        roomGuests = row?.guests?.find((g) => g.roomId === room.roomId || g.roomId === room.roomtypeId);
                    }
                    const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;
                    if (meals?.includes("lunch") && roomGuests) guests.push(roomGuests);
                });
                return totalGuestsByCategories(guests) || `0 ${t("persons")}`;
            },
            totalValue: (rows) => {
                let guests = [];
                rows?.forEach((row) => {
                    row?.rooms?.map((room) => {
                        let roomGuests;
                        if (room.roomId === "UNASSIGNED") {
                            roomGuests = row?.guests?.find((g) => g.roomId === room.roomtypeId);
                        } else {
                            roomGuests = row?.guests?.find(
                                (g) => g.roomId === room.roomId || g.roomId === room.roomtypeId
                            );
                        }
                        const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;
                        if (meals?.includes("lunch") && roomGuests) guests.push(roomGuests);
                    });
                });
                return totalGuestsByCategories(guests) || `0 ${t("persons")}`;
            },
        },
        {
            id: "dinner",
            header: t("dinner"),
            displayValue: (row) => {
                if (row?.checkout === params.get("date")) return `0 ${t("persons")}`;
                const guests = [];
                row?.rooms?.map((room) => {
                    let roomGuests;
                    if (room.roomId === "UNASSIGNED") {
                        roomGuests = row?.guests?.find((g) => g.roomId === room.roomtypeId);
                    } else {
                        roomGuests = row?.guests?.find((g) => g.roomId === room.roomId || g.roomId === room.roomtypeId);
                    }
                    const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;
                    if (meals?.includes("dinner") && roomGuests) guests.push(roomGuests);
                });
                return totalGuestsByCategories(guests) || `0 ${t("persons")}`;
            },
            totalValue: (rows) => {
                let guests = [];
                rows?.forEach((row) => {
                    if (row?.checkout === params.get("date")) return;
                    row?.rooms?.map((room) => {
                        let roomGuests;
                        if (room.roomId === "UNASSIGNED") {
                            roomGuests = row?.guests?.find((g) => g.roomId === room.roomtypeId);
                        } else {
                            roomGuests = row?.guests?.find(
                                (g) => g.roomId === room.roomId || g.roomId === room.roomtypeId
                            );
                        }
                        const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;
                        if (meals?.includes("dinner") && roomGuests) guests.push(roomGuests);
                    });
                });
                return totalGuestsByCategories(guests) || `0 ${t("persons")}`;
            },
        },
        {
            id: "all_inclusive",
            header: t("all_inclusive"),
            displayValue: (row) => {
                const guests = [];
                row?.rooms?.map((room) => {
                    let roomGuests;
                    if (room.roomId === "UNASSIGNED") {
                        roomGuests = row?.guests?.find((g) => g.roomId === room.roomtypeId);
                    } else {
                        roomGuests = row?.guests?.find((g) => g.roomId === room.roomId || g.roomId === room.roomtypeId);
                    }
                    const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;
                    if (meals?.includes("all_inclusive") && roomGuests) guests.push(roomGuests);
                });
                return totalGuestsByCategories(guests) || `0 ${t("persons")}`;
            },
            totalValue: (rows) => {
                let guests = [];
                rows?.forEach((row) => {
                    row?.rooms?.map((room) => {
                        let roomGuests;
                        if (room.roomId === "UNASSIGNED") {
                            roomGuests = row?.guests?.find((g) => g.roomId === room.roomtypeId);
                        } else {
                            roomGuests = row?.guests?.find(
                                (g) => g.roomId === room.roomId || g.roomId === room.roomtypeId
                            );
                        }
                        const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;
                        if (meals?.includes("all_inclusive") && roomGuests) guests.push(roomGuests);
                    });
                });
                return totalGuestsByCategories(guests) || `0 ${t("persons")}`;
            },
        },
    ];

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        if (!params.get("date")) return;
        const response = await fetch({
            operation: "query",
            endpoint: "activeReservations",
            responseData: `_id checkin checkout uuid clientsData{_id firstName lastName} status isBlockedRooms
                rooms{
                    roomId
                    roomtypeId
                    prices{
                        rateId
                        rateData{meals}
                    }
                    roomData{name meals}
                }
                guests{
                    roomId 
                    guests{name number}
                } 
                referer
                refererData{_id firstName lastName}
            `,
            data: { date: params.get("date") },
        });
        if (!response?.activeReservations) return;
        const data = response?.activeReservations?.filter(
            (reservation) => reservation.status !== "CANCELLED" && !reservation.isBlockedRooms
        );

        const newRaportGenerator = new ReportGenerator({
            data: data,
            columns,
            displayTotal: true,
        });
        setReport(newRaportGenerator.generateTable({ title: t("meals"), tableRef: mealsReportRef }));
        setData(data);
    };

    const exportToPdf = () => {
        const doc = new jsPDF();
        const totalPagesExp = "{total_pages_count_string}";

        const headers = mealsReportRef.current?.getTableData()?.[0]?.map((row) => t(row?.header));
        const rows = mealsReportRef.current.getTableData()?.map((row) => row?.map((col) => col?.value));

        doc.autoTable({
            margin: { top: 30 },
            head: [headers],
            body: rows,
            didDrawPage: (data) => {
                // Date
                doc.setFontSize(10);
                doc.setFont("helvetica", "italic");
                doc.text(
                    `${t("time_created")}: ` + DateTime.now().toFormat("dd-LL-yyyy HH:mm"),
                    data.settings.margin.left + 0,
                    28
                );

                // Footer
                var str = "Page " + doc.internal.getNumberOfPages();
                // Total page number plugin only available in jspdf v1.0+
                if (typeof doc.putTotalPages === "function") {
                    str = str + " of " + totalPagesExp;
                }
                doc.setFontSize(10);

                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                doc.text(str, data.settings.margin.left, pageHeight - 10);

                // var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
                var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
                var footerText = "HB";

                doc.setTextColor(100);
                doc.setFontSize(10);
                doc.text(footerText, pageWidth / 2, pageHeight - 10, "center");
            },
        });

        doc.autoTable({
            html: "#refererTable",
        });

        if (typeof doc.putTotalPages === "function") {
            doc.putTotalPages(totalPagesExp);
        }
        doc.save("Meals Report" + DateTime.now().toFormat("dd-LL-yyyy HH:mm"));
    };

    const reportByReferer = useMemo(() => {
        let refererData = {};
        data?.forEach((reservation) => {
            let refererId = reservation.referer;
            if (!refererId) refererId = t("no_referer");
            if (!refererData[refererId]) {
                refererData[refererId] = {
                    name: !!reservation?.refererData?.firstName
                        ? reservation?.refererData?.firstName + " " + (reservation?.refererData?.lastName || "")
                        : refererId,
                    breakfast: [],
                    lunch: [],
                    dinner: [],
                    all_inclusive: [],
                };
            }
            if (reservation?.checkin !== params.get("date")) {
                reservation?.rooms?.map((room) => {
                    let roomGuests;
                    if (room.roomId === "UNASSIGNED") {
                        roomGuests = reservation?.guests?.find((g) => g.roomId === room.roomtypeId);
                    } else {
                        roomGuests = reservation?.guests?.find(
                            (g) => g.roomId === room.roomId || g.roomId === room.roomtypeId
                        );
                    }
                    const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;
                    if (meals?.includes("breakfast") && roomGuests) refererData[refererId].breakfast.push(roomGuests);
                });
            }

            reservation?.rooms?.map((room) => {
                let roomGuests;
                if (room.roomId === "UNASSIGNED") {
                    roomGuests = reservation?.guests?.find((g) => g.roomId === room.roomtypeId);
                } else {
                    roomGuests = reservation?.guests?.find(
                        (g) => g.roomId === room.roomId || g.roomId === room.roomtypeId
                    );
                }
                const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;
                if (meals?.includes("lunch") && roomGuests) refererData[refererId].lunch.push(roomGuests);
            });

            if (reservation?.checkout !== params.get("date")) {
                reservation?.rooms?.map((room) => {
                    let roomGuests;
                    if (room.roomId === "UNASSIGNED") {
                        roomGuests = reservation?.guests?.find((g) => g.roomId === room.roomtypeId);
                    } else {
                        roomGuests = reservation?.guests?.find(
                            (g) => g.roomId === room.roomId || g.roomId === room.roomtypeId
                        );
                    }
                    const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;
                    if (meals?.includes("dinner") && roomGuests) refererData[refererId].dinner.push(roomGuests);
                });
            }

            reservation?.rooms?.map((room) => {
                let roomGuests;
                if (room.roomId === "UNASSIGNED") {
                    roomGuests = reservation?.guests?.find((g) => g.roomId === room.roomtypeId);
                } else {
                    roomGuests = reservation?.guests?.find(
                        (g) => g.roomId === room.roomId || g.roomId === room.roomtypeId
                    );
                }
                const meals = room?.prices[0]?.rateData?.meals || room?.roomData?.meals;
                if (meals?.includes("all_inclusive") && roomGuests)
                    refererData[refererId].all_inclusive.push(roomGuests);
            });
        });
        const refererDataAsArray = Object.values(refererData)?.map((referer) => {
            return {
                referer: referer.name,
                breakfast: totalGuestsByCategories(referer.breakfast) || `0 ${t("persons")}`,
                lunch: totalGuestsByCategories(referer.lunch) || `0 ${t("persons")}`,
                dinner: totalGuestsByCategories(referer.dinner) || `0 ${t("persons")}`,
                all_inclusive: totalGuestsByCategories(referer.all_inclusive) || `0 ${t("persons")}`,
            };
        });
        return refererDataAsArray;
    }, [data]);

    return (
        <div>
            <ReportHeader
                title={t("meals_report")}
                loading={loading}
                onReportRun={() => {
                    loadData();
                }}
            >
                <Stack direction="row" alignItems="center" gap={2}>
                    <DateControl
                        value={params.get("date")}
                        onChange={(e) => setParams({ date: e.target.value })}
                        label={t("date")}
                        fullWidth={false}
                    />
                    {data?.length > 0 && reportByReferer?.length > 0 ? (
                        <Button startIcon={<PictureAsPdfIcon />} onClick={() => exportToPdf()} variant="outlined">
                            {t("print")}
                        </Button>
                    ) : null}
                </Stack>
            </ReportHeader>
            {report}
            <ReservationsByReferertable data={reportByReferer} />
        </div>
    );
};

const ReservationsByReferertable = ({ data = [] }) => {
    const { t } = useTranslation();
    return (
        <Card sx={{ marginTop: 2 }}>
            <CardHeader title={t("reservations_by_referer")} />
            <CardContent style={{ padding: 0 }}>
                <Table id="refererTable">
                    <TableHead style={{ backgroundColor: "#F8F8F8" }}>
                        <TableRow>
                            <TableCell>{t("referer")}</TableCell>
                            <TableCell>{t("breakfast")}</TableCell>
                            <TableCell>{t("lunch")}</TableCell>
                            <TableCell>{t("dinner")}</TableCell>
                            <TableCell>{t("all_inclusive")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((data) => {
                            return (
                                <TableRow>
                                    <TableCell sx={{ fontWeight: "bold" }}>{data?.referer}</TableCell>
                                    <TableCell>{data?.breakfast}</TableCell>
                                    <TableCell>{data?.lunch}</TableCell>
                                    <TableCell>{data?.dinner}</TableCell>
                                    <TableCell>{data?.all_inclusive}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};

export default MealsReport;
