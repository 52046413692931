import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    LinearProgress,
    Typography,
    Menu,
    MenuItem,
    ListItemText,
    ListItemIcon,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SaveIcon from "@mui/icons-material/Save";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React, { useEffect, useMemo, useState } from "react";
import { InputControl, QuantityControl, SwitchControl } from "../../../components/form";
import { Modal } from "../../../components/modal";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { getLoggedUserData, toCurrency } from "../../../utils";
import "./styles.css";
import { isMobileDevice } from "../isMobileDevice";
import SelectDiscountModal from "./SelectDiscountModal";

const emptyListImage =
    "https://res.cloudinary.com/strowberry-code/image/upload/v1564938731/hb/hugo-list-is-empty_ohzzns.png";

const ReceiptPreview = ({
    loading,
    disableActions,
    orderedItems = [],
    courses,
    removeItem,
    editItem,
    onPayment,
    amountInfo,
    onSave,
    onClientClick,
    onAddCourse,
    activeCourse,
    onActiveCourseChange,
    removeCourse,
    pospoint = {},
    disableAddCourse,
    removeSaveButton = false,
    isSmallScreen,
    applyDiscount,
    discount,
}) => {
    const [editItemData, setEditItemData] = useState(null);
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const tableId = searchParams.get("table");
    const [anchorMenu, setAnchorMenu] = useState(null);

    const isMobile = useMemo(() => {
        return isMobileDevice();
    }, []);

    const [allowPrintingForMobile, setAllowPrintingForMobile] = useState(isMobile);

    const tableName = useMemo(() => {
        return pospoint?.tables?.find((t) => t._id === tableId)?.tableName || "";
    }, [pospoint]);
    const { currency } = pospoint?.currencyData || {};

    const renderItem = (item) => {
        return (
            <OrderedItemCard
                item={item}
                currency={currency}
                onEditItem={() => setEditItemData(item)}
                onRemoveItem={() => {
                    removeItem && removeItem(item.itemId);
                }}
            />
        );
    };

    const handleMenuClose = () => {
        setAnchorMenu(null);
    };

    return (
        <div
            style={{
                width: "100%",
                height: "calc(100vh - 100px)",
                paddingLeft: "10px",
                paddingRight: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 5,
                position: "relative",
            }}
        >
            <>
                {loading && <LinearProgress color="primary" />}
                <CardHeader
                    style={{ padding: "5px 0" }}
                    title={tableId ? t("table") + " " + tableName : ""}
                    subheader="walkin"
                    avatar={
                        <>
                            <Button onClick={() => onClientClick()} variant="outlined" sx={{ marginRight: "10px" }}>
                                <PersonSearchIcon color="primary" />
                            </Button>
                            <SelectDiscountModal
                                applyDiscount={(discount) => {
                                    applyDiscount && applyDiscount(discount);
                                }}
                                discount={discount}
                                pospoint={pospoint}
                            />
                        </>
                    }
                    titleTypographyProps={{ variant: "h6" }}
                    action={
                        <>
                            <IconButton
                                onClick={(event) => {
                                    setAnchorMenu(event.currentTarget);
                                }}
                            >
                                <MoreVertIcon color="primary" />
                            </IconButton>
                            <Menu
                                id="actions-menu"
                                anchorEl={anchorMenu}
                                open={Boolean(anchorMenu)}
                                onClose={handleMenuClose}
                            >
                                {!!pospoint?.liveOrderPrintingEnabled && (
                                    <MenuItem>
                                        <SwitchControl
                                            value={allowPrintingForMobile}
                                            onChange={(e) => {
                                                localStorage.setItem("isMobileDevice", e.target.checked);
                                                setAllowPrintingForMobile(e.target.checked);
                                            }}
                                            label={t("mobile")}
                                        />
                                    </MenuItem>
                                )}
                                {/* <MenuItem>
                                    <ListItemText disabled>{t("split")}</ListItemText>
                                </MenuItem>
                                <MenuItem>
                                    <ListItemText disabled>{t("transfer")}</ListItemText>
                                </MenuItem> */}
                            </Menu>
                        </>
                    }
                />
            </>
            <div
                style={{
                    overflowY: "scroll",
                    display: "flex",
                    flexDirection: "column-reverse",
                    justifyContent: "space-between",
                    flexGrow: 1,
                }}
            >
                <div></div>
                <div>
                    {!!courses ? (
                        courses.map((course, courseIndex) => {
                            return (
                                <Accordion
                                    variant="outlined"
                                    onChange={() => onActiveCourseChange(course)}
                                    expanded={activeCourse === course}
                                    sx={{ marginTop: "10px" }}
                                >
                                    <AccordionSummary
                                        expandIcon={
                                            <IconButton
                                                //color="secondary"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    removeCourse(course);
                                                }}
                                            >
                                                <HighlightOffIcon />
                                            </IconButton>
                                        }
                                    >
                                        <Typography>
                                            {t("course")} {courseIndex}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ backgroundColor: "transparent" }}>
                                        {orderedItems
                                            .filter((item) => item.courseId === course)
                                            .map((item) => (
                                                <OrderedItemCard
                                                    item={item}
                                                    currency={currency}
                                                    onEditItem={() => setEditItemData(item)}
                                                    onRemoveItem={() => {
                                                        removeItem && removeItem(item.itemId);
                                                    }}
                                                />
                                            ))}
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })
                    ) : orderedItems.length === 0 ? (
                        <div style={{ textAlign: "center", width: "100%" }}>
                            <img width={"100%"} height="auto" className="empty-card" alt="empty" src={emptyListImage} />
                        </div>
                    ) : (
                        orderedItems.map((item) => (
                            <OrderedItemCard
                                item={item}
                                currency={currency}
                                onEditItem={() => setEditItemData(item)}
                                onRemoveItem={() => {
                                    removeItem && removeItem(item.itemId);
                                }}
                            />
                        ))
                    )}
                </div>
            </div>
            <div>
                <Grid container spacing={1}>
                    {amountInfo?.discount !== 0 && (
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader
                                    action={
                                        <IconButton
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                applyDiscount({});
                                            }}
                                        >
                                            <HighlightOffIcon />
                                        </IconButton>
                                    }
                                    title={`${t("discount")}: ${toCurrency(amountInfo.discount, currency)}`}
                                    titleTypographyProps={{ variant: "body" }}
                                />
                            </Card>
                        </Grid>
                    )}
                    {!removeSaveButton && (
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant="outlined"
                                size="large"
                                startIcon={<SaveIcon />}
                                onClick={() => onSave()}
                                disabled={disableActions}
                            >
                                {t("save_order")}
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        {pospoint?.courseManagement && (
                            <Button
                                disabled={disableAddCourse}
                                onClick={() => onAddCourse()}
                                fullWidth
                                variant="outlined"
                                startIcon={<RoomServiceIcon />}
                                size="large"
                            >
                                {t("add_course")}
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            style={{ fontSize: 20, backgroundColor: "#1EB280", color: "#ffffff" }}
                            fullWidth
                            variant="contained"
                            size="big"
                            disabled={orderedItems.length === 0 || disableActions}
                            onClick={onPayment}
                        >
                            {t("pay")} {toCurrency(amountInfo.totalPrice, currency)}
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <OrderedItemModal
                open={editItemData !== null}
                onClose={() => setEditItemData(null)}
                onSave={(data) => {
                    editItem(data);
                    setEditItemData(null);
                }}
                itemData={editItemData}
            />
        </div>
    );
};

export default ReceiptPreview;

const OrderedItemModal = ({ open, onClose, itemData = {}, onSave }) => {
    const [values, setValues] = useState({});
    const { t } = useTranslation();
    useEffect(() => {
        if (!!itemData) setValues(itemData);
    }, [itemData]);
    const disablePriceChange = useMemo(() => {
        const { role } = getLoggedUserData() || {};
        if (role?.permissions?.orders?.change_price_in_sell) return false;
        return true;
    }, []);
    return (
        <Modal
            open={open}
            onClose={() => onSave(values)}
            titlelabel={t("edit")}
            maxWidth="xs"
            FormProps={{ values, onValuesChange: setValues }}
            noText="none"
            onSave={() => onSave(values)}
        >
            <Typography>{t("quantity")}</Typography>
            <QuantityControl
                min={0}
                value={values.quantity}
                onChange={(quantity) => setValues({ ...values, quantity })}
            />
            {!disablePriceChange && <InputControl name="price" label={t("price")} />}
            <InputControl multiline minRows={3} name="note" label={t("note")} />
        </Modal>
    );
};

const OrderedItemCard = ({ onEditItem, item, currency, onRemoveItem }) => {
    const itemTotalPrice = item.price * item.quantity;

    return (
        <Card
            key={`item_card_${item.itemId}_${item.quantity}`}
            className="ordered-item-card-animation"
            onClick={onEditItem}
            style={{ marginTop: "5px" }}
        >
            <CardHeader
                avatar={parseFloat(item.quantity).toFixed(2)}
                style={{ paddingTop: "5px", paddingBottom: "0px", cursor: "pointer" }}
                title={`${item.name}`}
                titleTypographyProps={{ variant: "body" }}
                subheader={isNaN(itemTotalPrice) ? "" : toCurrency(itemTotalPrice, currency)}
                subheaderTypographyProps={{ variant: "subtitle2" }}
                action={
                    <IconButton
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onRemoveItem();
                        }}
                    >
                        <HighlightOffIcon />
                    </IconButton>
                }
            />
            <CardContent
                sx={{
                    paddingTop: "0px",
                    marginTop: "0px",
                    paddingBottom: "0px!important",
                }}
            >
                <Typography variant="body2">{item?.note}</Typography>
            </CardContent>
        </Card>
    );
};
