import {
    Button,
    IconButton,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    LinearProgress,
    Typography,
    Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, Link } from "react-router-dom";
import { useApi } from "../../components/hooks";
import { WaiterModal } from "../login";
import { ProtectedAction, getLoggedUserData } from "../../utils";
import { POSPOINTS } from "../../utils/DataTypes";

const PospointList = () => {
    const [pospoints, setPospoints] = useState([]);
    const [waiterRevenue, setWaiterRevenue] = useState({});
    const [selectedPos, setSelectedPos] = useState({});
    const { loading, fetch } = useApi();
    const [openModal, setOpenModal] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await fetch({
            operation: "query",

            multipleEndpoints: [
                {
                    endpoint: "myShift",
                    responseData: `_id starting{timestamp} closing{timestamp} lastClosed`,
                },
                {
                    endpoint: "pospoints",
                    responseData: POSPOINTS,
                },
            ],
        });
        if (response?.pospoints) setPospoints(response.pospoints);
        if (response?.myShift) setWaiterRevenue(response.myShift);
    };

    const onDeleteHandler = async (_id) => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "deletePosPoint",
            data: { _id },
        });
        if (response?.deletePosPoint === "success") {
            setPospoints(pospoints.filter((pos) => pos._id !== _id));
            enqueueSnackbar(t("deleted_with_success"), { variant: "info" });
        }
    };

    const isUserShiftOpen = () => {
        if (waiterRevenue?.closing || !waiterRevenue?._id) return false;
        else return true;
    };
    const startWaiterShift = async () => {
        const response = await fetch({
            operation: "mutation",
            endpoint: "openShift",
            responseData: `_id`,
            data: { userId: getLoggedUserData()?._id },
        });
        if (response?.openShift?._id) {
            enqueueSnackbar(t("user_shift_started"), { variant: "success" });
            if (!!selectedPos.hasTables) {
                navigate(`/pos/tables?pos=${selectedPos?._id?.split("pospoints_")[1]}`);
                return;
            }
            navigate(`/pos/sell?pos=${selectedPos?._id?.split("pospoints_")[1]}`);
        }
    };
    const addRecordPosPoint = (data) => {
        if (pospoints?.some((r) => r._id === data._id)) {
            setPospoints(
                pospoints?.map((r) => {
                    if (r._id === data?._id) return data;
                    return r;
                })
            );
            return;
        }
        setPospoints([...pospoints, data]);
    };

    const handlePosClick = (pos) => {
        if (!pos.isActive) return;

        const { role } = getLoggedUserData() || {};
        if (role?.permissions?.users?.waiter_login) {
            navigate(`/pos/sell-login?pos=${pos?._id?.split("pospoints_")[1]}`);
            return;
        }

        if (!isUserShiftOpen()) {
            setOpenModal(true);
            setSelectedPos(pos);
            return;
        }
        if (!!pos.hasTables) {
            navigate(`/pos/tables?pos=${pos?._id?.split("pospoints_")[1]}`);
            return;
        }
        navigate(`/pos/sell?pos=${pos?._id?.split("pospoints_")[1]}`);
    };

    return (
        <div>
            {loading && <LinearProgress color="primary" />}
            <Grid container style={{ margin: "20px 0" }}>
                <ProtectedAction permission={{ posplaces: { list: true } }}>
                    <Button
                        component={Link}
                        to="/pos/posplaces"
                        sx={{ borderRadius: "25px", marginLeft: "20px" }}
                        variant="contained"
                        color="link"
                    >
                        {t("posplaces")}
                    </Button>
                    <Button
                        component={Link}
                        to="/pos/confirm-orders"
                        sx={{ borderRadius: "25px", marginLeft: "20px" }}
                        variant="contained"
                        color="link"
                    >
                        {t("third_party_orders")}
                    </Button>
                </ProtectedAction>
            </Grid>
            <Grid container spacing={2} alignItems="center">
                {pospoints.map((pos) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <PosCard
                            t={t}
                            {...pos}
                            onEdit={() => navigate(`${pos?._id?.split("pospoints_")[1]}`)}
                            onClick={() => {
                                handlePosClick(pos);
                            }}
                            onDelete={() => onDeleteHandler(pos._id)}
                        />
                    </Grid>
                ))}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <ProtectedAction permission={{ pospoints: { create: true } }}>
                        <Button onClick={() => navigate("create")}>
                            <AddIcon style={{ fontSize: 200 }} />
                        </Button>
                    </ProtectedAction>
                </Grid>
            </Grid>
            <WaiterModal
                open={openModal}
                t={t}
                onClose={() => {
                    setOpenModal(false);
                    if (!!selectedPos.hasTables) {
                        navigate(`/pos/tables?pos=${selectedPos?._id?.split("pospoints_")[1]}`);
                        return;
                    }
                    navigate(`/pos/sell?pos=${selectedPos?._id?.split("pospoints_")[1]}`);
                }}
                onSave={() => {
                    setOpenModal(false);
                    startWaiterShift();
                }}
            />
            <Outlet context={{ addRecordPosPoint }} />
        </div>
    );
};

const defaultImage = "https://res.cloudinary.com/strowberry-code/image/upload/v1686059969/hb/boba-payment_dmlvzo.png";

const PosCard = ({ t, name, isActive, onEdit, onClick, onDelete, onSellLogin }) => {
    return (
        <Card>
            <CardMedia
                style={{ cursor: !!isActive ? "pointer" : "not-allowed", width: "auto", margin: "0 auto" }}
                onClick={onClick}
                height="200"
                component="img"
                image={defaultImage}
            />
            <CardContent style={{ cursor: !!isActive ? "pointer" : "not-allowed" }} onClick={onClick}>
                <Typography variant="h5" component="h2">
                    {name}
                </Typography>
            </CardContent>
            <CardActions>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <ProtectedAction permission={{ pospoints: { edit: true } }}>
                            <Button onClick={onEdit} variant="contained" fullWidth startIcon={<EditIcon />}>
                                {t("edit")}
                            </Button>
                        </ProtectedAction>
                    </Grid>
                    <Grid item xs={6}>
                        <ProtectedAction permission={{ pospoints: { delete: true } }}>
                            <Button onClick={onDelete} variant="outlined" fullWidth startIcon={<DeleteIcon />}>
                                {t("delete")}
                            </Button>
                        </ProtectedAction>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
};

export default PospointList;
